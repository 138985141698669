// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  FIELD_TYPES,
  ICustomTableProps,
  IDropdownMenuItemProps,
  IInputConfigProps,
} from "../../../components/src/CommonType.web";
import {
  availableTeachersListMock,
  assignCatalogToTeacherTableHeaderMock,
} from "../assets/tenantCourseManagementMockdata";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End


// Customizable Area Start
const configJSON = require("./config.js");

export const assignCatalogTabsList = [
  {
    id: 0,
    label: "Available Teachers",
    value: "available_teachers",
  },
  {
    id: 1,
    label: "Assigned Teachers",
    value: "added_teachers",
  },
];
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  open: boolean;
  onClose: any;
  onConfirm: any;
  getTeacherIds: any;
  assignData?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  assignCatalogTabIndex: number;
  catalogTitleList: Array<any>;
  selectedCatalogTitleListDDValue: any;
  catalogTitleListDDMenuItems: Array<IDropdownMenuItemProps>;
  tableHeaderColumns: Array<ICustomTableProps>;
  tableBodyData: Array<ICustomTableProps>;
  assignedTeachersList: Array<ICustomTableProps>;
  inputConfigs: Array<IInputConfigProps>;
  paginationLimit: number;
  availableTeacherPagination: any;
  availableTeachersListData: Array<any>;
  addedTeacherPagination: any;
  addedTeachers: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TenantAssignCatalogToTeacherModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  availableTeachersListData(availableTeachersListData: any, arg1: string) {
    throw new Error("Method not implemented.");
  }
  // Customizable Area Start
  apiGetTeachersList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      assignCatalogTabIndex: 0,
      catalogTitleList: [],
      catalogTitleListDDMenuItems: [],
      selectedCatalogTitleListDDValue: "",
      tableHeaderColumns: [],
      tableBodyData: [],
      assignedTeachersList: [],
      inputConfigs: [],
      paginationLimit: 10,
      availableTeacherPagination: {
        per_page: 10,
        current_page: 1,
        total_count: 0,
      },
      availableTeachersListData: [],
      addedTeacherPagination: {
        per_page: 10,
        current_page: 1,
        total_count: 0,
      },
      addedTeachers: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null && apiRequestCallId === this.apiGetTeachersList) {
        this.handleGetTeachersListResponse(
          responseJson,
          errorResponse
        );
      }
    }
  }
  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleAddCatalogTitleDropdownData();
    this.handleGetTeachersListApi({ user_type: "Teacher" }, {});
    this.handleSetTeachersListTableData();
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangeTeacherTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ assignCatalogTabIndex: newValue }, () => {
      this.handleSetTeachersListTableData();
    });
  };

  renderCatalogItem = (selected: any) => {
    const { catalogTitleListDDMenuItems } = this.state;
    if (!selected || selected.length === 0) {
      return `Select Catalog`;
    }
    const selectedItem = catalogTitleListDDMenuItems.find(
      (item: IDropdownMenuItemProps) => item.value === selected
    );
    const label = selectedItem?.label;
    return label;
  };

  handleCatalogDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    const { inputConfigs } = this.state;
    const updatedInputConfig = inputConfigs.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
          inputConfigs: updatedInputConfig,
        };
      });
    }
  };

  handleSetTeachersListTableData = () => {
    const { assignCatalogTabIndex } = this.state;
    const tenantTeacherMngTabCategory =
      assignCatalogTabsList[assignCatalogTabIndex].value;
    if (tenantTeacherMngTabCategory === "available_teachers") {
      const tableBodyData: Array<any> = availableTeachersListMock.map(
        (item: any) => {
          return {
            ...item,
            actionButton: {
              btnStyle: {
                height: "50px",
                padding: "10px",
                fontSize: "16px",
                backgroundColor: "#00c996",
                color: "#ffffff",
                borderRadius: "10px",
                width: "160px",
                textAlign: "center",
                fontWeight: 600,
              },
              btnText: "Assign",
              btnId: "tenant-assign-catalog-add",
              isDisabled: false,
              btnVariant: "contained",
              btnType: "button",
              handleAction: () => this.handleAddTeacher(item.id),
            },
          };
        }
      );
      this.setState({
        tableHeaderColumns: assignCatalogToTeacherTableHeaderMock,
        tableBodyData,
        selectedCatalogTitleListDDValue: "",
      });
    } else {
      const tableBodyData: Array<any> = availableTeachersListMock.map(
        (item: any) => {
          return {
            ...item,
            actionButton: {
              btnText: "Remove",
              btnStyle: {
                height: "50px",
                border: "1px solid #FF0000",
                width: "160px",
                color: "#FF0000",
                padding: "10px",
                borderRadius: "10px",
                fontSize: "16px",
                fontWeight: 600,
                backgroundColor: "inherit",
                textAlign: "center",
              },
              btnId: "tenant-assign-catalog-remove",
              isDisabled: false,
              btnVariant: "outlined",
              btnType: "button",
              handleAction: () => this.handleAddTeacher(item.id),
            },
          };
        }
      );
      this.setState({
        tableBodyData,
        tableHeaderColumns: assignCatalogToTeacherTableHeaderMock,
        selectedCatalogTitleListDDValue: "",
      });
    }
  };

  handleSearchTeacher = () => {
    console.log("clicked search Teacher");
  };

  handleGetTeachersListApi = async (query: any, pagination: any) => {
    this.showModalLoader();
    const baseUrl = configJSON.getUsersList + `?per_page=${this.state.paginationLimit}`;

    const token = await getStorageData("token");
    const header = {
      token,
    };

    let requestUrl = baseUrl;

    if (query.user_type) {
      requestUrl = requestUrl + `&type=${query.user_type}`;
    }

    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetTeachersList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeachersListResponse = (response: any, errorResponse: any) => {
    if (response?.data) {
      let teachersData = response.data.map(
        (item: any) => {
          return this.handleSetTeacherItem(item);
        }
      );

      const { addedTeachers } = this.state;
      teachersData.forEach((teacher: any) => {
        const check = addedTeachers.find((item: any) => item.id === teacher.id);
        if (check) {
          teacher.actionButton = this.setRemoveActionButton(teacher.id);
        }
      });

      const pagination = response?.meta?.pagination;
      
      this.setState({
        availableTeachersListData: teachersData,
        availableTeacherPagination: pagination,
      }, () => {
        this.handleSetTeachersListTableData();
      });
    } else {
      displayApiErrorMsg(response?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  showModalLoader = () => {
    this.setState({ loading: true });
  }

  hideModalLoader = () => {
    this.setState({ loading: false });
  }

  setRemoveActionButton = (id: number) => {
    const removeButton = {
      btnStyle: {
        height: "50px",
        width: "160px",
        backgroundColor: "inherit",
        color: "#FF0000",
        border: "1px solid #FF0000",
        padding: "10px",
        borderRadius: "10px",
        fontSize: "16px",
        fontWeight: 600,
        textAlign: "center",
      },
      btnText: "Remove",
      btnId: "tenant-teacher-mng-step-2-availableteachers-remove",
      isDisabled: false,
      btnVariant: "outlined",
      btnType: "button",
      handleAction: () => this.handleRemoveTeacher(id),
    }
    return removeButton;
  }

  setAddActionButton = (id: number) => {
    const addButton = {
      btnStyle: {
        width: "160px",
        height: "50px",
        padding: "10px",
        backgroundColor: "#00c996",
        color: "#ffffff",
        borderRadius: "10px",
        fontSize: "16px",
        fontWeight: 600,
        textAlign: "center",
      },
      btnText: "Add",
      btnId: "tenant-teacher-mng-step-2-availableteachers-add",
      isDisabled: false,
      btnVariant: "contained",
      btnType: "button",
      handleAction: () => this.handleAddTeacher(id),
    }
    return addButton;
  }

  handleAddTeacher = (id?: any) => {
    const { availableTeachersListData, addedTeacherPagination } = this.state;
    let addedTeachers: any = this.state.addedTeachers;
    let teacherToAdd: any = availableTeachersListData.find((item) => item.id === id);
    if (teacherToAdd) {
      teacherToAdd.actionButton = this.setRemoveActionButton(id);
      addedTeachers.push(teacherToAdd);
    }

    let pagination: any = addedTeacherPagination;
    pagination.total_count += 1;
    pagination.total_pages = Math.ceil(pagination.total_count / pagination.per_page);

    this.setState({ addedTeachers: addedTeachers, addedTeacherPagination: pagination });
  };

  handleRemoveTeacher = (id?: any) => {
    const { addedTeacherPagination } = this.state;
    let addedTeachers: any = this.state.addedTeachers;
    let teachersAfterRemoved = addedTeachers.filter((item: any) => item.id !== id);
    let teacherToRemove: any = addedTeachers.find((item: any) => item.id === id);
    if (teacherToRemove) {
      teacherToRemove.actionButton = this.setAddActionButton(id);
    }

    let pagination: any = addedTeacherPagination;
    pagination.total_count -= 1;
    pagination.total_pages = Math.ceil(pagination.total_count / pagination.per_page);

    this.setState({ addedTeachers: teachersAfterRemoved, addedTeacherPagination: pagination });
  };

  handleSetTeacherItem = (item: any) => {
    const teacher = item.attributes;
    const { assignCatalogTabIndex } = this.state;
    return {
      id: item.id,
      teacher: {
        name: `${teacher.first_name} ${teacher.last_name}`,
        icon: teacher.icon ? teacher.icon : '',
        boxWidth: "32px",
        boxHeight: "32px",
      },
      curriculum: this.props.assignData?.curriculumName,
      catalog: this.props.assignData?.catalogName,
      actionButton: assignCatalogTabIndex === 0 ? this.setAddActionButton(item.id) : this.setRemoveActionButton(item.id),
    }
  }

  addAssignTeacherList = () => {
    const { addedTeachers } = this.state;
    if (addedTeachers.length > 0) {
      const teacherIds = this.state.addedTeachers.map((items) => items.id)
      console.log(teacherIds, "add addAssignTeacherList")
      this.props.getTeacherIds(teacherIds)
    } else {
      toast.warning("Please add a Teacher.");
    }
  }

  handleAvailableTeacherPageChange = (page: number) => {
    this.handleGetTeachersListApi({ user_type: "Teacher" }, { page: page });
  }

  handleAddedTeacherPageChange = (page: number) => {
    console.log('page: ', page);
  }

  handleAddCatalogTitleDropdownData = () => {
    const { assignData } = this.props;
    if (assignData) {
      this.setState({
        catalogTitleListDDMenuItems: [{
          id: 1,
          label: assignData?.catalogName,
          value: 1,
        }]
      }, () => {
        this.setInputConfigs();
      });
    }
  }

  setInputConfigs = () => {
    this.setState({
      inputConfigs: [{
        fieldType: FIELD_TYPES.SELECT,
        id: "tenant-assign-catalog-to-teacher-catalog-menu",
        name: "selectedCatalogTitleListDDValue",
        placeHolderText: "Select Catalog",
        fieldName: "catalogTitleListDDMenuItems",
        value: 1,
        menuItems: this.state.catalogTitleListDDMenuItems,
        label: "Catalog Title",
      }]
    });
  }
  // Customizable Area End
}
