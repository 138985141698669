// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import {
  displayApiErrorMsg,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import { ITableActionBtnConfigProps } from "../../../components/src/CommonType.web";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}

export interface IDDConfigProps {
  id: any;
  name: string;
  fieldName: string;
  value: any;
  menuItems: any;
  label: string;
}

export interface IUserMngTableProps {
  id: string;
  label: string;
  type: string;
  columnId: number;
  width?: string;
  status?: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  tenantUserMngTabCategory: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  nameDDMenuItems: Array<IDropdownItems>;
  selectedNameDDValue: string;
  gradeLevelList: Array<any>;
  gradeLevelDDMenuItems: Array<IDropdownItems>;
  selectedGradeLevelDDValue: any;
  curriculumList: Array<any>;
  curriculumDDMenuItems: Array<IDropdownItems>;
  selectedCurriculumDDValue: any;
  subjectHandledList: Array<any>;
  subjectHandledDDMenuItems: Array<IDropdownItems>;
  selectedSubjectHandledDDValue: any;
  schoolYearList: Array<any>;
  schoolYearDDMenuItems: Array<IDropdownItems>;
  selectedSchoolYearDDValue: any;
  rolesList: Array<any>;
  rolesDDMenuItems: Array<IDropdownItems>;
  selectedRolesDDValue: any;
  statusList: Array<any>;
  statusDDMenuItems: Array<IDropdownItems>;
  selectedStatusDDValue: any;
  studentParentList: Array<any>;
  teacherList: Array<any>;
  othersList: Array<any>;
  tableHeaderColumns: Array<IUserMngTableProps>;
  studentTableHeaderColumns: Array<IUserMngTableProps>;
  teacherTableHeaderColumns: Array<IUserMngTableProps>;
  otherTableHeaderColumns: Array<IUserMngTableProps>;
  tableBodyData: Array<IUserMngTableProps>;
  dropdownConfig: Array<IDDConfigProps>;
  studentParentDDConfig: Array<IDDConfigProps>;
  teacherDDConfig: Array<IDDConfigProps>;
  otherDDConfig: Array<IDDConfigProps>;
  isFilter: boolean;
  currentPage: number;
  token: string;
  totalStudentParentRecords: number;
  currentStudentParentListingPage: number;
  userManageTableActionButtonConfig: Array<ITableActionBtnConfigProps>;
  perPageRecords: number;
  gradeSubjectId: string;
  userMngTableProps: string;
  userViewId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class UserManagementTabComponentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStudentParaentAPIRequestId: string = "";
  getStudentParaentNames: string = "";
  apiGetGradesListingId: string = "";
  apiGetCurriculumListing: string = "";
  apiGetSchoolYearData: string = "";
  apiGetRoleNamesData: string = "";
  handleSubjectsListAPIRequestId: string = "";
  updatePatchStatusApiCallId: string = "";
  getUserDetailId: string = "";
  apiGetUserMngCustomFieldId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      totalStudentParentRecords: 0,
      currentStudentParentListingPage: 1,
      perPageRecords: 10,
      nameDDMenuItems: [],
      selectedNameDDValue: "",
      gradeLevelList: [],
      gradeLevelDDMenuItems: [],
      selectedGradeLevelDDValue: "",
      curriculumList: [],
      curriculumDDMenuItems: [],
      selectedCurriculumDDValue: "",
      subjectHandledList: [],
      subjectHandledDDMenuItems: [],
      selectedSubjectHandledDDValue: "",
      schoolYearList: [],
      schoolYearDDMenuItems: [],
      selectedSchoolYearDDValue: "",
      rolesList: [],
      rolesDDMenuItems: [],
      selectedRolesDDValue: "",
      statusList: [],
      statusDDMenuItems: [
        { id: "draft", label: "Draft", value: "draft" },
        { id: "inactive", label: "Inactive", value: "inactive" },
        { id: "active", label: "Active", value: "active" },
      ],
      selectedStatusDDValue: "",
      studentParentList: [],
      teacherList: [],
      othersList: [],
      tableHeaderColumns: [],
      studentTableHeaderColumns: [
        {
          id: "fullName",
          label: "Name",
          type: tableColumnTypes.TEXT,
          columnId: 1,
        },
        {
          columnId: 2,
          id: "grade",
          type: tableColumnTypes.NUMBER,
          label: "Grade Level",
        },
        {
          id: "role",
          label: "Role",
          columnId: 3,
          type: tableColumnTypes.NUMBER_LESS_WIDTH,
          width: "150px",
        },
        {
          columnId: 4,
          id: "curriculum",
          type: tableColumnTypes.NUMBER,
          label: "Curriculum",
        },
        {
          columnId: 8,
          id: "schoolYear",
          type: tableColumnTypes.TEXT,
          label: "School Year Title",
        },
        {
          columnId: 9,
          id: "status",
          type: tableColumnTypes.CHIP,
          label: "Status",
        },
      ],
      teacherTableHeaderColumns: [
        {
          id: "fullName",
          columnId: 1,
          label: "Name",
          type: tableColumnTypes.TEXT,
        },
        {
          id: "grades",
          columnId: 2,
          label: "Grade Level",
          type: tableColumnTypes.NUMBER,
        },
        {
          id: "role",
          columnId: 3,
          label: "Role",
          type: tableColumnTypes.NUMBER_LESS_WIDTH,
        },
        {
          id: "curriculum",
          columnId: 4,
          label: "Curriculum",
          type: tableColumnTypes.NUMBER,
        },
        {
          id: "subjectHandled",
          columnId: 5,
          label: "Subject Handled",
          type: tableColumnTypes.NUMBER,
        },
        {
          id: "schoolYear",
          columnId: 9,
          label: "School Year Title",
          type: tableColumnTypes.TEXT,
        },
        {
          id: "status",
          columnId: 10,
          label: "Status",
          type: tableColumnTypes.CHIP,
        },
      ],
      otherTableHeaderColumns: [
        {
          id: "fullName",
          columnId: 1,
          label: "Name",
          type: tableColumnTypes.TEXT,
        },
        {
          id: "role",
          columnId: 3,
          label: "Role",
          type: tableColumnTypes.NUMBER,
        },
        {
          id: "status",
          columnId: 5,
          label: "Status",
          type: tableColumnTypes.CHIP,
        },
      ],
      tableBodyData: [],
      dropdownConfig: [],
      studentParentDDConfig: [
        {
          id: "tenant-user-mng-student-username",
          fieldName: "nameDDMenuItems",
          value: "",
          name: "selectedNameDDValue",
          label: "Name",
          menuItems: [],
        },
        {
          fieldName: "gradeLevelDDMenuItems",
          id: "tenant-user-mng-student-gradeLevel",
          name: "selectedGradeLevelDDValue",
          menuItems: [],
          value: "",
          label: "Grade Level",
        },
        {
          fieldName: "rolesDDMenuItems",
          id: "tenant-user-mng-student-roles",
          name: "selectedRolesDDValue",
          menuItems: [],
          value: "",
          label: "Role",
        },
        {
          fieldName: "curriculumDDMenuItems",
          id: "tenant-user-mng-student-curriculum",
          name: "selectedCurriculumDDValue",
          label: "Curriculum",
          menuItems: [],
          value: "",
        },
        {
          name: "selectedSchoolYearDDValue",
          fieldName: "schoolYearDDMenuItems",
          id: "tenant-user-mng-student-schoolYear",
          menuItems: [],
          value: "",
          label: "School Year Title",
        },
      ],
      teacherDDConfig: [
        {
          name: "selectedNameDDValue",
          fieldName: "nameDDMenuItems",
          id: "tenant-user-mng-teacher-username",
          value: "",
          label: "Name",
          menuItems: [],
        },
        {
          fieldName: "gradeLevelDDMenuItems",
          id: "tenant-user-mng-teacher-gradeLevel",
          name: "selectedGradeLevelDDValue",
          menuItems: [],
          value: "",
          label: "Grade Level Handled",
        },
        {
          id: "tenant-user-mng-teacher-curriculum",
          fieldName: "curriculumDDMenuItems",
          name: "selectedCurriculumDDValue",
          value: "",
          label: "Curriculum",
          menuItems: [],
        },
        {
          id: "tenant-user-mng-teacher-subject",
          fieldName: "subjectHandledDDMenuItems",
          value: "",
          name: "selectedSubjectHandledDDValue",
          menuItems: [],
          label: "Subject Handled",
        },
        {
          fieldName: "rolesDDMenuItems",
          id: "tenant-user-mng-teacher-roles",
          name: "selectedRolesDDValue",
          menuItems: [],
          label: "Role",
          value: "",
        },
        {
          id: "tenant-user-mng-teacher-schoolYear",
          name: "selectedSchoolYearDDValue",
          value: "",
          fieldName: "schoolYearDDMenuItems",
          menuItems: [],
          label: "School Year Title",
        },
      ],
      otherDDConfig: [
        {
          id: "tenant-user-mng-others-username",
          name: "selectedNameDDValue",
          fieldName: "nameDDMenuItems",
          value: "",
          menuItems: [],
          label: "Name",
        },
        {
          id: "tenant-user-mng-others-roles",
          name: "selectedRolesDDValue",
          fieldName: "rolesDDMenuItems",
          value: "",
          menuItems: [],
          label: "Role",
        },
        {
          id: "tenant-user-mng-others-status",
          name: "selectedStatusDDValue",
          fieldName: "statusDDMenuItems",
          value: "",
          menuItems: [],
          label: "Status",
        },
      ],
      userManageTableActionButtonConfig: [
        {
          label: "active",
          action: (value: string) => this.updateUserStatus(value),
        },
        {
          label: "view",
          action: (value: string) => this.userViewFunct(value),
        },
      ],
      isFilter: false,
      currentPage: 1,
      gradeSubjectId: "0",
      token: "",
      userMngTableProps: "",
      userViewId: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetUsersCreateToken();
    this.handleGetCustomFieldsList();
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetUserMngCustomFieldDataResponse = (responseJson: any) => {
    if (responseJson?.data) {
      if (responseJson.data?.length) {
        const curriculumCustomFieldHeaders = responseJson.data.map(
          (
            item: { attributes: { id: number; label: string } },
            index: number
          ) => {
            return {
              columnId: `${item?.attributes?.id}-${item?.attributes?.label}`,
              id: item?.attributes?.label,
              label: item?.attributes?.label,
              type: tableColumnTypes.TEXT,
            };
          }
        );
        this.setState({
          studentTableHeaderColumns: [
            ...this.state.studentTableHeaderColumns,
            ...curriculumCustomFieldHeaders,
          ],
          teacherTableHeaderColumns: [
            ...this.state.teacherTableHeaderColumns,
            ...curriculumCustomFieldHeaders,
          ],
          otherTableHeaderColumns: [
            ...this.state.otherTableHeaderColumns,
            ...curriculumCustomFieldHeaders,
          ],
        });
      }
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.handleSetUserListTableData();
  };

  handleGetCustomFieldsList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserMngCustomFieldId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customFieldEndpoint}?model_type=User_management`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetUsersCreateToken = async () => {
    const token = await getStorageData("token");
    if (token) {
      this.setState({ token: token });
      this.fetchStudentParentTableData(false);
      this.getGrades(token);
      this.getCurriculumList(token);
      this.handleGetSchoolYearList(token);
      this.handleGetRoleNamesList(token);
      this.getNamesStudentParentDropdown();
    }
  };

  setDDValue = (selected: any, fieldName: string) => {
    const {
      nameDDMenuItems,
      gradeLevelDDMenuItems,
      curriculumDDMenuItems,
      rolesDDMenuItems,
      statusDDMenuItems,
      subjectHandledDDMenuItems,
      schoolYearDDMenuItems,
    } = this.state;

    let label: any = "";

    if (fieldName === "nameDDMenuItems") {
      const selectedItem = nameDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "gradeLevelDDMenuItems") {
      const selectedItem = gradeLevelDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "rolesDDMenuItems") {
      const selectedItem = rolesDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "statusDDMenuItems") {
      const selectedItem = statusDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "schoolYearDDMenuItems") {
      const selectedItem = schoolYearDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "curriculumDDMenuItems") {
      const selectedItem = curriculumDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else {
      const selectedItem = subjectHandledDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    }
    return label;
  };

  renderDDMenuItem = (selected: any, fieldName: string, emptyText: string) => {
    if (!selected || selected.length === 0) {
      return `Select ${emptyText}`;
    }
    return this.setDDValue(selected, fieldName);
  };

  handleSetUserListTableData = () => {
    const { tenantUserMngTabCategory } = this.props;
    const {
      studentParentList,
      teacherList,
      othersList,
      studentTableHeaderColumns,
      teacherTableHeaderColumns,
      otherTableHeaderColumns,
      nameDDMenuItems,
      rolesDDMenuItems,
      statusDDMenuItems,
      gradeLevelDDMenuItems,
      subjectHandledDDMenuItems,
      schoolYearDDMenuItems,
      curriculumDDMenuItems,
    } = this.state;
    let ddConfig: Array<IDDConfigProps> = [];
    if (tenantUserMngTabCategory === "student_parent") {
      ddConfig = [
        {
          id: "tenant-user-mng-student-username",
          name: "selectedNameDDValue",
          fieldName: "nameDDMenuItems",
          value: "",
          menuItems: nameDDMenuItems,
          label: "Name",
        },
        {
          id: "tenant-user-mng-student-gradeLevel",
          name: "selectedGradeLevelDDValue",
          fieldName: "gradeLevelDDMenuItems",
          value: "",
          menuItems: gradeLevelDDMenuItems,
          label: "Grade Level",
        },
        {
          id: "tenant-user-mng-student-roles",
          name: "selectedRolesDDValue",
          fieldName: "rolesDDMenuItems",
          value: "",
          menuItems: rolesDDMenuItems,
          label: "Role",
        },
        {
          id: "tenant-user-mng-student-curriculum",
          name: "selectedCurriculumDDValue",
          fieldName: "curriculumDDMenuItems",
          value: "",
          menuItems: curriculumDDMenuItems,
          label: "Curriculum",
        },
        {
          id: "tenant-user-mng-student-schoolYear",
          name: "selectedSchoolYearDDValue",
          fieldName: "schoolYearDDMenuItems",
          value: "",
          menuItems: schoolYearDDMenuItems,
          label: "School Year Title",
        },
      ];
      this.setState({
        tableBodyData: studentParentList,
        tableHeaderColumns: studentTableHeaderColumns,
        dropdownConfig: ddConfig,
      });
    } else if (tenantUserMngTabCategory === "teacher") {
      ddConfig = [
        {
          id: "tenant-user-mng-teacher-username",
          name: "selectedNameDDValue",
          fieldName: "nameDDMenuItems",
          value: "",
          menuItems: nameDDMenuItems,
          label: "Name",
        },
        {
          id: "tenant-user-mng-teacher-gradeLevel",
          name: "selectedGradeLevelDDValue",
          fieldName: "gradeLevelDDMenuItems",
          value: "",
          menuItems: gradeLevelDDMenuItems,
          label: "Grade Level Handle",
        },
        {
          id: "tenant-user-mng-teacher-curriculum",
          name: "selectedCurriculumDDValue",
          fieldName: "curriculumDDMenuItems",
          value: "",
          menuItems: curriculumDDMenuItems,
          label: "Curriculum",
        },
        {
          id: "tenant-user-mng-teacher-subject",
          name: "selectedSubjectHandledDDValue",
          fieldName: "subjectHandledDDMenuItems",
          value: "",
          menuItems: subjectHandledDDMenuItems,
          label: "Subject Handled",
        },
        {
          id: "tenant-user-mng-teacher-roles",
          name: "selectedRolesDDValue",
          fieldName: "rolesDDMenuItems",
          value: "",
          menuItems: rolesDDMenuItems,
          label: "Role",
        },
        {
          id: "tenant-user-mng-teacher-schoolYear",
          name: "selectedSchoolYearDDValue",
          fieldName: "schoolYearDDMenuItems",
          value: "",
          menuItems: schoolYearDDMenuItems,
          label: "School Year Title",
        },
      ];
      this.setState({
        tableBodyData: teacherList,
        tableHeaderColumns: teacherTableHeaderColumns,
        dropdownConfig: ddConfig,
      });
    } else {
      ddConfig = [
        {
          id: "tenant-user-mng-others-username",
          name: "selectedNameDDValue",
          fieldName: "nameDDMenuItems",
          value: "",
          menuItems: nameDDMenuItems,
          label: "Name",
        },
        {
          id: "tenant-user-mng-others-roles",
          name: "selectedRolesDDValue",
          fieldName: "rolesDDMenuItems",
          value: "",
          menuItems: rolesDDMenuItems,
          label: "Role",
        },
        {
          id: "tenant-user-mng-others-status",
          name: "selectedStatusDDValue",
          fieldName: "statusDDMenuItems",
          value: "",
          menuItems: statusDDMenuItems,
          label: "Status",
        },
      ];
      this.setState({
        tableBodyData: othersList,
        tableHeaderColumns: otherTableHeaderColumns,
        dropdownConfig: ddConfig,
      });
    }
  };

  updateUserStatus = (value: string) => {
    const { tableBodyData } = this.state;
    const tableUserData: IUserMngTableProps[] = tableBodyData;
    const user: IUserMngTableProps | undefined = tableUserData.find(
      (item) => item.id === value
    );

    if (user) {
      let userStatus;
      if (user.status === "draft") {
        userStatus = "active";
      } else if (user.status === "active") {
        userStatus = "inactive";
      } else {
        userStatus = "active";
      }
      this.setState(
        {
          userMngTableProps: userStatus,
        },
        () => {
          this.updateCreatePostData(value);
        }
      );
    }
  };

  userViewFunct = (value: string) => {
    this.setState({
      userViewId: value,
    });
    this.getViewUserDetails(value);
  };

  handlePageChange = (event: any, page: number) => {
    this.setState({ currentStudentParentListingPage: page }, () => {
      this.fetchStudentParentTableData(true);
    });
  };

  fetchStudentParentTableData = (isFilter: boolean = false) => {
    this.setState({ loading: true });
    showCustomLoader();
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudentParaentAPIRequestId = getUserStatusMsg.messageId;
    let newEndpoint;
    if (this.props.tenantUserMngTabCategory === "student_parent") {
      newEndpoint = "Student";
    } else if (this.props.tenantUserMngTabCategory === "teacher") {
      newEndpoint = "Teacher";
    } else if (this.props.tenantUserMngTabCategory === "other") {
      newEndpoint = "Other";
    }

    let nameSearchText = "";
    if (
      this.state.selectedNameDDValue &&
      typeof this.state.selectedNameDDValue === "string"
    ) {
      nameSearchText = this.state.selectedNameDDValue.split(" ")[0];
    }
    const currentPage = this.state.currentStudentParentListingPage;

    let studentParentListingEndpoint =
      configJSON.studentParentListingApiEndpoint +
      `${newEndpoint}&page=${currentPage}&per_page=${this.state.perPageRecords}`;

    if (isFilter) {
      studentParentListingEndpoint += `&role_id=${
        this.state.selectedRolesDDValue
      }&curriculum_id=${
        this.state.selectedCurriculumDDValue
      }&name=${nameSearchText}&school_year_id=${
        this.state.selectedSchoolYearDDValue
      }&grade_id=${
        this.state.selectedGradeLevelDDValue
          ? JSON.stringify([this.state.selectedGradeLevelDDValue.toString()])
          : ""
      }&status=${this.state.selectedStatusDDValue}`;
    }

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      studentParentListingEndpoint
    );
    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  };

  getNamesStudentParentDropdown = () => {
    this.setState({ loading: true });
    // showCustomLoader()
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStudentParaentNames = getUserStatusMsg.messageId;

    let userType;
    if (this.props.tenantUserMngTabCategory === "student_parent") {
      userType = "Student";
    } else if (this.props.tenantUserMngTabCategory === "teacher") {
      userType = "Teacher";
    } else {
      userType = "Other";
    }

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentParentNames + userType
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  };

  getGrades = (token: string) => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token,
    };
    let getGradesEndpoint = configJSON.gradesEndpoint;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradesListingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getGradesEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCurriculumList = (token: string) => {
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurriculumListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.curriculumFilterList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetSchoolYearList = (token: string) => {
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSchoolYearData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolYearEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetRoleNamesList = (token: string) => {
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRoleNamesData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherParentNmaesList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getHandleSubjectsList() {
    showCustomLoader();

    const getSubjectsAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.handleSubjectsListAPIRequestId = getSubjectsAPIMessage.messageId;

    getSubjectsAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectHandlesList + this.state.gradeSubjectId
    );

    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };

    getSubjectsAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );
    runEngine.sendMessage(getSubjectsAPIMessage.id, getSubjectsAPIMessage);
  }

  updateCreatePostData(selectedId: string) {
    showCustomLoader();
    const header = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };
    const attrs = {
      data: {
        attributes: {
          status: this.state.userMngTableProps,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePatchStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatusApiEndpont + `/${selectedId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchPostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getViewUserDetails = (value: string) => {
    showCustomLoader();
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDetailId = getUserStatusMsg.messageId;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatusApiEndpont + "/" + value
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == null) {
      return;
    }

    switch (apiRequestCallId) {
      case this.getStudentParaentAPIRequestId:
        this.handleStudentParentResponse(responseJson);
        break;
      case this.apiGetGradesListingId:
        this.handleGradeListResponse(responseJson);
        break;
      case this.apiGetCurriculumListing:
        this.handleCurriculumsListResponse(responseJson);
        break;
      case this.apiGetSchoolYearData:
        this.handleSchoolYearResponse(responseJson, errorResponse);
        break;
      case this.getStudentParaentNames:
        this.handleNamesListResponse(responseJson, errorResponse);
        break;
      case this.apiGetRoleNamesData:
        this.handleRoleNamesResponse(responseJson, errorResponse);
        break;
      case this.handleSubjectsListAPIRequestId:
        this.handleHandleSubjectListResponse(responseJson);
        break;
      case this.updatePatchStatusApiCallId:
        this.handleUpdateUserStatus();
        break;
      case this.getUserDetailId:
        this.handleViewUserDetails(responseJson);
        break;
      case this.apiGetUserMngCustomFieldId:
        this.handleGetUserMngCustomFieldDataResponse(responseJson);
        break;
    }
  }

  handleStudentParentResponse(responseJson: any) {
    this.setState({ loading: false });
    hideCustomLoader();
    if (responseJson) {
      const newData = this.studentParentResponseData(responseJson);
      this.setState({
        totalStudentParentRecords: responseJson?.meta?.pagination?.total_count,
      });
      this.updateStateStuParWithData(newData);
    }
  }

  handleGradeListResponse(responseJson: any) {
    if (responseJson) {
      const newData = this.gradeListResponseData(responseJson);
      this.updateStateGradeListWithData(newData);
      this.getDropDownData(newData);
    }
  }

  handleCurriculumsListResponse(responseJson: any) {
    if (responseJson) {
      const newData = this.CurriculumsListResponseData(responseJson);
      this.updateStateCurriculumsListWithData(newData);
      this.getDropDownDataCurriculums(newData);
    }
  }

  handleSchoolYearResponse(responseJson: any, errorResponse: any) {
    if (responseJson) {
      const newData = this.schoolYearListResponseData(responseJson);
      this.getDropDownDataSchoolYear(newData);
    }
  }

  async handleRoleNamesResponse(responseJson: any, errorResponse: any) {
    if (responseJson) {
      const newData =await this.RoleNamesListResponseData(responseJson);
      this.getDropDownDataRoleNames(newData);
    }
  }

  handleViewUserDetails(responseJson: {
    data: {
      id: string;
      attributes: {
        user_custom_fields?: {
          data: {
            attributes: {
              field_value: string;
            };
          }[];
        };
        expiring_on: string;
        profile_url: string;
        status: string;
        school_year: { title: string };
        role: { name: string };
        DOB: string;
        id_number: string;
        curriculum: { name: string };
        first_name: string;
        last_name: string;
        email: string;
        grade: { name: string };
      };
    };
  }) {
    hideCustomLoader();
    let getUserRole = responseJson?.data.attributes.role.name;

    if (
      this.props.tenantUserMngTabCategory == "student_parent" &&
      getUserRole == "Student"
    ) {
      this.props.navigation?.navigate(
        "ViewStudentProfileDetails",
        {},
        {
          studentId: this.state.userViewId,
        }
      );
    }
  }

  handleNamesListResponse(
    responseJson: { name: string }[],
    errorResponse: any
  ) {
    if (responseJson) {
      const uniqueNamesSet = new Set();

      const studentParentNames = responseJson
        .map((item: { name: string }, index: number) => {
          if (!uniqueNamesSet.has(item.name)) {
            uniqueNamesSet.add(item.name);
            return {
              id: ++index,
              label: item.name,
              value: item.name,
            };
          }
        })
        .filter(Boolean);

      const ddConfig = this.state.dropdownConfig;
      ddConfig.forEach((item) => {
        if (item.fieldName === "nameDDMenuItems") {
          item.menuItems = studentParentNames;
        }
      });

      this.setState({
        dropdownConfig: ddConfig,
      });

      this.namesListSetData(studentParentNames);
    }
  }

  getDropDownData(newData: any) {
    const gradeLevelDropdownData = this.state.dropdownConfig.find(
      (item: { id: any }) => item.id === "tenant-user-mng-student-gradeLevel"
    );
    if (gradeLevelDropdownData) {
      gradeLevelDropdownData.menuItems = newData;
      this.setState({
        gradeLevelDDMenuItems: newData,
      });
    }
    const gradeLevelDropdownDataTeacher = this.state.dropdownConfig.find(
      (item: { id: any }) => item.id === "tenant-user-mng-teacher-gradeLevel"
    );
    if (gradeLevelDropdownDataTeacher) {
      gradeLevelDropdownDataTeacher.menuItems = newData;
      this.setState({
        gradeLevelDDMenuItems: newData,
      });
    }
  }

  getDropDownDataCurriculums(newData: any) {
    const curriculumLevelDropdownData = this.state.dropdownConfig.find(
      (item: { id: any }) => item.id === "tenant-user-mng-student-curriculum"
    );
    if (curriculumLevelDropdownData) {
      curriculumLevelDropdownData.menuItems = newData;
      this.setState({
        curriculumDDMenuItems: newData,
      });
    }
    const curriculumLevelDropdownDataTeacher = this.state.dropdownConfig.find(
      (item: { id: any }) => item.id === "tenant-user-mng-teacher-curriculum"
    );
    if (curriculumLevelDropdownDataTeacher) {
      curriculumLevelDropdownDataTeacher.menuItems = newData;
      this.setState({
        curriculumDDMenuItems: newData,
      });
    }
  }

  getDropDownDataSchoolYear(newData: any) {
    const schoolYearLevelDropdownData = this.state.dropdownConfig.find(
      (item: { id: any }) => item.id === "tenant-user-mng-student-schoolYear"
    );
    if (schoolYearLevelDropdownData) {
      schoolYearLevelDropdownData.menuItems = newData;
      this.setState({
        schoolYearDDMenuItems: newData,
      });
    }

    const schoolYearLevelDropdownDataTeacher = this.state.dropdownConfig.find(
      (item: { id: any }) => item.id === "tenant-user-mng-teacher-schoolYear"
    );
    if (schoolYearLevelDropdownDataTeacher) {
      schoolYearLevelDropdownDataTeacher.menuItems = newData;
      this.setState({
        schoolYearDDMenuItems: newData,
      });
    }
  }

  getDropDownDataSubject(newData: any) {
    const subjectDropdownDataTeacher = this.state.dropdownConfig.find(
      (item: { id: any }) => item.id === "tenant-user-mng-teacher-subject"
    );
    if (subjectDropdownDataTeacher) {
      subjectDropdownDataTeacher.menuItems = newData;
      this.setState({
        subjectHandledDDMenuItems: newData,
      });
    }
  }

  getDropDownDataRoleNames(newData: any) {
    const idsToUpdate = [
      "tenant-user-mng-student-roles",
      "tenant-user-mng-teacher-roles",
      "tenant-user-mng-others-roles"
    ];
    const updatedDropdownConfig = this.state.dropdownConfig.map((item) => {
      if (idsToUpdate.includes(item.id)) {
        return {
          ...item,
          menuItems: newData
        };
      }
      return item;
    });
  
    this.setState({
      dropdownConfig: updatedDropdownConfig,
      rolesDDMenuItems: newData
    });
  }

  handleHandleSubjectListResponse = (responseJson: any) => {
    if (responseJson) {
      hideCustomLoader();
      const subjectLIstList = responseJson.data.map(
        (item: any, index: number) => {
          return {
            id: ++index,
            label: item.attributes.name,
            value: Number(item.id),
          };
        }
      );
      this.getDropDownDataSubject(subjectLIstList);
    }
  };

  handleUpdateUserStatus = () => {
    this.fetchStudentParentTableData(true);
  };

  studentParentResponseData(responseJson: any) {
    return responseJson.data.map((item: any) => {
      const fullName = `${item.attributes.first_name} ${item.attributes.last_name}`;
      const role = item.attributes.role.name.toLowerCase();
      const gradeNames =item.attributes.grade?.map((item: any) => item.name).join(",") || "NA";
      let firstCurriculum = item.attributes.curriculum?.name || "NA";
      let firstSubject =
        item.attributes.subject.map((item: any) => item.name).join(",") || "NA";
      const customField = item?.attributes?.user_custom_fields?.data?.map((item: { id: string, attributes: { id: number, field_value: "string", label: string}}) => {
        return {
          id: item?.id,
          value: item?.attributes?.field_value || "NA",
          label: item?.attributes?.label,
        };
      });
      const schoolYear = item.attributes.school_year?.title || "NA";
      const status = item.attributes.status;
      
      const userDetailObject: any = {
          id: item.id,
          fullName: fullName,
          grade: gradeNames,
          role: role,
          curriculum: firstCurriculum,
          schoolYear: schoolYear,
          status: status,
          customField,
      };
      item?.attributes?.user_custom_fields?.data?.forEach((data: any) => {
        userDetailObject[data?.attributes?.label] = data?.attributes?.field_value || "NA";
      });
      if (this.props.tenantUserMngTabCategory === "teacher") {
          return { ...userDetailObject, subjectHandled: firstSubject, grades: gradeNames };
      } else {
        return userDetailObject;
      }
    });
  }

  gradeListResponseData(responseJson: any) {
    return responseJson.data.map((item: any) => {
      const name = item.attributes.name;
      const value = item.attributes.id;
      return {
        id: item.attributes.id,
        label: name,
        value: value,
      };
    });
  }

  CurriculumsListResponseData(responseJson: any) {
    return responseJson.curriculums?.map((item: { name: any; id: any }) => {
      const name = item.name;
      const value = item.id;
      return {
        id: item.id,
        label: name,
        value: value,
      };
    });
  }

  schoolYearListResponseData(responseJson: any) {
    return responseJson.data.map(
      (item: { attributes: { title: any }; id: any }) => {
        const name = item.attributes.title;
        return {
          id: item.id,
          label: name,
          value: item.id,
        };
      }
    );
  }

  RoleNamesListResponseData(responseJson: any) {
    if (this.props.tenantUserMngTabCategory === "student_parent") {
      const selectedItems = responseJson.data.filter(
        (role: any) =>
          role.attributes.name === "Student" ||
          role.attributes.name === "Parent"
      );
      return selectedItems.map(
        (item: { attributes: { name: any }; id: any }) => {
          const name = item.attributes.name;

          return {
            label: name,
            id: item.id,
            value: item.id,
          };
        }
      );
    } else if (this.props.tenantUserMngTabCategory === "teacher") {
      const selectedItems = responseJson.data.filter(
        (role: any) =>
          role.attributes.name === "Teacher" ||
          role.attributes.name === "Parent Teacher"
      );
      return selectedItems.map(
        (item: { attributes: { name: any }; id: any }) => {
          const name = item.attributes.name;

          return {
            label: name,
            value: item.id,
            id: item.id,
          };
        }
      );
    } else if (this.props.tenantUserMngTabCategory === "other") {
      const excludedRoles = ["Teacher", "Student", "Parent", "Parent Teacher"];
      const selectedItems = responseJson.data.filter(
        (role: any) => !excludedRoles.includes(role.attributes.name)
      );
      return selectedItems.map(
        (item: { attributes: { name: any }; id: any }) => {
          const name = item.attributes.name;

          return {
            value: item.id,
            id: item.id,
            label: name,
          };
        }
      );
    }
  }

  updateStateCurriculumsListWithData(newData: any) {
    this.setState({
      curriculumDDMenuItems: newData,
    });
  }

  updateStateGradeListWithData(newData: any) {
    this.setState({
      gradeLevelDDMenuItems: newData,
    });
  }

  updateStateStuParWithData(newData: any) {
    this.setState({
      tableBodyData: newData,
      loading: false,
    });
  }

  namesListSetData(namesList: any) {
    this.setState({
      nameDDMenuItems: namesList,
    });
  }

  handleSelectDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: string;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    const { dropdownConfig } = this.state;
    let fieldValue: any = value;
    if (fieldValue) {
      const ddConfig = dropdownConfig.map((item) => {
        if (item.name === name) {
          item.value = value;
        }
        return item;
      });
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
          dropdownConfig: ddConfig,
        };
      });
      if (
        this.props.tenantUserMngTabCategory == "teacher" &&
        name == "selectedGradeLevelDDValue"
      ) {
        this.setState(
          {
            gradeSubjectId: value,
          },
          () => {
            this.getHandleSubjectsList();
          }
        );
      }
    }
  };

  handleSearchUsers = () => {
    this.setState({loading: true, currentStudentParentListingPage: 1 }, () => {
      this.fetchStudentParentTableData(true);
    });
  };

  // Customizable Area End
}
// Customizable Area End
