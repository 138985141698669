// Customizable Area Start
import React from "react";
import { Box, Checkbox, Typography, Link } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import AccountLoginController, { Props } from "./AccountLoginController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import AuthLayout from "../../../components/src/AuthLayout.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    wrapperBox: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    textFieldWrapper: {
      maxWidth: theme.spacing(55),
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: theme.spacing(40),
      },
    },
    text: {
      fontSize: 16,
      fontWeight: 400,
      color: "#888888",
      textAlign: "left",
      marginBottom: theme.spacing(1.25),
    },
    checkBox: {
      borderRadius: "6px",
      root: {
        color: "#01C996",
        "&$checked": {
          color: "#01C996",
        },
      },
    },
    forgotPasswordWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: theme.spacing(2, 0, 4, 0),
    },
    forgotPasswordText: {
      fontWeight: 500,
      fontSize: 16,
      color: "#0D46BC",
      cursor: "pointer",
    },
    rememberMe: {
      fontSize: 14,
      fontWeight: 400,
      color: "#888888",
    },
  });
// Customizable Area End

// Customizable Area Start
export class AccountLogin extends AccountLoginController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { username, password, rememberMe } = this.state;
    return (
      <AuthLayout
        title="Login"
        subTitle="Welcome back! Please login to your account"
      >
        <Box className={classes.wrapperBox}>
          <div className={classes.textFieldWrapper}>
            <Typography className={classes.text}>Username</Typography>
            <CustomTextField
              placeHolderText="Enter Username"
              textFieldStyles={textFildStyle}
              fieldName="username"
              fieldId="username-field"
              fieldValue={username}
              fieldWidth={"100%"}
              fieldType="text"
              handleChange={(e: any) => this.handleChange(e)}
            />
            <Typography className={classes.text} style={{ marginTop: "30px" }}>
              Password
            </Typography>
            <CustomTextField
              placeHolderText="Enter Password"
              textFieldStyles={textFildStyle}
              fieldName="password"
              fieldId="login-password-field"
              fieldValue={password}
              fieldWidth={"100%"}
              fieldType="password"
              handleChange={(e: any) => this.handleChange(e)}
            />
            <Box className={classes.forgotPasswordWrapper}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  data-test-id="remember-me-checkbox"
                  style={{
                    color: "#01C996",
                  }}
                  checked={rememberMe}
                  onChange={() => this.setState({ rememberMe: !rememberMe })}
                />
                <Typography className={classes.rememberMe} data-test-id="remember">
                  Remember me
                </Typography>
              </Box>
              <Typography
                onClick={() => this.handleGoToForgotPassword()}
                data-test-id="forgot-password"
                component={"span"}
                className={`${classes.fontSize16} ${classes.primaryColor5} ${classes.cursorPointer}`}
              >
                Forgot Password
              </Typography>
            </Box>
          </div>
          <CustomButton
            btnText="Login"
            btnStyle={btnStyle}
            buttonId={"login-btn"}
            buttonVariant={"contained"}
            type={"button"}
            handleButtonClick={() => this.handleLoginButton()}
            isDisabled={false}
          />
        </Box>
      </AuthLayout>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const btnStyle = {
  padding: "10px 10px",
  backgroundColor: "#0D46BC",
  color: "#ffffff",
  borderRadius: "10px",
  width: "100%",
  heght: "60px",
  fontSize: 20,
  fontWeight: 500,
  maxWidth: "440px",
} as const;

const textFildStyle = {
  padding: "10px 0px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AccountLogin);
// Customizable Area End
