import React from "react";

// Customizable Area Start
import { Grid, Box, Typography } from "@material-ui/core";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import CustomColorRadioButtonWeb from "../../../../components/src/CustomColorRadioButton.web";
import CustomButtonWeb from "../../../../components/src/CustomButton.web";
import GradeBookTemplateOrientationController,{Props,themesList} from "./GradeBookTemplateOrientationController";
import { ArrowBack } from "@material-ui/icons";
import EditGradeBookTemplateWeb from "../../../../components/src/gradebook/EditGradeBookTemplate.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    themeMainGrid: { 
      padding: "10px 20px",
      borderRadius:"12px"
    },
    themeMainGridItem: {
      padding: "0px 0px 30px 0px",
      marginBottom: "25px",
      
    },
    themeMainGridItemWithBottomBorder: {
      borderBottom: "1px solid #f1f1f1",
      height:"500px"
    },
    leftMargin: {
      marginLeft: "10px",
    },
    themeOptions: {
      display: "flex",
      gap:"40px",
      padding:"25px"
    },
    themeOptionRadioBox: {

      textAlign:"left"
    },
    themeOptionBox: {
      display: "flex",
      alignItems: "center",
      
      flexDirection: "column",
    
    },
    defaultDetailsBox: {
      display: "flex",
      padding: "0px 10px",
      marginBottom: "8px",
    },
    defaultLogo: {
      width: "100px",
      objectFit: "contain",
    },
    labelBottomMargin: {
      marginBottom: "10px",
    },
    saveBtnGrid: {
      display: "flex",
      justifyContent: "flex-end",
      paddingBottom: "15px",
    },
    themeImgBox: {
      borderRadius: "10px",
     
    },
    themeImgBoxSelected: {
      border: "3px solid #FBD82C",
      borderRadius:"12px"
    },
    colorCircle: {
      display: "inline-block",
      width: "32px",
      height: "32px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    accentColorBox: {
      padding: "25px 15px",
      display: "flex",
      marginTop: "20px",
      width: "fit-content",
      borderRadius: "10px",
    },
    avatarBox: {
      borderRadius: "50%",
      "& img": {
        height: "45px",
      },
    },
    themeImg: {
    },
    accentHighlightItem: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "45px",
    },
    accentHighlightLabel: {
      marginBottom: "15px",
    },
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
  },
  backArrowBox: {
      cursor: "pointer",
    },
    childMainWrapperPadding:{
      padding:"40px"
    }
  });
// Customizable Area End

export class GradeBookTemplateOrientation extends GradeBookTemplateOrientationController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { selectedThemeType } =
      this.state;
    return (
     
       <Box
            className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
            height={"100%"}
            >
                <Grid  container direction="row">
                <Grid item xs={12}>
                        <Box marginTop={"1%"}>
                            <Typography
                                className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText12} ${classes.smallTopHeader}`}
                                component={"span"}
                            >
                                Setup / Grade Book
                            </Typography>
                            <Typography
                                component={"span"}
                                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textCommonColor} ${classes.textCapital}`}
                            >
                            {this.state.openEditTemplate?  `/Touch Program (DepED) /` :  `  /Touch Program (DepED) /`  }
                            </Typography>
                            <Typography
                                component={"span"}
                                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5} ${classes.textCapital}`}
                            >
                          {this.state.openEditTemplate? `${this.state.selectedThemeType}` : "Template Orientation" }
                            </Typography>
                        </Box>
                    </Grid>
             <Grid item xs={12} className={classes.pageName} >
                    <Box display={"flex"}>
                  <Box
                    className={classes.backArrowBox}
                    onClick={this.state.openEditTemplate ? this.handleBackFromEditTemplate : this.handleBack}
                    id="back-button"
                  >
                    <ArrowBack
                      fontSize="large"
                      className={classes.textPrimaryColor}
                    />
                  </Box>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
                    component={"span"}
                  >
                    {`Template Orientation` }
                  </Typography>
                </Box>
              </Grid>
              {this.state.openEditTemplate 
            ? <EditGradeBookTemplateWeb 
                gradebookTemplateType={this.state.templateType} 
                gradeBookId={this.state.gradeBookId} 
                handlCreateTemplateApi={this.handlCreateTemplateApi} 
                templateType={this.state.selectedThemeType}
                getDataOfTemplate={this.state.getDataOfTemplate}
                orientationType={this.state.orientationType}
                handleUpdateTemplateApi={this.handleUpdateTemplateApi}
                getTemplateId={this.state.getTemplateId}
                data-testId="edit-grade-book-template"
                AttandanceTableData={this.state.AttandanceTableData}
                AttandanceTableHead={this.state.AttandanceTableHead}
                gradebookListTableHead={this.state.gradebookListTableHead}
                gradebookTableData={this.state.gradebookTableData}
                behaviourTableHead={this.state.behaviourTableHead}
                behaviourTableData={this.state.behaviourTableData}
              />  :
      <Grid item xs={12} className={`${classes.themeMainGrid} ${classes.bgColor}`} container direction="row">
        <Grid className={`${classes.themeMainGridItem} ${classes.themeMainGridItemWithBottomBorder}`} item xs={12}>
          <Box className={`${classes.themeOptions}`}>
            {themesList.map((themeItem: any) => (
 
              <Box key={themeItem.id} 
              className={` ${classes.secondaryBgColor} ${classes.themeImgBox} ${
                selectedThemeType === themeItem.value
                  ? classes.themeImgBoxSelected
                  : ""
              }`} 
              style={{width:"244px"}}
              >
                <Box
                  style={{
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column',
                    padding:"20px",
                    textAlign:'center'
                  }}
                >
                  <Box className={`${classes.themeOptionRadioBox}`} style={{width:themeItem.width}} >
                  <CustomColorRadioButtonWeb
                    checked={selectedThemeType === themeItem.value}
                    radioColor="#FBD82C"
                    fieldName={themeItem.name}
                    handleChange={(e: any) =>
                      this.handleChangeThemeType(themeItem.value)
                    }
                  />
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16} ${classes.leftMargin}`}
                    component={"span"}
                  >
                    {themeItem.name}
                  </Typography>
                </Box>
                  <img
                    className={`${classes.themeImg}`}
                    src={themeItem.image}
                    alt={themeItem.name}
                  />
                </Box>
              
              </Box>

            ))}
          </Box>
        </Grid>


        <Grid className={`${classes.saveBtnGrid}`} item xs={12}>
          <CustomButtonWeb
            btnText="Next"
            btnStyle={saveBtn}
            buttonId="next-btn"
            buttonVariant="contained"
            isDisabled={false}
            type={"button"}
            handleButtonClick={this.handleSaveDetails}
          />
        </Grid>
      </Grid>
  }
      </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const saveBtn = {
  width: "160px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  padding: "10px 10px",
  height: "45px",
  textAlign: "center",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(GradeBookTemplateOrientation);
// Customizable Area End
