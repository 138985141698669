// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CustomImageComponentController, {
  Props,
} from "./CustomImageComponentController.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    buttonCss: {
      textTransform: "capitalize",
    },
  });

// Customizable Area End

export class CustomImageComponent extends CustomImageComponentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { imgStyles, src, altText, isUserProfileImg } = this.props;
    return (
      <img
        src={src}
        alt={altText}
        style={imgStyles}
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = !isUserProfileImg ? require("./invalidImage.svg") : require("./empty_user_profile.png");
        }}
      />
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export default withStyles(styles, { withTheme: true })(CustomImageComponent);
// Customizable Area End
