// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  studentProfile,
  parent1,
  parent2,
  englishSubjectIcon,
  historySubjectIcon,
  mathSubjectIcon,
  physicsSubjectIcon,
} from "./assets";
import { configJSON } from "./UserManagementTabComponentController.web";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import moment from "moment";

// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}

export interface Parent {
  id: number;
  parent_id: string;
  student_id: number;
  created_at: string;
  updated_at: string;
}

export interface IUserDetailProps {
  id: any;
  emailId: string;
  rollNumber: any;
  curriculum: any;
  dob: any;
  classDetail: string;
  schoolYearTitle: string;
  role: string;
  expiringOn: string;
  status: string;
  profilePic?: any;
  customField1: any;
  customField2: any;
  customField3: any;
}

export interface IParentDetailProps {
  id: any;
  emailId: string;
  fullName: string;
  relationship: string;
  contactNumber: string;
  profilePic?: any;
  role: string;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  userFullName: string;
  userDetails: IUserDetailProps;
  userParentDetails: Array<IParentDetailProps>;
  coursesList: Array<any>;
  performanceProgressDetail: Array<any>; // Customizable Area End
  userId: string;
  token: string,
  setStudData: Array<IUserDetailProps>,
  fullName: string,
  firstParentId: string,
  secondParentId: string
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ViewStudentProfileDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  getUserDetailId: string = "";
  getParentDetailsId: string = "";
  getParentTwoDetailsId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      userFullName: "",
      userDetails: {
        id: "",
        emailId: "",
        rollNumber: "",
        curriculum: "",
        dob: "",
        classDetail: "",
        schoolYearTitle: "",
        role: "",
        expiringOn: "",
        status: "",
        customField1: "",
        customField2: "",
        customField3: ""
      },
      userParentDetails: [
      ],
      coursesList: [
        {
          id: 1,
          subjectName: "Science",
          subjectIcon: physicsSubjectIcon,
          subjectBgColor: "#01C996",
        },
        {
          id: 2,
          subjectName: "Mathematics",
          subjectIcon: mathSubjectIcon,
          subjectBgColor: "#0D46BC",
        },
        {
          id: 3,
          subjectName: "English",
          subjectIcon: englishSubjectIcon,
          subjectBgColor: "#019AFC",
        },
        {
          id: 4,
          subjectName: "History",
          subjectIcon: historySubjectIcon,
          subjectBgColor: "#FBD82C",
        },
      ],
      performanceProgressDetail: [
        {
          id: 1,
          subjectName: "Algebra",
          subjectBgColor: "#0D46BC",
          subjectMarks: 70,
          progressPercentage: 49,
        },
        {
          id: 2,
          subjectBgColor: "#DF9D33",
          subjectName: "Computer",
          subjectMarks: 85,
          progressPercentage: 56,
        },
        {
          id: 3,
          subjectName: "Physics",
          subjectMarks: 58,
          progressPercentage: 65,
          subjectBgColor: "#01C996",
        },
        {
          subjectName: "French",
          subjectMarks: 34,
          id: 4,
          subjectBgColor: "#5FD9C4",
          progressPercentage: 10,
        },
        {
          subjectMarks: 40,
          id: 5,
          subjectName: "Roman History",
          subjectBgColor: "#FBD82C",
          progressPercentage: 68,
        },
        {
          subjectMarks: 67,
          id: 6,
          subjectName: "Victorian Literature",
          subjectBgColor: "#019AFC",
          progressPercentage: 68,
        },
        {
          id: 7,
          subjectBgColor: "#019AFC",
          subjectName: "Creative Writing",
          subjectMarks: 67,
          progressPercentage: 90,
        },
        {
          id: 8,
          subjectName: "English Poetry",
          subjectMarks: 67,
          subjectBgColor: "#019AFC",
          progressPercentage: 68,
        },
        {
          subjectBgColor: "#FC9301",
          id: 9,
          subjectMarks: 98,
          subjectName: "Social Behavior",
          progressPercentage: 68,
        },
      ],
      token: "",
      userId: "",
      setStudData: [],
      fullName: "",
      firstParentId: "",
      secondParentId: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetUsersCreateToken();
    // Customizable Area End
  }

  // Customizable Area Start

  handleSetUsersCreateToken = async () => {
    const token = await getStorageData("token");
    if (token) {
      this.setState({ token: token });
      this.handleSetStudentDetails(token);
    }
  };

  handleSetStudentDetails = (token: string) => {
    this.setState({ loading: true });
    showCustomLoader()
    const studentId = this.props.navigation?.getParam("studentId");
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDetailId = getUserStatusMsg.messageId;


    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatusApiEndpont + "/" + studentId
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  };

  getParentDetails = (firstParentId: string) => {
    this.setState({ loading: true });
    showCustomLoader()
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getParentDetailsId = getUserStatusMsg.messageId;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatusApiEndpont + "/" + firstParentId
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  }

  getParentTwoDetails = (firstParentId: string) => {
    this.setState({ loading: true });
    showCustomLoader()
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getParentTwoDetailsId = getUserStatusMsg.messageId;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatusApiEndpont + "/" + firstParentId
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (apiRequestCallId == null) {
      return;
    }

    switch (apiRequestCallId) {
      case this.getUserDetailId:
        this.studentParentResponseData(responseJson);
        break;
      case this.getParentDetailsId:
        this.parentsDataResponce(responseJson);
        break;
      case this.getParentTwoDetailsId:
         this.parentsTwoDataResponce(responseJson)
         break;
    }
  }

  studentParentResponseData(responseJson?: {
    data: {
      id: string, attributes: {
        user_custom_fields?: {
          data: {
            attributes: {
              field_value: string;
            }
          }[]
        }, parents?: Parent[], expiring_on: string, profile_url: string, status: string, school_year: { title: string }, role: { name: string }, DOB: string, id_number: string, curriculum: { name: string }, first_name: string, last_name: string, email: string, grade: { name: string }
      }
    }
  }) {

    let studData = this.setDataFunc(responseJson);
    this.setState({
      userDetails: studData
    })
    hideCustomLoader();
  }

  parentsDataResponce(responseJson?: {
    data: {
      id: string, attributes: {
        role?: { name: string }, first_name: string,
        last_name: string, contact_number: string, relationship: string, profile_url: string, email: string
      }
    }
  }) {

    const parentData = this.setParentData(responseJson)
    if(this.state.firstParentId) {
      this.setState({
        userParentDetails: [...this.state.userParentDetails , parentData]
      })
    }
    hideCustomLoader();
  }

  parentsTwoDataResponce(responseJson?: {
    data: {
      id: string, attributes: {
        role?: { name: string }, first_name: string,
        last_name: string, contact_number: string, relationship: string, profile_url: string, email: string
      }
    }
  }) {

    const parentTwoData = this.setParentData(responseJson)
    if(this.state.firstParentId) {
      this.setState({
        userParentDetails:  [...this.state.userParentDetails , parentTwoData]
      })
    }
    hideCustomLoader();
  }

  setDataFunc(responseJson?: {
    data: {
      id: string, attributes: {
        user_custom_fields?: {
          data: {
            attributes: {
              field_value: string;
            }
          }[]
        }, parents?: Parent[], expiring_on: string, profile_url: string, status: string, school_year: { title: string },
        role: { name: string }, DOB: string, id_number: string, curriculum: { name: string }, first_name: string,
        last_name: string, email: string, grade: { name: string }
      }
    }
  }) {
    const fullName = responseJson?.data.attributes.first_name + " " + responseJson?.data.attributes.last_name;
    const firstParentId = responseJson?.data.attributes?.parents?.[0]?.parent_id;
    const secondParentId = responseJson?.data.attributes?.parents?.[1]?.parent_id;

    this.setState({
      userFullName: fullName
    })
    if (firstParentId) {
      this.setState({
        firstParentId: firstParentId
      }, () => {
        this.getParentDetails(firstParentId)
      })
    }
    if(secondParentId) {
      this.setState({
        secondParentId: secondParentId
      }, () => {
        this.getParentTwoDetails(secondParentId)
      })
    }
    let dateOfBirth = moment(responseJson?.data.attributes.DOB).format('DD/MM/YYYY') || 'NA'

    return {
      id: responseJson?.data.id,
      emailId: responseJson?.data.attributes.email || "NA",
      rollNumber: responseJson?.data.attributes.id_number || 'NA',
      curriculum: responseJson?.data.attributes.curriculum?.name || 'NA',
      dob: dateOfBirth,
      classDetail: responseJson?.data.attributes.grade?.name || 'NA',
      role: responseJson?.data.attributes.role?.name || 'NA',
      schoolYearTitle: responseJson?.data.attributes.school_year?.title || 'NA',
      status: responseJson?.data.attributes.status || 'NA',
      expiringOn: responseJson?.data.attributes.expiring_on || 'NA',
      profilePic: responseJson?.data.attributes.profile_url || 'NA',
      customField1: responseJson?.data.attributes.user_custom_fields?.data[0].attributes.field_value || 'NA',
      customField2: responseJson?.data.attributes.user_custom_fields?.data[1].attributes.field_value || 'NA',
      customField3: responseJson?.data.attributes.user_custom_fields?.data[2].attributes.field_value || 'NA',
    }
  }

  setParentData(responseJson?: { data: { id: string, attributes: { role?: { name: string }, first_name: string, 
    last_name: string, contact_number: string, relationship: string, profile_url: string, email: string } } }) {
    return {
      id: responseJson?.data.id,
      fullName: responseJson?.data.attributes.first_name + " " + responseJson?.data.attributes.last_name,
      contactNumber: responseJson?.data.attributes.contact_number || 'NA',
      relationship: responseJson?.data.attributes.relationship || 'NA',
      profilePic: responseJson?.data.attributes.profile_url || 'NA',
      emailId: responseJson?.data.attributes.email || "NA",
      role: responseJson?.data.attributes.role?.name || 'NA',
    }
  }

  handleGetGraphColor = () => {
    const isDarkMode =
      localStorage.getItem("dark_mode") || JSON.stringify(false);
    if (JSON.parse(isDarkMode)) return "#ffffff";
    return "#000";
  };

  getProgressPerformanceGraphLabels = () => {
    const { performanceProgressDetail } = this.state;
    const labelsList: Array<string> = performanceProgressDetail?.map(
      (data: any) => data.subjectName
    );
    return labelsList || [];
  };

  getProgressPerformanceGraphData = () => {
    const { performanceProgressDetail } = this.state;
    const graphDataList: Array<number> = performanceProgressDetail?.map(
      (data: any) => data.subjectMarks
    );
    return graphDataList || [];
  };

  getProgressPerformanceGraphBarBgColor = () => {
    const { performanceProgressDetail } = this.state;
    const graphBarBgColor: Array<string> = performanceProgressDetail?.map(
      (data: any) => data.subjectBgColor
    );
    return graphBarBgColor || [];
  };

  // Customizable Area End
}
// Customizable Area End
