// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {  Box, Typography } from '@material-ui/core';
import { customThemeStyles } from '../../CustomThemeStyles.web';
import { combineStyles } from '../../CombineStyles.web';


import { profilePic1 } from '../../gradebookDetails/assets';
import StudentDetailsController,{Props} from './StudentDetailsController';


// Customizable Area End
// Customizable Area Start
const styles = (theme: Theme) => 
    createStyles({
        fieldWrapper:{
            display: "flex",
            gap: "15px",
            alignItems: "center",
            // padding: "25px"
        },
        inputLabel: {
            margin: theme.spacing(2.5, 0),
            color: "#888888",
            fontSize: "16px",
            fontWeight: 400
          },
        boxWrapper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // padding: "0 25px"
          },
        fieldWrapper2:{
            display: "flex",
            gap: "15px",
            alignItems: "center",
            marginTop:"10px"
            // paddingLeft: "25px"
        },
        subjectLogoBg: {
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "45px",
            minHeight: "45px",
            height: "45px",
            width: "45px",
        },
    })

// Customizable Area End

// Customizable Area Start

export class StudentDetails extends StudentDetailsController  {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes} = this.props;
        const {
         
        } = this.state;
        
        return (
            <Box style={{width:"100%"}}>
     <Box style={{padding:"20px"}}>
            <Box className={classes.fieldWrapper}>
            <img 
            alt="student-profile" 
            className={classes.subjectLogoBg}
            src={this.props.studentData?.profile_pic || profilePic1} 
            onError={(e) => { e.currentTarget.src = profilePic1 }} // Fallback to default if the image fails to load
            />
            <Typography
              className={`${classes.studentHeader} ${classes.textPrimaryColor}`}
            >
            {this.props.studentData ? this.props.studentData.full_name : " Antonin Hafer"}{" "}
            </Typography>
          </Box>
          <Box className={classes.boxWrapper}>
            <Box>
              <Typography className={classes.inputLabel}>
                Email ID:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                   {this.props?.studentData ? this.props.studentData?.email: "antoninhafer521@gmail.com"}{" "}
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                Role:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                     {this.props?.studentData ? this.props.studentData?.role: "Student"}{" "}
                  
                </span>
              </Typography>
             

            </Box>
            <Box>
              <Typography className={classes.inputLabel}>
                Class:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                   {this.props.className && this.props.className.trim().length > 0 ? this.props.className : "Class 9A"}
                </span>
              </Typography>
          
            </Box>
   
          </Box>
        </Box>
        </Box>
        );

        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const saveButtonStyle = {
    height: "50px",
    padding: "10px 10px",
    fontSize: "18px",
    width: "120px",
    borderRadius: "12px",
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    fontWeight: 500,
    marginLeft: "15px",
} as const;

const cancelButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    height: "50px",
    borderRadius: "12px",
    border: "1px solid",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    marginLeft: "15px",
} as const;

const customInputStyle = {
    width: "99%",
    height: "36px",
    marginTop: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentDetails);
// Customizable Area End
