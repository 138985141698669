// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  permissionsListMock,
  roleDetailsInputConfigMock,
  rolePermissionsListMock,
} from "../assets/tenantRolesAndPermissionsMock.web";
import {
  FIELD_TYPES,
  IInputConfigProps,
} from "../../../components/src/CommonType.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  roleTitle: string;
  permissionsList: Array<any>;
  permissionsInputConfig: Array<IInputConfigProps>;
  isEdit: boolean;
  isView: boolean;
  roleId: string;
  roleFromUrl: string;
  openDeleteConfirmModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddEditTenantRolesAndPermissionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      roleTitle: "",
      permissionsList: permissionsListMock,
      isEdit: false,
      isView: false,
      roleFromUrl: "",
      roleId: "",
      permissionsInputConfig: roleDetailsInputConfigMock,
      openDeleteConfirmModal: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleSetRoleDetails();
    // Customizable Area End
  }

  // Customizable Area Start
  handleGoBackToMainScreen = () => {
    this.props.navigation?.navigate("TenantRolesAndPermissions");
  };

  handleEditNewRole = () => {
    const { roleId } = this.state;
    this.props.navigation?.navigate(
      "EditTenantRolesAndPermissions",
      {},
      {
        roleId: roleId,
      }
    );
  };

  handleDeleteRolePermissionConfirm = () => {
    this.setState({ openDeleteConfirmModal: false }, () => {
      this.handleGoBackToMainScreen();
    });
  };

  handleOpenDeleteRolePermission = () => {
    this.setState({ openDeleteConfirmModal: true });
  };

  handleDeleteRolePermissionCancel = () => {
    this.setState({ openDeleteConfirmModal: false });
  };

  handleSetRoleDetails = () => {
    const paramRoleId = this.props.navigation?.getParam("roleId");
    const pathName = window.location.pathname;
    if (paramRoleId) {
      const rolePermissionDetail = rolePermissionsListMock.find(
        (item: any) => parseInt(item.id) === parseInt(paramRoleId)
      );
      const roleDetailsInputConfigMock = [
        {
          id: "tenant-roles-permissions-role-title",
          name: "roleTitle",
          fieldName: "roleTitle",
          value: rolePermissionDetail?.role_title || "",
          menuItems: [],
          label: "Role Title",
          fieldType: FIELD_TYPES.TEXT,
          isDisabled: true,
          placeHolderText: "Enter Role Title",
        },
        {
          id: "tenant-roles-permissions-base-role",
          name: "baseRole",
          fieldName: "baseRole",
          value: "Tenant Admin",
          menuItems: [],
          label: "Base Role",
          fieldType: FIELD_TYPES.TEXT,
          isDisabled: true,
          placeHolderText: "Enter Base Role",
        },
      ];
      this.setState({
        roleFromUrl: rolePermissionDetail?.role_title || "",
        roleId: paramRoleId,
        isEdit: pathName.toLowerCase().includes("edit"),
        isView: pathName.toLowerCase().includes("view"),
      });
      if (
        pathName.toLowerCase().includes("view") ||
        pathName.toLowerCase().includes("edit")
      ) {
        this.setState({
          permissionsInputConfig: roleDetailsInputConfigMock,
        });
      }
    }
  };

  handleGetUpdatedSubCategoryList = (
    list: any,
    subCategoryId: any,
    subItemId: any,
    isRead?: boolean
  ) => {
    const updatedList = list.subCategory.map((subCategoryItem: any) => {
      if (subCategoryItem.id === subCategoryId) {
        subCategoryItem.subItems.map((subItem: any) => {
          if (subItem.id === subItemId) {
            if (isRead) subItem.isReadChecked = !subItem.isReadChecked;
            else subItem.isWriteChecked = !subItem.isWriteChecked;
          }
          return subItem;
        });
      }
      return subCategoryItem;
    });
    return updatedList;
  };

  handleGetUpdatedSubItemsList = (
    list: any,
    subItemId: any,
    isRead?: boolean
  ) => {
    const updatedList = list.subItems.map((subItem: any) => {
      if (subItem.id === subItemId) {
        if (isRead) subItem.isReadChecked = !subItem.isReadChecked;
        else subItem.isWriteChecked = !subItem.isWriteChecked;
      }
      return subItem;
    });
    return updatedList;
  };

  handleGetUpdatedPermissionsList = (
    permissionId: any,
    subItemId: any,
    isRead?: boolean,
    subCategoryId?: any
  ) => {
    const { permissionsList } = this.state;
    const updatedPermissionsList = permissionsList.map((item) => {
      if (item.id === permissionId) {
        if (subCategoryId) {
          this.handleGetUpdatedSubCategoryList(
            item,
            subCategoryId,
            subItemId,
            isRead
          );
        } else {
          this.handleGetUpdatedSubItemsList(item, subItemId, isRead);
        }
      }
      return item;
    });
    return updatedPermissionsList;
  };

  handleUpdateReadPermissions = (
    permissionId: any,
    subItemId: any,
    subCategoryId?: any
  ) => {
    const updatedList = this.handleGetUpdatedPermissionsList(
      permissionId,
      subItemId,
      true,
      subCategoryId
    );
    this.setState({ permissionsList: updatedList });
  };

  handleUpdateWritePermissions = (
    permissionId: any,
    subItemId: any,
    subCategoryId?: any
  ) => {
    const updatedList = this.handleGetUpdatedPermissionsList(
      permissionId,
      subItemId,
      false,
      subCategoryId
    );
    this.setState({ permissionsList: updatedList });
  };

  handleTextChange = (event: any) => {
    const { permissionsInputConfig } = this.state;
    const { name, value } = event.target;
    const updatedInputConfig = permissionsInputConfig.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        permissionsInputConfig: updatedInputConfig,
      };
    });
  };

  // Customizable Area End
}
// Customizable Area End
