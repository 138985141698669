// Customizable Area Start
import React from "react";
import {
    Box,
    Chip,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Avatar,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomTableController, { Props } from "./CustomTableController.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import CustomProgressCircle from "./CustomProgressCircle.web";
import CustomLinearProgressBar from "./CustomLinearProgressBar.web";
import { combineStyles } from "./CombineStyles.web";
import CustomButtonWeb from "./CustomButton.web";
import CustomCheckBoxComponentWeb from "./CustomCheckBoxComponent.web";
import CheckboxCheckIcon from "./SVGIcons/CheckboxCheckIcon.web";
import CheckboxUnCheckIcon from "./SVGIcons/CheckboxUnCheckIcon.web";
import DownloadIcon from "./SVGIcons/DownloadIcon.web";
import { rightTick } from "../../blocks/cfthresholdforgrades2/src/assets";
import { getBgColor, getColor } from "./CommonHelper.web";
import DaysPattern from "./DaysPattern.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
    createStyles({
        fontSize15: {
            fontSize: "15px"
        },
        textEllipsis: {
            whiteSpace: "nowrap",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        tableBodyCell: {
            paddingTop: "3px",
            paddingBottom: "3px"
        },
        tableContainer: {
            overflowY: "hidden",
            position: "relative",
        },
        colorCircle: {
            display: 'inline-block',
            width: 32,
            height: 32,
            borderRadius: '50%',
        },
        nameText: {
            whiteSpace: "nowrap",
        },
        paginationRoot: {
            '& > *': {
                marginTop: theme.spacing(3),
            },
            '& .Mui-selected': {
                color: "#FFFFFF",
                backgroundColor: '#FFD92E !important',
            },
            '& .MuiPaginationItem-root': {
                borderRadius: '6px',
            },
            '& .MuiPaginationItem-icon': {
                color: '#0D46BC',
            },
            "& .MuiPaginationItem-page": {
                backgroundColor: "#FFFFFF",
            },
        },
        customWidth: {
            maxWidth: '150px',
            backgroundColor: '#FFE4E4',
            color: '#ED1111',
            padding: '12px 30px 12px 6px',
        },
        statusChip: {
            textTransform: "capitalize",
            fontWeight: 600,
            '& .MuiChip-label': {
                fontSize: 13,
                fontWeight: 600,
            }
        },
        subjectLogoBg: {
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "45px",
            minHeight: "45px",
            height: "45px",
            width: "45px",
        },
        subjectIcon: {
            width: "32px",
            height: "32px",
            objectFit: "contain",
        },
        studentIcon: {
            height: "45px",
            width: "45px",
            objectFit: "inherit",
            borderRadius: "50%",
        },
        columnIcon: {
            width: '9.99px',
            height: '10.66px',
            marginTop: '6.5px',
            marginLeft: '6px',
            cursor: "pointer",
        },
        paginationBox: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: "10px 20px 10px 21px"
        },
        capitalText: {
            textTransform: "capitalize"
        },
        stickyMenuColumn: {
            position: 'sticky',
            right: 0,
            zIndex: 1,
        },
        downloadIcon: {
            width: "22px",
            height: "22px",
            objectFit: "contain",
            marginLeft: "10px"
        },
        customCheckbox: {
            width: "17px",
            height: "17px",
            border: "1px solid #c8c5c5",
            borderRadius: "3px",
            background: "transparent",
            position: "relative",
        },
        customCheckboxSelected: {
            borderColor: 'transparent !important',
            background: "#FBD82C !important"
        },
        tickMark: {
            width: "15px",
            height: "auto"
        },
        rowSelectInput: {
            position: "absolute",
            opacity: 0,
            cursor: "pointer",
            height: "20px",
            width: "20px",
            top: 0,
            left: 0,
            margin: 0
        },
        unwrapped: {
            whiteSpace: "nowrap",
        },
        avatar: {
            borderRadius: "50%"
        }
    })

export const tableColumnTypes = {
    TEXT: "text",
    NUMBER: "number",
    NUMBER_LESS_WIDTH: "number_less_width",
    CIRCULAR_DASHED_PROGRESS: "circular_dashed_progress",
    LINEAR_PROGRESS: "linear_progress",
    CHIP: "chip",
    ICON: "icon",
    COLOR_CIRCLE: "color_circle",
    COMPONENT: "component",
    ICON_NAME: "icon_name",
    ProgressBar: 'progressbar',
    PROGRESS_TEXT: 'progresstext',
    ASSIGNED_ICON: 'assignedicon',
    ASSIGNED_ASSESSMENT_AVG_GRADE: "assigned_assessment_avg_grade",
    ASSIGNED_TITLE: 'assignedtitle',
    STUDENT_ICON: 'student_icon',
    
    STUDENT_PROGRESS_BAR: 'student_progress_bar',
    DUE_DATE: 'duedate',
    STUD_PROGRESS_ICON: 'stud_progress_icon',
    PROGRESS_TAB_ICON: 'progress_tab_icon',
    PROGRESS_TAB_ICON_COURSE: 'progress_tab_icon_course',
    RECENT_ACTIVITY_ICON_TEXT: 'recent_activity_icon_text',
    PENDING_ACTIVITY_ICON_TEXT: 'pending_activity_icon_text',
    CATALOG_NAME: "catalogname",
    COURSES_NAME: "coursesname",
    COURSES_NAME_ALERT: "coursesnamealert",
    SUBJECT_ICON_NAME: 'subject_icon_name',
    SUBJECT_ICON_NAME_TEMPLATE: 'subject_icon_name_template',
    TEXT_AUTO: "text_auto",
    STATUS_CHIP: "status_chip",
    COURSE_STATUS: "course_status",
    STUDENT_SUBMISSION_PROGRESS: "student_submission_progress",
    BUTTON: "button",
    GRADEBOOK_PROGRESS_BAR: 'gradebook_progress_bar',
    GRADEBOOK_AVG: "gradebook_avg",
    DOWNLOAD: "download",
    DAY_PATTERN_SM: "days_pattern_sm",
    EVENT_COLOR_TITLE: "event_color_title"
}

const getTableCellWidth = (cellValueType: string) => {
    switch (cellValueType) {
        case tableColumnTypes.TEXT:
            return "140px"
        case tableColumnTypes.NUMBER:
            return "132px"
        case tableColumnTypes.NUMBER_LESS_WIDTH:
            return "108px"
        case tableColumnTypes.CIRCULAR_DASHED_PROGRESS:
            return "100px"
        case tableColumnTypes.LINEAR_PROGRESS:
            return "165px"
        case tableColumnTypes.CHIP:
            return "100px"
        case tableColumnTypes.ICON:
            return "140px"
        case tableColumnTypes.COLOR_CIRCLE:
            return "140px"
        case tableColumnTypes.COMPONENT:
            return "auto"
        case tableColumnTypes.ICON_NAME:
            return "53px"
        case tableColumnTypes.ProgressBar:
            return "75px"
        case tableColumnTypes.PROGRESS_TEXT:
            return "20px"
        case tableColumnTypes.ASSIGNED_ICON:
            return "40px"
        case tableColumnTypes.ASSIGNED_ASSESSMENT_AVG_GRADE:
            return "40px"
        case tableColumnTypes.ASSIGNED_TITLE:
            return "340px"
        case tableColumnTypes.STUDENT_ICON:
            return "200px"
        case tableColumnTypes.STUDENT_PROGRESS_BAR:
            return "340px"
        case tableColumnTypes.DUE_DATE:
            return "20px"
        case tableColumnTypes.STUD_PROGRESS_ICON:
            return "400px"
        case tableColumnTypes.PROGRESS_TAB_ICON:
            return "300px"
        case tableColumnTypes.PROGRESS_TAB_ICON_COURSE:
            return "210px"
        case tableColumnTypes.RECENT_ACTIVITY_ICON_TEXT:
            return "340px"
        case tableColumnTypes.PENDING_ACTIVITY_ICON_TEXT:
            return "383px"
        case tableColumnTypes.COURSES_NAME:
            return "300px"
        case tableColumnTypes.COURSES_NAME_ALERT:
            return "300px"
        case tableColumnTypes.TEXT_AUTO:
            return "auto"
        case tableColumnTypes.STATUS_CHIP:
            return "auto"
        case tableColumnTypes.COURSE_STATUS:
            return "auto"
        case tableColumnTypes.SUBJECT_ICON_NAME:
            return "220px"
        case tableColumnTypes.SUBJECT_ICON_NAME_TEMPLATE:
            return "220px"
        case tableColumnTypes.STUDENT_SUBMISSION_PROGRESS:
            return "220px"
        case tableColumnTypes.GRADEBOOK_PROGRESS_BAR:
            return "auto"
        case tableColumnTypes.GRADEBOOK_AVG:
            return "auto"
        default:
            return "140px"
    }
}
const getTableCellMaxWidth = (cellValueType: string) => {
    switch (cellValueType) {
        case tableColumnTypes.ICON_NAME:
            return "53px"
        case tableColumnTypes.ProgressBar:
            return "90px"
        case tableColumnTypes.ASSIGNED_TITLE:
            return "400px"
        case tableColumnTypes.STUDENT_PROGRESS_BAR:
            return "400px"
        case tableColumnTypes.COMPONENT:
            return "auto"
        case tableColumnTypes.CATALOG_NAME:
            return "auto"
        case tableColumnTypes.GRADEBOOK_PROGRESS_BAR:
            return "auto"
        case tableColumnTypes.GRADEBOOK_AVG:
            return "auto"
        default:
            return "190px"
    }
}
// Customizable Area End

export class CustomTable extends CustomTableController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            classes,
            tableHeaderColumns,
            tableBodyData,
            tableId,
            showMenuIcon,
            showPagination,
            showPageCount,
            actionButtonConfig,
            showEditMenuIcon,
            showResourceMenu,
            showMyCoursesMenu,
            showUserActionMenu,
            makeRowSelectable,
            showSelectAllCheckbox,
            allSelected,
            onSelectAllRecords,
            onRowSelect,
            onColumnSelect,
            handleColumnSorting,
            actionButtonConfigstatus,
            isHandleCellAction,
            showRowWiseActionMenu,
            rowWiseTableActionConfig,
        } = this.props
        const { menuAnchorEl, selectedRowId } = this.state

        const getIconColor = () => {
            const isDarkTheme =
                localStorage.getItem("dark_mode") || JSON.stringify(false);
            return JSON.parse(isDarkTheme) ? "#FBD82C" : "#0D46BC";
        };

        const getTableBodyCellData = (valueType: string, data: any, tableRow?: any) => {
                 
            switch (valueType) {
                case tableColumnTypes.TEXT:
                    return (<Typography className={`${classes.fontSize18} ${classes.textPrimaryColor} ${classes.textEllipsis}`} component={"span"}>
                        {data}
                       { console.log("jjjjj",data) }
                    </Typography>)
                case tableColumnTypes.NUMBER:
                    return (<Typography id="number-cell" className={`${classes.fontSize18} ${classes.capitalText} ${classes.textPrimaryColor}`} component={"span"}>
                        {data}
                    </Typography>)
                case tableColumnTypes.CIRCULAR_DASHED_PROGRESS:
                    return (<CustomProgressCircle centerTextFontSize="12px" progressDataPosition={{
                        top: "6px",
                        left: "6px"
                    }} progressCircleDimensions={50} progressData={{
                        hoverText: "",
                        progressPercentage: data,
                    }} customClasses={classes} />)
                case tableColumnTypes.LINEAR_PROGRESS:
                    return (
                        <CustomLinearProgressBar customClasses={classes} barColor="blue" progressPercentage={data} />
                    )
                case tableColumnTypes.CHIP:
                    return (
                        <Chip style={{ backgroundColor: getBgColor(data), color: getColor(data), width: "max-content" }} className={classes.statusChip} label={data} />
                    )
                case tableColumnTypes.ICON:
                    return (
                        <Box display={"flex"} alignItems="center" my={"3px"}>
                           {data?.icon &&
                            <Box mr={"10px"} className={classes.subjectLogoBg}>
                                <img src={data?.icon} alt="Subject Logo" />
                            </Box>
                            }
                            <Box>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                    {data?.name}
                                </Typography>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.COLOR_CIRCLE:
                    return (
                        <span className={classes.colorCircle} style={{ backgroundColor: data }}></span>
                    )
                case tableColumnTypes.COMPONENT:
                    return (
                        data
                    )
                case tableColumnTypes.ICON_NAME:
                    return (
                        <Box display={"flex"} alignItems="center" my={"3px"}>
                            <Box mr={"10px"} className={classes.subjectLogoBg}>
                                <img src={data?.icon} />
                            </Box>
                            <Box>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                    {data?.name}
                                </Typography>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.ProgressBar:
                    return (
                        <Box display={'flex'} alignItems="center">
                            <Box minWidth={100} maxWidth={100} display={'flex'} mr={1} >
                                <Box width={`${data?.value1}%`} height={30} style={{ backgroundColor: '#01C996', borderTopLeftRadius: '7px', borderBottomLeftRadius: '7px' }}></Box>
                                <Box width={`${data?.value2}%`} height={30} style={{ backgroundColor: '#0D46BC' }}></Box>
                                <Box width={`${data?.value3}%`} height={30} style={{ backgroundColor: '#FBD82C', borderTopRightRadius: '7px', borderBottomRightRadius: '7px' }}></Box>
                            </Box>
                            <Box display={'flex'} alignItems="center" >
                                <Box display={'flex'} alignItems="center">
                                    <Box width={20} height={20} style={{ backgroundColor: '#01C996', borderRadius: '4px', marginRight: '6px' }}></Box>
                                    <Typography className={`${classes.textCommonColor} ${classes.fontText14}`} style={{ marginRight: '12px' }} component={"span"}>
                                        {data?.value1}%
                                    </Typography>
                                </Box>
                                <Box width={20} height={20} style={{ backgroundColor: '#0D46BC', borderRadius: '4px', marginRight: '6px' }}></Box>
                                <Typography className={`${classes.textCommonColor} ${classes.fontText14}`} style={{ marginRight: '12px' }} component={"span"}>
                                    {data?.value2}%
                                </Typography>
                                <Box width={20} height={20} style={{ backgroundColor: '#FBD82C', borderRadius: '4px', marginRight: '6px' }}></Box>
                                <Typography className={`${classes.textCommonColor} ${classes.fontText14}`} style={{ marginRight: '8px' }} component={"span"}>
                                    {data?.value3}%
                                </Typography>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.PROGRESS_TEXT:
                    return (<Typography className={`${classes.fontSize15} ${classes.textPrimaryColor}`} component={"span"}>
                        {data}%
                    </Typography>)
                case tableColumnTypes.ASSIGNED_ICON:
                    return (
                        <Box display={"flex"} alignItems="center">
                            <Box mr={"10px"} className={classes.subjectLogoBg}>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                    {data?.name}
                                </Typography>
                            </Box>
                            <Box ml={`${data?.name === '' ? '-45px' : '0px'}`}>
                                <Tooltip classes={{ tooltip: classes.customWidth }} placement="right" title="None of the submitted response has been graded">
                                    <img src={data?.icon} alt={data?.icon} />
                                </Tooltip>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.ASSIGNED_ASSESSMENT_AVG_GRADE:
                    return (
                        <Box data-test-id="assigned assessment avg grade" display={"flex"} alignItems="center">
                            <Box mr={"10px"} className={classes.subjectLogoBg}>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                    {data?.grade}
                                </Typography>
                            </Box>
                            <Box ml={`${(data?.grade === "") ? '-45px' : '0px'}`}>
                                <Tooltip classes={{ tooltip: classes.customWidth }} placement="right" title={data?.infoMsg}>
                                    <img src={require("./redalert.png")} alt="info icon" />
                                </Tooltip>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.ASSIGNED_TITLE:
                    return (<Typography className={`${classes.fontSize15} ${classes.textPrimaryColor}`} component={"span"}>
                        {data}
                    </Typography>)
                case tableColumnTypes.STUDENT_ICON:
                    return (
                        <Box display={"flex"} alignItems="center">
                            {makeRowSelectable && !!onRowSelect && (
                                <Box display={"flex"} alignItems={"center"} height={"30px"} style={{ cursor: 'pointer' }}>
                                    <Box marginRight={"8px"} className={`${classes.customCheckbox} ${tableRow.isSelected ? classes.customCheckboxSelected : ''}`} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                        <label htmlFor="tableRowSelect">
                                            <input value={tableRow?.isSelected} onChange={(e) => onRowSelect(tableRow)} className={`${classes.rowSelectInput}`} type="checkbox" id="tableRowSelect" />
                                            <img style={{ display: tableRow?.isSelected ? 'inline-block' : 'none' }} className={`${classes.tickMark}`} src={rightTick} />
                                        </label>
                                    </Box>
                                </Box>
                            )}

                            <Box display={"flex"} alignItems={"center"}>
                                <Box mr={"10px"} className={classes.subjectLogoBg}>
                                    {data?.icon ? <img alt="Profile" className={`${classes.studentIcon}`} src={data?.icon} onError={(e: any) => {
                                        e.target.onerror = null;
                                        e.target.src = require("./invalidImage.svg");
                                    }} /> : <Avatar className={`${classes.avatar}`}
                                        variant="rounded" src=""
                                    />}
                                </Box>

                                <Box style={{ cursor: isHandleCellAction ? "pointer" : "none" }} onClick={(event: any) => isHandleCellAction ? this.props.handleAction(data) : event?.preventDefault()}>
                                    <Typography className={`${classes.textPrimaryColor} ${classes.fontText15} ${classes.nameText} ${classes.textEllipsis}`} component="span">
                                        {data?.name}
                                    </Typography>
                                </Box>
                              {data?.error_msg &&  <Box ml={"5px"}>
                                <Tooltip classes={{ tooltip: classes.customWidth }} placement="right" title={data?.error_msg}>
                                    <img src={require("./redalert.png")} alt="info icon" />
                                </Tooltip>
                                </Box>
                                }
                              
                            </Box>

                        </Box>
                    )
                case tableColumnTypes.STUDENT_PROGRESS_BAR:
                    return (
                        <>
                            <Box id="student-progress-bar" display={'flex'} alignItems="center">
                                <Box minWidth={120} maxWidth={120} display={'flex'} mr={"30px"} >
                                    {(data?.value1 > 0) && <Box width={`${data.value1}%`} height={30} style={{ backgroundColor: '#01C996', borderTopLeftRadius: '7px', borderBottomLeftRadius: '7px', borderTopRightRadius: (data?.value3 || data?.value2) ? "0px" : "7px", borderBottomRightRadius: (data?.value3 || data?.value2) ? "0px" : "7px" }}></Box>}
                                    {(data?.value2 > 0) && <Box width={`${data?.value2}%`} height={30} style={{ backgroundColor: '#0D46BC', borderTopLeftRadius: data?.value1 ? "0px" : "7px", borderBottomLeftRadius: data?.value1 ? "0px" : "7px", borderTopRightRadius: data?.value3 ? "0px" : "7px", borderBottomRightRadius: data?.value3 ? "0px" : "7px" }}></Box>}
                                    {(data?.value3 > 0) && <Box width={`${data?.value3}%`} height={30} style={{ backgroundColor: '#FBD82C', borderTopRightRadius: '7px', borderBottomRightRadius: '7px', borderTopLeftRadius: (data?.value1 || data?.value2) ? "0px" : "7px", borderBottomLeftRadius: (data?.value1 || data?.value2) ? "0px" : "7px" }}></Box>}
                                </Box>
                                <Box display={'flex'} alignItems="center" >
                                    <Box minWidth={"86px"} display={'flex'} alignItems="center">
                                        <Tooltip placement="top" title="Completed">
                                            <Box width={20} height={20} style={{ backgroundColor: '#01C996', borderRadius: '4px', marginRight: '6px' }}></Box>
                                        </Tooltip>
                                        <Typography className={`${classes.textCommonColor} ${classes.fontText14}`} style={{ marginRight: '12px' }} component={"span"}>
                                            {data?.value1}%
                                        </Typography>
                                    </Box>
                                    <Box minWidth={"86px"} display={'flex'} alignItems="center">
                                        <Tooltip placement="top" title="Remaining">
                                            <Box width={20} height={20} style={{ backgroundColor: '#0D46BC', borderRadius: '4px', marginRight: '6px' }}></Box>
                                        </Tooltip>
                                        <Typography className={`${classes.textCommonColor} ${classes.fontText14}`} style={{ marginRight: '12px' }} component={"span"}>
                                            {data?.value2}%
                                        </Typography>
                                    </Box>
                                    <Box minWidth={"86px"} display={'flex'} alignItems="center">
                                        <Tooltip placement="top" title="Not Tackled">
                                            <Box width={20} height={20} style={{ backgroundColor: '#FBD82C', borderRadius: '4px', marginRight: '6px' }}></Box>
                                        </Tooltip>
                                        <Typography className={`${classes.textCommonColor} ${classes.fontText14}`} style={{ marginRight: '8px' }} component={"span"}>
                                            {data?.value3}%
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )
                case tableColumnTypes.STUDENT_SUBMISSION_PROGRESS:
                    return (
                        <Box id="student-progress-bar" display={'flex'} alignItems="center"
                            style={{
                                height: '50px', width: '100%', position: 'relative',
                                background: 'grey', borderRadius: '10px'
                            }}
                        >
                            <Box width={`${data?.value}%`} style={{
                                position: 'absolute', height: '50px',
                                background: 'red', borderRadius: '10px',
                            }}>
                            </Box>

                        </Box>
                    )
                case tableColumnTypes.DUE_DATE:
                    return (<Typography id="due-date-cell" className={`${classes.fontSize15} ${classes.textPrimaryColor}`} component={"span"}>
                        {data}
                    </Typography>)
                case tableColumnTypes.STUD_PROGRESS_ICON:
                    return (
                        <Box display={"flex"} alignItems="center">
                            <Box mr={"10px"} bgcolor={data?.color} style={{ width: '34px', borderRadius: '50%', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={data?.icon} alt="progress Logo" style={{ width: '24px', height: '20px', objectFit: 'scale-down' }} />
                            </Box>
                            <Box>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                    {data?.name}
                                </Typography>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.PROGRESS_TAB_ICON:
                    return (
                        <Box display={"flex"} alignItems="center" my={"5px"} onClick={() => { this.props.handleAction(data) }}>
                            <Box mr={"10px"} bgcolor={data?.color} style={{ width: '34px', borderRadius: '50%', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={data?.icon} alt="progress Icon" style={{ width: '24px', height: '20px', objectFit: 'scale-down' }} />
                            </Box>
                            <Box>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                    {data?.name}
                                </Typography>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.PROGRESS_TAB_ICON_COURSE:
                    return (
                        <Box display={"flex"} alignItems="center" my={"5px"} onClick={() => { this.props.handleAction(data) }}>
                            <Box mr={"10px"} bgcolor={data?.color} style={{ width: '34px', borderRadius: '50%', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={data?.icon} alt="progress Icon" style={{ width: '24px', height: '20px', objectFit: 'scale-down' }} />
                            </Box>
                            <Box style={{ cursor: isHandleCellAction ? "pointer" : "none" }} className={classes.textEllipsis}>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                    {data?.name}
                                </Typography>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.RECENT_ACTIVITY_ICON_TEXT:
                    return (
                        <Box id={"recent-activity-icon-text"} display={"flex"} alignItems="center" my={"5px"} onClick={() => { this.props.handleAction(data) }}>
                            <Box mr={"10px"} bgcolor={data?.color} style={{ width: '34px', borderRadius: '50%', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={data?.icon} alt="progress Icon" style={{ width: '24px', height: '20px', objectFit: 'scale-down' }} />
                            </Box>
                            <Box>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                    {data?.name}
                                </Typography>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.PENDING_ACTIVITY_ICON_TEXT:
                    return (
                        <Box id="prending-activity-icon-text" display={"flex"} alignItems="center" my={"5px"} onClick={() => { this.props.handleAction(data) }}>
                            <Box mr={"10px"} bgcolor={data?.color} style={{ width: '34px', borderRadius: '50%', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={data?.icon} alt="progress Icon" style={{ width: '24px', height: '20px', objectFit: 'scale-down' }} />
                            </Box>
                            <Box>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                    {data?.name}
                                </Typography>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.CATALOG_NAME:
                    return (
                        <Typography className={`${classes.fontSize15} ${classes.textPrimaryColor}`} component={"span"}
                            style={{ cursor: 'pointer' }} onClick={() => { this.props.handleAction(data.id) }}>
                            {data?.name}
                            {console.log("catty",data)}
                        </Typography>
                    )
                case tableColumnTypes.COURSES_NAME:
                    return (
                        <Box id="course-name" display={"flex"} alignItems="center" my={"3px"} style={{ cursor: 'pointer' }} onClick={() => { this.props.handleAction(tableRow) }}>
                            <Box mr={"10px"} width={data?.boxWidth ? data?.boxWidth : "42px"} height={data?.boxHeight ? data?.boxHeight : "42px"} bgcolor={data?.bgColor} className={classes.subjectLogoBg}>
                                <img className={`${classes.subjectIcon}`} src={data?.icon} onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = require("./invalidImage.svg");
                                }} />
                            </Box>
                            <Box overflow={"hidden"}>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}  ${classes.nameText} ${classes.textEllipsis}`} component="span">
                                    {data?.name}
                                    {console.log("catty2",data.name)}
                                </Typography>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.COURSES_NAME_ALERT:
                    return (
                        <Box id="course-name" display={"flex"} alignItems="center" style={{ cursor: 'pointer' }} onClick={() => { this.props.handleAction(data) }}>
                            <Box mr={"10px"} className={classes.subjectLogoBg}>
                                <img src={data?.icon} />
                            </Box>
                            <Box>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                    {data?.name}
                                </Typography>
                            </Box>
                            <Box ml={"4px"}>
                                <Tooltip classes={{ tooltip: classes.customWidth }} placement="right" title="Too many assessments. Shouldn't be more than 15 for Grade 3">
                                    <img src={data?.alertIcon} />
                                </Tooltip>
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.SUBJECT_ICON_NAME:
                    return (
                        <Box display={"flex"} alignItems="center" my={"3px"}>
                            <Box mr={"10px"} width={data?.boxWidth ? data?.boxWidth : "42px"} height={data?.boxHeight ? data?.boxHeight : "42px"} bgcolor={data?.bgColor} className={classes.subjectLogoBg}>
                                <img className={`${classes.subjectIcon}`} src={data?.icon} onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = require("./invalidImage.svg");
                                }} />
                            </Box>
                            <Box overflow={"hidden"}>
                                <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}  ${classes.nameText} ${classes.textEllipsis}`} component="span">
                                    {data?.name}
                                </Typography>
                            </Box>
                            {this.props.requestIconShow  && (
                            <Tooltip classes={{ tooltip: classes.customWidth }} placement="right" title={data.rejectionReason}>
                               <img src={require("./redalert.png")} alt="rejectionReason" style={{marginLeft:"10px"}}/>
                                </Tooltip>
                                )}
                        </Box>
                    )
                case tableColumnTypes.NUMBER_LESS_WIDTH:
                    return (<Typography id="number-cell-less-width" className={`${classes.fontSize18} ${classes.capitalText} ${classes.textPrimaryColor}`} component={"span"}>
                        {data}
                    </Typography>)
                case tableColumnTypes.TEXT_AUTO:
                    return (<Typography className={`${classes.fontSize18} ${classes.textPrimaryColor} ${classes.unwrapped}`} component={"span"}>
                        {data}
                    </Typography>)
                case tableColumnTypes.STATUS_CHIP:
                    return (
                        <Chip style={{ backgroundColor: getBgColor(data), color: getColor(data), height: "25px" }} className={classes.statusChip} label={data} />
                    )
                case tableColumnTypes.COURSE_STATUS:
                    return (
                        <Chip style={{ fontSize: "12px", backgroundColor: getBgColor(data), color: getColor(data), height: "25px", width: "120px" }} className={classes.statusChip} label={data.replaceAll('_', ' ')} />
                    )
                case tableColumnTypes.BUTTON:
                    return (
                        <CustomButtonWeb btnStyle={data.btnStyle} btnText={data.btnText} buttonId={data.btnId} buttonVariant={data.btnVariant} isDisabled={data.isDisabled} type={data.btnType} btnIcon={data?.btnIcon} handleButtonClick={() => data.handleAction(data?.itemId)} />
                    )
                case tableColumnTypes.GRADEBOOK_PROGRESS_BAR:
                    return (
                        <Box id="gradebook-progress-bar" display={'flex'} alignItems="center">
                            <Typography component="span" style={{ fontSize: "15px", marginRight: "10px", width: "45px" }} className={classes.textPrimaryColor}>{data}%</Typography>
                            <Box minWidth={120} maxWidth={120} display={'flex'} mr={1} style={{ background: "#D2D2D2", borderRadius: "6px",overflow:"hidden"  }}>
                                <Box width={`${data}%`} height={30} style={{ backgroundColor: '#01C996', borderTopLeftRadius: '7px', borderBottomLeftRadius: '7px' }}></Box>
                            </Box>
                        </Box> 
                    )
                case tableColumnTypes.GRADEBOOK_AVG:
                    if (data === '80%') {
                        return (
                            <Typography style={{ color: "#01C996" }} className={`${classes.fontSize18} ${classes.capitalText}`} component={"span"}>
                                {data}
                            </Typography>
                        )
                    } else if (typeof data === 'object' && data.hasOwnProperty('value') && data.hasOwnProperty('status')) {
                        return (
                            <Typography className={getStatusColor(data.status)} component={"span"}>
                                {data.value}%
                            </Typography>
                        )
                    } else {
                        return (
                            <Typography className={`${classes.fontSize18} ${classes.capitalText} ${classes.textPrimaryColor}`} component={"span"}>
                                {data}
                            </Typography>
                        )
                    }
                case tableColumnTypes.DOWNLOAD:
                    return (
                        <Box onClick={() => this.props.handleDownload(data)} className={`${classes.cursorPointer}`} display={"flex"} alignItems={"center"}>
                            <Typography className={`${classes.fontSize18} ${classes.textButton} ${classes.capitalText}`} component={"span"}>
                                Download
                            </Typography>
                            <Box marginLeft={"10px"}>
                                <DownloadIcon
                                    width="20"
                                    height="18"
                                    viewBox="0 0 20 18"
                                    stroke={"none"}
                                    strokeWidth="1"
                                    fill={getIconColor()}
                                />
                            </Box>
                        </Box>
                    )
                case tableColumnTypes.DAY_PATTERN_SM:
                    return (
                        <DaysPattern availableDays={[...data]} />
                    )
                case tableColumnTypes.EVENT_COLOR_TITLE:
                    return (
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            <Box
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    borderRadius: "50%",
                                    backgroundColor: data?.color,
                                }}
                            />
                            <Box className={`${classes.textEllipsis}`} marginLeft={"10px"}>
                                <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontSize18}`}>
                                    {data?.title}
                                </Typography>
                            </Box>
                        </Box>
                    )
                    case tableColumnTypes.SUBJECT_ICON_NAME_TEMPLATE:
                        return (
                            <Box display={"flex"} alignItems="center" my={"3px"}>
                                {
                                data.icon && <Box mr={"10px"} width={data?.boxWidth ? data?.boxWidth : "42px"} height={data?.boxHeight ? data?.boxHeight : "42px"} bgcolor={data?.bgColor} className={classes.subjectLogoBg}>
                                    <img className={`${classes.subjectIcon}`} src={data?.icon} onError={(e: any) => {
                                        e.target.onerror = null;
                                        e.target.src = require("./invalidImage.svg");
                                    }} />
                                </Box>
                                }
                                <Box overflow={"hidden"}>
                                    <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}  ${classes.nameText} ${classes.textEllipsis}`} component="span">
                                        {data?.name}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                default:
                    return (<Typography className={`${classes.fontSize15} ${classes.textPrimaryColor}`} component={"span"}>
                        {valueType}
                    </Typography>)
            }
        }

        const getStatusColor = (status: string) => {
            switch (status) {
                case "open":
                    return `${classes.fontSize18} ${classes.capitalText} ${classes.textPrimaryColor}`;

                case "waiting_teacher_approval":
                    return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor17}`;

                case "waiting_registrar_approval":
                    return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor18}`;

                case "lockedin":
                    return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor4}`;

                case "rejected":
                    return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor19}`;
            }
        };

        return (
            <>
                <TableContainer className={classes.tableContainer} id={tableId}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableHeaderColumns.map((column: any, index: number) => (
                                    <TableCell
                                        key={column.id}
                                        style={{
                                            paddingTop: column.hasOwnProperty('isSelected') ? "0px" : "16px"
                                        }}
                                    >
                                        <Box display={"flex"} alignItems={"flex-end"} >
                                            {showSelectAllCheckbox && !!onSelectAllRecords && index === 0 && (
                                                <Box marginRight={"8px"} marginBottom={"3px"} className={`${classes.customCheckbox} ${allSelected ? classes.customCheckboxSelected : ''}`} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                    <label htmlFor="allRowsSelect">
                                                        <input checked={allSelected ? allSelected : false} onChange={(e) => onSelectAllRecords(e)} className={`${classes.rowSelectInput}`} type="checkbox" id="allRowsSelect" />
                                                        <img style={{ display: allSelected ? 'inline-block' : 'none' }} className={`${classes.tickMark}`} src={rightTick} />
                                                    </label>
                                                </Box>
                                            )}

                                            <Box display={'flex'} flexDirection={'column'} justifyContent={"flex-end"} alignItems={"flex-start"}>
                                                {column.hasOwnProperty('isSelected') && !!onColumnSelect && (
                                                    <Box alignSelf={"center"} className={`${classes.customCheckbox} ${column.isSelected ? classes.customCheckboxSelected : ''}`} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                        <label htmlFor="tableColumnSelect">
                                                            <input checked={column.isSelected ? column.isSelected : false} onChange={(e) => onColumnSelect(column)} className={`${classes.rowSelectInput}`} type="checkbox" id="tableColumnSelect" />
                                                            <img style={{ display: column.isSelected ? 'inline-block' : 'none' }} className={`${classes.tickMark}`} src={rightTick} />
                                                        </label>
                                                    </Box>
                                                )}


                                                <Box display={'flex'} style={{ justifyContent: column?.align ? column?.align : "inherit", textAlign: column?.align ? column?.align : "inherit" }}>
                                                    <Typography className={`${classes.fontSize18} ${classes.textCommonColor} ${classes.unwrapped}`} component={"span"}>
                                                        {column.label}
                                                    </Typography>
                                                {column?.icon  && <img src={column.icon} className={classes.columnIcon}  onClick={() => this.props.handleColumnSorting(column)}   data-test-id="column-sort-button" />}
                                                </Box>
                                            </Box>
                                        </Box>

                                    </TableCell>
                                ))}
                                {showMenuIcon && <TableCell className={`${classes.stickyMenuColumn} ${classes.bgColor}`}></TableCell>}

                                {showEditMenuIcon && <TableCell></TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableBodyData?.length > 0 &&
                                tableBodyData.map((row: any, i: any) => {
                                    return (
                                        <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                                            {tableHeaderColumns.map((column: any) => {
                                                const value = row[column.id];
                                                console.log("value1",value)
                                                const type = column.type;
                                                return (
                                                    <TableCell
                                                        className={`${classes.tableBodyCell}`}
                                                        style={{
                                                            minWidth: column?.width ? column?.width : getTableCellWidth(type),
                                                            maxWidth: column?.maxWidth ? column?.maxWidth : getTableCellMaxWidth(type),
                                                            textAlign: column?.align ? column?.align : "inherit",
                                                            pointerEvents: column.disableKey ? 'none' : 'auto', 
                                                            opacity: row.disableKey ? 0.5 : 1, 
                                                        }}
                                                        key={`${column.id}-${row.id}`}
                                                    >
                                                        {
                                                            <>
                                                                {getTableBodyCellData(type, value, row)}
                                                                {console.log("value2",value)}
                                                            </>
                                                        }
                                                    </TableCell>
                                                );
                                            })}
                                            {showMenuIcon && <TableCell className={`${classes.stickyMenuColumn} ${classes.bgColor}`} align="right">
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={(e: any) => {
                                                        this.handleActionClick(e, row,row.disableKey)
                                                    }}
                                                >
                                                    <MoreVertIcon className={`${classes.textCommonColor}`} />
                                                </IconButton>
                                            </TableCell>}
                                            {showEditMenuIcon && <TableCell align="left">
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={(e: any) =>
                                                        this.handleEditActionClick(e, row)
                                                    }
                                                >
                                                    <MoreVertIcon className={`${classes.textCommonColor}`} />
                                                </IconButton>
                                            </TableCell>}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
             {showRowWiseActionMenu ? (
                    rowWiseTableActionConfig.map((item: any) => (
                        selectedRowId === item.id &&
                        <Menu
                            id={`${tableId}-menu-action`}
                            key={item.id}
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={this.handleMenuClose}
                            className={`${classes.menuAction}`}
                            PaperProps={
                                {
                                    className: `${classes.menuAction}`
                                }
                            }
                        >
                            {item.menu?.map((item: any, index: number) => {
                                if (this.state.selectedDisable && index === 0) {
                                    return null;
                                }
                            return (
                                <MenuItem key={item.label} onClick={() => { item.action(selectedRowId), this.handleMenuClose() }}>
                                    <Box color={"#888888"}>
                                        {item?.label}
                                    </Box>
                                </MenuItem>
                            )}
                        )}
                        </Menu>
                    ))
                ) : actionButtonConfig ? (
                    <Menu
                        id={`${tableId}-menu-action`}
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={this.handleMenuClose}
                        className={`${classes.menuAction}`}
                        PaperProps={
                            {
                                className: `${classes.menuAction}`
                            }
                        }
                    >
                        {actionButtonConfig?.map((item: any, index: number) => (
                            <MenuItem disabled={item?.isDisabled} key={item.label} onClick={() => { item.action(selectedRowId), this.handleMenuClose() }}>
                                <Box color={"#888888"}>
                                    {item?.label}
                                </Box>
                            </MenuItem>
                        ))}
                    </Menu>
                ) :
                    showEditMenuIcon ?
                        <Menu
                            id={`${tableId}-menu-action`}
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={this.handleEditMenuClose}
                        >
                            <MenuItem onClick={this.handleEditMenuClose}>
                                Edit Grade
                            </MenuItem>
                            <MenuItem onClick={this.handleEditMenuClose}>
                                Add Remark
                            </MenuItem>
                        </Menu> :
                        showResourceMenu ?
                            <Menu
                                id={`${tableId}-menu-action`}
                                anchorEl={menuAnchorEl}
                                open={Boolean(menuAnchorEl)}
                                onClose={this.handleEditMenuClose}
                            >
                                <MenuItem onClick={this.handleEditMenuClose}>
                                    View More
                                </MenuItem>
                                <MenuItem onClick={this.handleEditMenuClose}>
                                    Edit
                                </MenuItem>
                                <MenuItem onClick={this.handleEditMenuClose}>
                                    Delete
                                </MenuItem>
                            </Menu> :
                            showMyCoursesMenu ?
                                <Menu
                                    id={`${tableId}-menu-action`}
                                    anchorEl={menuAnchorEl}
                                    open={Boolean(menuAnchorEl)}
                                    onClose={this.handleEditMenuClose}
                                >
                                    <MenuItem onClick={this.handleEditMenuClose}>
                                        Publish
                                    </MenuItem>
                                    <MenuItem onClick={this.handleEditMenuClose}>
                                        Copy
                                    </MenuItem>
                                    <MenuItem onClick={this.handleEditMenuClose}>
                                        View
                                    </MenuItem>
                                    <MenuItem onClick={this.handleEditMenuClose}>
                                        Edit
                                    </MenuItem>
                                </Menu> :
                                (
                                    actionButtonConfigstatus && showUserActionMenu && (
                                        <Menu
                                            id={`${tableId}-menu-action`}
                                            anchorEl={menuAnchorEl}
                                            open={Boolean(menuAnchorEl)}
                                            onClose={this.handleMenuClose}
                                            className={`${classes.menuAction}`}
                                            PaperProps={
                                                {
                                                    className: `${classes.menuAction}`
                                                }
                                            }
                                        >
                                            {actionButtonConfigstatus.map((item, index: number) => (
                                                <MenuItem key={item.label} onClick={() => { item.action(selectedRowId), this.handleMenuClose() }}>
                                                    <Box color={"#888888"}>
                                                        {this.getUserStatusOption(item.label)}
                                                    </Box>
                                                </MenuItem>

                                            ))}
                                        </Menu>
                                    )


                                )
                } 
                {         
                     (showPageCount || showPagination) && (
                        <Box className={classes.paginationBox}>
                            <Box style={{ width: '50%', marginTop: '23px' }}>
                                {showPageCount && (
                                    <Box display={"flex"} style={{ gap: '6px' }}>
                                        <Typography
                                            className={`${classes.textCommonColor} ${classes.fontText18}`}
                                            component="span"
                                        >
                                            Records
                                        </Typography>
                                        <Typography
                                            className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold600}`}
                                            component="span"
                                        >
                                            11-20
                                        </Typography>
                                        <Typography
                                            className={`${classes.textCommonColor} ${classes.fontText18}`}
                                            component="span"
                                        >
                                            of 20
                                        </Typography>
                                    </Box>

                                )}
                            </Box>
                            <Box>
                            </Box>
                            {showPagination && (
                                <Box className={classes.paginationRoot}>
                                    <Pagination count={10} variant="outlined" shape="rounded" boundaryCount={1} siblingCount={0} />
                                </Box>
                            )}
                        </Box>
                    )
                }
            </>
        );
        // Customizable Area End
    }

}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(CustomTable);
// Customizable Area End