// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  sort,
} from "./assets";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { ICustomTableProps } from "../../../components/src/CommonType.web";
import {
  studentListTableColumns,subjectCoursesDetailsTableColumns,subjectTableColumns
} from "../assets/mockdata"; 
import { createRef } from "react";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export interface IDropdownItems {
  id: number;
  label: string;
  value: any;
}

export interface ITableProps {
  id: string;
  label: string;
  type: string;
  columnId: number;
  width?: string;
  bgColor?: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  schoolSubjectsList: any;
  schoolYearsList: Array<{
    id: string;
    value: string;
    label: string;
  }>;
  selectedSubjectCoursesGradesDetails: any;
  selectedSubjectId: any;
  schoolYearDDMenuItems: Array<IDropdownItems>;
  selectedSchoolYearDDValue: any;
  subjectTableColumns: Array<ITableProps>;
  subjectCoursesDetailsTableColumns: Array<ITableProps>;
  entireSchoolYearDataList: Array<{
    id: number | string;
    semesters: Array<{
      id: number;
      title: string;
    }>;
  }>;
  studentListTableColumns: Array<ICustomTableProps>;
  gradebookTableSemestersColumn:any

  selectedSchoolYearValue: number | string;
  teacherSchoolYearsList: Array<{
    id: string;
    value: string;
    label: string;
  }>;
  selectedStudentValue:string;
  rowWidth:number;
  tableHeight:number,
  totalAvgOfAllCourses:number;
  currentTermAvg:number
  schoolYear:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentMyGradebookController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetSchoolYearList:string=""      
  apiGetGradebookDetails:string=""
  apiGetGradebookAssignmentDetails:string=""
  tableRef: any;
  rowRef: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.tableRef = createRef();
    this.rowRef = createRef()

    this.state = {
      loading: false,
      gradebookTableSemestersColumn:[],
      totalAvgOfAllCourses:0,
      currentTermAvg:0,
      schoolSubjectsList: [],
      schoolYearsList: [],
      schoolYear:"",
      selectedSubjectId: 1, 
      selectedSubjectCoursesGradesDetails: [],
      schoolYearDDMenuItems: [],
      selectedSchoolYearDDValue: "",
      subjectTableColumns: subjectTableColumns,
      subjectCoursesDetailsTableColumns:subjectCoursesDetailsTableColumns,
      entireSchoolYearDataList:[],
      studentListTableColumns:studentListTableColumns,


      selectedSchoolYearValue: "",
      selectedStudentValue:"",
      teacherSchoolYearsList: [],
      rowWidth:0,
      tableHeight:0



    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    // const studentId = await 
    this.setTableHeight();
    window.addEventListener('resize', this.handleResize);
    this.handleSetSchoolYearDDItems();
    this.handleSetSelectedSchoolSubject(1);
    this.handleGetTeacherSchoolYearList()
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const { apiRequestCallId, responseJson } = getMessageData(message);
  
        if (apiRequestCallId != null) {
          switch (apiRequestCallId) {
              case this.apiGetSchoolYearList:
                  {
                    this.handleGetTeacherSchoolYearDataResponse(responseJson);
                  }
                  break;
              case this.apiGetGradebookDetails:{
                this.handleGetGradeBookDataResponse(responseJson)
              }
              break;
              case this.apiGetGradebookAssignmentDetails:{
                this.handleGetGradeBookAssignmentDetailsDataResponse(responseJson)
              }
                 

          }
        }
      }

  }
  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps:any, prevState:any) {
    if (prevState.selectedSubjectId !== this.state.selectedSubjectId) {
      this.setTableHeight();
    }
    if (prevState.schoolSubjectsList !== this.state.schoolSubjectsList) {
      this.setTableHeight();
    }
    if (prevState.selectedSubjectCoursesGradesDetails !== this.state.selectedSubjectCoursesGradesDetails) {
      this.setTableHeight();
    }
  }

  handleResize = () => {
    this.setTableHeight();
  };

  setTableHeight = () => {
    if (this.tableRef.current) {
      let height = this.tableRef.current.clientHeight -10;
      this.setState({ tableHeight: height });
    }
    if (this.rowRef.current) {
      let width = this.rowRef.current.offsetWidth - 415;
      this.setState({ rowWidth: width });
    }
  };
  handleSetSelectedSchoolSubject = (subjectId: any) => {
    const { selectedSubjectId } = this.state;
  
    if (subjectId === selectedSubjectId) {
      this.setState({
        selectedSubjectId: "",
        selectedSubjectCoursesGradesDetails: [],
      
      });
    } 
    else {
     
      this.setState({
        selectedSubjectId: subjectId,
        selectedSubjectCoursesGradesDetails: [], 
      }, async () => {
        const userId = await getStorageData("user_id")
        await this.handleGetGradebookStudentsAssignmentDetails(userId, subjectId, this.state.selectedSchoolYearValue);
  
      
      });
    }
  };

  handleSetSchoolYearDDItems = () => {
    const { schoolYearsList } = this.state;
    const schoolYearDDMenuItems: Array<IDropdownItems> = schoolYearsList?.map(
      (item: any) => {
        return {
          id: item?.id,
          value: item?.year,
          label: item?.year,
        };
      }
    );
    this.setState({ schoolYearDDMenuItems });
  };

  renderSchoolYearDDValue = (selected: any) => {
   
    if (!selected || selected.length === 0) {
      return "Select School Year";
    }
    const { schoolYearsList } = this.state;
    const selectedValue = schoolYearsList.find(
      (status) => status?.value === selected
    );
    return selectedValue?.label;
  };


  onDropdownValueChange = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
   
    if (fieldValue) {
      this.setState(
        (prev) => {
          return {
            ...prev,
            [name]: fieldValue,
          };
        },
        () => {
          if (
            name === "selectedSchoolYearValue"
          ) {
          
            this.setState({ selectedStudentValue: "" });
            this.handleSetSemesterColumns();
          }
        }
      );
    }
  };
  renderSchoolYearDropDownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select School Year";
    }
    const { teacherSchoolYearsList } = this.state;
    const selectedValue = teacherSchoolYearsList.find(
      (status) => status?.value === selected
    );
    return selectedValue?.label;
  };
  handleSearchMyGradebook = () => {
    console.log("search gradebook", this.state.selectedSchoolYearDDValue);
  };

  handleChangeDDSelect = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      },()=>{
        if (
          name === "selectedSchoolYearDDValue" 
        ) {
          this.setState({ selectedSchoolYearDDValue: "" });
          this.handleSetSemesterColumns();
        }
      });
    }
  };
  handleSetSemesterColumns = () => {
    const { selectedSchoolYearValue, entireSchoolYearDataList } = this.state;
    const selectedSchoolYearSemesterList =
      entireSchoolYearDataList.find(
        (item) => item?.id === selectedSchoolYearValue
      )?.semesters || [];
    const semesterList: Array<any> = selectedSchoolYearSemesterList?.map(
      (semesterItem) => {
        return {
          columnId: `${semesterItem?.id}`,
          id: semesterItem?.title,
          label: semesterItem?.title ,
          type: tableColumnTypes?.GRADEBOOK_AVG,
          isSelected: false,
          isSortingColumn: true,
          sortByOrder: "",
          
        };
      }
    );
    let columns = [...studentListTableColumns];
    columns.splice(1, 0, ...semesterList);
    const findObj = this.state.teacherSchoolYearsList.find((objdata)=>objdata.value === this.state.selectedSchoolYearValue) 
    const schoolYear = findObj?.label || "";
    this.setState(
      {
        gradebookTableSemestersColumn: semesterList,
        studentListTableColumns: [...columns],
        schoolYear:schoolYear
      },
      async() => {
        const studentId = await getStorageData("user_id");
        this.handleGetGradebookStudentsDetails(studentId,this.state.selectedSchoolYearValue);
      }
    );
  };
  handleGetTeacherSchoolYearList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSchoolYearList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherSchoolYear
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeacherSchoolYearDataResponse = (responseJson: {
    data: Array<{
      id: string;
      attributes: {
        title: string;
        semesters: Array<{
          id: number;
          title: string;
        }>;
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      const { studentListTableColumns } = this.state;
      const semesterList = responseJson?.data?.[0]?.attributes?.semesters?.map(
        (semester) => {
          return {
            columnId: semester?.id,
            id: semester?.title,
            label: semester?.title,
            type: tableColumnTypes?.GRADEBOOK_AVG,
            icon: sort,
            isSelected: false,
            isSortingColumn: true,
            sortByOrder: "",
          };
        }
      );
      const columns = [...studentListTableColumns];
      columns.splice(1, 0, ...semesterList);
      const columnDetails:any = [...subjectCoursesDetailsTableColumns]
      columnDetails.splice(2, 0, ...semesterList);
      const schoolYearList = responseJson?.data?.map((item) => {
        return {
          id: item?.id,
          value: item?.id,
          label: item?.attributes?.title,
        };
      });
      const allSchoolYearList = responseJson?.data?.map((item) => {
        return {
          id: item?.id,
          semesters: item?.attributes?.semesters,
        };
      });
    
   
      this.setState(
        {
          teacherSchoolYearsList: schoolYearList,
          subjectCoursesDetailsTableColumns:[...columnDetails],
          selectedSchoolYearValue: schoolYearList?.[0]?.id || "",
          schoolYear:schoolYearList?.[0]?.label || "",
          studentListTableColumns: [...columns],
          selectedStudentValue: "",
          entireSchoolYearDataList: allSchoolYearList,
          gradebookTableSemestersColumn: semesterList,
        },
          async() => {
            const studentId = await getStorageData("user_id");
            this.handleGetGradebookStudentsDetails(studentId,this.state.selectedSchoolYearValue);
  
          }
      );
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader()
  };
  handleGetGradebookStudentsDetails = async (student_id:any,school_id:any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradebookDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.gradebookStudentDetailsEndpoint}?student_id=${student_id}&school_year_id=${school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetGradeBookDataResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const tablebodydata = this.transformData(responseJson)
      let totalAvgOfAllCoursesData = responseJson.data.attributes.courses_averages.find(
        (course: any) => course.total_avgs_of_all_courses
      )?.total_avgs_of_all_courses || 0;
      totalAvgOfAllCoursesData = parseFloat(totalAvgOfAllCoursesData.toFixed(2));

      let currenTermAvgData= responseJson.data.attributes.courses_averages.find(
        (course: any) => course.current_semester_avg
      )?.current_semester_avg || 0;
      currenTermAvgData = parseFloat(currenTermAvgData.toFixed(2));


     this.setState({schoolSubjectsList:tablebodydata,totalAvgOfAllCourses:totalAvgOfAllCoursesData,currentTermAvg:currenTermAvgData})
        
    } 
    else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };
  transformData = (apiData:any) => {
    const courses = apiData.data.attributes.courses_averages.filter((course:any) => !course.total_avgs_of_all_courses && course.avgs_of_courses);
  
    return courses.map((course:any, index:any) => {
      const mapSemesterScoresToQuarters = (avgs:any) => {
        const quarterScores:any = [];
        if (Array.isArray(avgs)) {
          avgs.forEach((avg, i) => {
            quarterScores.push({
              quarter: `quarterScore${i + 1}`,
              score: avg.semester_avg || 0,
              status:avg.status
            });
          });
        }
  
        return quarterScores;
      };
  
      
      const quarters = mapSemesterScoresToQuarters(course.avgs_of_courses || []);
      const quarterScores = quarters.reduce((acc:any, curr:any) => {
        acc[curr.quarter] = curr.score;
        acc[`${curr.quarter}Status`] = curr.status;
        return acc;
      }, {});
  
      return {
        id: index + 1,
        subject: {
          name: course.course_title,
          bgColor:course.course_image.color,
          icon:course.course_image.icon 
        },
        subjectId: course.course_id,
        ...quarterScores,
        progress:course.course_progress?.completed === 100 ? "100%" : `${(Math.round((course.course_progress?.completed || 0) * 100) / 100).toFixed(2)}`,
        totalScore: course.total_avg_of_course,
        grade:course.grade === "No grading_system for this value" ? "NA" : course.grade,
        coursesDetails: {
          assignment: {
            firstQuarterScore: 0,
            secondQuarterScore: 0,
            thirdQuarterScore: 0,
            fourthQuarterScore: 0,
            totalScore: 0,
            weight: 15,
          },
        },
      };
    });
  };
  handleGetGradebookStudentsAssignmentDetails = async (student_id:any,course_id:any,school_year_id:any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradebookAssignmentDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.gradebookStudentAssignmentDetailsEndpoint}?student_id=${student_id}&course_id=${course_id}&school_year_id=${school_year_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  transformDataForAssignment = (responseJson:any) => {
    const data = responseJson.data;
  const selectedSubjectCoursesGradesDetails:any = {};

 
  const mapSemesterScoresToQuarters = (gradingData:any) => {
    const quarterScores:any = {};

    gradingData.forEach((grade:any, index:any) => {
      quarterScores[`quarter${index + 1}Score`] = (Number.isInteger(grade.semester_avg) ? grade.semester_avg : parseFloat(grade.semester_avg.toFixed(2)) ) || 0;
    });

    return quarterScores;
  };


  data.forEach((item:any) => {
    const gradingComponent = item.attributes.grading_component;
    const gradingComponentAverages = item.attributes.grading_component_averages;

   
    if (gradingComponentAverages && gradingComponentAverages.grading && Array.isArray(gradingComponentAverages.grading)) {
     
      gradingComponentAverages.grading.forEach((average:any) => {
        if (!selectedSubjectCoursesGradesDetails[gradingComponent]) {
          selectedSubjectCoursesGradesDetails[gradingComponent] = {
            id: average.id,
            title: gradingComponent,
            totalScore: Number.isInteger(gradingComponentAverages.total_grading_average) 
            ? gradingComponentAverages.total_grading_average 
            : parseFloat(gradingComponentAverages.total_grading_average.toFixed(2)),
            weight: `${item.attributes.weightage}%`,
            ...mapSemesterScoresToQuarters(gradingComponentAverages.grading), 
          };
        } else {
        
          selectedSubjectCoursesGradesDetails[gradingComponent].totalScore += average.total_received_score;
          selectedSubjectCoursesGradesDetails[gradingComponent].quarter1Score += average.semester_avg || 0;
        }
      });
    } else {
      console.error('grading_component_averages.grading is not an array:', gradingComponentAverages);
    }
  });

  
  const transformedDataArray = Object.values(selectedSubjectCoursesGradesDetails);

  return transformedDataArray;
  };
  handleGetGradeBookAssignmentDetailsDataResponse = (responseJson: any) => {
    if (responseJson?.data) {
        const selectedSubjectCoursesGradesDetails = this.transformDataForAssignment(responseJson);
        this.setState({
          selectedSubjectCoursesGradesDetails: selectedSubjectCoursesGradesDetails,
        });
    } 
    else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };


  // Customizable Area End
}
// Customizable Area End
