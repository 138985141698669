// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  studentName?: string;
  studentClassId?: number;
  studentPerformanceData: Array<{
    id: number;
    courseName: string;
    subjectName: string;
    courseMarks: number;
    subjectBgColor: string;
    progressPercentage: number;
  }>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  studentProgressPerformanceData: Array<{
    id: number;
    courseName: string;
    courseMarks: number;
    subjectName: string;
    subjectBgColor: string;
    progressPercentage: number;
  }>;
  TabNames: any;
  currentRole: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentPerformanceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      TabNames: [],
      studentProgressPerformanceData: [],
      currentRole: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleSetRole();
    this.handleSetPerformanceData();
    // Customizable Area End
  }
  // Customizable Area Start

  handleSetRole = async() => {
    const role = await getStorageData("role");
    this.setState({ currentRole: role });
  }

  handleSetPerformanceData = () => {
    const { studentPerformanceData } = this.props;
    this.setState({ studentProgressPerformanceData: studentPerformanceData })
  }

  getPerformanceGraphLabels = () => {
    const { studentProgressPerformanceData } = this.state;
    const labelLists: Array<string> = studentProgressPerformanceData?.map(
      (data: any) => data.courseName
    );
    return labelLists || [];
  };

  getPerformanceGraphData = () => {
    const { studentProgressPerformanceData } = this.state;
    const graphDataLists: Array<number> = studentProgressPerformanceData?.map(
      (data: any) => data.progressPercentage
    );
    return graphDataLists || [];
  };

  getPerformanceGraphBarBgColor = () => {
    const { studentProgressPerformanceData } = this.state;
    const graphBarBgColors: Array<string> = studentProgressPerformanceData?.map(
      (data: any) => data.subjectBgColor
    );
    return graphBarBgColors || [];
  };

  handlePerformanceGraphColor = () => {
    const isDarkMode =
      localStorage.getItem("dark_mode") || JSON.stringify(false);
    if (JSON.parse(isDarkMode)) return "#ffffff";
    return "#000";
  };

  handleBarClick = (clickedInfo: any) => {
    const { currentRole } = this.state;
    const { studentName, studentClassId } = this.props;
    if (clickedInfo.dataIndex !== undefined) {
      const subjectName =
        this.state.studentProgressPerformanceData[clickedInfo.dataIndex]
          ?.subjectName;
      this.setState({
        TabNames: subjectName,
      });
      if (currentRole.toLowerCase() === "registrar") {
        this.props.navigation.navigate(
          "RegistrarStudentPerformanceAssessment", {}, { studentName: studentName, classId: studentClassId });
      } else {
        this.props.navigation.navigate("TeacherStudentPerformanceAssessment", {}, { studentName: studentName, classId: studentClassId });
      }
    }
  };
  // Customizable Area End
}
// Customizable Area End
