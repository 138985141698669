Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.userStatusTitle = "User Status List";
exports.userStatusApiMethod = "GET";
exports.userCreateAPiMethod="POST"
exports.patchPostAPiMethod="PUT";
exports.userStatusApiContentType = "application/json";
exports.userStatusApiEndpont = "account_block/users";
exports.studentParentListingApiEndpoint = "account_block/users?type=";
exports.gradesEndpoint = "bx_block_grade/grades";
exports.curriculumsEndpoint = "bx_block_curriculum/curriculums?subject_id=";
exports.curriculumsGradeEndpoint = "bx_block_curriculum/curriculums?grade_id=";
exports.getSchoolYearEndpoint = "bx_block_school_year/school_years";
exports.getStudentParentNames = "account_block/users/search_name?type=";
exports.getTeacherParentTeacherNames = "account_block/users/search_name?type=Teacher";
exports.getTeacherParentNmaesList= "bx_block_roles_permissions/roles";
exports.getSubjectHandlesList = "bx_block_subject/subjects?grade_id=";
exports.getSearchParentList = "account_block/users/search_parent";
exports.curriculumFilterList = "bx_block_curriculum/curriculums/fetch_filters";
exports.customFieldEndpoint = "bx_block_custom_field/custom_fields";
// Customizable Area End