import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

// Customizable Area Start
import { studentCoursesList, studentListTableBody, studentListTableColumns } from "../assets/mockdata";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  studentCoursesList: any;
  studentListTableBody: Array<any>;
  studentListTableColumns: Array<any>;
  selectedSchoolYearValue: string,
  selectedCurriculumValue: string,
  selectedStudentValue: string,
  selectedSortByGpaValue: string,
  // Customizable Area End
}

export default class ParenTeacherGradebookController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      studentCoursesList: studentCoursesList,
      studentListTableBody: studentListTableBody,
      studentListTableColumns: studentListTableColumns,
      selectedSchoolYearValue: '',
      selectedCurriculumValue: '',
      selectedStudentValue: '',
      selectedSortByGpaValue: '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }

  getDropdownItemsArray = (dropdownName: string) => {
    const refArray = [1, 2, 3, 4, 5];
    let dropdownItems: Array<any> = [];
    refArray.forEach(item => {
      dropdownItems.push({
        id: item,
        label: `${dropdownName}${item}`,
        value: `${dropdownName}${item}`,
      });
    });
    return dropdownItems;
  }

  checkAndGetSelectedValue = (selected: any, dropdownName: string, dropdownItems: any) => {
    if (!selected || selected.length === 0) {
      return `${dropdownName}`;
    }
    const selctedOption = dropdownItems.find((item: any) => item?.value === selected);
    return selctedOption?.label;
  }

  renderSchoolYearDropdownValue = (selected: any) => {
    const dropdownName = "School Year";
    const dropdownItems = this.getDropdownItemsArray('2020-202');
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderCurriculumDropdownValue = (selected: any) => {
    const dropdownName = "Curriculum";
    const dropdownItems = this.getDropdownItemsArray('Curriculum');
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderStudentDropdownValue = (selected: any) => {
    const dropdownName = "All Students";
    const dropdownItems = this.getDropdownItemsArray('Student');
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderSortByGPADropdownValue = (selected: any) => {
    const dropdownName = "Ascending";
    const dropdownItems = [
      {
        id: 1,
        label: `Ascending`,
        value: `Ascending`,
      },
      {
        id: 2,
        label: `Decending`,
        value: `Decending`,
      }
    ];
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  handleChangeDropdownValue = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
  }>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  openUserDetailPage = () => {
    this.props.navigation.navigate('GradeUserDetailPage', {});
  }
  // Customizable Area End
}