// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedRowId: number;
  selectedBrowseTitleDDValue: any;
  selectedBrowseSubjectDDValue: any;
  selectedBrowseGradeDDValue: any;
  titleBrowseTitleComponentValue: Array<any>;
  BrowseSubjectComponentValue: Array<any>;
  BrowseGradeComponentValue: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherBrowseCourseCatModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      selectedRowId: -1,
      selectedBrowseTitleDDValue: "",
      selectedBrowseSubjectDDValue:"",
      selectedBrowseGradeDDValue: "",
      titleBrowseTitleComponentValue: [
        {
          id: 1,
          label: "Profit and Loss",
          value: 1,
        },
        {
          id: 2,
          label: "Roman History",
          value: 2,
        },
        {
          id: 3,
          label: "Victorian Literature",
          value: 3,
        },
        {
          id: 4,
          label: "English Poetry",
          value: 4,
        },
      ],
     BrowseSubjectComponentValue:[
        {
          id: 1,
          label: "Science",
          value: 1,
        },
        {
          id: 2,
          label: "Maths",
          value: 2,
        },
        {
          id: 3,
          label: "History",
          value: 3,
        },
        {
          id: 4,
          label: "English",
          value: 4,
        },
      ],
      BrowseGradeComponentValue:[
        {
          id: 1,
          label: "Grade 1",
          value: 1,
        },
        {
          id: 2,
          label: "Grade 2",
          value: 2,
        },
        {
          id: 3,
          label: "Grade 3",
          value: 3,
        },
        {
          id: 4,
          label: "Grade 4",
          value: 4,
        },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();

  }

  renderBrowseTitleDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Title";
    }
    const { titleBrowseTitleComponentValue } = this.state;
    const selectedGradingComponents = titleBrowseTitleComponentValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderBrowseSubjectDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Subject";
    }
    const { BrowseSubjectComponentValue } = this.state;
    const selectedGradingComponents = BrowseSubjectComponentValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderBrowseGradeDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Grade";
    }
    const { BrowseGradeComponentValue } = this.state;
    const selectedGradingComponents = BrowseGradeComponentValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  handleBrowseDropdownChange = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
  }>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  handleSearch = () => {
    console.log("Search");
  }

  handleSelectedRow = (tableRowId: number) => {
    if (tableRowId) {
      this.setState({ selectedRowId: tableRowId });
    } else {
      this.setState({ selectedRowId: -1 });
    }
  }
  // Customizable Area End
}
// Customizable Area End