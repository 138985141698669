// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { RefObject, createRef } from "react";
// Customizable Area End

// Customizable Area Start

interface IRoleProps {
  id: any;
  label: string;
  value: any;
  disabled?: boolean;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  open: boolean;
  handleNext: (role: string) => void;
  handleClose: () => void;
  roleOptions: Array<IRoleProps>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedRole: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddUserRoleSelectPopupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  readonly fileInputRef: RefObject<HTMLInputElement>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedRole: "",
    };
    this.fileInputRef = createRef<HTMLInputElement>();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderDDMenuItem = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Role";
    }
    const { roleOptions } = this.props;
    const selectedItem = roleOptions.find(
      (item: IRoleProps) => item.value === selected
    );
    return selectedItem?.label;
  };

  handleSelectDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  handleClosePopup = () => {
    this.setState({ selectedRole: "" });
    this.props.handleClose();
  };

  handleNext = () => {
    const { selectedRole } = this.state;
    const { roleOptions } = this.props;
    const selectedItem: any = roleOptions.find(
      (item: IRoleProps) => item.value === selectedRole
    );
    this.setState({ selectedRole: "" }, () => {
      this.props.handleNext(selectedItem?.value);
    });
  };

  // Customizable Area End
}
// Customizable Area End
