// Customizable Area Start
import React from "react";
import { Grid, Box, Typography, Button, Avatar } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import RegularTeacherController, {
  Props,
} from "./RegularTeacherController.web";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import EmptyResults from "../../../../components/src/EmptyResults.web";
import CustomTextField from "../../../../components/src/CustomTextField.web";
import CustomButton from "../../../../components/src/CustomButton.web";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CustomImageComponentWeb from "../../../../components/src/CustomImageComponent.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    teacherGridItem: {
      minHeight: "128px",
    },
    avatar: {
      borderRadius: "50%",
      width: "50px",
      height: "50px",
      marginRight: "5px"
    },
    hoveredCourse: {
      "&:hover": {
        backgroundColor: "#666666", // Darker transparent color on hover
        cursor: "pointer",
      },
      position: "relative",
      "&:before": {
        position: "absolute",
        content: '""',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
        background: "rgba(0, 0, 0, 0.3)",
        transition: "background-color 0.3s ease-in-out",
        opacity: 0,
        pointerEvents: "none",
        borderRadius: "12px",
      },
      "&:hover:before": {
        opacity: 1,
      },
    },
    hoveredButton: {
      "&:hover": {
        backgroundColor: "#01C996",
      },
      top: "50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      left: "50%",
      background: "#0d45bc",
      borderRadius: "12px",
      padding: "12px",
      cursor: "pointer",
      color: "#fff",
      transition: "opacity 0.3s",
      pointerEvents: "auto",
      zIndex: 2,
      width: "120px",
      textTransform: "capitalize",
      boxShadow: "none",
      fontSize: "16px",
      backgroundColor: "#01C996",
    },
    removeHoveredButton: {
      "&:hover": {
        backgroundColor: "##ED1111",
      },
      backgroundColor: "##ED1111",
    },
    card: {
      padding: "30px 20px 20px 20px",
      backgroundColor: "#F5F5F5",
      borderRadius: "12px",
      maxWidth: "263px",
      width: "auto",
      height: "128px",
    },
    teacherProfileIconBox: {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
    },
    teacherProfileIcon: {
      width: "inherit",
      height: "inherit",
      objectFit: "contain",
      borderRadius: "50%",
    },
  });
// Customizable Area End

// Customizable Area Start
export class RegularTeacher extends RegularTeacherController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End
  //istanbul ignore next
  render() {
    // Customizable Area Start
    const { classes } = this.props;

    const {
      searchField,
      loading,
      hoverTeacher,
      selectedTeachers,
      teachersData,
      hoverSelectedTeacher,
    } = this.state;
    return (
      <Box padding={"0px 18px 0px 30px"}>
        <Box mb={4}>
          <CustomTextField
            placeHolderText="Search Teacher's Profile"
            fieldName="searchField"
            fieldId="regular-other-teacher-search-field"
            textFieldStyles={{
              padding: "10px 0",
              width: "440px",
              maxWidth: "100%",
              marginRight: "20px",
            }}
            fieldValue={searchField}
            fieldWidth={"100%"}
            fieldType="text"
            handleChange={this.handleChange}
          />
          <CustomButton
            btnText="Search"
            btnStyle={searchBtnStyle}
            buttonId={`regular-other-teacher-search-button`}
            buttonVariant={"contained"}
            type={"button"}
            handleButtonClick={() => this.handleGetTeachersList()}
            isDisabled={false}
          />
        </Box>
        <Box>
          <Grid container direction="row" spacing={3}>
            {selectedTeachers?.map((selectedTeacher: any) => (
              <Grid
                className={`${classes.teacherGridItem}`}
                key={selectedTeacher?.id}
                item
                xs={12}
                sm={6}
                lg={4}
              >
                <Box
                  key={selectedTeacher?.id}
                  data-test-id={`selectedTeacher-${selectedTeacher?.id}`}
                  onMouseEnter={() =>
                    this.handleMouseEnterSelectedTeacher(selectedTeacher.id)
                  }
                  onMouseLeave={() => this.handleMouseLeaveSelectedTeacher()}
                  className={`${classes.card} ${
                    hoverSelectedTeacher ? classes.hoveredCourse : ""
                  } ${classes.cardBgColor}`}
                  style={{
                    height: "auto",
                    border: "1px solid #FFD92E",
                    position: "relative",
                  }}
                >
                  <CheckCircleIcon
                    style={{
                      color: "#FFD92E",
                      width: 26,
                      height: 26,
                      position: "absolute",
                      top: 10,
                      right: 10,
                    }}
                  />
                  <Box style={{ alignItems: "center", display: "flex" }}>
                      <Box className={classes.teacherProfileIconBox}>
                        <CustomImageComponentWeb
                          key={selectedTeacher?.profileImg || "teacher-profile"}
                          src={selectedTeacher?.profileImg}
                          altText="teacher-profile-picture"
                          imgStyles={{
                            width: "55px",
                            height: "55px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          isUserProfileImg
                        />
                      </Box>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}
                    >
                      {selectedTeacher?.name}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      alignItems: "center",
                      margin: "10px 0",
                      display: "flex",
                    }}
                  >
                    <Typography
                      className={`${classes.fontText12} ${classes.textCommonColor}`}
                    >
                      Email ID:
                    </Typography>
                    <Typography
                      className={`${classes.fontText12} ${classes.textPrimaryColor}`}
                    >
                      {selectedTeacher?.email}
                    </Typography>
                  </Box>
                  <Box style={{ alignItems: "center", display: "flex" }}>
                    <Typography
                      className={`${classes.fontText12} ${classes.textCommonColor}`}
                    >
                      Role:
                    </Typography>
                    <Typography
                      className={`${classes.fontText12} ${classes.textPrimaryColor}`}
                    >
                      Teacher
                    </Typography>
                  </Box>
                  {hoverSelectedTeacher === selectedTeacher?.id && (
                    <Button
                      className={`${classes.removeHoveredButton} ${classes.hoveredButton}`}
                      data-test-id={`remove-teacher-btn-${selectedTeacher?.id}`}
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        this.handleRemoveTeacher(selectedTeacher.id)
                      }
                      style={{ backgroundColor: "#ED1111" }}
                    >
                      Remove
                    </Button>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        {teachersData?.length > 0 ? (
          <Grid
            style={{ marginBottom: "20px" }}
            container
            direction="row"
            spacing={3}
          >
            {teachersData.map((teacher) => (
              <Grid
                style={{ minHeight: "128px" }}
                key={teacher?.id}
                item
                data-test-id={`all-teachers-list-${teacher?.id}`}
                xs={12}
                sm={6}
                lg={4}
              >
                <Box
                  key={teacher?.id}
                  data-test-id={`teacher-${teacher?.id}`}
                  onMouseEnter={() => this.handleMouseEnter(teacher?.id)}
                  onMouseLeave={this.handleMouseLeave}
                  className={`${classes.card} ${
                    hoverTeacher ? classes.hoveredCourse : ""
                  } ${classes.cardBgColor}`}
                  style={{ height: "auto" }}
                >
                  <Box
                    data-test-id={`teacher-profile-data-box-${teacher?.id}`}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                      <Box className={classes.teacherProfileIconBox}>
                        <CustomImageComponentWeb
                          src={teacher?.profileImg}
                          isUserProfileImg
                          altText="teacher-profile-picture"
                          imgStyles={{
                            height: "55px",
                            borderRadius: "50%",
                            width: "55px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    <Typography
                      className={`${classes.fontText16} ${classes.textPrimaryColor} ${classes.fontBold600}`}
                      data-test-id={`teacher-name-${teacher?.id}`}
                    >
                      {teacher?.name}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "10px 0",
                    }}
                    data-test-id={`teacher-email-data-box-${teacher?.id}`}
                  >
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText12} `}
                    >
                      Email ID:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText12}`}
                    >
                      {teacher?.email}
                    </Typography>
                  </Box>
                  <Box
                    data-test-id={`teacher-role-data-box-${teacher?.id}`}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText12}`}
                    >
                      Role:
                    </Typography>
                    <Typography
                      data-test-id={`role-${teacher?.id}`}
                      className={`${classes.textPrimaryColor} ${classes.fontText12}`}
                    >
                      Teacher
                    </Typography>
                  </Box>
                  {hoverTeacher === teacher?.id && (
                    <Button
                      data-test-id={`add-teacher-btn-${teacher?.id}`}
                      className={`${classes.hoveredButton}`}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.handleTeacherAdd(teacher?.id);
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <EmptyResults
            message={
              !loading
                ? "You will see teachers profiles once you search for it from above search box."
                : "No data yet."
            }
          />
        )}
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const searchBtnStyle = {
  width: "130px",
  padding: "10px 10px",
  backgroundColor: "#0D46BC",
  color: "#ffffff",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(RegularTeacher);
// Customizable Area End
