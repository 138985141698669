// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  englishSubjectIcon,
  historySubjectIcon,
  mathSubjectIcon,
  physicsSubjectIcon,
  psychologySubjectIcon,
} from "./assets";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export const myPathCategoryTabsList = [
  {
    id: 1,
    label: "Today",
    value: "today",
  },
  {
    id: 2,
    label: "Overdue",
    value: "overdue",
  },
  {
    id: 3,
    label: "Upcoming",
    value: "upcoming",
  },
  {
    id: 4,
    label: "Review",
    value: "review",
  },
];
export interface IDropdownItems {
  id: number;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  studentMyPathCourseList: any;
  studentSubjectsList: any;
  selectedMyPathCategoryTab: number;
  subjectDDMenuItems: Array<IDropdownItems>;
  selectedSubjectDDValue: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentMyPathController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      studentMyPathCourseList: [
        {
          id: 1,
          courseName: "Physics",
          subjectIcon: physicsSubjectIcon,
          subjectBgColor: "#01C996",
          subjectId: 1,
          topicName: "Kinematics",
          dueDate: "24/08/2023",
          score: 51,
          typeOfAssessment: "quiz",
          assessmentTitle: "Graphing Quiz",
        },
        {
          id: 2,
          courseName: "Calculus",
          subjectIcon: mathSubjectIcon,
          subjectBgColor: "#0D46BC",
          subjectId: 2,
          topicName: "Derivatives",
          dueDate: "24/08/2023",
          score: 44,
          typeOfAssessment: "exam",
          assessmentTitle: "Differentiability Exam",
        },
        {
          id: 3,
          courseName: "Creative Writing",
          subjectIcon: englishSubjectIcon,
          subjectBgColor: "#019AFC",
          subjectId: 3,
          topicName: "Contemporary Fiction",
          dueDate: "24/08/2023",
          score: 80,
          typeOfAssessment: "assignment",
          assessmentTitle: "Fiction Assignment",
        },
        {
          id: 4,
          courseName: "Chemistry",
          subjectIcon: physicsSubjectIcon,
          subjectBgColor: "#FBD82C",
          subjectId: 1,
          topicName: "Organic Chemistry",
          dueDate: "24/08/2023",
          score: 31,
          typeOfAssessment: "quiz",
          assessmentTitle: "Alkenes Quiz",
        },
        {
          id: 5,
          courseName: "Child Psychology",
          subjectIcon: psychologySubjectIcon,
          subjectBgColor: "#FF0000",
          subjectId: 5,
          topicName: "Intelligence & Attainment",
          dueDate: "24/08/2023",
          score: 47,
          typeOfAssessment: "project",
          assessmentTitle: "Intelligences Project",
        },
        {
          id: 6,
          courseName: "Roman History",
          subjectIcon: historySubjectIcon,
          subjectBgColor: "#FF0000",
          subjectId: 5,
          topicName: "Punic Wars",
          dueDate: "24/08/2023",
          score: 31,
          typeOfAssessment: "quiz",
          assessmentTitle: "First Punic War Quiz",
        },
      ],
      studentSubjectsList: [
        {
          id: 1,
          subjectName: "Physics",
        },
        {
          id: 2,
          subjectName: "Calculus",
        },
        {
          id: 3,
          subjectName: "Creative Writing",
        },
        {
          id: 4,
          subjectName: "Chemistry",
        },
        {
          id: 5,
          subjectName: "Child Psychology",
        },
        {
          id: 6,
          subjectName: "Roman History",
        },
      ],
      selectedMyPathCategoryTab: 0,
      subjectDDMenuItems: [],
      selectedSubjectDDValue: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetSubjectDDItems();
    // Customizable Area End
  }

  // Customizable Area Start

  handleSetSubjectDDItems = () => {
    const { studentSubjectsList } = this.state;
    const subjectDDMenuItems: Array<IDropdownItems> = studentSubjectsList?.map(
      (subject: any) => {
        return {
          id: subject?.id,
          value: subject?.id,
          label: subject?.subjectName,
        };
      }
    );
    this.setState({ subjectDDMenuItems });
  };

  handleChangeMyPathCategoryTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ selectedMyPathCategoryTab: newValue });
  };

  renderSubjectDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Subject";
    }
    const { subjectDDMenuItems } = this.state;
    const selctedSubject = subjectDDMenuItems.find(
      (subject: any) => subject?.id === selected
    );
    return selctedSubject?.label;
  };

  handleSearchMyPathCourse = () => {
    console.log("search assessment", this.state.selectedSubjectDDValue);
  };

  handleChangeDDSelect = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  // Customizable Area End
}
// Customizable Area End
