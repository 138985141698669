// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentProfileController, {
  Props
} from "./StudentProfileController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { studentProfile, parent1, parent2 } from "./assets";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import GearIconDropDown from "../../../components/src/GearIconDropDown.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    inputLabel: {
      margin: theme.spacing(2.5, 0),
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400
    },
    fieldValue: {
      color: "#090909",
      fontSize: "16px",
      fontWeight: 600
    },
    fieldBox: {
      margin: theme.spacing(2.5, 0),
      backgroundColor: "#FFFFFF",
      borderRadius: "12px"
    },
    parentHeader: {
      color: "#090909",
      fontSize: "20px",
      fontWeight: 600,
      padding: "25px"
    },
    studentHeader: {
      color: "#090909",
      fontSize: "22px",
      fontWeight: 600
    },
    profileHeading: {
      color: "#090909",
      fontSize: "34px",
      fontWeight: 700
    },
    headerWrappper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "15px 0 30px 0"
    },
    headLineWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      cursor: "pointer"
    },
    backIcon: {
      cursor: "pointer",
      fontSize: "34px"
    },
    fieldWrapper: {
      display: "flex",
      gap: "15px",
      alignItems: "center",
      padding: "25px"
    },
    boxWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "0 25px"
    },
    parentHeaderWrapper: {
      backgroundColor: "#F1F1F1",
      height: "1px"
    },
    parentBodyWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "0 25px"
    },
    contentCenter: {
      display: "flex",
      alignItems: "center"
    },
    imageMargin: {
      margin: "0 8px"
    },
    rootBox: {
      padding: "40px"
    },
    flexBox: {
      display: "flex",
      flexDirection: "column"
    }
  });
// Customizable Area End

// Customizable Area Start
export class StudentProfile extends StudentProfileController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${
          classes.highlightBgColor
        } ${classes.secondaryBgColor} ${classes.rootBox}`}
        height={"100%"}
      >
        <Box marginTop={"1%"}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Dashboard /`}
          </Typography>
          <Typography
            className={`${classes.primaryColor5} ${classes.fontText14} ${
              classes.fontBold600
            }`}
            component={"span"}
          >
            {` My Profile`}
          </Typography>
        </Box>
        <Box className={classes.headerWrappper}>
          <Box className={classes.headLineWrapper} onClick={this.handleGoBack}>
            <KeyboardBackspaceIcon
              className={`${classes.backIcon} ${classes.textPrimaryColor}`}
            />
            <Typography
              className={`${classes.profileHeading} ${
                classes.textPrimaryColor
              }`}
            >
              My Profile
            </Typography>
          </Box>
          <GearIconDropDown
            data-testid="profile-settings"
            options={this.state.profileSettingOptions}
          />
        </Box>
        <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
          <Box className={classes.fieldWrapper}>
            <img alt="student-profile" src={studentProfile} />
            <Typography
              className={`${classes.studentHeader} ${classes.textPrimaryColor}`}
            >
              Antonin Hafer{" "}
            </Typography>
          </Box>
          <Box className={classes.boxWrapper}>
            <Box>
              <Typography className={classes.inputLabel}>
                Email ID:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  antoninhafer521@gmail.com
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                Date of Birth:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Jan 06, 2008
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                Role:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Student
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                Custom Field 1:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Value 1
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                Status:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Active
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.inputLabel}>
                ID Number:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  5201
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                Grade Level:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Grade 7
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                Class:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Class B{" "}
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                Custom Field 2:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Value 1
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.inputLabel}>
                Curriculum:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Value
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                School Year Title:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  2022-2023
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                Expiring On:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  31/05/2023
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                Custom Field 3:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Value 1
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
          <Box>
            <Typography
              className={`${classes.parentHeader} ${classes.textPrimaryColor}`}
            >
              Parent 1
            </Typography>
          </Box>
          <Box className={classes.parentHeaderWrapper} />
          <Box className={`${classes.parentBodyWrapper} ${classes.bgColor}`}>
            <Box>
              <Box className={classes.contentCenter}>
                <Typography
                  className={`${classes.contentCenter} ${classes.inputLabel}`}
                >
                  Name:{" "}
                  <span
                    className={`${classes.contentCenter} ${classes.fieldValue} ${
                      classes.textPrimaryColor
                    }`}
                  >
                    {" "}
                    <img
                      alt="student-profile-parent1"
                      src={parent1}
                      className={classes.imageMargin}
                    />
                    Alice Hafer
                  </span>
                </Typography>
              </Box>
              <Typography className={classes.inputLabel}>
                Email ID:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  alicehafer@gmail.com
                </span>
              </Typography>
            </Box>
            <Box
              className={classes.flexBox}
              style={{ justifyContent: "inherit" }}
            >
              <Typography className={classes.inputLabel}>
                Relationship:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Mother
                </span>
              </Typography>
              <Typography className={classes.inputLabel}>
                Role:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Parent
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.inputLabel}>
                Contact Number:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  +91-9876543210
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
          <Box>
            <Typography
              className={`${classes.parentHeader} ${classes.textPrimaryColor}`}
            >
              Parent 2
            </Typography>
          </Box>
          <Box className={classes.parentHeaderWrapper} />
          <Box className={classes.parentBodyWrapper}>
            <Box>
              <Box className={classes.contentCenter}>
                <Typography
                  className={`${classes.inputLabel} ${classes.contentCenter}`}
                >
                  Name:{" "}
                  <span
                    className={`${classes.contentCenter} ${
                      classes.fieldValue
                    } ${classes.textPrimaryColor}`}
                  >
                    <img
                      alt="student-profile-parent2"
                      src={parent2}
                      className={classes.imageMargin}
                    />
                    Robert Hafer
                  </span>
                </Typography>
              </Box>
              <Typography className={classes.inputLabel}>
                Email ID:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  roberthafer@gmail.com
                </span>
              </Typography>
            </Box>
            <Box
              className={classes.flexBox}
              style={{ justifyContent: "inherit" }}
            >
              <Box>
                <Typography className={classes.inputLabel}>
                  Relationship:{" "}
                  <span
                    className={`${classes.fieldValue} ${
                      classes.textPrimaryColor
                    }`}
                  >
                    Father
                  </span>
                </Typography>
              </Box>
              <Typography className={classes.inputLabel}>
                Role:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  Parent
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.inputLabel}>
                Contact Number:{" "}
                <span
                  className={`${classes.fieldValue} ${
                    classes.textPrimaryColor
                  }`}
                >
                  +91-9876543210
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentProfile);
// Customizable Area End
