// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  coursesDetailsMock,
  fileStorageDetailsMock,
  topTenCoursesListMock,
  userTypesListMock,
} from "../assets/tenantDashboardMock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  userTypesList: Array<any>;
  topTenCoursesList: Array<any>;
  coursesDetails: Array<any>;
  fileStorageDetails: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantStatisticsDashboardTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTenantStatisticsInfo: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      userTypesList: userTypesListMock,
      coursesDetails: coursesDetailsMock,
      fileStorageDetails: fileStorageDetailsMock,
      topTenCoursesList: topTenCoursesListMock,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setStatisticsData();
    // Customizable Area End
  }

  // Customizable Area Start
  setStatisticsData = () => {
    const { userTypesList } = this.state;
    const updatedUserTypesList = userTypesList.map((item) => {
      item.total = `${item.active + item.inactive} / ${
        item.total_count
      }`;
      return item;
    });
    this.setState({ userTypesList: updatedUserTypesList });
  };
  // Customizable Area End
}
// Customizable Area End
