// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  classId: string[]|string;
  searchByStudent: string;
  gradingComponentsdata:any;
  currentRole:string;
  studentIds:string| number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedStudentName: string;
  courseName: string;
  selectedClassComponentDDValue: any;
  selectedStatusComponentDDValue: any;
  selectedDueDateComponentDDValue: any;
  gradingComponent: Array<any>;
  StatusComponent: Array<any>;
  DueDateComponent: Array<any>;
  AssessmentAllTableColumn: Array<any>;
  AssessmentTableBody: Array<any>;
  searchStudent: string;
  paginationLimit: number;
  paginationData: any;
  timer: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AssessmentsAllController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProfileData: string = "";
  apiGetTeacherAssessments: string = "";
  getAllAssessmentSearchStudentsId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      selectedStudentName: "",
      courseName: "",
      searchStudent: "",
      gradingComponent: [
        {
          id: 1,
          label: "Quiz",
          value: "quiz",
        },
        {
          id: 2,
          label: "Exam",
          value: "exam",
        },
        {
          id: 3,
          label: "Assignment",
          value: "assignment",
        },
        {
          id: 4,
          label: "Project",
          value: "project",
        }
      ],
      StatusComponent: [
        {
          id: 2,
          label: "Graded",
          value: "graded",
        },
        {
          id: 3,
          label: "Assigned",
          value: "assigned",
        }
      ],
      DueDateComponent: [
        {
          id: 1,
          label: "Ascending",
          value: "asc",
        },
        {
          id: 2,
          label: "Decending",
          value: "desc",
        }
      ],
      AssessmentAllTableColumn: [
        {
          id: "Title",
          label: "Assessment Title",
          columnId: 1,
          type: tableColumnTypes.PROGRESS_TAB_ICON_COURSE,
          width: "100px"
        },
        {
          columnId: 2,
          id: "Grading_Component",
          label: "Grading Component",
          type: tableColumnTypes.TEXT,
        },
        {
          id: "Due_On",
          columnId: 3,
          type: tableColumnTypes.DUE_DATE,
          label: "Due On",
        },
        {
          id: "status",
          columnId: 4,
          label: "Status",
          type: tableColumnTypes.CHIP,
        },
      ],
      AssessmentTableBody: [],
      selectedClassComponentDDValue: "",
      selectedStatusComponentDDValue: "",
      selectedDueDateComponentDDValue: "asc",
      paginationLimit: 10,
      paginationData: {},
      timer: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleAPICall()
    // Customizable Area End
  }
  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.classId && prevProps.classId !== this.props.classId) {
      this.handleGetTeacherAssessmentsApi({}, {});
    }
    if (prevProps.searchByStudent !== this.props.searchByStudent) {
      this.handleAPICall()
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(message);
      if (apiRequestCallId != null && (apiRequestCallId == this.apiGetTeacherAssessments || apiRequestCallId  == this.getAllAssessmentSearchStudentsId)) {
        this.handleGetTeacherAssessmentsResponse(
          responseJson,
          errorResponse
        );
      }
    }
  }
  handleAPICall=()=>{
    if(this.props.searchByStudent.length > 0 && this.props.searchByStudent !== "All"){
      this.handleGetAssessmentSearchStudentApi(this.props.studentIds,{})
    }else{
      this.handleGetTeacherAssessmentsApi({}, {});
    }
  }

  handleChangeSearch = (event: any) => {
    this.setState({
      searchStudent: event.target?.value,
    });
  };

  handleClassDropdownValues = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };
  renderClassComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponents = gradingComponent.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGradingComponents?.label;
  };
  handleStatusDropdownValues = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };
  renderStatusComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Status";
    }
    const { StatusComponent } = this.state;
    const selectedStatusComponents = StatusComponent.find(
      (grade: any) => grade?.value === selected
    );
    return selectedStatusComponents?.label;
  };
  handleDueDateDropdownValues = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };
  renderDueDateComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Ascending";
    }
    const { DueDateComponent } = this.state;
    const selectedDueDateComponents = DueDateComponent.find(
      (grade: any) => grade?.value === selected
    );
    return selectedDueDateComponents?.label;
  };

  handleGetTeacherAssessmentsApi = async (filter: any, pagination: any) => {
    let classIds = this.props.classId
    const {currentRole} = this.props
    showCustomLoader();
    let apiConfig = currentRole==="parent teacher"? configJSON.parentTeacherAssignmentEndpoint : configJSON.teacherAssessmentEndpoint;
    let pageLimit = 10;
    if (filter.studentSearch) {
      pageLimit = 100;
    }

    const baseUrl = apiConfig + `?per_page=${pageLimit}`;
    let requestUrl = baseUrl;

    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }
    if (this.props.classId && currentRole!=="parent teacher") {
      requestUrl = requestUrl + `&class_id=${classIds}`;
    }

    if (filter.grading_component) {
      requestUrl = requestUrl + `&grading_component=${filter.grading_component}`;
    }

    if (filter.due_date) {
      requestUrl = requestUrl + `&due_date=${filter.due_date}`;
    }

    if (filter.status) {
      requestUrl = requestUrl + `&type=${filter.status}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeacherAssessments = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeacherAssessmentsResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      let assessmentList = responseJson.data.map(
        (item: any, index: number) => {
          const activity = item.attributes;
          return {
            id: 1,
            Title: {
              name: activity?.activities_title,
              color: activity?.subject?.color,
              icon: activity?.subject?.icon,
            },
            Grading_Component: activity?.grading_component,
            Due_On: activity?.due_date ? moment(activity?.due_date).format('MMMM DD, YYYY') : 'NA',
            status: activity?.assessment_status || 'NA',
            students: activity.students || [],
          };
        }
      );
      let assessmentPagination = responseJson?.meta?.pagination;
      let updatedAllAssessmentList = assessmentList;
     
      this.setState({
        AssessmentTableBody: updatedAllAssessmentList,
        paginationData: assessmentPagination,
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleGetAssessmentSearchStudentApi = async (studentId:string | number,pagination:any) => {      
    
    let pageLimit = 10;
    let requestUrl = configJSON.getAllSearchStudents + `?per_page=${pageLimit}&student_id=${studentId}`
    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getAllAssessmentSearchStudentsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSearchFilter = () => {
    const filters = this.getFiltersObject();
    this.handleGetTeacherAssessmentsApi(filters, {});
  }

  handlePagination = (event: any, page: number) => {
    const filters = this.getFiltersObject();

    if(this.props.searchByStudent.length > 0 && this.props.searchByStudent !== "All"){
      this.handleGetAssessmentSearchStudentApi(this.props.studentIds,{ page: page })
    }else{
      this.handleGetTeacherAssessmentsApi(filters, { page: page });
    }
  }

  handleSearchAssessmentByStudent = (student: string) => {
    const { timer } = this.state;
    if (student.length >= 1) {
      clearTimeout(timer)
      const newTimer = setTimeout(() => {
        this.handleGetTeacherAssessmentsApi({ studentSearch: student }, {});
      }, 500)
      this.setState({ timer: newTimer });
    } else {
      this.handleGetTeacherAssessmentsApi({}, {});
    }
  }

  getFiltersObject = () => {
    const {
      selectedClassComponentDDValue,
      selectedDueDateComponentDDValue,
      selectedStatusComponentDDValue,
    } = this.state;

    const filters = {
      grading_component: selectedClassComponentDDValue,
      due_date: selectedDueDateComponentDDValue,
      status: selectedStatusComponentDDValue
    }
    return filters;
  }
  // Customizable Area End
}
// Customizable Area End
