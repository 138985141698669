// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { CreateRequestBody, UserGradingDetails } from "./TenantAdminGradeBookController";


// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: (requestBody:CreateRequestBody) => void;
  open: boolean;
  gradeId:number;
  isEdit?: boolean;
  handleUpdateCourse: any;
  handleAddNewCourse: any;
  gradeSystemId?: number;
  userGradingDetails?:UserGradingDetails
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  titleValue: string;
  titleError: boolean;
  titleErrorMsg: string;
  loading: boolean;
  minScore: string;
  minScoreError: boolean;
  minScoreErrorMsg: string;
  maxScore: string;
  maxScoreError: boolean;
  maxScoreErrorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class NewGradeTitlePopController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.state = {
      titleValue: "",
      titleError: false,
      titleErrorMsg: "",
      loading: false,
      minScore: "",
      minScoreError: false,
      minScoreErrorMsg: "",
      maxScore: "",
      maxScoreError: false,
      maxScoreErrorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }

  async componentDidMount() {
    await super.componentDidMount();
  }
  async componentDidUpdate(prevProps: Props) {
    if (
      this.props.isEdit &&
      (prevProps.userGradingDetails !== this.props.userGradingDetails ||
        prevProps.isEdit !== this.props.isEdit)
    ) {
      if (this.props.userGradingDetails) {
        this.setState({
          titleValue: this.props.userGradingDetails.title || "",
          minScore: this.props.userGradingDetails.minimum.toString() || "",
          maxScore: this.props.userGradingDetails.maximum.toString() || "",
        });
      }
    }
  }


  handleClosePopup = () => {
    this.handleEmptyField();
    this.props.onCancel();
  };

  handleEmptyField = () => {
    this.setState({
      titleValue: "",
      minScore:"",
      maxScore:""
    });
  };
  handleChange = (event: any) => {
    const { name, value } = event.target;
  
    // Helper function to check if a value is a valid number
    const isValidNumber = (val: string) => !isNaN(Number(val));
  
    switch (name) {
      case "titleValue":
        if (value.length) {
          this.setState({ titleError: false, titleErrorMsg: "" });
        }
        break;
      case "minScore":
        if (isValidNumber(value)) {
          this.setState({ minScoreError: false, minScoreErrorMsg: "" });
        } else {
          this.setState({ minScoreError: true, minScoreErrorMsg: "Invalid number" });
        }
        break;
      case "maxScore":
        if (isValidNumber(value)) {
          this.setState({ maxScoreError: false, maxScoreErrorMsg: "" });
        } else {
          this.setState({ maxScoreError: true, maxScoreErrorMsg: "Invalid number" });
        }
        break;
    }
  
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  

  handleRequestBody = () => {
    if (this.handleFormValidation()) {
      const {
        titleValue,
        minScore,
        maxScore

      } = this.state;

      const requestBody = {
          grade_book_id:this.props.gradeId,
          title: titleValue,
          minimum: parseInt(minScore),
          maximum: parseInt(maxScore)
      };
      console.warn(requestBody);
      this.props.onConfirm(requestBody);
      this.handleEmptyField() 
    }
  }
  handleFormValidation = () => {
    let isValid = true;
    const {
      titleValue,
      minScore,
      maxScore
    } = this.state;

    if (!titleValue.trim()) {
      this.setState({ titleError: true, titleErrorMsg: "Please enter grade title" });
      isValid = false;
    }
    if (isNaN(parseInt(minScore)) ||!minScore.trim()) {
      this.setState({ minScoreError: true, minScoreErrorMsg: "Please enter a valid min Score value" });
      isValid = false;
    }
    if (isNaN(parseInt(maxScore)) || !maxScore.trim()) {
      this.setState({ maxScoreError: true, maxScoreErrorMsg: "Please enter a valid max Score value" });
      isValid = false;
    }
    return isValid;
  };


  // Customizable Area End
}
// Customizable Area End
