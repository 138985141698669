// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, Grid, Button, Input } from '@material-ui/core';
import AddRemarkModalController, {
    Props
} from "./AddRemarkModalController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../components/src/CustomButton.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';
import CustomTextEditor from '../../../components/src/CustomTextEditor.web';
import CustomTextField from '../../../components/src/CustomTextField.web';

// Customizable Area End
// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        addRemarkModal: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
            "& .MuiDialog-paperWidthSm": {
                maxWidth: "100%",
                overflowY: "hidden",
                width: "806px",
                minHeight: "500px",
                overflowX: "hidden",
            }
        },
        customTextarea: {
            borderRadius: "12px",
            width: "96%",
            border: "1px solid #888",
            padding: "17px 15px",
            fontSize: "14px",
            fontWeight: 400,
            marginTop: "10px",
            "&:hover:not(.Mui-)::before": {
                borderRadius: "12px",
                borderBottom: "1px solid #888",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            },
            "& .MuiInput-underline::before": {
                borderBottom: "0px",
                content: "none",
            },
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
            "& .MuiInput-underline::after": {
                borderBottom: "0px",
            },
        },
        customInput: {
            height: "50px",
            borderRadius: "12px",
            width: "100%",
            padding: "17px 15px",
            border: "1px solid #888",
            marginTop: "10px",
            fontWeight: 400,
            fontSize: "14px",
            "&:hover:not(.Mui-)::before": {
                borderBottom: "1px solid #888",
                borderRadius: "12px",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            },
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
        },
    })

// Customizable Area End

// Customizable Area Start
export class AddRemarkModal extends AddRemarkModalController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open } = this.props;
        const {
            studentNameValue,
            gradeValue,
            remarkValue,
            gradeError,
            gradeMsg,
            remarkError,
            remarkMsg,
        } = this.state;
        return (
            <Box>
                <Dialog className={`${classes.highlightBgColor} ${classes.addRemarkModal}`} aria-labelledby="quiz-view-answer-confirm-dialog-title" open={open} scroll={'body'}>
                    <Box padding={"30px"} className={`${classes.bgColor}`}>
                        <Grid direction="row" container>
                            <Grid xs={12} item>
                                <Typography className={`${classes.fontText24} ${classes.textPrimaryColor}`} component={"span"}>Graphing Quiz Remarks</Typography>
                                <Button onClick={this.props.onCancel} className={classes.textPrimaryColor} style={{ float: "right" }}> X </Button>
                            </Grid>
                        </Grid>
                        <DialogContent style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}>
                            <Grid container spacing={2} direction="row">
                                <Grid xs={6} item>
                                    <Box marginBottom={"10px"} width={"99%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Student
                                        </Typography>
                                        <CustomTextField
                                            fieldName="studentNameValue"
                                            fieldWidth={"100%%"}
                                            fieldId={'student-name-input'}
                                            fieldValue={studentNameValue}
                                            isDisabled={true}
                                            textFieldStyles={customInputStyle}
                                            placeHolderText={"Enter student name"}
                                            fieldType="text"
                                            handleChange={this.handleInputChange}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Grade
                                        </Typography>
                                        <CustomTextField
                                            fieldName="gradeValue"
                                            fieldWidth={"100%%"}
                                            fieldId={'student-grade-input'}
                                            fieldValue={gradeValue}
                                            isDisabled={false}
                                            textFieldStyles={customInputStyle}
                                            placeHolderText={"Enter Grade"}
                                            fieldType="number"
                                            handleChange={this.handleInputChange}
                                            fieldError={gradeError}
                                            helperText={gradeMsg}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography style={{ marginBottom: "15px" }} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Remarks
                                        </Typography>
                                        <CustomTextEditor
                                            editorId="add-remark-textbox"
                                            editorContent={remarkValue}
                                            placeHolderText="Enter Remarks"
                                            onWysEditorStateChange={this.handleEditorChange}
                                            editorError={remarkError}
                                            helperText={remarkMsg}                    
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box style={{ width: "100%" }} marginBottom={"10px"}>
                                        <CustomButton btnText="Save" buttonId={`add-remark-save-button`} btnStyle={saveButtonStyle} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleSaveRemark} isDisabled={false} />
                                        <CustomButton btnText="Cancel" btnStyle={cancelButtonStyle} buttonVariant={"outlined"} buttonId={`add-remark-cancel-button`} type={"button"} handleButtonClick={this.props.onCancel} isDisabled={false} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Box>
                </Dialog>
            </Box>
        );

        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const saveButtonStyle = {
    height: "50px",
    padding: "10px 10px",
    fontSize: "18px",
    width: "120px",
    borderRadius: "12px",
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    fontWeight: 500,
    marginLeft: "15px",
} as const;

const cancelButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    height: "50px",
    borderRadius: "12px",
    border: "1px solid",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    marginLeft: "15px",
} as const;

const customInputStyle = {
    width: "99%",
    height: "36px",
    marginTop: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddRemarkModal);
// Customizable Area End
