// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  resourceData: Array<any>;
  getEditResourceId?: any;
  getSearchQueryData?: any;
  getDeleteResourceData?: any;
  pagination: any;
  getPaginationNumber: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectResourseNameValue: string;
  selectResourseSubjectValue: string;
  selectedGradeValue: string;
  loading: boolean;
  selectResourseStatusValue: string;
  selectedNoOfLessonsPerDayValue: string;
  ResourseStatusComponentValue: Array<any>;
  subjectDropdownList: Array<any>;
  gradeDropdownList: Array<any>;
  lessonsPerDayDropdownList: Array<any>;
  titleDtopdownList: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherResoursTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      loading: false,
      selectResourseStatusValue: "",
      selectResourseSubjectValue: "",
      selectedGradeValue: "",
      selectedNoOfLessonsPerDayValue: "All",
      selectResourseNameValue: "All",
      subjectDropdownList: [],
      gradeDropdownList: [],
      lessonsPerDayDropdownList: [],
      titleDtopdownList: [],
      ResourseStatusComponentValue: [
        {
          id: 1,
          label: "Scheduled",
          value: "Scheduled",
        },
        {
          id: 2,
          label: "Late",
          value: "Late",
        },
        {
          id: 3,
          label: "Completed",
          value: "Completed",
        },
      ],
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    this.handleDropdownListData();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.resourceData.length > prevProps.resourceData.length) {
      this.handleDropdownListData();
    }
  }

  getResourseDropdownItemsArray = (dropdownName: string) => {
    const refArray = [1, 2, 3, 4, 5];
    let dropdownItems: Array<any> = [];
    refArray.forEach((item) => {
      dropdownItems.push({
        id: item,
        label: `${dropdownName}${item}`,
        value: `${dropdownName}${item}`,
      });
    });
    return dropdownItems;
  };

  renderResourseTitleDropdownValue = (selected: any) => {
    const dropdownName = "Title";
    const dropdownItems = this.state.titleDtopdownList;
    return this.checkAndGetResourseSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderResourseNoOfLessonsPerDayValue = (selected: any) => {
    const dropdownName = "Option";
    const dropdownItems = this.state.lessonsPerDayDropdownList;
    return this.checkAndGetResourseSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderResourseStatusDropdownValue = (selected: any) => {
    const dropdownName = "Status";
    const dropdownItems = this.state.ResourseStatusComponentValue;
    return this.checkAndGetResourseSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderResourseSubjectDropdownValue = (selected: any) => {
    const dropdownName = "Subject";
    const dropdownItems = this.state.subjectDropdownList;
    return this.checkAndGetResourseSelectedValue(selected, dropdownName, dropdownItems);
  };

  handleResoursesChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  renderResourseGradeDropdownValue = (selected: any) => {
    const dropdownName = "Grade";
    const dropdownItems = this.state.gradeDropdownList;
    return this.checkAndGetResourseSelectedValue(selected, dropdownName, dropdownItems);
  };

  handleResourceTabSearchButton = () => {
    const { selectResourseStatusValue, selectResourseNameValue, selectedNoOfLessonsPerDayValue } = this.state;
    const searchQuery = {
      topic_title: selectResourseNameValue,
      status: selectResourseStatusValue,
      no_of_lessons_per_day: selectedNoOfLessonsPerDayValue,
    }
    console.log("search query: ", searchQuery);
    this.props.getSearchQueryData(searchQuery);
  };

  checkAndGetResourseSelectedValue = (
    selected: any,
    dropdownName: string,
    dropdownItems: any
  ) => {
    if (!selected || selected.length === 0) {
      return `Select ${dropdownName}`;
    }
    const selctedOption = dropdownItems.find(
      (item: any) => item?.value === selected
    );
    return selctedOption?.label;
  };

  handleResourceEditAction = (data: any) => {
    this.props.getEditResourceId(data);
  }

  handleDropdownListData = () => {
    const resources = this.props.resourceData;
    const data = this.props.resourceData[0];
    let titleDropdwon = [{ id: 0, label: 'All', value: 'All' }];
    let lessonPerDayDropdwon = [{ id: 0, label: 'All', value: 'All' }];

    resources.forEach((item: any) => {
      titleDropdwon.push({
        id: item.id,
        label: item.Title_Resourse,
        value: item.Title_Resourse,
      });

      lessonPerDayDropdwon.push({
        id: item.id,
        label: item?.LessonsPerDay_Resourse,
        value: item?.LessonsPerDay_Resourse,
      });
    });

    const subjectDropdwon = [{ id: 1, label: data?.Subject_Resourse, value: data?.Subject_Resourse }];
    const gradeDropdwon = [{ id: 1, label: data?.Grade_Resourse, value: data?.Grade_Resourse }];
    lessonPerDayDropdwon = this.removeDuplicateKeyValuePairs(lessonPerDayDropdwon);

    this.setState({
      titleDtopdownList: titleDropdwon,
      subjectDropdownList: subjectDropdwon,
      gradeDropdownList: gradeDropdwon,
      lessonsPerDayDropdownList: lessonPerDayDropdwon,
      selectResourseSubjectValue: data?.Subject_Resourse,
      selectedGradeValue: data?.Grade_Resourse,
    });
  }

  handleResourceDeleteAction = (data: any) => {
    const resources = this.props.resourceData;
    const deleteData = {
      id: data,
      resourceTitle: resources.find((item: any) => item.id === data)?.Title_Resourse,
    }
    this.props.getDeleteResourceData(deleteData);
  }

  removeDuplicateKeyValuePairs(arr: Array<any>) {
    const uniqueObjects = [];
    const objectKeys = new Set();

    for (const obj of arr) {
      const key = obj.value;
      if (!objectKeys.has(key)) {
        objectKeys.add(key);
        uniqueObjects.push(obj);
      }
    }
    console.log("uniqueObjects: ", uniqueObjects)
    return uniqueObjects;
  }

  handlePagination = (event: any, page: number) => {
    this.props.getPaginationNumber(page);
  }
  // Customizable Area End
}
