// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  id?: string;
  navigation?: any;
  classes?: any;
  forTeacher?: boolean;
  detail: any;
  questionList?: Array<any>;
  handleAddQuestion?: any;
  handleUpdateQuestion?: any;
  handleDeleteQuestion?: any;
  setSelectedQuestion?: any;
  isFromRegistrar?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  editQuestion: boolean;
  openSubjectiveQuestionModal: boolean;
  openObjectiveQuestionModal: boolean;
  openDeleteQuestionConfirmModal: boolean;
  selectedQuestionDetail: any;
  loadManualUi: boolean;
  isMyCoursesPath:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class QuizActivityComponentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiAssessmentActivityDetails: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.state = {
      loading: false,
      editQuestion: false,
      openSubjectiveQuestionModal: false,
      openObjectiveQuestionModal: false,
      openDeleteQuestionConfirmModal: false,
      selectedQuestionDetail: "",
      loadManualUi: false,
      isMyCoursesPath:false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }

  async componentDidMount() {
    await super.componentDidMount();
    const urlPath = new URL(document.URL);    
    const currentPath = urlPath.pathname.toLowerCase();      
    this.setState({ isMyCoursesPath: currentPath === '/mycourses' });
  }

  handleGoToAssessmentDueScreen = (typeOfAssessment: string, title: string) => {
    this.props.navigation?.navigate("StudentDueAssessmentMain", {}, {
      typeOfAssessment: typeOfAssessment,
      title: title
    });
  }

  handleCloseSubjectiveQuestionModal = () => {
    this.setState({ openSubjectiveQuestionModal: false });
  }

  handleOpenSubjectiveQuestionModal = () => {
    this.setState({ openSubjectiveQuestionModal: true });
  }

  handleEditSubjectiveQuestion = (id: any) => {
    this.handleLoadManualUi();
    const { questionList } = this.props;
    const selectedSubjectiveQuizQuestion = questionList?.find((item: any) => parseInt(item.id) === parseInt(id));
    this.setState({ selectedQuestionDetail: selectedSubjectiveQuizQuestion }, () => {
      this.setState({ editQuestion: true });
      this.handleOpenSubjectiveQuestionModal()
      this.props.setSelectedQuestion(selectedSubjectiveQuizQuestion?.id);
    });
  }

  handleAddSubjectiveQuestion = () => {
    this.handleLoadManualUi();
    this.setState({ editQuestion: false });
    this.handleOpenSubjectiveQuestionModal();
  }

  handleCloseObjectiveQuestionModal = () => {
    this.setState({ openObjectiveQuestionModal: false });
  }

  handleOpenObjectiveQuestionModal = () => {
    this.setState({ openObjectiveQuestionModal: true });
  }

  handleEditObjectiveQuestion = (id: any) => {
    const { questionList } = this.props;
    const selectedQuestion = questionList?.find((item: any) => parseInt(item.id) === parseInt(id));
    this.setState({ selectedQuestionDetail: selectedQuestion }, () => {
      this.setState({ editQuestion: true });
      this.handleOpenObjectiveQuestionModal();
      this.props.setSelectedQuestion(selectedQuestion?.id);
    });
  }

  handleDeleteQuizObjectiveQuestion = (id: any) => {
    const { questionList } = this.props;
    const selectedQuestion = questionList?.find((item: any) => parseInt(item.id) === parseInt(id));
    this.setState({ selectedQuestionDetail: selectedQuestion }, () => {
      this.setState({ openDeleteQuestionConfirmModal: true });
      this.props.setSelectedQuestion(selectedQuestion?.id);
    });
  }

  handleCloseQuizDeleteConfirmModal = () => {
    this.setState({ openDeleteQuestionConfirmModal: false });
  }

  handleConfirmQuizDeleteQuestion = () => {
    this.setState({ openDeleteQuestionConfirmModal: false, openObjectiveQuestionModal: false, openSubjectiveQuestionModal: false, });
    this.props.handleDeleteQuestion();
  }

  handleAddObjectiveQuestion = () => {
    this.setState({ editQuestion: false });
    this.handleOpenObjectiveQuestionModal();
  }

  handleSaveQuiz = () => {
    console.log("Save");
  }

  handleQuizAddQuestion = (data: any) => {
    this.handleCloseObjectiveQuestionModal();
    this.handleCloseSubjectiveQuestionModal();
    this.props.handleAddQuestion(data);
  }

  handleQuizUpdateQuestion = (data: any) => {
    this.handleCloseObjectiveQuestionModal();
    this.handleCloseSubjectiveQuestionModal();
    this.props.handleUpdateQuestion(data);
  }

  handleLoadManualUi() {
    if (this.props.detail.evaluationType === "manual") {
      this.setState({ loadManualUi: true });
    } else {
      this.setState({ loadManualUi: false });
    }
  }

  // Customizable Area End
}
// Customizable Area End