// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  FIELD_TYPES,
  ICustomTableProps,
  IDropdownMenuItemProps,
  IInputConfigProps,
  ITableActionBtnConfigProps,
  TOASTER_NOTIFICATION_MSG,
  USER_ROLES_CAMEL,
} from "../../../components/src/CommonType.web";
import {
  availableCoursesListMock,
  teacherNameListMock,
  gradeLevelListMock,
  acsDescMock,
  subjectsListMock,
  assignCourseToCatalogInputConfigMock,
  titleListMock,
} from "../assets/tenantCourseManagementMockdata";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export const manageRequestsTabsList = [
  {
    id: 1,
    label: "Pending Requests",
    value: "pending_requests",
  },
  {
    id: 2,
    label: "Approved Requests",
    value: "approved_requests",
  },
  {
    id: 3,
    label: "Rejected Requests",
    value: "rejected_requests",
  },
];
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedManageRequestTabIndex: number;
  titlesList: Array<any>;
  titleDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedTitleDDValue: any;
  courseTitleDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedCourseTitleDDValue: any;
  assignCourseToCatalogDDMenuItems: Array<any>;
  selectedAssignCourseToCatalogTitleDDValue: any;
  gradeLevelList: Array<any>;
  selectedGradeLevelDDValue: any;
  gradeLevelDDMenuItems: Array<IDropdownMenuItemProps>;
  requestedByList: Array<any>;
  requestedByDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedRequestedByDDValue: any;
  requestedOnDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedRequestedOnDDValue: any;
  acceptedOnDDMenuItems: Array<IDropdownMenuItemProps>;
  rejectedOnDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedAcceptedOnDDValue: any;
  selectedRejectedOnDDValue: any;
  subjectHandledList: Array<any>;
  subjectHandledDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedSubjectHandledDDValue: any;
  coursesList: Array<any>;
  tableHeaderColumns: Array<ICustomTableProps>;
  tableBodyData: Array<any>;
  manageRequestInputConfig: Array<IInputConfigProps>;
  tableActionButtonConfig: Array<ITableActionBtnConfigProps>;
  openAssignCourseToCatalogModal: boolean;
  assignCourseToCatalogInputConfig: Array<IInputConfigProps>;
  openAssignConfirmModal: boolean;
  rejectRequest: boolean;
  isFromAssignCourseToCatalog: boolean;
  openRejectReasonPopup: boolean;
  requestRejectionReason: string;
  openAssignSuccessPopup: boolean;
  paginationLimit: number;
  role: string;
  requestsTableData: Array<any>;
  paginationData: object;
  currentRequestType: string;
  acceptRejectRequestId: number;
  acceptRejectRequestData: any;
  acceptRejectSuccessMesg: string;
  assignCourseId: string,
  assignCourseTitle: string,
  assignCourseSubject: string,
  assignCourseGrade: string,
  assignCourseCatalogName: string,
  rowWiseTableActionConfig: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TenantCourseManagementManageRequestMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetRequestsList: string = "";
  apiGetTeachersList: string = "";
  apiGetSubjectsDropdownData: string = "";
  apiGetGradesDropdownData: string = "";
  apiPutAcceptRejectRequest: string = "";
  apiGetCatalogDropdownData: string = "";
  apiAssignCourseToCatalog: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleGoBackToCourseMngMain =
      this.handleGoBackToCourseMngMain.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedManageRequestTabIndex: 0,
      titlesList: [],
      titleDDMenuItems: [],
      selectedTitleDDValue: "",
      courseTitleDDMenuItems: titleListMock,
      selectedCourseTitleDDValue: "",
      assignCourseToCatalogDDMenuItems: [],
      selectedAssignCourseToCatalogTitleDDValue: "",
      gradeLevelDDMenuItems: [],
      gradeLevelList: [],
      selectedGradeLevelDDValue: "",
      requestedByList: [],
      requestedByDDMenuItems: [],
      selectedRequestedByDDValue: "",
      subjectHandledList: [],
      subjectHandledDDMenuItems: [],
      selectedSubjectHandledDDValue: "",
      requestedOnDDMenuItems: acsDescMock,
      selectedRequestedOnDDValue: "",
      acceptedOnDDMenuItems: acsDescMock,
      rejectedOnDDMenuItems: acsDescMock,
      selectedAcceptedOnDDValue: "",
      selectedRejectedOnDDValue: "",
      coursesList: availableCoursesListMock,
      tableHeaderColumns: [],
      tableBodyData: [],
      manageRequestInputConfig: [],
      tableActionButtonConfig: [],
      openAssignCourseToCatalogModal: false,
      assignCourseToCatalogInputConfig: [],
      openAssignConfirmModal: false,
      rejectRequest: false,
      isFromAssignCourseToCatalog: false,
      openRejectReasonPopup: false,
      requestRejectionReason: "",
      openAssignSuccessPopup: false,
      paginationLimit: 10,
      role: '',
      requestsTableData: [],
      paginationData: {},
      currentRequestType: 'pending',
      acceptRejectRequestId: 0,
      acceptRejectRequestData: {},
      acceptRejectSuccessMesg: "",
      assignCourseId: "",
      assignCourseTitle: "",
      assignCourseSubject: "",
      assignCourseGrade: "",
      assignCourseCatalogName: "",
      rowWiseTableActionConfig: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetRequestsList:
            {
              this.handleGetRequestListResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGetTeachersList:
            {
              this.handleGetTeachersListResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetSubjectsDropdownData:
            {
              this.handleSubjectsDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetGradesDropdownData:
            {
              this.handleGradesDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiPutAcceptRejectRequest:
            {
              this.handleAcceptRejectRequestResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetCatalogDropdownData:
            {
              this.handleGetCatalogDropdownDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiAssignCourseToCatalog:
            {
              this.handleAssignCourseToCatalogResponse(
                responseJson,
                errorResponse
              );
            }
            break;
        }
      }
    }
  }
  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.setState({ role: await getStorageData("role") });
    this.handleGetTeachersListApi({ user_type: 'Teacher' }, {});
    this.handleGetSubjectsDropdownListApi();
    this.handleGetGradesDropdownListApi();
    this.handleGetRequestsDataApi({}, {});
    this.handleSetManageRequestsListData();
    this.handleSetAssignCourseToCatalogInputConfig();
    this.handleGetCatalogDropdownDataApi();
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeManageRequestTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({
      selectedManageRequestTabIndex: newValue,
      tableBodyData: [],
      requestsTableData: [],
      tableHeaderColumns: [],
      manageRequestInputConfig: [],
      tableActionButtonConfig: [],
      selectedTitleDDValue: "",
      selectedAcceptedOnDDValue: "",
      selectedRequestedByDDValue: "",
      selectedGradeLevelDDValue: "",
      selectedSubjectHandledDDValue: "",
      selectedRequestedOnDDValue: "",
      selectedRejectedOnDDValue: "",
      loading: false,
    }, () => {
      this.handleSetManageRequestsListData();
      if (newValue == 0) {
        this.setState({ currentRequestType: 'pending' }, () => {
          this.loadTabApiData();
        });
      } else if (newValue == 1) {
        this.setState({ currentRequestType: 'accepted' }, () => {
          this.loadTabApiData();
        });
      } else if (newValue == 2) {
        this.setState({ currentRequestType: 'rejected' }, () => {
          this.loadTabApiData();
        });
      }
    });
  };

  loadTabApiData = () => {
    this.handleGetRequestsDataApi({}, {});
  }

  setDDValue = (selected: any, fieldName: string) => {
    const {
      titleDDMenuItems,
      gradeLevelDDMenuItems,
      requestedByDDMenuItems,
      subjectHandledDDMenuItems,
      requestedOnDDMenuItems,
      acceptedOnDDMenuItems,
      rejectedOnDDMenuItems,
      courseTitleDDMenuItems,
      assignCourseToCatalogDDMenuItems,
    } = this.state;

    let label: any = "";

    if (fieldName === "titleDDMenuItems") {
      const selectedItem = titleDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "gradeLevelDDMenuItems") {
      const selectedItem = gradeLevelDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "requestedOnDDMenuItems") {
      const selectedItem = requestedOnDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "acceptedOnDDMenuItems") {
      const selectedItem = acceptedOnDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "rejectedOnDDMenuItems") {
      const selectedItem = rejectedOnDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "subjectHandledDDMenuItems") {
      const selectedItem = subjectHandledDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "courseTitleDDMenuItems") {
      const selectedItem = courseTitleDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "assignCourseToCatalogDDMenuItems") {
      const selectedItem = assignCourseToCatalogDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else {
      const selectedItem = requestedByDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    }
    return label;
  };

  renderDDMenuItem = (selected: any, fieldName: string, emptyText: string) => {
    if (!selected || selected.length === 0) {
      return `Select ${emptyText}`;
    }
    return this.setDDValue(selected, fieldName);
  };

  handleSetManageRequestsListData = () => {
    const {
      role,
      selectedManageRequestTabIndex,
      requestsTableData,
      titleDDMenuItems,
      requestedByDDMenuItems,
      subjectHandledDDMenuItems,
      gradeLevelDDMenuItems,
    } = this.state;

    const tenantCourseMngTabCategory = manageRequestsTabsList[selectedManageRequestTabIndex].value;
    let manageRequestInputConfigs: Array<IInputConfigProps> = [];
    let tableHeaderColumnConfigs: Array<any> = [];
    let tableActionMenuConfigs: Array<any> = [];

    if (tenantCourseMngTabCategory === "pending_requests") {

      if (this.capitalizeString(role) === USER_ROLES_CAMEL.TENANT_ADMIN) {
        manageRequestInputConfigs = [
          {
            id: "pending_requests_tenant_title",
            name: "selectedTitleDDValue",
            value: "",
            fieldName: "titleDDMenuItems",
            menuItems: titleDDMenuItems,
            fieldType: FIELD_TYPES.SELECT,
            placeHolderText: "Select Title",
            label: "Title",
          },
          {
            fieldType: FIELD_TYPES.SELECT,
            id: "pending_requests_tenant_requested_by",
            placeHolderText: "Select Option",
            name: "selectedRequestedByDDValue",
            fieldName: "requestedByDDMenuItems",
            menuItems: requestedByDDMenuItems,
            label: "Requested By",
            value: "",
          },
          {
            id: "pending_requests_tenant_requested_on",
            fieldType: FIELD_TYPES.SELECT,
            name: "selectedRequestedOnDDValue",
            placeHolderText: "Select Option",
            value: "",
            fieldName: "requestedOnDDMenuItems",
            label: "Requested On",
            menuItems: acsDescMock,
          },
          {
            id: "pending_requests_tenant_subject",
            name: "selectedSubjectHandledDDValue",
            fieldType: FIELD_TYPES.SELECT,
            fieldName: "subjectHandledDDMenuItems",
            placeHolderText: "Select Subject",
            value: "",
            label: "Subject",
            menuItems: subjectHandledDDMenuItems,
          },
          {
            id: "pending_requests_tenant_grade",
            name: "selectedGradeLevelDDValue",
            value: "",
            fieldName: "gradeLevelDDMenuItems",
            fieldType: FIELD_TYPES.SELECT,
            menuItems: gradeLevelDDMenuItems,
            placeHolderText: "Select Grade Level",
            label: "Grade",
          },
        ];

        tableHeaderColumnConfigs = [
          {
            columnId: 11,
            label: "Title",
            id: "title",
            width: "250px",
            type: tableColumnTypes.SUBJECT_ICON_NAME,
            maxWidth: "300px",
          },
          {
            columnId: 12,
            label: "Subject",
            id: "subjectName",
            width: "100px",
            type: tableColumnTypes.TEXT,
            maxWidth: "150px",
          },
          {
            columnId: 13,
            label: "Grade",
            id: "grade",
            type: tableColumnTypes.TEXT,
            maxWidth: "150px",
            width: "100px",
          },
          {
            id: "requestedBy",
            columnId: 14,
            type: tableColumnTypes.TEXT,
            label: "Requested By",
            maxWidth: "200px",
            width: "150px",
          },
          {
            id: "requestedOn",
            columnId: 15,
            label: "Requested On",
            width: "120px",
            type: tableColumnTypes.TEXT,
            maxWidth: "200px",
          },
          {
            id: "createdOn",
            columnId: 16,
            type: tableColumnTypes.TEXT,
            label: "Created On",
            width: "120px",
            maxWidth: "200px",
          },
          {
            columnId: 17,
            id: "modifiedOn",
            width: "120px",
            label: "Last Modified On",
            maxWidth: "200px",
            type: tableColumnTypes.TEXT,
          },
        ];

        tableActionMenuConfigs = [
          {
            label: "View",
            action: (value: any) => this.handleTenantViewRequestDetails(value),
          },
          {
            label: "Accept",
            action: (value: any) => this.handleAcceptPendingRequest(value),
          },
          {
            label: "Reject",
            action: (value: any) => this.handleRejectPendingRequest(value),
          },
        ];

      } else if (this.capitalizeString(role) === USER_ROLES_CAMEL.TEACHER || this.capitalizeString(role) === USER_ROLES_CAMEL.PARENT_TEACHER) {
        manageRequestInputConfigs = [
          {
            id: "pending_requests_teacher_title",
            name: "selectedTitleDDValue",
            value: "",
            fieldName: "titleDDMenuItems",
            menuItems: titleDDMenuItems,
            fieldType: FIELD_TYPES.SELECT,
            placeHolderText: "Select Title",
            label: "Title",
          },
          {
            id: "pending_requests_teacher_requested_on",
            name: "selectedRequestedOnDDValue",
            placeHolderText: "Select Option",
            fieldType: FIELD_TYPES.SELECT,
            fieldName: "requestedOnDDMenuItems",
            menuItems: acsDescMock,
            label: "Requested On",
            value: "",
          },
          {
            id: "pending_requests_teacher_subject",
            name: "selectedSubjectHandledDDValue",
            fieldType: FIELD_TYPES.SELECT,
            fieldName: "subjectHandledDDMenuItems",
            placeHolderText: "Select Subject",
            value: "",
            label: "Subject",
            menuItems: subjectHandledDDMenuItems,
          },
          {
            name: "selectedGradeLevelDDValue",
            id: "pending_requests_teacher_grade",
            fieldName: "gradeLevelDDMenuItems",
            value: "",
            placeHolderText: "Select Grade Level",
            fieldType: FIELD_TYPES.SELECT,
            menuItems: gradeLevelDDMenuItems,
            label: "Grade",
          },
        ];

        tableHeaderColumnConfigs = [
          {
            columnId: 21,
            label: "Title",
            id: "title",
            width: "250px",
            maxWidth: "300px",
            type: tableColumnTypes.SUBJECT_ICON_NAME,
          },
          {
            id: "subjectName",
            columnId: 22,
            width: "100px",
            label: "Subject",
            type: tableColumnTypes.TEXT,
            maxWidth: "150px",
          },
          {
            id: "grade",
            columnId: 23,
            type: tableColumnTypes.TEXT,
            label: "Grade",
            maxWidth: "150px",
            width: "100px",
          },
          {
            id: "requestedOn",
            columnId: 24,
            type: tableColumnTypes.TEXT,
            label: "Requested On",
            maxWidth: "200px",
            width: "120px",
          },
          {
            id: "createdOn",
            columnId: 25,
            type: tableColumnTypes.TEXT,
            label: "Created On",
            maxWidth: "200px",
            width: "120px",
          },
          {
            id: "modifiedOn",
            columnId: 26,
            type: tableColumnTypes.TEXT,
            label: "Last Modified On",
            maxWidth: "200px",
            width: "120px",
          },
        ];

        tableActionMenuConfigs = [
          {
            label: "Copy to My Courses",
            action: () => { },
          },
          {
            label: "View",
            action: () => { },
          },
        ];
      }

      this.setState({
        tableBodyData: requestsTableData,
        tableHeaderColumns: tableHeaderColumnConfigs,
        manageRequestInputConfig: manageRequestInputConfigs,
        tableActionButtonConfig: tableActionMenuConfigs,
      });

    } else if (tenantCourseMngTabCategory === "approved_requests") {

      if (this.capitalizeString(role) === USER_ROLES_CAMEL.TENANT_ADMIN) {
        manageRequestInputConfigs = [
          {
            name: "selectedTitleDDValue",
            id: "approved_requests_tenant_title",
            fieldName: "titleDDMenuItems",
            value: "",
            label: "Title",
            menuItems: titleDDMenuItems,
            placeHolderText: "Select Title",
            fieldType: FIELD_TYPES.SELECT,
          },
          {
            id: "approved_requests_tenant_requested_by",
            fieldType: FIELD_TYPES.SELECT,
            placeHolderText: "Select Option",
            fieldName: "requestedByDDMenuItems",
            name: "selectedRequestedByDDValue",
            value: "",
            label: "Requested By",
            menuItems: requestedByDDMenuItems,
          },
          {
            id: "approved_requests_tenant_requested_on",
            fieldType: FIELD_TYPES.SELECT,
            name: "selectedRequestedOnDDValue",
            placeHolderText: "Select Option",
            value: "",
            fieldName: "requestedOnDDMenuItems",
            menuItems: acsDescMock,
            label: "Requested On",
          },
          {
            id: "approved_requests_tenant_accepted_on",
            fieldType: FIELD_TYPES.SELECT,
            name: "selectedAcceptedOnDDValue",
            fieldName: "acceptedOnDDMenuItems",
            placeHolderText: "Select Option",
            value: "",
            label: "Accepted On",
            menuItems: acsDescMock,
          },
          {
            id: "approved_requests_tenant_subject",
            name: "selectedSubjectHandledDDValue",
            placeHolderText: "Select Subject",
            fieldType: FIELD_TYPES.SELECT,
            value: "",
            menuItems: subjectHandledDDMenuItems,
            fieldName: "subjectHandledDDMenuItems",
            label: "Subject",
          },
          {
            id: "approved_requests_tenant_grade",
            fieldName: "gradeLevelDDMenuItems",
            name: "selectedGradeLevelDDValue",
            fieldType: FIELD_TYPES.SELECT,
            value: "",
            menuItems: gradeLevelDDMenuItems,
            label: "Grade",
            placeHolderText: "Select Grade Level",
          },
        ];

        tableHeaderColumnConfigs = [
          {
            columnId: 31,
            label: "Title",
            id: "title",
            width: "250px",
            type: tableColumnTypes.SUBJECT_ICON_NAME,
            maxWidth: "300px",
          },
          {
            columnId: 32,
            label: "Subject",
            id: "subjectName",
            width: "100px",
            type: tableColumnTypes.TEXT,
            maxWidth: "150px",
          },
          {
            columnId: 33,
            type: tableColumnTypes.TEXT,
            id: "grade",
            label: "Grade",
            width: "100px",
            maxWidth: "150px",
          },
          {
            columnId: 34,
            id: "requestedBy",
            type: tableColumnTypes.TEXT,
            label: "Requested By",
            width: "150px",
            maxWidth: "200px",
          },
          {
            id: "requestedOn",
            columnId: 35,
            type: tableColumnTypes.TEXT,
            label: "Requested On",
            maxWidth: "180px",
            width: "120px",
          },
          {
            id: "acceptedOn",
            columnId: 36,
            type: tableColumnTypes.TEXT,
            label: "Approved On",
            maxWidth: "180px",
            width: "120px",
          },
          {
            label: "Created On",
            columnId: 37,
            id: "createdOn",
            width: "120px",
            type: tableColumnTypes.TEXT,
            maxWidth: "180px",
          },
          {
            id: "modifiedOn",
            label: "Last Modified On",
            columnId: 38,
            type: tableColumnTypes.TEXT,
            maxWidth: "200px",
            width: "150px",
          },
        ];

        tableActionMenuConfigs = [
          {
            label: "View",
            action: (value: any) => this.handleTenantViewRequestDetails(value),
          },
          {
            label: "Assign to Catalog",
            action: (value: any) =>
              this.handleAssignToCatalogTableAction(value),
          },
        ];

      } else if (this.capitalizeString(role) === USER_ROLES_CAMEL.TEACHER || this.capitalizeString(role) === USER_ROLES_CAMEL.PARENT_TEACHER) {
        manageRequestInputConfigs = [
          {
            id: "approved_requests_teacher_title",
            fieldName: "titleDDMenuItems",
            name: "selectedTitleDDValue",
            value: "",
            label: "Title",
            fieldType: FIELD_TYPES.SELECT,
            menuItems: titleDDMenuItems,
            placeHolderText: "Select Title",
          },
          {
            name: "selectedRequestedOnDDValue",
            id: "approved_requests_teacher_requested_on",
            placeHolderText: "Select Option",
            fieldType: FIELD_TYPES.SELECT,
            fieldName: "requestedOnDDMenuItems",
            value: "",
            label: "Requested On",
            menuItems: acsDescMock,
          },
          {
            id: "approved_requests_teacher_accepted_on",
            fieldType: FIELD_TYPES.SELECT,
            name: "selectedAcceptedOnDDValue",
            fieldName: "acceptedOnDDMenuItems",
            placeHolderText: "Select Option",
            label: "Accepted On",
            menuItems: acsDescMock,
            value: "",
          },
          {
            id: "approved_requests_teacher_subject",
            fieldType: FIELD_TYPES.SELECT,
            name: "selectedSubjectHandledDDValue",
            fieldName: "subjectHandledDDMenuItems",
            placeHolderText: "Select Subject",
            menuItems: subjectHandledDDMenuItems,
            value: "",
            label: "Subject",
          },
          {
            name: "selectedGradeLevelDDValue",
            id: "approved_requests_teacher_grade",
            fieldName: "gradeLevelDDMenuItems",
            fieldType: FIELD_TYPES.SELECT,
            value: "",
            menuItems: gradeLevelDDMenuItems,
            placeHolderText: "Select Grade Level",
            label: "Grade",
          },
        ];

        tableHeaderColumnConfigs = [
          {
            id: "title",
            columnId: 41,
            label: "Title",
            width: "250px",
            type: tableColumnTypes.SUBJECT_ICON_NAME,
            maxWidth: "300px",
          },
          {
            label: "Subject",
            columnId: 42,
            id: "subjectName",
            type: tableColumnTypes.TEXT,
            maxWidth: "150px",
            width: "100px",
          },
          {
            id: "grade",
            columnId: 43,
            label: "Grade",
            width: "100px",
            maxWidth: "150px",
            type: tableColumnTypes.TEXT,
          },
          {
            columnId: 45,
            label: "Requested On",
            width: "120px",
            type: tableColumnTypes.TEXT,
            id: "requestedOn",
            maxWidth: "180px",
          },
          {
            id: "acceptedOn",
            columnId: 46,
            type: tableColumnTypes.TEXT,
            label: "Approved On",
            maxWidth: "180px",
            width: "120px",
          },
          {
            columnId: 47,
            label: "Created On",
            type: tableColumnTypes.TEXT,
            width: "120px",
            id: "createdOn",
            maxWidth: "180px",
          },
          {
            columnId: 48,
            id: "modifiedOn",
            width: "150px",
            label: "Last Modified On",
            type: tableColumnTypes.TEXT,
            maxWidth: "200px",
          },
        ];

        tableActionMenuConfigs = [
          {
            label: "Copy to My Courses",
            action: () => { },
          },
          {
            label: "View",
            action: () => { },
          },
        ];
      }

      this.setState({
        tableBodyData: requestsTableData,
        tableHeaderColumns: tableHeaderColumnConfigs,
        manageRequestInputConfig: manageRequestInputConfigs,
        tableActionButtonConfig: tableActionMenuConfigs,
      });

    } else if (tenantCourseMngTabCategory === "rejected_requests") {

      if (this.capitalizeString(role) === USER_ROLES_CAMEL.TENANT_ADMIN) {
        manageRequestInputConfigs = [
          {
            id: "rejected_requests_tenant_title",
            fieldName: "titleDDMenuItems",
            name: "selectedTitleDDValue",
            value: "",
            label: "Title",
            menuItems: titleDDMenuItems,
            placeHolderText: "Select Title",
            fieldType: FIELD_TYPES.SELECT,
          },
          {
            fieldType: FIELD_TYPES.SELECT,
            id: "rejected_requests_tenant_requested_by",
            placeHolderText: "Select Option",
            name: "selectedRequestedByDDValue",
            value: "",
            menuItems: requestedByDDMenuItems,
            fieldName: "requestedByDDMenuItems",
            label: "Requested By",
          },
          {
            name: "selectedRequestedOnDDValue",
            fieldType: FIELD_TYPES.SELECT,
            id: "rejected_requests_tenant_requested_on",
            fieldName: "requestedOnDDMenuItems",
            placeHolderText: "Select Option",
            value: "",
            label: "Requested On",
            menuItems: acsDescMock,
          },
          {
            fieldType: FIELD_TYPES.SELECT,
            name: "selectedRejectedOnDDValue",
            id: "rejected_requests_tenant_rejected_on",
            placeHolderText: "Select Option",
            value: "",
            fieldName: "rejectedOnDDMenuItems",
            menuItems: acsDescMock,
            label: "Rejected On",
          },
          {
            id: "rejected_requests_tenant_subject",
            name: "selectedSubjectHandledDDValue",
            placeHolderText: "Select Subject",
            fieldType: FIELD_TYPES.SELECT,
            fieldName: "subjectHandledDDMenuItems",
            menuItems: subjectHandledDDMenuItems,
            value: "",
            label: "Subject",
          },
          {
            name: "selectedGradeLevelDDValue",
            id: "rejected_requests_tenant_grade",
            value: "",
            fieldName: "gradeLevelDDMenuItems",
            fieldType: FIELD_TYPES.SELECT,
            menuItems: gradeLevelDDMenuItems,
            placeHolderText: "Select Grade Level",
            label: "Grade",
          },
        ];

        tableHeaderColumnConfigs = [
          {
            id: "title",
            columnId: 51,
            type: tableColumnTypes.SUBJECT_ICON_NAME,
            width: "250px",
            label: "Title",
            maxWidth: "300px",
          },
          {
            columnId: 52,
            label: "Subject",
            id: "subjectName",
            type: tableColumnTypes.TEXT,
            maxWidth: "150px",
            width: "100px",
          },
          {
            label: "Grade",
            columnId: 53,
            id: "grade",
            width: "100px",
            type: tableColumnTypes.TEXT,
            maxWidth: "150px",
          },
          {
            id: "requestedBy",
            columnId: 54,
            type: tableColumnTypes.TEXT,
            label: "Requested By",
            maxWidth: "200px",
            width: "150px",
          },
          {
            columnId: 55,
            label: "Requested On",
            id: "requestedOn",
            width: "120px",
            type: tableColumnTypes.TEXT,
            maxWidth: "180px",
          },
          {
            id: "rejectedOn",
            columnId: 56,
            type: tableColumnTypes.TEXT,
            width: "120px",
            label: "Rejected On",
            maxWidth: "180px",
          },
          {
            columnId: 57,
            label: "Created On",
            id: "createdOn",
            width: "120px",
            maxWidth: "180px",
            type: tableColumnTypes.TEXT,
          },
          {
            columnId: 58,
            id: "modifiedOn",
            type: tableColumnTypes.TEXT,
            label: "Last Modified On",
            maxWidth: "200px",
            width: "150px",
          },
        ];

        tableActionMenuConfigs = [
          {
            label: "View",
            action: (value: any) => this.handleTenantViewRequestDetails(value),
          },
        ];

      } else if (this.capitalizeString(role) === USER_ROLES_CAMEL.TEACHER || this.capitalizeString(role) === USER_ROLES_CAMEL.PARENT_TEACHER) {
        manageRequestInputConfigs = [
          {
            name: "selectedTitleDDValue",
            id: "rejected_requests_teacher_title",
            value: "",
            fieldName: "titleDDMenuItems",
            label: "Title",
            menuItems: titleDDMenuItems,
            placeHolderText: "Select Title",
            fieldType: FIELD_TYPES.SELECT,
          },
          {
            id: "rejected_requests_teacher_requested_on",
            name: "selectedRequestedOnDDValue",
            fieldType: FIELD_TYPES.SELECT,
            placeHolderText: "Select Option",
            fieldName: "requestedOnDDMenuItems",
            label: "Requested On",
            menuItems: acsDescMock,
            value: "",
          },
          {
            id: "rejected_requests_teacher_rejected_on",
            fieldType: FIELD_TYPES.SELECT,
            name: "selectedRejectedOnDDValue",
            fieldName: "rejectedOnDDMenuItems",
            value: "",
            placeHolderText: "Select Option",
            label: "Rejected On",
            menuItems: acsDescMock,
          },
          {
            id: "rejected_requests_teacher_subject",
            fieldType: FIELD_TYPES.SELECT,
            name: "selectedSubjectHandledDDValue",
            fieldName: "subjectHandledDDMenuItems",
            placeHolderText: "Select Subject",
            menuItems: subjectHandledDDMenuItems,
            label: "Subject",
            value: "",
          },
          {
            name: "selectedGradeLevelDDValue",
            id: "rejected_requests_teacher_grade",
            fieldName: "gradeLevelDDMenuItems",
            fieldType: FIELD_TYPES.SELECT,
            value: "",
            menuItems: gradeLevelDDMenuItems,
            label: "Grade",
            placeHolderText: "Select Grade Level",
          },
        ];

        tableHeaderColumnConfigs = [
          {
            columnId: 61,
            label: "Title",
            id: "title",
            width: "250px",
            maxWidth: "300px",
            type: tableColumnTypes.SUBJECT_ICON_NAME,
          },
          {
            id: "subjectName",
            columnId: 62,
            type: tableColumnTypes.TEXT,
            label: "Subject",
            maxWidth: "150px",
            width: "100px",
          },
          {
            columnId: 63,
            label: "Grade",
            id: "grade",
            width: "100px",
            maxWidth: "150px",
            type: tableColumnTypes.TEXT,
          },
          {
            id: "requestedOn",
            columnId: 64,
            type: tableColumnTypes.TEXT,
            label: "Requested On",
            maxWidth: "180px",
            width: "120px",
          },
          {
            label: "Rejected On",
            columnId: 65,
            type: tableColumnTypes.TEXT,
            id: "rejectedOn",
            maxWidth: "180px",
            width: "120px",
          },
          {
            id: "createdOn",
            columnId: 66,
            label: "Created On",
            width: "120px",
            type: tableColumnTypes.TEXT,
            maxWidth: "180px",
          },
          {
            columnId: 67,
            label: "Last Modified On",
            type: tableColumnTypes.TEXT,
            id: "modifiedOn",
            maxWidth: "200px",
            width: "150px",
          },
        ];

        tableActionMenuConfigs = [
          {
            label: "View",
            action: () => { },
          },
        ];
      }

      this.setState({
        tableBodyData: requestsTableData,
        tableHeaderColumns: tableHeaderColumnConfigs,
        manageRequestInputConfig: manageRequestInputConfigs,
        tableActionButtonConfig: tableActionMenuConfigs,
      });
    }
  };

  handleSelectDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    const { manageRequestInputConfig } = this.state;
    let fieldValue: any = value;
    if (fieldValue) {
      const updatedDDConfig = manageRequestInputConfig.map((item) => {
        if (item.name === name) {
          item.value = value;
        }
        return item;
      });
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
          manageRequestInputConfig: updatedDDConfig,
        };
      });
    }
  };

  handleSelectAssignCourseToCatalogDDValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    if (value) {
      this.setState({
        selectedAssignCourseToCatalogTitleDDValue: value,
      }, () => this.handleSetAssignCourseToCatalogInputConfig());
    }
  };

  handleSearchRequests = () => {
    this.setState({ loading: true });
    const {
      selectedTitleDDValue,
      selectedAcceptedOnDDValue,
      selectedRequestedByDDValue,
      selectedGradeLevelDDValue,
      selectedSubjectHandledDDValue,
      selectedRequestedOnDDValue,
      selectedRejectedOnDDValue,
    } = this.state;
    const searchQuery = {
      course_title: selectedTitleDDValue,
      requested_on: this.capitalizeString(selectedRequestedOnDDValue),
      accepted_on: this.capitalizeString(selectedAcceptedOnDDValue),
      rejected_on: this.capitalizeString(selectedRejectedOnDDValue),
      sender_id: selectedRequestedByDDValue,
      grade_id: selectedGradeLevelDDValue,
      subject_id: selectedSubjectHandledDDValue,
    }
    this.handleGetRequestsDataApi(searchQuery, {});
  };

  capitalizeString = (word: string) => {
    if (typeof word !== 'string') return '';

    if (word.length === 0) return word;
    
    return word
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  handleManageRequestTableAction = (value?: any) => {
    console.log("table action", value);
  };

  handleRejectReason = (e: any) => {
    this.setState({ requestRejectionReason: e?.target?.value });
  };

  handleOpenRejectRequestModal = () => {
    this.setState(
      { rejectRequest: true, isFromAssignCourseToCatalog: false },
      () => this.handleAssignRejectApproveConfirmModalOpen()
    );
  };

  handleOpenAcceptRequestModal = () => {
    this.setState(
      { rejectRequest: false, isFromAssignCourseToCatalog: false },
      () => this.handleAssignRejectApproveConfirmModalOpen()
    );
  };

  handleAssignToCatalogTableAction = (value?: any) => {
    if (value) {
      const { tableBodyData } = this.state;
      const selectedRequest = tableBodyData.find(
        (item: any) => item.id === value
      );
      console.log("selectedRequest: ", selectedRequest);
      this.setState({
        assignCourseId: selectedRequest?.courseId,
        assignCourseTitle: selectedRequest?.title?.name,
        assignCourseSubject: selectedRequest?.subjectName,
        assignCourseGrade: selectedRequest?.grade,
        acceptRejectRequestData: selectedRequest,
      }, () => {
        this.handleSetAssignCourseToCatalogInputConfig();
        this.handleOpenAssignCourseToCatalogModal();
      }
      );
    }
  };

  handleOpenAssignCourseToCatalogModal = () => {
    this.setState({
      openAssignConfirmModal: false,
      openAssignCourseToCatalogModal: true,
    });
  };

  handleCloseAssignCourseToCatalogModal = () => {
    this.setState({
      openAssignCourseToCatalogModal: false,
      rejectRequest: false,
      assignCourseToCatalogInputConfig: assignCourseToCatalogInputConfigMock,
    });
  };

  handleTenantViewRequestDetails = (value?: any) => {
    this.setState({ loading: false });
    const { requestsTableData, currentRequestType } = this.state;
    const { navigation } = this.props;
    const course = requestsTableData?.find((item: any) => item.id === value);
    navigation.navigate("CourseManagementPendingRequestDetail", {}, {
      requestType: currentRequestType,
      selfCourse: "MyCourses",
      courseName: course?.title?.name,
      courseId: course?.courseId,
    })
  };

  handleGoBackToCourseMngMain = () => {
    this.props.navigation?.navigate("CourseManagementMain", {}, {});
  };

  handleAssignConfirm = () => {
    const {
      selectedAssignCourseToCatalogTitleDDValue,
      assignCourseToCatalogDDMenuItems
    } = this.state;
    const selectedItem = assignCourseToCatalogDDMenuItems.find(
      (item: IDropdownMenuItemProps) => item.id === selectedAssignCourseToCatalogTitleDDValue
    );
    const catalogName = selectedItem?.label;
    this.setState({
      isFromAssignCourseToCatalog: true,
      rejectRequest: false,
      assignCourseCatalogName: catalogName || "Catalog",
    }, () => this.handleAssignRejectApproveConfirmModalOpen());
  };

  handleAssignRejectApproveConfirmModalOpen = () => {
    this.setState({
      openAssignConfirmModal: true,
      openAssignCourseToCatalogModal: false,
    });
  };

  handleRejectReasonModalClose = () => {
    this.setState({ openRejectReasonPopup: false, rejectRequest: false });
  };

  handleAssignRejectApproveConfirmModalConfirm = () => {
    const { rejectRequest, isFromAssignCourseToCatalog } = this.state;
    if (rejectRequest && !isFromAssignCourseToCatalog) {
      this.setState(
        {
          openRejectReasonPopup: true,
        },
        () => this.setState({ openAssignConfirmModal: false })
      );
    } else if (isFromAssignCourseToCatalog) {
      this.setState({ openAssignConfirmModal: false }, () => {
        this.handleAssignCourseToCatalogApi();
      });
    } else {
      this.setState({ openAssignConfirmModal: false });
      const requestBody = { id: this.state.acceptRejectRequestId, is_accepted: true, rejection_reason: "" }
      this.handleAcceptRejectRequestApi(requestBody);
    }
  };

  handleAssignSuccessModalClose = () => {
    this.setState({
      openAssignSuccessPopup: false,
    });
  };

  handleAssignRejectApproveConfirmModalClose = () => {
    this.setState({
      openAssignConfirmModal: false,
      rejectRequest: false,
      isFromAssignCourseToCatalog: false,
    });
  };

  handleGetRequestsDataApi = async (query: any, pagination: any) => {
    showCustomLoader();
    const { currentRequestType } = this.state;
    const baseUrl = configJSON.getRequestsData + `?type=${currentRequestType}&per_page=${pagination.limit || this.state.paginationLimit}`;
    let requestUrl = baseUrl;

    if (query.course_title) {
      requestUrl = requestUrl + `&course_title=${query.course_title}`;
    }

    if (query.requested_on) {
      requestUrl = requestUrl + `&requested_on=${query.requested_on}`;
    }

    if (query.accepted_on) {
      requestUrl = requestUrl + `&accepted_on=${query.accepted_on}`;
    }

    if (query.rejected_on) {
      requestUrl = requestUrl + `&rejected_on=${query.rejected_on}`;
    }

    if (query.sender_id) {
      requestUrl = requestUrl + `&sender_id=${query.sender_id}`;
    }

    if (query.grade_id) {
      requestUrl = requestUrl + `&grade_id=${query.grade_id}`;
    }

    if (query.subject_id) {
      requestUrl = requestUrl + `&subject_id=${query.subject_id}`;
    }

    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRequestsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetRequestListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      let requestsData = responseJson?.data?.map(
        (item: any, index: number) => {
          return this.handleSetCourseDataItem(item);
        }
      );

      const titleDropdownData = requestsData.map((item: any) => {
        return {
          id: _.uniqueId(item.id),
          label: item.title.name,
          value: item.title.name,
        }
      });

      this.setState({
        requestsTableData: requestsData,
        paginationData: responseJson?.meta?.pagination,
        titleDDMenuItems: titleDropdownData,
      }, () => {
        this.handleSetManageRequestsListData();
        this.handleUpdateCatalogTableActionConfigs(requestsData);
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleSetCourseDataItem = (item: any) => {
    const request = item.attributes;
    return {
      id: item?.id,
      courseId: request?.course?.id,
      title: {
        name: request?.course?.course_title,
        icon: request?.subject ? request?.subject?.icon : '',
        bgColor: request?.subject ? request?.subject?.color : '#000',
        boxWidth: "32px",
        boxHeight: "32px",
        rejectionReason:request.rejection_reason
      },
      subjectName: request?.subject ? request?.subject.name : '-',
      grade: request?.grade?.name,
      createdOn: moment(request?.course?.created_at).format('MMMM DD, YYYY'),
      modifiedOn: moment(request?.course?.updated_at).format('MMMM DD, YYYY'),
      requestedBy: `${request?.sender?.first_name} ${request?.sender?.last_name}`,
      acceptedOn: moment(request?.accepted_on).format('MMMM DD, YYYY'),
      rejectedOn: moment(request?.rejected_on).format('MMMM DD, YYYY'),
      requestedOn: moment(request?.requested_on).format('MMMM DD, YYYY'),
      
    }
  };

  handleRequestPaginationChange = (data: number) => {
    this.setState({ loading: true });
    this.handleGetRequestsDataApi({}, { page: data, limit: this.state.paginationLimit });
  }

  handleGetTeachersListApi = async (query: any, pagination: any) => {
    showCustomLoader();

    const baseUrl = configJSON.getUsersList + `?per_page=100`;
    let requestUrl = baseUrl;

    if (query.user_type) {
      requestUrl = requestUrl + `&type=${query.user_type}`;
    }

    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTeachersList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeachersListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      let teachersData = responseJson?.data?.map(
        (item: any, index: number) => {
          const teacher = item.attributes;
          return {
            id: _.uniqueId(item.id),
            label: `${teacher.first_name} ${teacher.last_name}`,
            value: item.id,
          }
        }
      );

      this.setState({
        requestedByDDMenuItems: teachersData,
      }, () => {
        this.handleSetManageRequestsListData();
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetSubjectsDropdownListApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetSubjectsDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsDropdwonData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSubjectsDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const subjectsDDList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: _.uniqueId(item.attributes.id),
            value: item.attributes.id,
            label: item.attributes.name,
            grade: item.attributes.grade,
          };
        }
      );
      this.setState({
        subjectHandledDDMenuItems: subjectsDDList,
      });
      this.handleSetManageRequestsListData();
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetGradesDropdownListApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetGradesDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradesDropdownData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGradesDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const gradesList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: _.uniqueId(item.id),
            value: item.id,
            label: item?.attributes.name,
          };
        }
      );
      this.setState({
        gradeLevelDDMenuItems: gradesList,
      });
      this.handleSetManageRequestsListData();
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleAcceptRejectRequestApi = async (requestBody: any) => {
    showCustomLoader();

    if (requestBody.is_accepted) {
      this.setState({ acceptRejectSuccessMesg: TOASTER_NOTIFICATION_MSG.REQUEST_ACCEPT_SUCCESS });
    } else {
      this.setState({ acceptRejectSuccessMesg: TOASTER_NOTIFICATION_MSG.REQUEST_REJECT_SUCCESS });
    }

    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPutAcceptRejectRequest = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequestsData}/review`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAcceptRejectRequestResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      const { requestsTableData, acceptRejectRequestId, acceptRejectSuccessMesg } = this.state;
      const updatedRequestsList = requestsTableData.filter(
        (item: any) => item.id !== acceptRejectRequestId
      );
      this.setState({ requestsTableData: updatedRequestsList }, () => {
        this.handleSetManageRequestsListData();
      });
      toast.success(acceptRejectSuccessMesg);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleAcceptPendingRequest = (id: any) => {
    const requestData = this.state.requestsTableData.find((item: any) => item.id === id);
    this.setState({ acceptRejectRequestId: id, acceptRejectRequestData: requestData }, () => {
      this.handleOpenAcceptRequestModal();
    })
  }

  handleRejectPendingRequest = (id: any) => {
    const requestData = this.state.requestsTableData.find((item: any) => item.id === id);
    this.setState({ acceptRejectRequestId: id, acceptRejectRequestData: requestData, rejectRequest: true }, () => {
      this.handleOpenRejectRequestModal();
    })
  }

  handleAddRejectReason = () => {
    const { requestRejectionReason } = this.state;
    const requestBody = { id: this.state.acceptRejectRequestId, is_accepted: false, rejection_reason: requestRejectionReason }
    this.setState({ openRejectReasonPopup: false });
    this.handleAcceptRejectRequestApi(requestBody);
  }

  setUserRole = (value: string) => {
    this.setState({ role: value });
  }

  setRequestTabIndex = (value: number) => {
    this.setState({ selectedManageRequestTabIndex: value });
  }

  handleSetAssignCourseToCatalogInputConfig = () => {
    const {
      assignCourseGrade,
      assignCourseSubject,
      assignCourseTitle,
      assignCourseToCatalogDDMenuItems,
      selectedAssignCourseToCatalogTitleDDValue,
    } = this.state;
    this.setState({
      assignCourseToCatalogInputConfig: [
        {
          id: "tenant-assign-course-to-catalog-courseTitle-menu",
          name: "assignCourseTitle",
          fieldName: "courseTitle",
          value: assignCourseTitle,
          menuItems: [],
          label: "Course Title",
          fieldType: FIELD_TYPES.TEXT,
          placeHolderText: "Title",
          isDisabled: true,
        },
        {
          id: "tenant-assign-course-to-catalog-subject",
          name: "assignCourseSubject",
          fieldType: FIELD_TYPES.TEXT,
          placeHolderText: "",
          fieldName: "subject",
          value: assignCourseSubject,
          menuItems: [],
          isDisabled: true,
          label: "Subject",
        },
        {
          id: "tenant-assign-course-to-catalog-gradeLevel",
          name: "assignCourseGrade",
          fieldName: "gradeLevel",
          value: assignCourseGrade,
          fieldType: FIELD_TYPES.TEXT,
          placeHolderText: "",
          menuItems: [],
          isDisabled: true,
          label: "Grade",
        },
        {
          fieldType: FIELD_TYPES.SELECT,
          id: "tenant-assign-course-to-catalog-catalog-menu",
          name: "selectedAssignCourseToCatalogTitleDDValue",
          placeHolderText: "Catalog",
          fieldName: "assignCourseToCatalogDDMenuItems",
          value: selectedAssignCourseToCatalogTitleDDValue,
          menuItems: assignCourseToCatalogDDMenuItems,
          label: "Catalog",
        },
      ]
    })
  }

  handleGetCatalogDropdownDataApi = async () => {
    showCustomLoader();
    const requestUrl = configJSON.postAddNewCatalog + `?per_page=200`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetCatalogDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetCatalogDropdownDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      const response = responseJson?.data;
      const catalogList = response?.map((item: any) => {
        const catalog = item.attributes;
        return {
          id: item.id,
          label: catalog.name,
          value: item.id,
          courses: catalog?.courses ? catalog?.courses : [],
          gradeId: catalog?.grade?.id,
          curriculumId: catalog?.curriculum?.id,
          schoolYearId: catalog?.school_year?.id,
        }
      });
      this.setState({
        assignCourseToCatalogDDMenuItems: catalogList,
      }, () => this.handleSetAssignCourseToCatalogInputConfig());
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleAssignCourseToCatalogApi = async () => {
    const {
      selectedAssignCourseToCatalogTitleDDValue,
      assignCourseToCatalogDDMenuItems,
      assignCourseId,
    } = this.state;
    const catalog = assignCourseToCatalogDDMenuItems.find(
      (item: any) => item.id === selectedAssignCourseToCatalogTitleDDValue
    );
    let addedCourseIds: any = [];
    if (catalog) {
      addedCourseIds = catalog?.courses.map((item: any) => { return (item.id).toString() });
    }
    addedCourseIds.push(assignCourseId);
    let requestBody = {
      data: {
        attributes: {
          name: catalog?.label,
          school_year_id: catalog?.schoolYearId,
          grade_id: catalog?.gradeId,
          curriculum_id: catalog?.curriculumId,
          course_ids: addedCourseIds,
          remove_course_ids: [],
        }
      }
    }
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiAssignCourseToCatalog = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewCatalog}/${this.state.selectedAssignCourseToCatalogTitleDDValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAssignCourseToCatalogResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.setState({ openAssignSuccessPopup: true },
        () => this.setState({ openAssignConfirmModal: false })
      );
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleUpdateCatalogTableActionConfigs = (dataList: any) => {
    const { tableActionButtonConfig } = this.state;
    let rowWiseActions: Array<any> = [];
    dataList.forEach((request: any) => {
      rowWiseActions.push({
        id: request.id,
        menu: tableActionButtonConfig
      });
    });
    this.setState({ rowWiseTableActionConfig: rowWiseActions });
  }
  // Customizable Area End
}
