// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { createTheme } from "@material-ui/core/styles";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
// Define interface for custom variables
interface CustomBackgroundThemeProps {
  bgColor: string;
  secondaryBgColor: string;
  cardBgColor: string;
  cardBgColor02: string;
  highlightBgColor: string;
  audioPlayerCardBgColor: string;
  secondaryInfoBgColor: string;
  gradeBgGreenColor: string;
}

interface CustomTextColorThemeProps {
  primaryColor: string;
  secondaryColor: string;
  commonColor: string;
  lightGreyColor: string;
  textClickColor: string;
}

interface CustomContainedButtonColorThemeProps {
  primaryColor: string;
  customizedColor?:string
}

declare module "@material-ui/core/styles" {
  interface ThemeOptions {
    customBackground?: CustomBackgroundThemeProps;
    customText?: CustomTextColorThemeProps;
    customButton?: CustomContainedButtonColorThemeProps;
  }
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id?: string;
  classes: any;
  children: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  fullName: string;
  keepSidebarOpen: boolean;
  isSmallScreen: boolean;
  themeSettingDetails: any;
  defaultThemeSettingDetails: any;
  isDarkMode: boolean;
  themeData:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LayoutAppearanceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      fullName: "",
      keepSidebarOpen: true,
      isSmallScreen: false,
      themeSettingDetails: null,
      defaultThemeSettingDetails: createTheme({
        customBackground: {
          bgColor: "#ffffff",
          secondaryBgColor: "#F7F7F7",
          cardBgColor: "#EDEDED",
          cardBgColor02: "#EDEDED",
          highlightBgColor: "#FBD82C",
          audioPlayerCardBgColor: "#F2FCF9",
          secondaryInfoBgColor: "#e2e4e933",
          gradeBgGreenColor: "#effbf9",
        },
        customText: {
          primaryColor: "#090909",
          secondaryColor: "#090909",
          commonColor: "#959595",
          lightGreyColor: "#B6B6B6",
          textClickColor: "#0D46BC",
        },
        customButton: {
          primaryColor: "#0D46BC",
          customizedColor:"#0D46BC"
        },
      }),
      isDarkMode: false,
      themeData:{}
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    window.addEventListener("resize", this.resize.bind(this));
    window.addEventListener('appearanceTheme', this.handleGetAppearanceTheme);
    window.addEventListener('storage', this.handleSetSelectedThemeModePreferenceInStorage.bind(this));
    this.resize();
    this.handleSetSelectedThemeModePreferenceInStorage();
    this.handleGetAppearanceTheme();
    // Customizable Area End
  }
  // Customizable Area Start

  handleGetAppearanceTheme = async () => {
    const themeData = await getStorageData("appearanceTheme");

    if (themeData) {

      try {
    
        const appearanceThemes = JSON.parse(themeData);
        
        this.setState({themeData:appearanceThemes,isDarkMode:appearanceThemes.dark_mode},()=>{
          this.handleSetSelectedThemeModePreferenceInStorage()
        })
      } catch (error) {
        console.error("Failed to parse themeData. It might not be valid JSON.", error);
      }
    } else {
      console.warn("No themeData found in local storage.");
    }
  };

  resize = () => {
    this.setState({
      isSmallScreen: window.outerWidth <= 760,
      keepSidebarOpen: window.outerWidth > 760,
    });
  };

  componentWillUnmount(): any {
    window.removeEventListener("resize", this.resize.bind(this));
    window.removeEventListener('appearanceTheme', this.handleGetAppearanceTheme);
   
    window.removeEventListener("storage", this.handleSetSelectedThemeModePreferenceInStorage.bind(this));
  }

  handleSetSelectedThemeModePreferenceInStorage = () => {
    const isDarkMode: any =
    localStorage.getItem("dark_mode") || JSON.stringify(false);
    this.setState({ isDarkMode: JSON.parse(isDarkMode) }, () => {
      if (this.state.isDarkMode) this.handleSetDarkThemeSettings();
      else this.handleSetLightThemeSettings();
    });
  };

  toggleSidebar = () => {
    this.setState({ keepSidebarOpen: !this.state.keepSidebarOpen });
  };

  handleSetLightThemeSettings = async () => {
  
    const themeSettings = {
      data: {
        highlightColor: "#07cae8",
        primaryTextColor: "#090909",
        secondaryTextColor: "#888888",
        backgroundColor: "#F7F7F7",
        accentColor: "#e610b4",
      },
    };
    const defaultTheme = createTheme({
      customBackground: {
        bgColor: "#ffffff",
        secondaryBgColor: themeSettings.data.backgroundColor,
        cardBgColor: "#f0f0f0",
        cardBgColor02: "#f7f7f7",
        highlightBgColor: themeSettings.data.highlightColor,
        audioPlayerCardBgColor: "#F2FCF9",
        secondaryInfoBgColor: "#e2e4e933",
        gradeBgGreenColor: "#effbf9",
      },
      customText: {
        primaryColor: "#090909",
        secondaryColor: "#090909",
        commonColor: "#959595",
        lightGreyColor: "#B6B6B6",
        textClickColor: "#0D46BC",
      },
      customButton: {
        primaryColor: "#0D46BC",
        customizedColor:this.state.themeData.accent_color || "#0D46BC"
      },
    });
    this.setState({ defaultThemeSettingDetails: defaultTheme });
    await setStorageData("theme", defaultTheme);
  };

  handleSetDarkThemeSettings = async () => {
    const darkThemeSettings = {
      data: {
        highlightColor: "#e6e610",
        primaryTextColor: "#fff",
        secondaryTextColor: "#888888",
        backgroundColor: "#333436",
        accentColor: "#91048a",
      },
    };
    const darkTheme = createTheme({
      customBackground: {
        bgColor: "#090909",
        secondaryBgColor: darkThemeSettings.data.backgroundColor,
        cardBgColor: "#090909",
        cardBgColor02: "#333436",
        highlightBgColor: darkThemeSettings.data.highlightColor,
        audioPlayerCardBgColor: "#090909",
        secondaryInfoBgColor: "#161719",
        gradeBgGreenColor: "#255e54",
      },
      customText: {
        primaryColor: "#fff",
        secondaryColor: "#D1D1D1",
        commonColor: "#D1D1D1",
        lightGreyColor: "#D1D1D1",
        textClickColor: "yellow",
      },
      customButton: {
        primaryColor:"#FBD82C",
        customizedColor:this.state.themeData.accent_color || "#FBD82C" 
      },
    });
    this.setState({ defaultThemeSettingDetails: darkTheme });
    await setStorageData("theme", darkTheme);
  };

  handleToggleThemeSettings = () => {
    this.setState({ isDarkMode: !this.state.isDarkMode }, () => {
      localStorage.setItem("dark_mode", JSON.stringify(this.state.isDarkMode));
      if (this.state.isDarkMode) {
        this.handleSetDarkThemeSettings();
      } else {
        this.handleSetLightThemeSettings();
      }
    });
  };

  // Customizable Area End
}
