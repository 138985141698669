// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  AttandanceTableHead,
  BehaviorsTableHead,
  TemplateData,
  gradebookListTableHead,
  reportsListMock,
} from "../assets/tenantDashboardMock";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, hideCustomLoader } from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
import { GradeBook } from "./TenantAdminGradeBookListController.web";
import { TemplateContentType, Templates } from "./gradebookTemplate/GradeBookTemplateOrientationController";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { physicsSubjectIcon } from "./assets";
import { isArray } from "lodash";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  gradeId:number;
  handleClose:()=>void
  
  // Customizable Area End
}



export interface SchoolYearResponse {
    id: string;
    type: string;
    attributes: {
      id: number;
      title: string;
      description: string;
      number_of_grading: number;
      grade_period_title: string;
      school_id: number;
      start_date: string;
      end_date: string;
      status: string;
      semesters: Semester[];
    };
}

interface Semester {
  id: number;
  title: string;
  start_date: string;
  end_date: string;
  school_year_id: number;
  created_at: string;
  updated_at: string;
}
interface GradingList{
    id: number;
    titles: string;
    min: number;
    max: number;
}
export interface CreateRequestBody{
    title: string;
    minimum: number;
    maximum: number;
}
interface CurriculumRequest {
  title: string;
  grade_book_id: number;
  curriculum_ids: number[];
}
export interface CreateGradingComponentRequestBody{
    title: string;
    min_attempts: number;
    max_attempts: number;
    recommended: number;
    weightage: number;
}
export interface ActionItem {
  label: string;
  action: (value: any) => void;
}

export interface UserGradingDetails{
    id: number;
    title: string;
    minimum: number;
    maximum: number;
  }


export interface GradingListComponent{
    id: number;
    titles: string;
    weightage: number;
    minAttempts: number;
    maxAttempts: number;
    recommended: number;
}
export interface BehaviorList{
  id: number;
  titles: string;
  evalutionKeys: string; 
  NumOfItems: number;    
}
export interface BehaviorDetails{
  id: string;
  type: string;
  attributes: {
    id: number;
    obeservation_title: string;
    school_id: number;
    evaluation_keys: Array<{
        id: number;
        title: string;
        behaviour_id: number;
        created_at: string;
        updated_at: string;
    }>;
    skills: Array<{
        id: number;
        skill_title: string;
        created_at: string;
        updated_at: string;
        sub_skills: Array<{
            id: number;
            title: string;
            skill_id: number;
            created_at: string;
            updated_at: string;
        }>;
    }>;
  };
}
interface Curriculum {
  id: number;
  name: string;
  subject_id: string[]; 
  grade_id: number;
  school_id: number;
  created_at: string;
  updated_at: string;
}

interface GradeBookRuleAttributes {
  id: number;
  title: string | null; 
  grade_book_id: number;
  school_id: number;
  created_at: string;
  updated_at: string;
  curriculums: Curriculum[];
}

interface GradeBookRule {
  length: number;
  id: string; 
  type: "grade_book_rule";
  attributes: GradeBookRuleAttributes;
}
interface TableRulesDataDetails{
    id: number;
    titles: string;
    options: string;
}
interface Curriculum {
  id: number;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  name: string;
  grade: Grade;
  curriculums:{
    id:number;
  }[]
}

interface Grade {
  id: number;
  name: string;
  description: string | null;
  status: string;
  created_at: string;
  updated_at: string;
  school_id: number;
}
export interface CurriculumList{
  id: string;
  value: string;
  label: string;
}
interface S {
  // Customizable Area Start
  loading: boolean;
  reportsList: Array<any>;
  checked:boolean
  tableBodyData:any
  assessmentStudentsColumns:any
  openAddNewGradingComponentModal:boolean
  openAddNewGradeTitleModal:boolean
  openObservation:boolean;
  gradingSystemList:GradingList[];
  gradesTableActionButtonConfig:ActionItem[];
  isEditNewGradeTitleModal:boolean,
  isEditNewGradeComponentModal:boolean,
  editNewGradeId:number,
  userGradingDetails:UserGradingDetails;
  userGradingComponentDetails:GradingListComponent
  tableBodyDataWeightage:GradingListComponent[]
  gradesTableActionButtonConfigWeightage:ActionItem[];
  tableBodyDataBehavior:BehaviorList[]
  gradesTableActionButtonConfigBehavior:ActionItem[],
  gradeBookId:number,
  behaviourDetails:BehaviorDetails;
  tableBodyDataRules:TableRulesDataDetails[]
  gradesTableActionButtonConfigRules:ActionItem[]
  openRuleModal:boolean
  isEditRuleModal:boolean
  curriculumList:CurriculumList[],
  selectedIds:string[],
  selectedCurriculum:CurriculumList[];
  tenantDetails: {
    id: number;
    title: string | null;
    teacher_comments: boolean;
    reporting_type: boolean;
},
teacherComment:boolean;
reportingType:boolean;
openGradeBookTemplate:boolean;
isEditingTitle:boolean;
titleInput:string;
titleError: string;  
isTitleError:boolean;
menuAnchorEl: any;
selectedRowId:number;
TemplateDataList: {
  id: number;
  title: string;
  value: string;
  templateId:number;
}[];
isPreviewOpen:boolean,
getDataOfficalTemplate:{
  templateType: string;
  getDataOfTemplate: TemplateContentType[];
},
getDataUnofficalTemplate:{
  templateType: string;
  getDataOfTemplate: TemplateContentType[];
},
selectedTemplateData:{
  templateType: string;
  getDataOfTemplate: TemplateContentType[];
},
AttandanceTableHead:any
AttandanceTableData:any
gradebookListTableHead:any
gradebookTableData:Array<any>;
behaviourTableHead:any;
behaviourTableData:any;
// TemplateData:
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  
  // Customizable Area End
}

// Customizable Area Start
export default class TenantAdminGradeBookController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTenantStatisticsInfo: string = "";
  apiGetGradingSystem:string="";
  createGradingApiCallId:string=""
  deleteGradingSystemCallId:string=""
  apiGetGradingSystemDetailsById:string=""
  updateGradingApiCallId:string=""
  apiGetGradingComponentId:string=""
  createGradingComponentApiCallId:string=""
  deleteGradingComponentCallId:string=""
  apiGetGradingComponentDetailsById:string=""
  updateGradingComponentApiCallId:string=""
  apiGetBehavioursId :string=""
  deleteBehaviourId:string=""
  apiGetBehaviourDetailsById:string=""
  updateBehaviourApiCallId:string=""
  apiGetRules:string=""
  createRuleApiCallId:string=""
  deleteRuleCallId:string=""
  updateRuleApiCallId:string=""
  apiGetCurriculum:string=""
  apiGetGradeBookTenantDetailsById:string=""
  updateGradeBookTenantApiCallId:string=""
  deleteTemplateCallId:string=""
  apiGetGradebookTemplateApiCallId:string="";
  apiGetTableDataApiCallId:string="";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      reportsList: reportsListMock,
      checked:false,
      tableBodyData:[],
      assessmentStudentsColumns:[],
      openAddNewGradingComponentModal:false,
      openAddNewGradeTitleModal:false,
      openObservation:false,
      gradingSystemList:[],
      isEditingTitle:false,
      isPreviewOpen:false,
      titleInput: '',
      gradesTableActionButtonConfig:[
        {
            label: "Edit",
            action: (value: number) => this.handleEditNewGradingSystem(value),
        },
        {
            label: "Delete",
            action: (value: number) => this.handleDeletePostGradeingSystemApi(value),
        },
    ],
    gradesTableActionButtonConfigWeightage:[
      {
          label: "Edit",
          action: (value: number) => this.handleEditNewGradingComponent(value),
      },
      {
          label: "Delete",
          action: (value: number) => this.handleDeletePostGradeingComponentApi(value),
      },
  ],
    isTitleError:false,
    isEditNewGradeTitleModal:false,
    isEditNewGradeComponentModal:false,
    editNewGradeId:-1,
    userGradingDetails:{
      id:0,
      title: "",
      minimum: 0,
      maximum: 0
    },
    titleError: "",
    userGradingComponentDetails:{
      id: 0,
      titles: "",
      weightage: 0,
      minAttempts: 0,
      maxAttempts: 0,
      recommended: 0
    },
    menuAnchorEl: null,
    tableBodyDataWeightage:[],
    tableBodyDataBehavior:[],
    gradesTableActionButtonConfigBehavior :[
      {
          label: "Edit Observation",
          action: (value: number) => this.handleEditBehaviour(value),
      },
      {
          label: "View Added Skills",
          action:(value: number) => this.handleopenObservation(value),
      },
      {
          label: "Delete Observation",
          action: (value: any) => this.handleDeleteBehaviourApi(value),
      },
  ],
  selectedRowId:-1,
  gradeBookId:this.props.gradeId,
   behaviourDetails:{
     id: "",
     type: "",
     attributes: {
       id: 0,
       obeservation_title: "",
       school_id: 0,
       evaluation_keys: [],
       skills: []
     }
   },
   tableBodyDataRules:[],
   gradesTableActionButtonConfigRules:[
    {
      label: "Edit",
      action: (value: number) => this.handleEditRule(value),
  },
  {
      label: "Delete",
      action: (value: number) => this.handleDeleteRuleApi(value),
  },
   ],
   openRuleModal:false,
   isEditRuleModal:false,
   curriculumList:[],
   selectedIds:[],
   selectedCurriculum:[],
   tenantDetails:{
     id: 0,
     title: null,
     teacher_comments: false,
     reporting_type: false
   },
    teacherComment:false,
    reportingType:false,
    openGradeBookTemplate:false,
    TemplateDataList:TemplateData,
    getDataOfficalTemplate:{
      templateType: "",
      getDataOfTemplate: []
    },
    getDataUnofficalTemplate:{
      templateType: "",
      getDataOfTemplate: []
    },
    selectedTemplateData:{
      templateType: "",
      getDataOfTemplate: []
    },
    AttandanceTableHead:AttandanceTableHead,
    AttandanceTableData:[],
    gradebookListTableHead:gradebookListTableHead,
    gradebookTableData:[],
    behaviourTableHead:BehaviorsTableHead,  
    behaviourTableData:[]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetGradingSystemsApi()
    this.handleGetGradingComponentsApi()
    this.handleGetBehavioursApi()
    this.handleGetRulesApi()
    this.handleGetCurriculumApi()
    this.handleGetGradeBookTenantDetailsByIdApi(this.props.gradeId)
    this.handleGetGradebookTemplateApi()
    this.handleGetGradebookTemplateTableDatApi()
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );
 
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetGradingSystem:
              this.handleGetGradingSystemListResponse(responseJson);
              break;
          case this.createGradingApiCallId:
              this.handlePostGradingSystemResponse(responseJson)
              break;
          case this.deleteGradingSystemCallId:
            this.handleDeleteGradingSystemResponse(responseJson)
            break;
          case this.apiGetGradingSystemDetailsById:
            this.handleGetGradingSystemDetailsResponse(responseJson);
            break;
          case this.updateGradingApiCallId:
            this.handleUpdateGradingSystemResponse(responseJson)
            break;
          case this.apiGetGradingComponentId:
            this.handleGetGradingComponentsListResponse(responseJson)
            break;
          case this.createGradingComponentApiCallId:
            this.handlePostGradingComponentResponse(responseJson)
            break;
          case this.deleteGradingComponentCallId:
            this.handleDeleteGradingComponentResponse(responseJson)
            break;
          case this.apiGetGradingComponentDetailsById:
            this.handleGetGradingComponentDetailsResponse(responseJson)
            break;
          case this.updateGradingComponentApiCallId:
            this.handleUpdateGradingComponentResponse(responseJson)
            break;
          case this.apiGetBehavioursId:
            this.handleGetBehavioursListsResponse(responseJson)
            break;
          case this.deleteBehaviourId:
            this.handleDeleteBehaviourResponse(responseJson)
            break;
          case this.apiGetBehaviourDetailsById:
            this.handleGetBehaviourDetailsResponse(responseJson)
            break;
          case this.updateBehaviourApiCallId:
            this.handleUpdateBehaviourResponse(responseJson)
            break;
          case this.apiGetRules:
            this.handleGetRulesListResponse(responseJson)
            break;
          case this.createRuleApiCallId:
            this.handlePostRuleResponse(responseJson)
            break;
          case this.deleteRuleCallId:
            this.handleDeleteRuleResponse(responseJson)
            break;
          case this.updateRuleApiCallId:
            this.handleUpdateRuleResponse(responseJson)
            break;
          case this.apiGetCurriculum:
            this.handleGetCurriculumListResponse(responseJson)
            break;
          case this.apiGetGradeBookTenantDetailsById:
            this.handleGetGradeBookTenantDetailsResponse(responseJson)
            break;
          case this.updateGradeBookTenantApiCallId:
            this.handleUpdateGradeBookTenantResponse(responseJson)
            break;
          case this.apiGetGradebookTemplateApiCallId:
            this.handleGetGradebookTemplateResponse(responseJson);
            break;
          case this.deleteTemplateCallId:
            this.handleDeleteTemplateResponse(responseJson);
            break;
          case this.apiGetTableDataApiCallId:
            this.handleGetGradebookTemplateTableDataResponse(responseJson);
            break;
        }
      }
    }
  }
  handleOpenPreview =(id:number)=>{ 
    const typeSelected = TemplateData.find((item)=>item.id === id)
    if(typeSelected){
      const templateType = typeSelected.value;
      if(typeSelected.templateId !== -1){
        if (templateType === "official_template") {
          this.setState({
            selectedTemplateData: this.state.getDataOfficalTemplate,
            isPreviewOpen: true,
          });
        } else {
          this.setState({
            selectedTemplateData: this.state.getDataUnofficalTemplate,
            isPreviewOpen: true,
          });
        }
      }
    }
  }
  handleClosePreview = () => {
    this.setState({ isPreviewOpen: false });
  };
  handleActionClick = (e: any, rowItemId: any) => {
    this.setState({ menuAnchorEl: e?.currentTarget,selectedRowId:rowItemId });
  }
  handleDeleteTemplate=()=>{
    const {selectedRowId} = this.state
    const typeSelected = TemplateData.find((item)=>item.id === selectedRowId)
    if(typeSelected){
      const templateId= typeSelected.templateId;
      this.handleDeleteTemplateApi(templateId)
      this.handleMenuClose();
    }
  }
  isDisabledDeleteButton=()=>{
    const {selectedRowId} = this.state
    const typeSelected = TemplateData.find((item)=>item.id === selectedRowId)
    if(typeSelected){
      return typeSelected.templateId === -1 ? true : false
    }
    return true
  }
  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null })
  }
  toggleEditTitle = () => {
    this.setState((prevState):any => ({
      isEditingTitle: !prevState.isEditingTitle,
      titleInput: prevState.isEditingTitle ? this.state.tenantDetails.title : prevState.titleInput,
    }));
  };
  handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ titleInput: event.target.value });
  };
  saveTitle = () => {
    if (!this.state.titleInput.trim()) {
      this.setState({ titleError: 'Title is required.',isTitleError:true });
      return;
    }
    const requestBody={
      title:this.state.titleInput
    }
    this.handleUpdateGradeBookTenantApi(requestBody,this.props.gradeId)
    this.setState({ isEditingTitle: false,titleError:"",isTitleError:false });
  };
  
  cancelEditTitle = () => {
    this.setState({ isEditingTitle: false, titleInput: this.state.tenantDetails.title || "" });
  };
  
  handleEditTemplate = ()=>{
    const {selectedRowId} = this.state
    const typeSelected = TemplateData.find((item)=>item.id === selectedRowId)
    if(typeSelected){
      const typeSelectedValue = typeSelected.value
    this.props.navigation.navigate("TenantAdminSetupGradeBookTemplate",{
        templateType:typeSelectedValue
    },{
      gradebookId:this.props.gradeId
    })
    }
   
    
  }

  handleOpenAddNewGradeComponent=()=>{
    this.setState({
      openAddNewGradingComponentModal:true,
      isEditNewGradeComponentModal:false
    })
  }
  handleOpenRules=()=>{
    this.setState({
      openRuleModal:true,
      isEditRuleModal:false
    })
  }
  handleCloseAddNewGradeComponent=()=>{
    this.setState({
      openAddNewGradingComponentModal:false
    })
  }
  handleCloseRules=()=>{
    this.setState({
      openRuleModal:false
    })
  }

  handleOpenAddNewGradeTitle=()=>{
    this.setState({
      openAddNewGradeTitleModal:true,
      isEditNewGradeTitleModal:false
    })
  }
  handleEditNewGradingSystem=async(value:number)=>{
    await this.handleGetGradingSystemsDetailsByIdApi(value)
    this.setState({
      openAddNewGradeTitleModal:true,
      isEditNewGradeTitleModal:true,
    })
  }
  handleEditRule = async(value:number)=>{
    // await this.handleGetGradingSystemsDetailsByIdApi(value)
    this.setState({
      openRuleModal:true,
      isEditRuleModal:true,
    })
  }
  handleEditNewGradingComponent=async(value:number)=>{
    await this.handleGetGradingComponentDetailsByIdApi(value)
    this.setState({
      openAddNewGradingComponentModal:true,
      isEditNewGradeComponentModal:true,
    })
  }
  handleCloseAddNewGradeTitle=()=>{
    this.setState({
      openAddNewGradeTitleModal:false
    })
  }
  handleopenObservation=async(value:number)=>{
    await this.handleGetBehaviourDetailsByIdApi(value)
    this.setState({
      openObservation:true
    })
  }
  handleCloseObservation=()=>{
    this.setState({
      openObservation:false
    })
  }
  handleNavigateToNewBehaviour = ()=>{

    this.props.navigation.navigate("TenantAdminSetupAddNewBehaviour", {}, {
      title:"Touch Program (DepED)",
      gradebookId:this.props.gradeId
    })
  }

 
  handleSwitchTeacherCommentChange = (event: { target: { checked: any; }; }) => {
    this.setState({teacherComment:event.target.checked})
    const requestBody={
      teacher_comments:event.target.checked
    }
    this.handleUpdateGradeBookTenantApi(requestBody,this.props.gradeId)
  };
  handleSwitchReportingTypeChange =(event: { target: { checked: any; }; }) => {
    this.setState({reportingType:event.target.checked})
    const valueType = event.target.checked ? "time_based" : "progress_based" 
    const requestBody={
      reporting_type:valueType
    }
    this.handleUpdateGradeBookTenantApi(requestBody,this.props.gradeId)
  };


  handleGetGradingSystemsApi = async () => {
   
    const requestUrl = `${configJSON.gradingSystemListEndpoint}?grade_book_id=${this.state.gradeBookId}`;;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetGradingSystem = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetGradingSystemListResponse = async(responseJson: {
    data: Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        title: string;
        school_id: number;
        minimum: number;
        maximum: number;
        created_at: string;
        updated_at: string;
      };
    }>;
    errors: Array<{}>;})=>{

    if (responseJson?.data) {  
      const transformGradingSystemList =responseJson.data.map(item => ({
        id:item.attributes.id,
        titles: item.attributes.title,
        min: item.attributes.minimum,
        max: item.attributes.maximum,
      }));
    
      this.setState({ gradingSystemList: transformGradingSystemList });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }
  handleGetGradingSystemsDetailsByIdApi = async (gradeSystemId:number) => {
   
    const token = await getStorageData("token");
    const endpoint = `${configJSON.gradingSystemListEndpoint}/${gradeSystemId}?grade_book_id=${this.state.gradeBookId}`;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetGradingSystemDetailsById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetGradingSystemDetailsResponse = async(responseJson: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        title: string;
        school_id: number;
        minimum: number;
        maximum: number;
        created_at: string;
        updated_at: string;
      };
    };
    errors: Array<{}>;})=>{

    if (responseJson?.data) {  
      const responseObj = responseJson?.data
      const userGradingDetails={
        id:responseObj.attributes.id,
        title:responseObj.attributes.title,
        minimum:responseObj.attributes.minimum,
        maximum:responseObj.attributes.maximum
      }
      this.setState({ userGradingDetails });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }
  handleCreatePostGradeingSystemApi =async (requestBody:CreateRequestBody) =>{
    const requestUrl = configJSON.gradingSystemListEndpoint
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)   
      );
      this.createGradingApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handlePostGradingSystemResponse = async(responseJson: {
    data: Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        title: string;
        school_id: number;
        minimum: number;
        maximum: number;
        created_at: string;
        updated_at: string;
      };
    }>;
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
      await this.handleGetGradingSystemsApi()
      this.handleCloseAddNewGradeTitle()
      toast.success("Grading System created successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }
  handleDeletePostGradeingSystemApi  = async (gradeSystemId: number) => {
    const endpoint = `${configJSON.gradingSystemListEndpoint}/${gradeSystemId}?grade_book_id=${this.state.gradeBookId}`;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteGradingSystemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteGradingSystemResponse = async (responseJson: { success: boolean; errors?: Array<{}> }) => {
    hideCustomLoader();
    if (responseJson.success) {
      await this.handleGetGradingSystemsApi();
      toast.success("Grading System Deleted successfully!");
    } else {
      displayApiErrorMsg(responseJson.errors || [], this.props.navigation);
    }
  };
  handleOnConfirmGradingTitle = async (response:CreateRequestBody)=>{
    if(this.state.isEditNewGradeTitleModal){
      await this.handleUpdateGradeingSystemApi(response,this.state.userGradingDetails.id)
    }
    else{
      await this.handleCreatePostGradeingSystemApi(response)
    }
  }
  handleOnConfirmRules= async (response:any)=>{
    if(this.state.isEditRuleModal){
      await this.handleUpdateRuleApi(response,this.state.tableBodyDataRules[0].id)
    }
    else{
      await this.handleCreateRuleApi(response)
    }
  }

  handleUpdateGradeingSystemApi =async (requestBody:CreateRequestBody,gradeSystemId: number) =>{
    const endpoint = `${configJSON.gradingSystemListEndpoint}/${gradeSystemId}`;
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateGradingApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeUpdate
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleUpdateGradingSystemResponse = async(responseJson: {
    data: Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        title: string;
        school_id: number;
        minimum: number;
        maximum: number;
        created_at: string;
        updated_at: string;
      };
    }>;
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
      await this.handleGetGradingSystemsApi()
      this.setState({
        isEditNewGradeTitleModal:false,
        userGradingDetails:{
          id: 0,
          title: "",
          minimum: 0,
          maximum: 0
        }
      })
      this.handleCloseAddNewGradeTitle()
      toast.success("Grading System updated successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }

  handleGetGradingComponentsApi = async () => {

    const requestUrl = `${configJSON.gradingComponentListEndpoint}?grade_book_id=${this.state.gradeBookId}`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetGradingComponentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetGradingComponentsListResponse = async(responseJson: {
    data: Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        title: string;
        school_id: number,
        min_attempts: number,
        max_attempts: number;
        recommended: number;
        weightage: number;
        created_at: string;
        updated_at: string;
      };
    }>;
    errors: Array<{}>;})=>{

    if (responseJson?.data) {  
      const transformGradingSystemList =responseJson.data.map(item => ({
        id:item.attributes.id,
        titles: item.attributes.title.charAt(0).toUpperCase() + item.attributes.title.slice(1).toLowerCase(),
        weightage: item.attributes.weightage,
        minAttempts: item.attributes.min_attempts,
        maxAttempts: item.attributes.max_attempts,
        recommended: item.attributes.recommended,
      }));

      this.setState({ tableBodyDataWeightage: transformGradingSystemList });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }
  handleCreatePostGradeingComponentApi =async (requestBody:CreateGradingComponentRequestBody) =>{
    const requestUrl = configJSON.gradingComponentListEndpoint;
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createGradingComponentApiCallId= requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handlePostGradingComponentResponse = async(responseJson: {
    data: Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        title: string;
        school_id: number,
        min_attempts: number,
        max_attempts: number;
        recommended: number;
        weightage: number;
        created_at: string;
        updated_at: string;
      };
    }>;
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
      await this.handleGetGradingComponentsApi()
      this.handleCloseAddNewGradeComponent()
      toast.success("Grading Component created successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }
  handleDeletePostGradeingComponentApi  = async (gradeSystemId: number) => {
    const requestUrl = `${configJSON.gradingComponentListEndpoint}/${gradeSystemId}?grade_book_id=${this.state.gradeBookId}`;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteGradingComponentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteGradingComponentResponse = async (responseJson: { success: boolean; errors?: Array<{}> }) => {
    hideCustomLoader();
    if (responseJson.success) {
      await this.handleGetGradingComponentsApi();
      toast.success("Grading Component Deleted successfully!");
    } else {
      displayApiErrorMsg(responseJson.errors || [], this.props.navigation);
    }
  };
  handleOnConfirmGradingComponent= async(response:CreateGradingComponentRequestBody)=>{
    if(this.state.isEditNewGradeComponentModal){
      await this.handleUpdateGradeingComponentApi(response,this.state.userGradingComponentDetails.id)
    }
    else{
     await this.handleCreatePostGradeingComponentApi(response)
    }
  }
  handleGetGradingComponentDetailsByIdApi = async (gradeSystemId:number) => {
    const requestUrl = `${configJSON.gradingComponentListEndpoint}/${gradeSystemId}?grade_book_id=${this.state.gradeBookId}`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetGradingComponentDetailsById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetGradingComponentDetailsResponse = async(responseJson: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        title: string;
        school_id: number,
        min_attempts: number,
        max_attempts: number;
        recommended: number;
        weightage: number;
        created_at: string;
        updated_at: string;
      };
    };
    errors: Array<{}>;})=>{

    if (responseJson?.data) {  
      const responseObj = responseJson?.data
      const userGradingComponentDetails={
        id: responseObj.attributes.id,
        titles: responseObj.attributes.title,
        weightage: responseObj.attributes.weightage,
        minAttempts:responseObj.attributes.min_attempts,
        maxAttempts: responseObj.attributes.max_attempts,
        recommended:responseObj.attributes.recommended
      }
      this.setState({ userGradingComponentDetails });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }

  handleUpdateGradeingComponentApi =async (requestBody:CreateGradingComponentRequestBody,gradeSystemId: number) =>{
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateGradingComponentApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.gradingComponentListEndpoint + "/" + `${gradeSystemId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeUpdate
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleUpdateGradingComponentResponse = async(responseJson: {
    data: Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        title: string;
        school_id: number,
        min_attempts: number,
        max_attempts: number;
        recommended: number;
        weightage: number;
        created_at: string;
        updated_at: string;
      };
    }>;
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
      await this.handleGetGradingComponentsApi()
      this.setState({
        isEditNewGradeComponentModal:false,
        userGradingComponentDetails:{
          id: 0,
          titles: "",
          weightage: 0,
          minAttempts: 0,
          maxAttempts: 0,
          recommended: 0
        }
      })
      this.handleCloseAddNewGradeComponent()
      
    
      toast.success("Grading Component updated successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }
    

  
  handleGetBehavioursApi = async () => {
    const token = await getStorageData("token");
    const requestUrl = `${configJSON.behavioursListEndpoint}?grade_book_id=${this.state.gradeBookId}`;
    
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetBehavioursId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetBehavioursListsResponse = async(responseJson: {
    data: BehaviorDetails[];
    errors: Array<{}>;})=>{

    if (responseJson?.data) {  
      const transformGradingSystemList =responseJson.data.map(item => {
        
        const attributes = item.attributes;
        const id = attributes.id
        const titles = attributes.obeservation_title;
        const evalutionKeys = attributes.evaluation_keys.map(key => key.title).join(', ');
        const NumOfItems = attributes.skills.length;

        return {
            id,
            titles,
            evalutionKeys,
            NumOfItems
        };
      });

      this.setState({ tableBodyDataBehavior: transformGradingSystemList });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }

  handleDeleteBehaviourApi  = async (gradeSystemId: number) => {
    const endpoint = `${configJSON.behavioursListEndpoint}/${gradeSystemId}?grade_book_id=${this.state.gradeBookId}`;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteBehaviourId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteBehaviourResponse = async (responseJson: { success: boolean; errors?: Array<{}> }) => {
    hideCustomLoader();
    if (responseJson.success) {
      await this.handleGetBehavioursApi()
      toast.success("Behaviour Deleted successfully!");
    } else {
      displayApiErrorMsg(responseJson.errors || [], this.props.navigation);
    }
  };
  handleGetBehaviourDetailsByIdApi = async (gradeSystemId:number) => {
    const token = await getStorageData("token");
    const endpoint = `${configJSON.behavioursListEndpoint}/${gradeSystemId}?grade_book_id=${this.state.gradeBookId}`;
  
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetBehaviourDetailsById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetBehaviourDetailsResponse = async(responseJson: {
    data:BehaviorDetails
    errors: Array<{}>;})=>{ 

    if (responseJson?.data) {  
      const responseObj = responseJson?.data

      this.setState({ behaviourDetails:responseObj });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }
  handleUpdateBehaviourApi =async (requestBody:{
    grade_book_id: number;
    skills_attributes: {
        id: number;
        _destroy: boolean;
    }[]},gradeSystemId: number) =>{
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateBehaviourApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.behavioursListEndpoint + "/" + `${gradeSystemId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeUpdate
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleUpdateBehaviourResponse = async(responseJson: {
    data: BehaviorDetails,
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
        const responseObj = responseJson?.data

        this.setState({ behaviourDetails:responseObj });
      await this.handleGetBehavioursApi()

      toast.success("Behvaiour updated successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }
  handleDeleteBehaviourSkills = async(SkillId:number,behaviour_id:number)=>{
    const requestBody = {
      grade_book_id:this.state.gradeBookId,
      skills_attributes: [
          {
              id: SkillId,
              _destroy: true
          }
      ]
    }
    this.handleUpdateBehaviourApi(requestBody,behaviour_id)

  }
  handleEditBehaviour = (value:number)=>{
    this.props.navigation.navigate("TenantAdminSetupAddNewBehaviourById", {}, {
      title:"Touch Program (DepED)",
      gradebookId:this.props.gradeId,
      behaviourId:value
    })
  }
 
  handleGetRulesApi = async () => {
   
    const requestUrl = `${configJSON.rulesEndpoint}?grade_book_id=${this.state.gradeBookId}`;;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetRules = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetRulesListResponse = async(responseJson: {
    data: GradeBookRule 
    errors: Array<{}>;})=>{

    if (responseJson?.data && responseJson.data.attributes && responseJson.data.attributes.curriculums) {  

      const curriculums = responseJson.data.attributes.curriculums;
      const options = curriculums.map(c => c.name).join(',');
    
      const transformRules = [
        {
          id: responseJson.data.attributes.id,
          titles: "Curriculum",
          options
        }
      ]
      const curriculumIds = responseJson.data.attributes.curriculums.map(curriculum => curriculum.id.toString());
      const result = responseJson.data.attributes.curriculums.map(curriculum => ({
        id: curriculum.id.toString(),
        value: curriculum.id.toString(),
        label: curriculum.name 
    }));
      this.setState({ tableBodyDataRules: transformRules,selectedIds:curriculumIds,selectedCurriculum:result});
    }
    else if(Array.isArray(responseJson.data)){
      this.setState({ tableBodyDataRules: [],selectedIds:[],selectedCurriculum:[]});
    }
     else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }
  handleGetGradeBookTenantDetailsByIdApi = async (gradeSystemId:number) => {
   
    const token = await getStorageData("token");
    const endpoint = `${configJSON.gradeBookTenantEndpoint}/${gradeSystemId}`;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetGradeBookTenantDetailsById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetGradeBookTenantDetailsResponse = async(responseJson: {
    data: GradeBook
    errors: Array<{}>;})=>{

    if (responseJson?.data) {  
      const responseObj = responseJson?.data
      const valueObj=responseObj.attributes.reporting_type === "time_based" ? true :false
      const tenantDetails={
        id:responseObj.attributes.id,
        title:responseObj.attributes.title,
        teacher_comments:responseObj.attributes.teacher_comments,
        reporting_type:valueObj
      }
      this.setState({titleInput:responseObj.attributes.title || "", tenantDetails,teacherComment:responseObj.attributes.teacher_comments,reportingType:valueObj });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }
  handleCreateRuleApi =async (requestBody:CurriculumRequest) =>{
    const requestUrl = configJSON.rulesEndpoint
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)   
      );
      this.createRuleApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handlePostRuleResponse = async(responseJson: {
    data: GradeBookRule
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
        await this.handleGetRulesApi()
        this.handleGetGradebookTemplateTableDatApi()
         this.handleGetCurriculumApi()
        this.handleCloseRules()
     
      toast.success("Rule created successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }
  handleDeleteRuleApi  = async (gradeSystemId: number) => {
    const endpoint = `${configJSON.rulesEndpoint}/${gradeSystemId}?grade_book_id=${this.state.gradeBookId}`;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteRuleCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteRuleResponse = async (responseJson: { success: boolean; errors?: Array<{}> }) => {
    hideCustomLoader();
    if (responseJson.success) {
      await this.handleGetRulesApi()
      this.handleGetGradebookTemplateTableDatApi()
      toast.success("Rule Deleted successfully!");
    } else {
      displayApiErrorMsg(responseJson.errors || [], this.props.navigation);
    }
  };

  handleUpdateRuleApi =async (requestBody:any,gradeSystemId: number) =>{
    const endpoint = `${configJSON.rulesEndpoint}/${gradeSystemId}`;
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateRuleApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeUpdate
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleUpdateRuleResponse = async(responseJson: {
    data: GradeBookRule
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
      await this.handleGetRulesApi()
      this.handleGetGradebookTemplateTableDatApi()
      this.handleGetCurriculumApi()
      this.handleCloseRules()
      this.setState({
        isEditRuleModal:false,
      })
      toast.success("Grading System updated successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }

  handleGetCurriculumApi = async () => {
   
    const requestUrl = `${configJSON.curriculumEndpoint}?grade_book_id=${this.state.gradeBookId}`;;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetCurriculum = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetCurriculumListResponse = async(responseJson: {
    data: Curriculum[]
    errors: Array<{}>;})=>{

    if (responseJson?.data) {  
      const result = responseJson.data.map(curriculum => ({
        id: curriculum.id.toString(),
        value: curriculum.id.toString(),
        label: curriculum.attributes.name
    }));
  
    
      this.setState({ curriculumList: result });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }

  handleUpdateGradeBookTenantApi =async (requestBody:any,gradeSystemId:number) =>{
    const requestUrl = `${configJSON.gradeBookTenantEndpoint}/${gradeSystemId}`;
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateGradeBookTenantApiCallId= requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeUpdate
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleUpdateGradeBookTenantResponse = async(responseJson: {
    data:GradeBook;
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
        this.handleGetGradeBookTenantDetailsByIdApi(this.props.gradeId)
      toast.success("Grade book updated successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }


  handleDeleteTemplateApi  = async (templateId: number) => {
    const endpoint = `${configJSON.templateEndpoint}/${templateId}?grade_book_id=${this.state.gradeBookId}`;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTemplateCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteTemplateResponse = async (responseJson: { success: boolean; errors?: Array<{}> }) => {
    hideCustomLoader();
    if (responseJson.success) {
      this.handleGetGradebookTemplateApi()
      toast.success("Template Deleted successfully!");
    } else {
      displayApiErrorMsg(responseJson.errors || [], this.props.navigation);
    }
  };

  handleGetGradebookTemplateApi = async () => {
   
    const requestUrl = `${configJSON.templateEndpoint}?grade_book_id=${this.state.gradeBookId}`;;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetGradebookTemplateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getTemplateId = (data: any[]) => {
    return data.length > 0 ? parseInt(data[0].id) : -1;
  };
  handleGetGradebookTemplateResponse = async(responseJson: {
    data: Templates[];
    errors: Array<{}>;})=>{

    if (responseJson?.data) {  
      const backendData = responseJson.data[0];

      const updatedTemplateData = TemplateData.map(template => {
        const backendType = template.value === "official_template" ? backendData.official_templates : backendData.unofficial_templates;
        template.templateId= this.getTemplateId(backendType.data)
        return template;
      });
      this.setState({TemplateDataList:updatedTemplateData})
      
      if(backendData.official_templates.data.length>0){
       
        const resData = {
          templateType:backendData.official_templates.data[0].attributes.orientation_type === "portrait_template" ? "portrait" : "landscape",
          getDataOfTemplate:backendData.official_templates.data[0].attributes.template_content_types
        }
        this.setState({getDataOfficalTemplate:resData})
      }
      if(backendData.unofficial_templates.data.length>0){
        const resData = {
          templateType:backendData.unofficial_templates.data[0].attributes.orientation_type === "portrait_template" ? "portrait" : "landscape",
          getDataOfTemplate:backendData.unofficial_templates.data[0].attributes.template_content_types
        }
        this.setState({getDataUnofficalTemplate:resData})
      }
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
   
  }

  handleGetGradebookTemplateTableDatApi = async () => {
   
    const requestUrl = `${configJSON.gradebookTableEndpoint}?grade_book_id=${this.state.gradeBookId}`;;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTableDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getRandomAttendanceValue = () => Math.floor(Math.random() * 11);
  createGradeBookRow = (
    subjectData: any, 
    dynamicColumns: { id: string }[]
  ) => {
    return {
      id: subjectData.id,
      Name: {
        name: subjectData.name,
        icon: subjectData.icon,
        bgColor: subjectData.bgColor,
      },
      ...dynamicColumns.reduce((acc:{ [key: string]: number }, semester, index) => {
        const dynamicKey = semester.id 
        acc[dynamicKey] = this.getRandomAttendanceValue(); 
        return acc;
      }, {}),
      GPA: subjectData.gpa,  
      Grade: subjectData.grade,
    };
  };
  generateBehaviorData = (id: number, title: string, dynamicColumns: { id: string }[]) => {
    return {
      id,
      title,
      ...dynamicColumns.reduce((acc:{ [key: string]: number }, semester, index) => {
        const dynamicKey = semester.id 
        acc[dynamicKey] = this.getRandomAttendanceValue(); 
        return acc;
      }, {})
    };
  };
  handleGetGradebookTemplateTableDataResponse = async(responseJson: {
    data: SchoolYearResponse | [];
    errors: Array<{}>;})=>{

    if (responseJson?.data && !isArray(responseJson?.data)) {  
      const resData = responseJson.data.attributes.semesters
      let AttandanceTableHeadData = [...AttandanceTableHead];
      let gradebookListTableHeadData = [...gradebookListTableHead];
      let BehaviorsTableHeadData = [...BehaviorsTableHead];
      const dynamicColumns = resData.map((semester, index) => ({
        type: tableColumnTypes.TEXT,
        columnId: index + 2,
        id: semester.title,
        label: `${semester.title}`, 
        width: "100%",
        maxWidth: "100%",
      }));
      AttandanceTableHeadData.splice(1, 4, ...dynamicColumns);
      gradebookListTableHeadData.splice(1, 4, ...dynamicColumns)
      BehaviorsTableHeadData.splice(1,4,...dynamicColumns)
      const AttandanceTableData = [
        {
          id: 1,
          attendance: "No of School Days",
          ...dynamicColumns.reduce((acc:{ [key: string]: number }, semester, index) => {
            const dynamicKey = semester.id 
            acc[dynamicKey] = this.getRandomAttendanceValue(); 
            return acc;
          }, {}),
          total: dynamicColumns.reduce((total, semester) => total + this.getRandomAttendanceValue(), 0) // Calculate total
        }
      ]
      const physicsSubject = {
        id: 1,
        name: "Physics",
        icon: physicsSubjectIcon,
        bgColor: "#01C996",
        gpa: 7,
        grade: "A",

        
      };
      
      const totalGradeSubject = {
        id: 2,
        name: "Total Grade Point Average",
        gpa: 7,
        grade: "A",
      };
      const gradeBookTableData = [
        this.createGradeBookRow(physicsSubject, dynamicColumns),  
        this.createGradeBookRow(totalGradeSubject, dynamicColumns), 
      ];
      const behaviorTitles = [
        { id: 1, title: "Focused Attention" },
        { id: 2, title: "Focused Obedience" },
        { id: 3, title: "Truthfulness" }
      ];
      
      const BehaviorsTableData = behaviorTitles.map(({ id, title }) => this.generateBehaviorData(id, title,dynamicColumns));
      
      this.setState({
        gradebookListTableHead:gradebookListTableHeadData,
        AttandanceTableData:AttandanceTableData,
        AttandanceTableHead:AttandanceTableHeadData,
        gradebookTableData:gradeBookTableData,
        behaviourTableHead:BehaviorsTableHeadData,
        behaviourTableData:BehaviorsTableData
      })
    }else if(isArray(responseJson?.data)) {
      this.setState({
        gradebookListTableHead:gradebookListTableHead,
        AttandanceTableData:[],
        AttandanceTableHead:AttandanceTableHead,
        gradebookTableData:[],
        behaviourTableHead:BehaviorsTableHead,
        behaviourTableData:[]
      })
    }
    else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
   
  }
  // Customizable Area End
}
// Customizable Area End
