// Customizable Area Start
import React from "react";
import {
  Grid,
  Box,
  Typography,
  Hidden,
  Menu,
  MenuItem,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import WebHeaderController, { Props } from "./WebHeaderController.web";
import NotificationBellIcon from "./SVGIcons/NotificationBellIcon.web";
import ThemeNightIcon from "./SVGIcons/ThemeNightIcon.web";
import WbSunnyRoundedIcon from "@material-ui/icons/WbSunnyRounded";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
import CustomImageComponentWeb from "./CustomImageComponent.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    topNavbar: {
      padding: "10px",
      height: "85px",
      position: "fixed",
      top: "0px",
      right: 0,
      marginLeft: `calc(100% - 200px)`,
      zIndex: 2,
    },
    headerLogo: {
      padding: "0px 16px",
      height: "60px",
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        width: "100px",
      },
    },
    headerLogoGrid: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    headerProfilePicImg: {
      width: "inherit",
      height: "inherit",
      objectFit: "contain",
    },
    headerProfilePicImgDiv: {
      width: "70px",
      height: "70px",
      borderRadius: "50%",
    },
    headerProfileGrid: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    headerProfileGridItems: {
      marginLeft: "2%",
    },
    themeIconBox: {
      borderRadius: "50%",
      padding: "6px",
      cursor: "pointer",
    },
    notificationIconBox: {
      paddingLeft: "9px",
      paddingRight: "5px",
      paddingTop: "7px",
      paddingBottom: "5px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    hamburgerIcon: {
      cursor: "pointer",
      marginRight: "2%",
    },
    fontText20: {
      fontSize: "20px",
    },
    fontText16: {
      fontSize: "16px",
    },
    moreOptionArrow: {
      marginLeft: "5px",
      cursor: "pointer",
    },
    roleCapital: {
      textTransform: "capitalize",
    },
  });

// Customizable Area End

export class WebHeader extends WebHeaderController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    const { classes, isDarkMode } = this.props;
    const userFullName = JSON.parse(
      JSON.stringify(localStorage.getItem("user_name") || "User Name")
    );
    const userProfileUrl = JSON.parse(
      JSON.stringify(localStorage.getItem("user_profile_img") || "")
    );
    const { menuAnchorEl,logoUrl } = this.state;
    return (
      <Grid
        className={`${classes.topNavbar} ${classes.highlightBgColor} ${
          classes.bgColor
        }`}
        container
        direction="row"
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid className={classes.headerLogoGrid} item xs={4}>
              <img
                src={logoUrl ? logoUrl : require("./YUDU-logo.png")}
                alt="logo"
                className={classes.headerLogo}
              />
            </Grid>
            <Grid className={classes.headerProfileGrid} item xs={8}>
              <Box
                bgcolor={isDarkMode ? "#888888" : "#f1f1f1"}
                onClick={this.props.handleToggleThemeSettings}
                className={`${classes.themeIconBox} ${
                  classes.headerProfileGridItems
                }`}
              >
                {isDarkMode ? (
                  <WbSunnyRoundedIcon
                    stroke={isDarkMode ? "#000" : "#888888"}
                    fill={isDarkMode ? "#000" : "#888888"}
                  />
                ) : (
                  <ThemeNightIcon
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    stroke={isDarkMode ? "#000" : "#888888"}
                    strokeWidth="1.0"
                    fill={isDarkMode ? "#000" : "#888888"}
                  />
                )}
              </Box>
              <Box
                bgcolor={isDarkMode ? "#888888" : "#f1f1f1"}
                className={`${classes.notificationIconBox} ${
                  classes.headerProfileGridItems
                }`}
              >
                <NotificationBellIcon
                  width="22"
                  height="26"
                  viewBox="0 0 28 34"
                  stroke={isDarkMode ? "#000" : "#888888"}
                  strokeWidth="1.0"
                  fill={isDarkMode ? "#000" : "#888888"}
                />
              </Box>
              <Box ml={"2%"} pr={"3%"} display={"flex"} alignItems="center">
                <Hidden only={"xs"}>
                  <Box
                    className={`${classes.headerProfilePicImgDiv} ${
                      classes.headerProfileGridItems
                    }`}
                  >
                    {userProfileUrl ? (
                      <CustomImageComponentWeb
                        src={userProfileUrl}
                        altText="Profile Image"
                        imgStyles={{
                          width: "inherit",
                          height: "inherit",
                          objectFit: "cover",
                          borderRadius: "50%"
                          
                        }}
                        isUserProfileImg
                      />
                    ) : (
                      <AccountCircleIcon
                        style={{
                          color: isDarkMode ? "#888888" : "#ccc",
                        }}
                        className={`${classes.headerProfilePicImg}`}
                      />
                    )}
                  </Box>
                </Hidden>
                <Box
                  ml={"4%"}
                  display={"flex"}
                  justifyContent="center"
                  whiteSpace={"nowrap"}
                  flexDirection={"column"}
                >
                  <Typography
                    component={"span"}
                    className={`${classes.fontText20} ${
                      classes.textPrimaryColor
                    }`}
                  >
                    {userFullName}
                  </Typography>
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${
                        classes.textCommonColor
                      } ${classes.roleCapital}`}
                    >
                      {this.state.profileRole}
                    </Typography>
                    <ArrowDropDownCircleOutlinedIcon
                      className={`${classes.textCommonColor} ${
                        classes.moreOptionArrow
                      }`}
                      onClick={this.handleActionClick}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Menu
          id={`header-menu-action`}
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleMenuClose}
        >
          <MenuItem onClick={this.handleGotoProfile}>Profile</MenuItem>
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(WebHeader);
// Customizable Area End
