// Customizable Area Start
import React from "react";
import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import StudentPerformanceAssessmentController, {
  OptionType,
  Props,
} from "./StudentPerformanceAssessmentController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import TabPanel from "../../../components/src/TabPanel.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import SearchIcon from "../../../components/src/SVGIcons/SearchIcon.web";
import AssessmentsAll from "./AssessmentsAll.web";
import CustomButton from "../../../components/src/CustomButton.web";
import AssessmentAssigned from "./AssessmentAssigned.web";
import AssessmentGraded from "./AssessmentGraded.web";
import AddNewAssessmentModal from "./AddNewAssessmentModal.web";
import Select from 'react-select'
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    assesmentBreadcrumb: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    newAssessmentBtn: {
      width: "218px",
      height: "50px",
      borderRadius: "12px",
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      textTransform: "capitalize",
      marginRight: "30px",
      "&:hover": {
        backgroundColor: "#0D46BC",
      },
    },
    boxRoot: {
      marginTop: "30px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    assessmentAppBar: {
      borderRadius: "12px 12px 0 0",
      backgroundColor: "#ffffff",
      borderBottom: "1px solid #F1F1F1",
    },
    profileActiveTab: {
      color: "#0D46BC",
      fontWeight: 500,
      fontSize: 20,
      textTransform: "capitalize",
    },
    tabLabel: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "23px",
      color: "#090909",
      textTransform: "capitalize",
      padding: theme.spacing(2.5, 0),
    },
    profileNameBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    dividerWidth: {
      // margin: `5px 0 20px 0px`,
      height: "5px",
    },
    tabButton: {
      backgroundColor: "transparent",
      padding: "10px 4px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      display: "inline-block",
      fontSize: "16px",
      opacity: 1,
      minWidth: "25%",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        color: "#0D46BC !important",
        outline: "none",
        borderBottom: "1px solid #0D46BC",
        fontWeight: 600,
      },
      "&.MuiTab-root": {
        minHeight: "60px",
      },
    },
  });

const BtnTheme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: "0px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class StudentPerformanceAssessment extends StudentPerformanceAssessmentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      tabValue,
      currentRole,
      selectedClassComponentDDValue,
      openAddNewAssessment,
      editActivity,
      editActivityId,
      selectedLesson,
      selectedTopic,
      courseData,
      searchStudent,
      selectedStudentComponentDDValue
    } = this.state;
   
    const customStyles = this.getCustomeStyle();   
    return (
      <>
        <Box
          className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor
            } ${classes.secondaryBgColor}`}
          height={"100%"}
        >
          <Box className={classes.assesmentBreadcrumb}>
            <Typography
              className={`${classes.textCommonColor} ${classes.fontText14}`}
              component={"span"}
            >
              {`Assessment`}{" "}
            </Typography>
            <Typography
              className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText12
                }`}
              component={"span"}
            >
              {`/ ${this.state.tabName}`}
            </Typography>
          </Box>
          <Box className={classes.profileNameBox}>
            <Box>
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  style={{ fontWeight: 700 }}
                  className={`${classes.textPrimaryColor} ${classes.fontText34}`}
                  component={"span"}
                >
                  {`Assessment`}
                </Typography>
              </Box>
            </Box> 
            <Box>
              {((currentRole === "registrar" && tabValue === 2) ||
                currentRole === "teacher" || currentRole === "parent teacher") && (
                  <CustomButton
                    btnText="Add New Assessment"
                    btnStyle={addNewSchoolBtn}
                    buttonId={`my-assessment-search-mobile`}
                    buttonVariant={"contained"}
                    type={"button"}
                    handleButtonClick={this.handleOpenAddNewAssessmentModal}
                    isDisabled={false}
                  />
                )}
            </Box>
          </Box>
          <Box display={"flex"} mt={"30px"}>
            {(currentRole === "teacher") && <Box display={"flex"} flexDirection={"column"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText14}`}
                  component={"span"}
                  gutterBottom
                >
                  {`Class`}
                </Typography>
                <CustomSelectDropdown
                  fieldId="select-dropdown-assessment-class"
                  fieldName="selectedClassComponentDDValue"
                  handleChange={this.handleClassDropdownValues}
                  fieldValue={selectedClassComponentDDValue}
                  fieldError={false}
                  renderValue={this.renderClassComponenttDDValues}
                  fieldMenuItems={this.state.gradingComponent}
                  fieldStyles={dropdownStudent}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>}

              {(currentRole === "parent teacher") && <Box display={"flex"} flexDirection={"column"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText14}`}
                  component={"span"}
                  gutterBottom
                >
                  {`Student`}
                </Typography>
                <CustomSelectDropdown
                  fieldId="select-dropdown-assessment-student"
                  fieldName="selectedStudentComponentDDValue"
                  handleChange={this.handleStudentDropdownValues}
                  fieldValue={selectedStudentComponentDDValue}
                  fieldError={false}
                  renderValue={this.renderStudentComponenttDDValues}
                  fieldMenuItems={this.state.studentDropdownData}
                  fieldStyles={dropdownStudent}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>}
           {(currentRole !== "parent teacher") && <Box width={"19.5%"} display={"flex"} flexDirection={"column"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText14}`}
                component={"span"}
                gutterBottom
                style={{ marginLeft: currentRole === "teacher" ? "24px": "0px" }}
              >
                {`Student`}
              </Typography>
              <Box>
                  <Box style={{margin:"0 10px"}}>
                    <Select
                      value={this.state.selectedCategory}
                      data-test-id="selectedCategory"
                      onChange={this.handleChangeSearch}
                      options={this.state.studentSearchDrop}
                      onInputChange={this.handleInputChange}
                      isSearchable={true}
                      fieldStyles={dropdownStudent}
                      textFieldStyles={{
                        marginLeft: currentRole === "teacher" ? "24px" : "0px"
                      }}
                      placeholder={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <SearchIcon
                            style={{ marginRight: "8px", color: "gray", fontSize: "20px" }}
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            stroke={"#888888"}
                            strokeWidth="0.45"
                            fill={"#888888"}
                          />
                          <span style={{ color: "gray", fontSize: "15px" }}>Search by Student</span>
                        </div>
                      }
                      styles={customStyles}
                    />
                </Box>
              </Box>
            </Box>
            }
          </Box>
          <Box mt={3} style={{ width: "98%" }}>
            <Box className={classes.boxRoot}>
              <AppBar
                className={`${classes.assessmentAppBar} ${classes.bgColor}`}
                position="static"
                color="default"
              >
                <Tabs
                  id="tab-change-wrapper"
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#0D46BC",
                    },
                  }}
                  classes={{ indicator: classes.profileActiveTab }}
                  value={tabValue}
                  onChange={this.handleTabChange}
                >
                  <Tab
                    id="All"
                    classes={{
                      root:
                        tabValue === 0
                          ? classes.profileActiveTab
                          : classes.tabLabel,
                    }}
                    className={`${classes.textPrimaryColor} ${classes.tabButton}`}
                    label={"All"}
                  />
                  <Tab
                    id="Assigned"
                    classes={{
                      root:
                        tabValue === 1
                          ? classes.profileActiveTab
                          : classes.tabLabel,
                    }}
                    className={`${classes.textPrimaryColor} ${classes.tabButton}`}
                    label={"Assigned"}
                  />
                  <Tab
                    id="Graded"
                    classes={{
                      root:
                        tabValue === 2
                          ? classes.profileActiveTab
                          : classes.tabLabel,
                    }}
                    className={`${classes.textPrimaryColor} ${classes.tabButton}`}
                    label={"Graded"}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={0}>
                <AssessmentsAll studentIds={this.state.studentId} currentRole={currentRole} searchByStudent={searchStudent} classId={this.state.filteredClassId} gradingComponentsdata={this.state.gradingComponent}/>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <AssessmentAssigned currentRole={currentRole} searchByStudent={searchStudent} 
                navigation={this.props.navigation} classId={this.state.filteredClassId}  studentIds={this.state.studentId} getStudentData={this.state.getSearchStudentData} searchstu={this.state.search}/>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <AssessmentGraded currentRole={currentRole} searchByStudent={searchStudent} classId={this.state.filteredClassId}  studentIds={this.state.studentId} searchstu={this.state.search} getStudentData={this.state.getGradeStudentData}  handleInputStudentChange={this.handleChangeSearch}/>
              </TabPanel>
            </Box>
          </Box>
        </Box>
        {openAddNewAssessment && (
          <AddNewAssessmentModal
            open={openAddNewAssessment}
            onCancel={this.handleCloseAddNewAssessmentModal}
            onConfirm={this.handleCloseAddNewAssessmentModal}
            isEdit={editActivity}
            activityId={editActivityId}
            lesson={selectedLesson}
            topic={selectedTopic}
            course={courseData}
            handleAddNewActivity={(data: any) =>
              this.handleAddNewAssessmentEvent(data)
            }
            handleUpdateActivity={() => { }}
          />
        )}
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addNewSchoolBtn = {
  width: "241px",
  textAlign: "center",
  height: "50px",
  marginRight: "25px",
  fontSize: "18px",
  borderRadius: "12px",
  padding: "10px 10px",
  fontWeight: 500,
} as const;
const dropdownStudent = {
  width: "218px",
  borderRadius: "8px",
  height: "50px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentPerformanceAssessment);
// Customizable Area End
