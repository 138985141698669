// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ViewUserProfileDetailsController, {
  Props,
} from "./ViewUserProfileDetailsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    userInfoBox: {
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    mainPageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    profileInfoGrid: {
      padding: "15px 18px 15px 18px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    textCapital: {
      textTransform: "capitalize",
    },
    userName: {
      padding: "15px 18px 5px 18px",
    },
    leftMargin: {
      marginLeft: "8px",
    },
    detailGridItem: {
      padding: "8px 0px 8px 0px",
    },
    logoBg: {
      width: "55px",
      borderRadius: "50%",
      display: "flex",
      height: "55px",
      justifyContent: "center",
      alignItems: "center",
      padding: "2px",
    },
    profilePicImg: {
      width: "55px",
      height: "55px",
      objectFit: "contain",
    },
    curriculumCard: {
      padding: "15px 22px",
      borderRadius: "10px",
      width: "84%",
      marginBottom: "15px",
      [theme.breakpoints.down("xs")]: {
        width: "85% !important",
      },
      [theme.breakpoints.down("sm")]: {
        width: "75%",
      },
    },
    overflowText: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  });
// Customizable Area End

// Customizable Area Start
export class ViewUserProfileDetails extends ViewUserProfileDetailsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { userFullName, userRole, userDetails, curriculumList, wardsList } =
      this.state;

    return (
      <Box
        id="view-user-profile-main-box"
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid id="view-user-profile-main-container" direction="row" container>
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                User Management
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textCommonColor} ${classes.textCapital}`}
              >
                {` / ${userRole}`}
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5} ${classes.textCapital}`}
              >
                {` / ${userFullName}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.mainPageName} item xs={12}>
            <Box>
              <Typography
                id="view-user-profile-username"
                className={`${classes.textCapital} ${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
                component={"span"}
              >
                {userFullName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              id="view-user-profile-userInfoBox"
              className={`${classes.bgColor} ${classes.userInfoBox}`}
            >
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Box
                        className={`${classes.userName}`}
                        alignItems={"center"}
                        display={"flex"}
                      >
                        <Box className={`${classes.logoBg}`}>
                          <img
                            alt="teacher-profile-pic"
                            src={userDetails?.profilePic}
                            className={`${classes.profilePicImg}`}
                          />
                        </Box>
                        <Typography
                          component={"span"}
                          id="view-user-profile-userFullName"
                          className={`${classes.textCapital} ${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText24}`}
                        >
                          {userFullName}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        className={`${classes.profileInfoGrid}`}
                        container
                        direction="row"
                      >
                        <Grid
                          item
                          id="view-user-profile-emailid"
                          className={`${classes.detailGridItem}`}
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box
                            className={`${classes.overflowText}`}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Typography
                              component={"span"}
                              id="view-user-profile-email"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Email ID:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              {userDetails?.emailId}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-user-profile-role-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-user-profile-role"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Role:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userRole}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-user-profile-status-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-user-profile-status"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Status:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.textCapital} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userDetails?.status}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-user-profile-customField1-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-user-profile-customField1"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Custom Field 1:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userDetails?.customField1}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-user-profile-customField2-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-user-profile-customField2"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Custom Field 2:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.textCapital} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userDetails?.customField2}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-user-profile-customField3-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-user-profile-customField3"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Custom Field 3:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.textCapital} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userDetails?.customField3}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* Students Assigned */}
          {userRole === "parent" && (
            <Grid item xs={12}>
              <Grid container direction={"row"}>
                <Grid item xs={12}>
                  <Box paddingTop={"20px"} paddingBottom={"10px"}>
                    <Typography
                      className={`${classes.fontBold600} ${classes.fontText28} ${classes.textPrimaryColor}`}
                    >
                      Students
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {wardsList?.map((student: any) => (
                      <Grid
                        key={`${student?.fullName}-${student?.studentId}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                      >
                        <Box
                          className={`${classes.curriculumCard} ${classes.bgColor}`}
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box mr={"10px"} className={`${classes.logoBg}`}>
                            <img
                              src={student?.profilePic}
                              className={classes.profilePicImg}
                              alt="student-profile-pic"
                            />
                          </Box>
                          <Box>
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText20}`}
                              component="span"
                            >
                              {student.fullName}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* Curriculum */}
          {userRole !== "course manager" && (
            <Grid item xs={12}>
              <Grid container direction={"row"}>
                <Grid item xs={12}>
                  <Box paddingTop={"35px"} paddingBottom={"10px"}>
                    <Typography
                      className={`${classes.fontBold600} ${classes.textPrimaryColor} ${classes.fontText28}`}
                      component={"span"}
                    >
                      Curriculum
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row">
                    {curriculumList?.map((curriculum: any) => (
                      <Grid
                        key={`${curriculum.name}-${curriculum?.id}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                      >
                        <Box
                          className={`${classes.curriculumCard} ${classes.bgColor}`}
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box>
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}
                              component="span"
                            >
                              {curriculum.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ViewUserProfileDetails);
// Customizable Area End
