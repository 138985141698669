import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";

// Customizable Area Start
import { studentCoursesList, gradingComponent, studentListTableBody, studentListTableColumns } from "../../assets/mockdata";

import { getStorageData } from "../../../../framework/src/Utilities";

// Customizable Area End
// Customizable Area Start
const configJSON = require("./../config.js");
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // coursesData:any[];
  selectedCourseId: any;
  studentCoursesList: any;
  selectedSubjectTopicList: any;
  selectedCourse: any;
  gradingComponent: Array<any>;
  selectedGradingComponentDDValue: any;
  studentListTableBody: Array<any>;
  searchStudent: string;
  hoveredCourse: any;
  studentListTableColumns: Array<any>;
  // Customizable Area End
}

export default class StudentPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  apiGetTeachersAssignedClasses: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {      
      searchStudent: '',
      hoveredCourse: null,
      selectedCourseId: null,
      selectedCourse: null,
      selectedSubjectTopicList: [],
      selectedGradingComponentDDValue: '',      
      studentCoursesList: studentCoursesList,      
      gradingComponent: gradingComponent,
      studentListTableBody: studentListTableBody,
      studentListTableColumns: studentListTableColumns,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    this.handleGetStudentsClassesListApi();
  }
 

  handleGetStudentsClassesListApi = async () => {    
    const requestUrl = configJSON.studentDashboardClassesEndpoint;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeachersAssignedClasses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentDashboardClassesMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
  }
 
    handleApiResponse(apiRequestCallId: string | number, responseJson: any) {
      if (apiRequestCallId === this.apiGetTeachersAssignedClasses) {
        this.setState({studentCoursesList:responseJson.data})      
      }
    }
    
    getDataFromPath(data: any, path: string) {
      return path.split('.').reduce((acc, part) => acc && acc[part], data);
    }

  

  handleStudentCourse = (courseId: any) => {
    const { studentCoursesList } = this.state;
    const selectedCourse: any = studentCoursesList.find((course: any) => course?.id === courseId);
    this.setState({ selectedCourseId: courseId, selectedCourse })
  }

  renderGradingComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "All";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponents = gradingComponent.find((grade: any) => grade?.id === selected);
    return selectedGradingComponents?.label;
  };

  handleChangeDropdownValues = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
  }>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };

  handleclick = async (e: any) => {
    let nameId = this.state.studentListTableBody.find((item: any, index: any) => {
      return e.name === item?.Name.name
    })
    let name = nameId?.Name.name;
    // console.log(name,'nameeee')
    if (nameId !== undefined) {
      this.props.navigation.navigate('StudentProfileProgress', { name })
    }
  }

  handleChange = (event: any) => {
    this.setState({
      searchStudent: event.target.value
    })
  };

  handleMouseLeave = () => {
    this.setState({ hoveredCourse: null });
  };

  handleMouseEnter = (courseId: any) => {
    this.setState({ hoveredCourse: courseId });
  };
  // Customizable Area End
}