// Customizable Area Start
import React from "react";
import { Box, Menu, MenuItem, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TenantAdminGradeBookController, { Props } from "./TenantAdminGradeBookController";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { ArrowBack, MoreVert, RemoveRedEye ,Edit,Save, Cancel} from '@material-ui/icons';
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import GradeBookCommonTableWeb from "../../../components/src/gradebook/GradeBookCommonTable.web";
import { Rectangle } from "./assets";
import GradeBookCommonHeader from "../../../components/src/gradebook/GradeBookCommonHeader.web";
import NewGradingComponentPop from "./NewGradingComponentPop.web";
import NewGradeTitlePop from "./NewGradeTitlePop.web";
import Observation from "./Observation.web";
import AddGradeBookRulesPopWeb from "../../../components/src/gradebook/AddGradeBookRulesPop.web";
import  CustomTextField  from "../../../components/src/CustomTextField.web";
import TemplatePreviewDialogWeb from "../../../components/src/gradebook/TemplatePreviewDialog.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        root: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
        paginationBox: {
            display: "flex",
            alignItems: "center",
            padding: "10px 20px 10px 21px",
            justifyContent: "space-between",
        },
        headingText: {
            marginBottom: "9px",
            fontWeight: 600,
        },
        fieldBox: {
            borderRadius: "12px",
            margin: theme.spacing(2.5, 0),
            marginBottom: "20px",
        },
        textPaddingBox: {
            padding: "25px"
        },
        dividerLine: {
            height: "1px",
            background: "#F1F1F1",
           
        },
    });
const tableBodyHeadGradingSystem = [
    {
        type: tableColumnTypes.TEXT,
        columnId: 1,
        id: "titles",
        label: "Title",
        width: "500px",
        maxWidth: "550px",
    },
    {
        columnId: 2,
        id: "min",
        type: tableColumnTypes.NUMBER,
        label: "Min",
        width: "80px",
        maxWidth: "100px",
    },
    {
        columnId: 3,
        id: "max",
        type: tableColumnTypes.NUMBER,
        label: "Max",
        width: "80px",
        maxWidth: "100px",
    },
];


const tableBodyHeadBehavior = [
    {
        type: tableColumnTypes.TEXT,
        columnId: 1,
        id: "titles",
        label: "Title",
        width: "450px",
        maxWidth: "500px",
    },
    {
        columnId: 2,
        id: "evalutionKeys",
        type: tableColumnTypes.TEXT,
        label: "Evalution Key",
        width: "150px",
        maxWidth: "200px",
    },
    {
        columnId: 3,
        id: "NumOfItems",
        type: tableColumnTypes.NUMBER,
        label: "No. of Items",
        width: "80px",
        maxWidth: "100px",
    },
];



const tableBodyHeadWeightage = [
    {
        type: tableColumnTypes.TEXT,
        columnId: 1,
        id: "titles",
        label: "Title",
        width: "200px",
        maxWidth: "220px",
    },
    {
        columnId: 2,
        id: "minAttempts",
        type: tableColumnTypes.TEXT,
        label: "Min Attempts",
        width: "120px",
        maxWidth: "120px",
    },
    {
        columnId: 3,
        id: "maxAttempts",
        type: tableColumnTypes.NUMBER,
        label: "Max Attempts",
        width: "100px",
        maxWidth: "120px",
    },
    {
        columnId: 4,
        id: "recommended",
        type: tableColumnTypes.NUMBER,
        label: "Recommended",
        width: "100px",
        maxWidth: "120px",
    },
    {
        columnId: 5,
        id: "weightage",
        type: tableColumnTypes.GRADEBOOK_AVG,
        label: "Weightage",
        width: "100px",
        maxWidth: "120px",
    },
];


const tableBodyHeadRules = [
    {
        type: tableColumnTypes.TEXT,
        columnId: 1,
        id: "titles",
        label: "Title",
        width: "200px",
        maxWidth: "220px",
    },
    {
        columnId: 2,
        id: "options",
        type: tableColumnTypes.TEXT,
        label: "Selected Option",
        width: "120px",
        maxWidth: "120px",
    },

];



const sectionStyle = {
    padding: "20px",
    marginTop: "20px",
    borderRadius: "8px",
} as React.CSSProperties;



const templateCardStyle = {
    width: "238px",
    justifyContent: "right",
    fontSize: "16px",
    height: "238px",
    borderRadius: "8px",
    backgroundColor: "#e0e0e0",
    display: "flex",
} as React.CSSProperties;

// Customizable Area End

// Customizable Area Start
export class TenantAdminGradeBook extends TenantAdminGradeBookController {
    // Customizable Area Start

    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { isPreviewOpen,TemplateDataList ,menuAnchorEl,tenantDetails,selectedCurriculum,selectedIds,isEditRuleModal,curriculumList,openRuleModal,gradesTableActionButtonConfigRules,tableBodyDataRules,behaviourDetails ,gradesTableActionButtonConfigBehavior,tableBodyDataBehavior,tableBodyDataWeightage,isEditNewGradeComponentModal,userGradingComponentDetails, gradesTableActionButtonConfigWeightage,openObservation,userGradingDetails,editNewGradeId,isEditNewGradeTitleModal,gradesTableActionButtonConfig, openAddNewGradeTitleModal, openAddNewGradingComponentModal ,gradingSystemList} = this.state
        const { classes } = this.props;
      
        return (
            <>
                <Box className={`${classes.fieldBox}   ${classes.highlightBgColor
                    } ${classes.secondaryBgColor} !important`}>
                    <div style={sectionStyle} className={classes.bgColor}>
                    <Box display={"flex"} alignItems="center"  style={{marginBottom: "50px",gap:10 }}>
                  <Box
                    className={classes.backArrowBox}
                    onClick={this.props.handleClose}
                    style={{cursor: "pointer" }}
                    id="back-button"
                   
                  >
                    <ArrowBack
                      fontSize="large"
                      className={classes.textPrimaryColor}
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    {this.state.isEditingTitle ? (
                    <>
                     <Box style={{height:"40px"}}>
                        <CustomTextField
                        fieldName="gradebookTitle"
                        placeHolderText="Enter gradebook Title"
                        fieldId="grade-book-title"
                        fieldValue={this.state.titleInput}
                        fieldWidth={"250px"}
                        data-test-id="gradebook-title"
                        fieldType="text"
                        fieldError={this.state.isTitleError}
                        helperText={this.state.titleError}
                        handleChange={this.handleTitleChange}
                        />
                        </Box>
                        <Box display="flex" alignItems="center"  style={{gap:"3px"}} marginLeft="8px">
                        <Save
                            style={{
                            backgroundColor: "#0BB295",
                            color: "white",
                            padding: "8px", 
                            borderRadius: "20%",
                            cursor: "pointer", 
                            }}
                            data-testId="toggle-save-title"
                            onClick={this.saveTitle}
                        />
                        <Cancel
                            style={{
                            backgroundColor: "#BB0D0C",
                            color: "white",
                            padding: "8px",
                            borderRadius: "20%",
                            cursor: "pointer", 
                            }}
                            data-testId="toggle-cancel-title"
                            onClick={this.cancelEditTitle}
                        />
                        </Box>
                    </>
                    ) : (
                    <>
                        <Typography
                        className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                        component="span"
                        >
                        {tenantDetails.title}
                        </Typography>
                        <Box marginLeft="8px">
                        <Edit
                            style={{
                            backgroundColor: "#009DFF",
                            color: "white",
                            padding: "8px", 
                            borderRadius: "20%",
                            cursor: "pointer", 
                            }}
                            data-testId="toggle-edit-title"
                            onClick={this.toggleEditTitle}
                        />
                        </Box>
                    </>
                    )}
                 </Box>
                </Box>
                        <div style={{ display: 'flex', gap: "40px" }}>
                            {TemplateDataList.map((dataObj) => (
                                <div 
                                key={dataObj.id}
                                style={{
                                    width: "238px",
                                    height: "250px", display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography className={`${classes.textCommonColor}`}>{dataObj.title}</Typography>
                                    <div style={templateCardStyle}>

                                        <div>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    height: "100%",
                                                    width: "100%",
                                                }}
                                            >
                                                <img
                                                    src={Rectangle}
                                                    alt="Description"
                                                    style={{ height: "100%", width: "100%" }}
                                                />
                                                <MoreVert
                                                    style={{
                                                        position: "absolute",
                                                        right: "10",
                                                        top: "10px",
                                                        backgroundColor: "#009DFF",
                                                        color: "white",
                                                        padding: "2%",
                                                        borderRadius: "20%",
                                                    }}
                                                    onClick={(e: any) => this.handleActionClick(e, dataObj.id)}
                                                    data-testId="action-button"
                                                />
                                                <RemoveRedEye 
                                                    style={{
                                                        position: "absolute",
                                                        right: "10",
                                                        top: "50px",
                                                        padding: "2%",
                                                        borderRadius: "20%",
                                                        backgroundColor: "#009DFF",
                                                        color: "white",
                                                        
                                                    }}
                                                    data-testId="preview-button"
                                                    onClick={()=>this.handleOpenPreview(dataObj.id)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Menu
                                        anchorEl={menuAnchorEl}
                                        id={`gradebook-table-menu-action`}
                                        onClose={this.handleMenuClose}
                                        open={Boolean(menuAnchorEl)}
                                    >
                                        <MenuItem data-test-id="edit-template" onClick={this.handleEditTemplate} >
                                         Edit
                                        </MenuItem>
                                        <MenuItem data-test-id="delete-template" disabled={this.isDisabledDeleteButton()} onClick={this.handleDeleteTemplate}>
                                        Delete
                                        </MenuItem>
                                    </Menu>
                                </div>
                            ))}

                        </div>
                    </div>

                    <GradeBookCommonTableWeb
                        title={"Grading System"}
                        tableColumnHead={tableBodyHeadGradingSystem}
                        tableBodyData={gradingSystemList}
                        showMenuIcon={true}
                        addButtonClick={this.handleOpenAddNewGradeTitle}
                        actionButtonConfig={gradesTableActionButtonConfig}
                        switchChecked={true}
                        id={"add-title"}
                        addButton={true}
                        emptyTableText={"You don’t have any Grading System added.<br/> Click on add icon on top right corner of the block to add Grading System"}
                    />
                    <GradeBookCommonTableWeb
                        title={"Behavior"}
                        tableColumnHead={tableBodyHeadBehavior}
                        tableBodyData={tableBodyDataBehavior}
                        showMenuIcon={true}
                        id="navigate-behaviour"
                        addButtonClick={this.handleNavigateToNewBehaviour}
                        actionButtonConfig={gradesTableActionButtonConfigBehavior}
                        addButton={true}

                        emptyTableText={"You don’t have any Grading System added.<br/> Click on add icon on top right corner of the block to add Grading System"}
                    />
                    <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
                        <GradeBookCommonHeader id="teacher-comments"  title="Teacher Comments" switchChecked={true} checked={this.state.teacherComment} handleSwitchChange={this.handleSwitchTeacherCommentChange} leftText="Off" rightText="On" />
                        <Typography
                            className={`${classes.textCommonColor} ${classes.fontText15} ${classes.textCenter} ${classes.textPaddingBox}`}>
                            Progress based reporting is based on the progression of a student through the course content and activities completed. Completion and progression of a course is not based on schedules or chronological events on platform but rather the actual progress of a student through a specific courses
                        </Typography>
                    </Box>
                    <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
                        <GradeBookCommonHeader title="Reporting Type" id="reporting-type" switchChecked={true} checked={this.state.reportingType} handleSwitchChange={this.handleSwitchReportingTypeChange} leftText="Progress Based" rightText="Time Based" />

                       <Box className={` ${classes.textPaddingBox} `}>
                            <Typography className={`${classes.textPrimaryColor}  ${classes.headingText}`}>Progress Based Reporting (Flexible)</Typography>
                            <Typography
                                className={`${classes.textCommonColor} ${classes.fontText15} ${classes.textCenter} `}>
                                Progress based reporting is based on the progression of a student through the course content and activities completed. Completion and progression of a course is not based on schedules or chronological events on platform but rather the actual progress of a student through a specific courses
                            </Typography>
                     </Box>
                     <Box>
                        <Box style={{ paddingLeft: "25px",paddingRight:"25px"}}>
                        <Box className={classes.dividerLine} />
                     </Box>
                        </Box>
                    
                       <Box className={` ${classes.textPaddingBox}`}>
                            <Typography className={`${classes.textPrimaryColor} ${classes.headingText}`}>Time Based Reporting (Traditional)</Typography>
                            <Typography
                                className={`${classes.textCommonColor} ${classes.fontText15} ${classes.textCenter}`}>
                                Gradebook will be based on checkpoints and predefined dates based on curriculum regardless of completion of certain items. Lessons and assessments can be skipped.
                            </Typography>
                        </Box>

                    </Box>

                    <GradeBookCommonTableWeb
                        title={"Grading Component & Weightage"}
                        tableColumnHead={tableBodyHeadWeightage}
                        tableBodyData={tableBodyDataWeightage}
                        showMenuIcon={true}
                        addButton={true}
                        id="add-component"
                        addButtonClick={this.handleOpenAddNewGradeComponent}
                        actionButtonConfig={gradesTableActionButtonConfigWeightage}
                        emptyTableText={"You don’t have any Grading Component added.<br/> Click on add icon on top right corner of the block to add Grading Componet and weightage "}
                    />
                    <GradeBookCommonTableWeb
                        title={"Rules"}
                        addButton={tableBodyDataRules.length ===0 ?true:false}
                        id="add-component"
                        addButtonClick={this.handleOpenRules}
                        tableColumnHead={tableBodyHeadRules}
                        tableBodyData={tableBodyDataRules}
                        showMenuIcon={true}
                       
                        actionButtonConfig={gradesTableActionButtonConfigRules}
                        emptyTableText={"You don’t have any Rules added.<br/> Click on add icon on top right corner of the block to add Rules "}
                    />
                    <NewGradingComponentPop
                        open={openAddNewGradingComponentModal}
                        onCancel={this.handleCloseAddNewGradeComponent}
                        onConfirm={this.handleOnConfirmGradingComponent}
                        isEdit={isEditNewGradeComponentModal}
                        userGradingDetails={userGradingComponentDetails}
                        gradeId={this.props.gradeId}
                        handleAddNewCourse={() => { }}
                        handleUpdateCourse={() => { }}
                        courseId={2}
                        id="add-component-pop"
                    />
                    <NewGradeTitlePop
                        open={openAddNewGradeTitleModal}
                        onCancel={this.handleCloseAddNewGradeTitle}
                        onConfirm={this.handleOnConfirmGradingTitle}
                        isEdit={isEditNewGradeTitleModal}
                        userGradingDetails={userGradingDetails}
                        id="add-title-pop"
                        gradeId={this.props.gradeId}
                        handleAddNewCourse={() => { }}
                        handleUpdateCourse={() => { }}
                        gradeSystemId={editNewGradeId}
                    />
                    <Observation
                        open={openObservation}
                        onCancel={this.handleCloseObservation}
                        behaviourDetails={behaviourDetails}
                        onConfirm={this.handleCloseObservation}
                        handleAddNewCourse={() => { }}
                        handleUpdateCourse={() => { }}
                        gradeId={this.props.gradeId}
                        handleDeleteBehaviourSkills={this.handleDeleteBehaviourSkills}
                        courseId={2}
                        
                    />
                    <AddGradeBookRulesPopWeb
                        open={openRuleModal}
                        onCancel={this.handleCloseRules}
                        onConfirm={this.handleOnConfirmRules}
                        curriculumList={curriculumList}
                        isEdit={isEditRuleModal}
                        selectedIds={selectedIds}
                        gradeId={this.props.gradeId}
                        selectedCurriculum={selectedCurriculum}
                        //  userGradingDetails={userGradingDetails}
                        id="add-Rule-pop"
                        handleAddNewCourse={() => { } }
                        handleUpdateCourse={() => { } } courseId={0}                        //  gradeSystemId={editNewGradeId}
                    />
                   <TemplatePreviewDialogWeb
                        data-testId="close-preview"
                        isOpen={isPreviewOpen}
                        handleClose={this.handleClosePreview}
                        screenName={"front_back"}
                        getDataOfTemplate={this.state.selectedTemplateData.getDataOfTemplate}
                        templateType={this.state.selectedTemplateData.templateType} 
                        AttandanceTableData={this.state.AttandanceTableData}
                        AttandanceTableHead={this.state.AttandanceTableHead}
                        gradebookListTableHead={this.state.gradebookListTableHead}
                        gradebookTableData={this.state.gradebookTableData}
                        behaviourTableHead={this.state.behaviourTableHead}
                        behaviourTableData={this.state.behaviourTableData}
                   />
             

                </Box>
            </>


        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantAdminGradeBook);
// Customizable Area End
