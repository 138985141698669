// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import {  
  physicsSubjectIcon,
} from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
import moment from "moment";
import { ActionMeta, OptionTypeBase, ValueType } from "react-select";
import { StylesConfig  } from 'react-select'
import { IDropdownMenuItemProps } from "../../../components/src/CommonType.web";
import { AssessmentTestContext, AssessmentTestContextType } from "./context/AssessmentTestProvider";
// Customizable Area End

// Customizable Area Start
type Option = {
  value: string | number;
  label: string;
};
interface Role {
  id: number;
  name: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
  roles_title: string | null;
  base_role: string; 
}

interface Curriculum {
  id: number;
  name: string;
  subject_id: string;
  grade_id: number;
  school_id: number;
  created_at: string;
  updated_at: string;
}

interface SchoolYear {
  id: number;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  number_of_grading: number;
  grade_period_title: string;
  status: string;
  school_id: number;
  created_at: string;
  updated_at: string;
}

interface Parent {
  id: number;
  first_name: string;
  last_name: string;
  relationship: string | null;
  contact_number: string | null;
  email: string;
  role: Role;
  profile_url: string;
  filename: string;
  content_type: string;
}

interface Course {
  id: number;
  title: string;
  subject: Subject;
}

interface Subject {
  id: number;
  name: string;
  color: string;
  image: string | null;
  icon: string;
  filename: string;
  content_type: string;
}

interface PerformanceData {
  course: Course;
  percentage: number;
  time_spent: number;
}

interface Grade {
  id: number;
  name: string;
  description: string | null;
  status: string;
  created_at: string;
  updated_at: string;
  school_id: number;
}

interface StudentClass {
  id: number;
  name: string;
  grade_id: number;
  subject_id: string;
  school_year_id: number;
  curriculum_id: number;
  school_id: number;
  student_id: string[];
  status: string;
  created_at: string;
  updated_at: string;
}

interface UserCustomField {
  id: number;
  field_value: string;
  custom_field_id: number;
  custom_field_type: string;
  label: string;
}

interface UserCustomFields {
  data: UserCustomField[];
}

interface ProfileUrl {
  url: string;
  filename: string;
  content_type: string;
}

interface CourseWiseProgress {
  id: number;
  title: string;
  subject: Subject;
  completed_percentage: number;
  remaining_percentage: number;
  not_tackled_percentage: number;
}

interface Progress {
  completed: number;
  remaining: number;
  not_tackled: number;
  course_wise_progress: CourseWiseProgress[];
}

interface StudentProgressData {
  id: string;
  type: string;
  attributes: {
      first_name: string;
      last_name: string;
      email: string;
      role: Role;
      status: string;
      user_name: string;
      class_status: string;
      curriculum: Curriculum;
      school_year: SchoolYear;
      id_number: string;
      relationship: string | null;
      expiring_on: string;
      children: [];
      contact_number: string | null;
      parents: Parent[];
      DOB: string;
      last_access: string;
      performance_data: PerformanceData[];
      grade: Grade[];
      student_class: StudentClass[];
      user_custom_fields: UserCustomFields;
      profile_url: ProfileUrl;
      progress: Progress;
      semsester_avg: number;
      gpa: number;
  };
}
interface Subject {
  id: number;
  name: string;
  color: string;
  image: string | null;
  icon: string;
  filename: string;
  content_type: string;
}

interface StudentReceivedGrade {
  grade: number;
  avg: number;
  graded_on: string;
}

interface StudentActivity {
  id: number;
  student_id: number;
  activity_id: number;
  is_completed: boolean;
  created_at: string;
  updated_at: string;
  is_graded: boolean;
  total_marks: number;
  time_spent: number;
  remarks: string | null;
  allowed_attempts: number;
  submission_date: string;
  is_reattempt_allow: boolean;
}

interface ActivityAttributes {
  lesson_id: number;
  activities_title: string;
  activity_type: string;
  evolution_type: string;
  objective_type: string;
  grading_component: string;
  max_score: number;
  media_url: string | null;
  show_correct_answer: boolean;
  allowed_attempts: number;
  due_date: string;
  description: string;
  instruction: string;
  task: string;
  video_desc_placement: string;
  audio_desc_placement: string;
  duration: string;
  correct_answer: boolean;
  subject: Subject;
  student_received_grade: StudentReceivedGrade;
  submission_percenttage: number;
  time_spent: number;
  assessment_status: string;
  assigned_on: string;
  due_days: number;
  last_activity_perform_at: string | null;
  media_file_url: string | null;
  student_activity: StudentActivity;
  is_completed: boolean;
  xapi_file_url: string | null;
}

interface Activity {
  id: string;
  type: string;
  attributes: ActivityAttributes;
}

interface Pagination {
  per_page: number;
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
}

interface Meta {
  pagination: Pagination;
}

interface Student {
  id: number;
  first_name: string;
  last_name: string;
}

interface ApiResponse {
  data: Activity[];
  meta: Meta;
  student: Student;
}
interface Student {
  id: number;
  first_name: string;
  last_name: string;
}
interface GradeSubject {
  name: string;
  icon: string;
  bgColor: string;
}

interface GradedAssessment {
  id: string;
  gradeSubject: GradeSubject;
  subjectId: number;
  gradeComponent: string;
  grade: number;
  gradeDueDate: string;
  gradeon: string;
  maxScore: number;
  average: number;
  students: [];  
}
interface Student {
  id: number;
  first_name: string;
  last_name: string;
}

interface Subject {
  name: string;
  icon: string;
  bgColor: string;
  
}

interface AssignedActivity {
  id: string;
  subject: Subject;
  subjectId: number | string;
  gradingComponent: string;
  assignedon: string;
  dueDate: string;
  daysDue: number;
  students?: Student; 
}
interface AppState {
  data: StudentProgressData[];
  error?:Object
}
export interface OptionType extends OptionTypeBase {
  label: string;
  value: string | number;
}
const configJSON = require("./config.js")
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue: number;
  selectedStudentName: string;
  courseName: string;
  tabName: string;
  selectedClassComponentDDValue: any;
  selectedStudentComponentDDValue:any;
  gradingComponent: Array<any>;
  GradedActivitiesColumny: Array<any>;
  GradedActivitiesBody: Array<any>;
  searchStudent: string;
  searchByStudent: string;
  currentRole: string;
  openAddNewAssessment: boolean;
  editActivity: boolean,
  editActivityId: number,
  selectedLesson: any,
  selectedTopic: any,
  courseData: any,
  filteredClassId:string[],
  selectedCategory:Option | null,
  studentSearchDrop:Option[],
  studentId:number | string,
  getSearchStudentData:AssignedActivity[],
  getGradeStudentData:GradedAssessment[],
  search:boolean,
  isDarkMode: boolean,
  studentDropdownData: Array<IDropdownMenuItemProps>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentPerformanceAssessmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  static contextType = AssessmentTestContext;
  // Customizable Area Start
  apiGetProfileData: string = "";
  apiGetTeachersClasses: string = "";
  apiPostAddNewAssessment: string = "";
  getSearchStudentTextId: string = "";
  getAllSearchStudentsId: string = "";
  apiGetAllGradeStudent: string = "";
  apiGetParentTeacherDetails:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      tabValue: 0,
      selectedStudentName: '',
      courseName: '',
      tabName: '',
      searchStudent: "",
      searchByStudent: "",
      gradingComponent: [],
      isDarkMode: false,
      GradedActivitiesColumny: [
        {
          id: "Title",
          label: "Assessment Title",
          columnId: 1,
          type: tableColumnTypes.STUD_PROGRESS_ICON,
        },
        {
          columnId: 2,
          id: "Grading_Component",
          label: "Grading Component",
          type: tableColumnTypes.TEXT,
        },
        {
          id: "Graded_On",
          columnId: 3,
          type: tableColumnTypes.DUE_DATE,
          label: "Graded On",
        },
        {
          columnId: 4,
          id: "Points",
          label: "Points",
          type: tableColumnTypes.DUE_DATE,
        },
        {
          id: "Grade",
          label: " Grade",
          columnId: 5,
          type: tableColumnTypes.DUE_DATE,
        },
        {
          id: "Average",
          columnId: 5,
          label: "Average",
          type: tableColumnTypes.DUE_DATE,
        },
      ],
      GradedActivitiesBody: [
        {
          id: 1,
          Title: {
            name: "Fiction Assignment",
            color: "#01C996",
            icon: physicsSubjectIcon,
          },
          Grading_Component: "Exam",
          Graded_On: "July 21, 2022",
          Grade: 18,
          Points: 20,
          Average: "90%",
        },
        {
          id: 2,
          Title: {
            name: "Derivatives Exam",
            icon: physicsSubjectIcon,
            color: "#01C996",
          },
          Grading_Component: "Project",
          Graded_On: "July 20, 2022",
          Points: 100,
          Grade: 43,
          Average: "54%",
        },
        {
          id: 3,
          Title: {
            icon: physicsSubjectIcon,
            name: "Graphing Quiz",
            color: "#01C996",
          },
          Grading_Component: "Quiz",
          Graded_On: "July 20, 2022",
          Points: 100,
          Grade: 54,
          Average: "80%",
        },
        {
          id: 4,
          Title: {
            name: "Tenses Assignment - Part 2",
            icon: physicsSubjectIcon,
            color: "#01C996",
          },
          Grading_Component: "Assignment",
          Graded_On: "July 20, 2022",
          Points: 50,
          Grade: 58,
          Average: "96%",
        },
        {
          id: 5,
          Title: {
            name: "English Assignment - Unit 1",
            icon: physicsSubjectIcon,
            color: "#01C996",
          },
          Grading_Component: "Assignment",
          Graded_On: "July 20, 2022",
          Points: 30,
          Grade: 37,
          Average: "98%",
        },
        {
          id: 6,
          Title: {
            name: "World Map Quiz",
            icon: physicsSubjectIcon,
            color: "#01C996",
          },
          Grading_Component: "Quiz",
          Graded_On: "July 20, 2022",
          Points: 22,
          Grade: 11,
          Average: "95%",
        },
      ],
      selectedClassComponentDDValue: 'all',
      selectedStudentComponentDDValue:"all",
      currentRole: "",
      openAddNewAssessment: false,
      editActivity: false,
      editActivityId: 0,
      selectedLesson: "",
      selectedTopic: "",
      courseData: {},
      filteredClassId: [],
      selectedCategory: null,
      studentId:0,
      studentSearchDrop:[],
      getSearchStudentData:[],
      getGradeStudentData:[],
      search:false,
      studentDropdownData:[],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleClassFilterChange()
    this.handleSelectBarName();
    this.handleSetGradedTab();
    this.handleGetTeachersClassesApi();   
    this.handleGetParentTeacherDetailApi()
    this.getTabValueFromContext()
    // this.handleChangeSearch()
    // this.handleSearchStudentApi(this.state.searchByStudent)
    // Customizable Area End
  }
  componentDidUpdate(prevProps:Props, prevState:S) {
    const currentDarkMode = JSON.parse(localStorage.getItem('dark_mode') || 'false');
    if (prevState.isDarkMode !== currentDarkMode) {
      this.setState({ isDarkMode: currentDarkMode });
    }
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(message);
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetTeachersClasses) {
          this.handleGetTeachersClassesResponse(
            responseJson,
            errorResponse
          );
        }

        if (apiRequestCallId === this.apiPostAddNewAssessment) {
          this.handleAddNewAssessmentResponse(
            responseJson,
            errorResponse
          );
        }
        this.handleApiCall(apiRequestCallId ,responseJson ,errorResponse)
        this.handleApiResponse(apiRequestCallId ,responseJson ,errorResponse)
       
        
        if(apiRequestCallId === this.apiGetAllGradeStudent){
          this.handleGetAllGradeStudent(
            responseJson,
            errorResponse
          );          
        }
        if(apiRequestCallId === this.apiGetParentTeacherDetails){
          this.handleGetParentTeacherDetailResponse(responseJson)
        }
        
      }
    }
  }

  handleApiCall =(apiRequestCallId:string|number,responseJson: AppState, errorResponse: []) => {
    if(apiRequestCallId === this.getSearchStudentTextId){
         
     this.handleGetSearch(
        responseJson,
        errorResponse
      );          
    }
   
  }
  handleApiResponse = (apiRequestCallId:string|number,responseJson: ApiResponse, errorResponse: []) => {
    if(apiRequestCallId === this.getAllSearchStudentsId){
      this.handleGetAllSearchSearch(
        responseJson,
        errorResponse
      );          
    }
  }
  handleSetGradedTab = async () => {
    const currentRole = await getStorageData("role");
    this.setState({ currentRole:  currentRole?.toString()?.toLowerCase() })
    const studentName = this.props.navigation?.getParam("studentName");
    if(studentName) {
      this.handleTabChange({} as any, 2);
      this.setState({ searchStudent: studentName })
    }
  }

  getCustomeStyle = ()=>{
    const isDarkMode = this.state.isDarkMode;
    const customStyles: StylesConfig<OptionType, false> = {
      control: (provided, state) => ({
        ...provided,
        borderColor: isDarkMode ? "#888888" : "gray",
        backgroundColor: isDarkMode ? "#090909" : "white",
        borderRadius: "10px",
        padding: "5px 0px",
        fontSize: "15px",
        height:'50px',
        boxShadow: state.isFocused ? "none" : provided.boxShadow,
        "&:hover": {
          borderColor: "gray",
        },
      }),
      placeholder: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        fontSize: "15px",
        color: isDarkMode ? "lightgray" : "black",
      }),
      singleValue: (provided) => ({
        ...provided,
        fontSize: "15px",
        color: "#888888",
      }),
      input: (provided) => ({
        ...provided,
        marginLeft: "5px",
        color:"#888888",
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        zIndex: 2,
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? "#f0f0f0"
          : "white",
        color: "#888888",
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
      }),
    };
    return customStyles
  }
  handleTabChange = (event: any, newValue: number) => {
    const tabNames = ['All', 'Assigned', 'Graded'];
    this.setState({
      // searchStudent:this.state.searchStudent,
      search:false,
     
      tabValue: newValue,
      tabName: tabNames[newValue]
    }, async() => {
      if (this.state.searchStudent && (this.state.searchStudent.length > 0)  && (this.state.searchStudent !== "All")) {
        this.setState({ search: true }); 
      } 
    });
  };
  handleSelectBarName = () => {
    const historyLocationState = this.props.navigation?.getHistoryState();
    if (historyLocationState) {
      const { TabNames, StudentName } = historyLocationState;
      this.setState({ selectedStudentName: StudentName ?? "", courseName: TabNames });
    }
  }
  handleClassDropdownValues = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
  }>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      }, () => { this.handleClassFilterChange() });
    }
  };


  renderClassComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "All";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponents = gradingComponent.find((grade: any) => grade?.id === selected);
    return selectedGradingComponents?.label;
  };
  renderStudentComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "All";
    }
    const { studentDropdownData } = this.state;
    const selectedStudentDropdownData = studentDropdownData.find((grade: any) => grade?.id === selected);
    return selectedStudentDropdownData?.label;
  };
  handleSearch = async (event: any) => {
    this.setState({
      searchByStudent: event.target?.value
    })
  };
  handleGetAllGradeStudent =async (responseJson: ApiResponse ,errorResponse:[]) => {    

    if (responseJson && responseJson.data) {
      let gradeassessmentList :GradedAssessment[]= responseJson.data.map(
        (item: any, index: number) => {
          const activity :any = item.attributes;
          return {
            id: item.id,
            gradeSubject: {
              name: activity.activities_title,
              icon: activity.subject.icon,
              bgColor: activity.subject.color,
            },
            subjectId: activity.subject.id,
            gradeComponent: activity.grading_component,
            gradeon:activity.student_received_grade.graded_on,
            grade:activity.student_received_grade.grade,
            average:activity.student_received_grade.avg,            
            gradeDueDate: activity.due_date ? moment(activity.due_date).format('MMMM DD, YYYY') : 'NA',
            students: activity.students || [],
            maxScore: activity.max_score,
            subjectActivity:activity.student_activity,          
          };         
        }
      );     
      this.setState({
        getGradeStudentData: gradeassessmentList,
        studentId:responseJson.student.id
      });
    }    
  }
 
  handleGetAllSearchSearch = async (responseJson: any, errorResponse: []) => {
    if (responseJson &&  responseJson.data) {
      let assessmentList:AssignedActivity[] = responseJson.data.map(
        (item:any) => {
          const activity = item.attributes;
   
          return {
            id: item.id,
            subject: {
              name: activity.activities_title,
              icon: activity.subject.icon,
              bgColor: activity.subject.color,
            },
            subjectId: activity.subject.id,
            gradingComponent: activity.grading_component,
            assignedon: activity.assigned_on
              ? moment(activity.assigned_on).format('MMMM DD, YYYY')
              : 'NA',
            dueDate: activity.due_date
              ? moment(activity.due_date).format('MMMM DD, YYYY')
              : 'NA',
            daysDue: activity.due_days,
            students: responseJson.student,
          } ;
        }
      );
      this.setState({
        getSearchStudentData: assessmentList ,
      });
      
    }
  
    hideCustomLoader();
  };
  handleGetSearch = async (response: AppState, errorResponse: []) => {  
    
    if (response && response.data) {
      let data = response.data.map((student: StudentProgressData) => (
        student
      ));
      let studentdropData = data.map((student: StudentProgressData) => ({
  
        value: student.id,
        label: `${student.attributes.first_name} ${student.attributes.last_name}`,
      }));

    this.setState({
      studentSearchDrop: studentdropData,      
    });
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();  
};

  handleGetTeachersClassesApi = async () => {
    const requestUrl = configJSON.teacherClassesEndpoint;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeachersClasses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeachersClassesResponse = (response: any, errorResponse: any) => {
    if (response && response.data) {
      const classesDropdwonAll = [{
      id: "all",
      label: "All",
      value: "all",
    }];
      let classesDropdwonData = response.data.map(
        (item: any) => {
          return {
            label: item?.name,
            value: item?.id,
            id: item?.id,
          }
        }
      );
      this.setState({
        gradingComponent: classesDropdwonAll.concat(classesDropdwonData),
      }, () => {
        this.handleClassFilterChange()
        const classId = this.props.navigation?.getParam("classId");
        // this.setState({ selectedClassComponentDDValue: parseInt(classId) }) 
        console.log("gradingComponent: ", this.state.gradingComponent);
      });
    } else {
      displayApiErrorMsg(response?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleClassFilterChange = () => {
    const { selectedClassComponentDDValue, gradingComponent } = this.state;
    let filteredClassIds: string[] = [];
    if (selectedClassComponentDDValue === 'all') {
      filteredClassIds = gradingComponent
        .filter((classItem) => classItem.value !== 'all')
        .map((classItem) => classItem.id);      
        
    } else if (Array.isArray(selectedClassComponentDDValue)) {
      filteredClassIds = gradingComponent
        .filter((classItem) => selectedClassComponentDDValue.includes(classItem.value))
        .map((item) => item.id);
       
    } else {
      const selectedClass = gradingComponent.find(
        (classItem) => classItem.value === selectedClassComponentDDValue
      );
      if (selectedClass) {
        filteredClassIds.push(selectedClass.id);
      }
    }

    this.setState({ filteredClassId: filteredClassIds });
  };
  

  handleOpenAddNewAssessmentModal = () => {
    this.setState({ openAddNewAssessment: true });
  }

  handleCloseAddNewAssessmentModal = () => {
    this.setState({ openAddNewAssessment: false });
  }

  handleAddNewAssessmentEvent = (payload: any) => {
    console.log("New assessment payload: ", payload);
    this.handleCloseAddNewAssessmentModal();
    this.handleAddNewAssessmentApi(payload);
  }
  handleSearchStudentApi = async (searchStudent:string) => {    
    let apiConfig = configJSON.searchStudent;
    let pageLimit = 10;
   

    const baseUrl = apiConfig + `?per_page=${pageLimit}`;
    let requestUrl = baseUrl;

   
    if (this.state.filteredClassId) {
      requestUrl = requestUrl + `&class_id=[${this.state.filteredClassId}]`;
    }
    if (searchStudent) {
      requestUrl = requestUrl + `&name=${searchStudent}`
    }
    

    
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getSearchStudentTextId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl 
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetSearchStudentApi = async (studentId:string | number) => {      
    
    let pageLimit = 10;
  
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getAllSearchStudentsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSearchStudents + `?student_activity=assigned_activity&per_page=${pageLimit}&student_id=${studentId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetGradeStudentApi = async (studentId:string | number) => {      
    
    let pageLimit = 10;
  
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetAllGradeStudent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSearchStudents + `?student_activity=graded_activity&per_page=${pageLimit}&student_id=${studentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  handleAddNewAssessmentApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      // "Content-Type": "multipart/form-data",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostAddNewAssessment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postAddNewActivity
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      requestBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddNewAssessmentResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      console.log("assessment resp: ", responseJson?.data);
      toast.success("Assessment added successfully");
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };
  handleChangeSearch = (
    selectedOption: ValueType<Option, false>, 
    actionMeta: ActionMeta<Option>
  ) => {
    const searchText = selectedOption ? selectedOption.label : '';
    this.setState({
      selectedCategory: selectedOption as Option,
      studentId: selectedOption ? selectedOption.value : '',
      searchStudent: searchText,
      search: !!searchText
    }, () => {
       setStorageData("selectedActivityId", this.state.studentId);
       setStorageData("selectedText", this.state.searchStudent);
    });
  }

  handleStudentDropdownValues = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
   
    let studentData:{ label: string; value: string };
    let searchText='';
    const selectedStudent = this.state.studentDropdownData.find(student => student.value === value);
    if(selectedStudent){
      studentData ={ label:selectedStudent?.label, value: selectedStudent?.value }
      searchText = studentData ? studentData.label : '';
    }
    
    console.log("hello world here",searchText)
  
    if (selectedStudent) {
      this.setState(
        (prevState) => ({
          ...prevState,
          [name as string]: studentData.value,
          selectedCategory: studentData,
          studentId: studentData.value,
          searchStudent: searchText,
          search:searchText === "All" ? false:!!searchText,
        }),
        () => {
          if(this.state.searchStudent){
          console.log("hello world here",this.state.search,this.state.searchStudent,this.state.studentId)
          setStorageData("selectedActivityId", this.state.studentId);
          setStorageData("selectedText", this.state.searchStudent);
          }
        }
      );
    }
  };
  handleInputChange = (inputValue:string) => {
    if (inputValue) {
      this.handleSearchStudentApi(inputValue.toLowerCase());
    }
  };

handleGetParentTeacherDetailApi = async () => {
  const userId = await getStorageData("user_id");
  const token = await getStorageData("token");
  const header = { token };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetParentTeacherDetails = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.userDetailsEndpoint}/${userId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

handleGetParentTeacherDetailResponse = (responseJson: any) => {
  if (responseJson.data) {
    const userData = responseJson.data.attributes;


    const StudentDropdwonAll = [{
      id: "all",
      label: "All",
      value: "all",
    }];
     
    let studentsData = userData.children.map(
      (item: any, index: number) => {
        return {
          id: item.id,
          value: item.id,
          label: `${item.first_name} ${item.last_name}`,
        };
      }
    );
    this.setState({ studentDropdownData:StudentDropdwonAll.concat(studentsData) });
  } else {
    displayApiErrorMsg(responseJson?.errors, this.props.navigation);
  }
}

  /**
   * Gets the tabValue from context and sets it in the state
   */
  getTabValueFromContext = () => {
    const context = this.context as AssessmentTestContextType;
    const tabValue = context.tabValue;

    this.setState({
      tabValue
    })
  }

  // Customizable Area End
}
// Customizable Area End