// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export const myPathCategoryTabsList = [
  {
    id: 1,
    label: "Students / Parents",
    value: "student_parent",
  },
  {
    id: 2,
    label: "Teachers / Parent Teachers",
    value: "teacher",
  },
  {
    id: 3,
    label: "Others",
    value: "other",
  },
];
export interface IDropdownItems {
  id: number;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedUserManagementTab: number;
  openImportCSVPopup: boolean;
  openUserRoleSelectPopup: boolean;
  uploadedCSVFile: any;
  selectedAddNewUserRole: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantUserManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      selectedUserManagementTab: 0,
      openImportCSVPopup: false,
      openUserRoleSelectPopup: false,
      uploadedCSVFile: null,
      selectedAddNewUserRole: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeMyPathCategoryTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ selectedUserManagementTab: newValue });
  };

  handleOpenAddUserRoleSelectPopup = () => {
    this.setState({ openUserRoleSelectPopup: true });
  };

  handleCloseAddUserRoleSelectPopup = () => {
    this.setState({ openUserRoleSelectPopup: false });
  };

  handleAddUserFlow = (role: string) => {
    this.setState(
      {
        openUserRoleSelectPopup: false,
        selectedAddNewUserRole: role,
      },
      () => {
        if (role === "student") {
          this.props.navigation?.navigate("AddNewStudentDetails");
        } else {
          this.props.navigation?.navigate(
            "TenantUserManagementAddNewUserDetails",
            {},
            {
              role: role,
            }
          );
        }
      }
    );
  };

  handleFileUpload = (file: any) => {
    this.setState({ uploadedCSVFile: file });
    console.log(file);
  };

  handleOpenImportCsvDialog = () => {
    this.setState({ openImportCSVPopup: true });
  };

  handleCloseImportCsvDialog = () => {
    this.setState({ openImportCSVPopup: false });
  };

  // Customizable Area End
}
// Customizable Area End
