Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfparentdashboard";
exports.labelBodyText = "cfparentdashboard Body";

exports.btnExampleTitle = "CLICK ME";
exports.dashboarApiMethodType = "GET";
exports.studentDashboardClassesMethodType = "GET";
exports.studentDashboardClassesEndpoint = "bx_block_dashboard/parent_teacher_dashboard/get_parent_teacher_courses";
// Customizable Area End