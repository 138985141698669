// Customizable Area Start
import React from "react";
import { Button, Box, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import AssessmentDetailPageController, {
    Props,
} from "./AssessmentDetailPageController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CustomTextEditor from '../../../components/src/CustomTextEditor.web';
import CustomButton from "../../../components/src/CustomButton.web";
import SubmitScoreDialog from "./SubmitScoreDialog.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        boxRoot: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.spacing(2.5, 2.5, 2.5, 2.5),
            margin: "35px 40px 40px 5px",
            padding: "25px",
        },
        assesmentBreadcrumb: {
            margin: theme.spacing(2, 0, 2, 0),
        },
        profileNameBox: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
        },
        scoreBox: {
            width: "max-content",
            height: "100%",
            background: "rgba(0, 157, 255, 0.05)",
            color: "#009DFF",
            padding: "19px 25px",
            borderRadius: "12px",
            marginRight: "40px"
        },
        bottomScoreBox: {
            width: "max-content",
            background: "rgba(0, 157, 255, 0.05)",
            color: "#009DFF",
            padding: "11px 25px",
            borderRadius: "12px",
        },
        titleLabel: {
            marginBottom: "8px",
        },
        contentText: {
            marginBottom: "15px"
        },
        attachmentImage: {
            width: "110%",
            height: "250px",
            objectFit: "inherit",
        },
        attachmentImageBox: {
            border: "1px solid #F1F1F1",
            marginBottom: "12px"
        },
        customInput: {
            height: "50px",
            width: "100%",
            borderRadius: "12px",
            padding: "17px 15px",
            border: "1px solid #888",
            fontSize: "14px",
            marginTop: "10px",
            fontWeight: 400,
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
            "&:hover:not(.Mui-)::before": {
                borderBottom: "1px solid #888",
                borderRadius: "12px",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            }
        },
        dividerLine: {
            height: "1px",
            background: "#F1F1F1",
            margin: "25px -25px 25px -25px",
        },
        scoreErrorMessage: {
            color: "red",
            fontSize: "12px",
        },
        noAnswerErrorMesg: {
            padding: "20px",
            width: "max-content",
            fontSize: "14px",
            color: "red",
            background: "#ffdddb",
            borderRadius: "12px",
        }
    });
// Customizable Area End

// Customizable Area Start
export class AssessmentDetailPage extends AssessmentDetailPageController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            openSubmitDialog,
            assessmentName,
            studentName,
            assessmentDetails,
            receivedScore,
            totalMaxScore,
            totalReceivedScore,
            totalPercentage,
            showSubmitButton,
            calculateBtnText,
            showCalculateButton,
        } = this.state;

        return (
            <>
                <Box className={`${classes.highlightBgColor} ${classes.childMainWrapperPadding} ${classes.secondaryBgColor}`}>
                    <Box className={classes.assesmentBreadcrumb}>
                        <Typography
                            component={"span"}
                            className={`${classes.fontText14} ${classes.textCommonColor}`}
                        >
                            {`Assessment `}
                        </Typography>
                        <Typography
                            component={"span"}
                            className={`${classes.fontText14} ${classes.textCommonColor}`}
                        >
                            {` / Assigned `}
                        </Typography>
                        <Typography
                            component={"span"}
                            className={`${classes.fontText14} ${classes.textCommonColor}`}
                        >
                            {` / ${assessmentName} `}
                        </Typography>
                        <Typography
                            component={"span"}
                            className={`${classes.primaryColor5} ${classes.fontText12} ${classes.fontBold600}`}
                        >
                            {`/ ${studentName}`}
                        </Typography>
                    </Box>
                    <Box className={classes.profileNameBox}>
                        <Box>
                            <Box alignItems={"center"} display={"flex"}>
                                <Button onClick={this.goBackToAssessments}>
                                    <ArrowBackIcon className={classes.textPrimaryColor} fontSize={"large"} />
                                </Button>
                                <Typography
                                    component={"span"}
                                    className={`${classes.textPrimaryColor} ${classes.fontText34}`}
                                    style={{ fontWeight: 700 }}
                                >
                                    {assessmentName}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.scoreBox}>
                            <Typography
                                component={"span"}
                                className={`${classes.fontBold500} ${classes.fontText24}`}
                            >
                                Score: {totalPercentage || 0}%
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={`${classes.boxRoot} ${classes.bgColor}`}>
                        {(assessmentDetails.length > 0) ?
                            <>
                                {(assessmentDetails.map((assessmentItem: any, index) => (
                                    <>
                                        <Box id={assessmentItem.id} key={assessmentItem.id}>
                                            <Box>
                                                <Typography className={`${classes.titleLabel} ${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText18}`}>Question {assessmentItem.questionNumber}</Typography>
                                                <Typography className={`${classes.contentText} ${classes.textSecondaryColor} ${classes.fontBold400} ${classes.fontText18}`}
                                                    dangerouslySetInnerHTML={{ __html: assessmentItem.question }}
                                                >
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography className={`${classes.titleLabel} ${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText18}`}>Answer {assessmentItem.questionNumber}</Typography>
                                                <Typography className={`${classes.contentText} ${classes.textSecondaryColor} ${classes.fontBold400} ${classes.fontText18}`}
                                                    dangerouslySetInnerHTML={{ __html: assessmentItem.answer }}
                                                >
                                                </Typography>
                                            </Box>
                                            {assessmentItem.attachment.length > 0 &&
                                                <Box>
                                                    <Typography className={`${classes.titleLabel} ${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText18}`}>Attachments</Typography>
                                                    <Grid
                                                        container
                                                        spacing={4}
                                                        direction="row"
                                                    >
                                                        {(assessmentItem.attachment.map((attachItem: any, index: number) => (
                                                            <Grid item xs={2} lg={2} md={3} key={index + 1}>
                                                                <Box className={classes.attachmentImageBox}>
                                                                    <img className={classes.attachmentImage} src={attachItem.media_file_url} alt="attachment" />
                                                                </Box>
                                                                <Box>
                                                                    <Typography className={`${classes.fontText12} ${classes.textLightGreyColor}`}>
                                                                        {attachItem.filename}
                                                                    </Typography>
                                                                    <Typography className={`${classes.fontText12} ${classes.textLightGreyColor}`}>
                                                                        {attachItem.filesize}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        ))
                                                        )}
                                                    </Grid>
                                                </Box>
                                            }
                                            {assessmentItem.answerId ?
                                                <Box marginTop={"25px"}>
                                                    <Typography className={`${classes.titleLabel} ${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText18}`}>Grade and Remark</Typography>
                                                    <Grid container spacing={2} direction="row">
                                                        <Grid xs={6} item>
                                                            <Box marginBottom={"10px"} width={"100%"}>
                                                                <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                                    Max Score
                                                                </Typography>
                                                                <CustomTextField
                                                                    placeHolderText="Enter Score"
                                                                    textFieldStyles={{ height: "35px", marginTop: "10px" }}
                                                                    fieldName="maxScore"
                                                                    fieldId="maxScore"
                                                                    fieldValue={assessmentItem.maxScore}
                                                                    fieldWidth={"100%"}
                                                                    fieldType="text"
                                                                    isDisabled={true}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid xs={6} item>
                                                            <Box marginBottom={"10px"} width={"99%"}>
                                                                <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                                    Received Score
                                                                </Typography>
                                                                <CustomTextField
                                                                    placeHolderText="Enter Score"
                                                                    textFieldStyles={{ height: "35px", marginTop: "10px" }}
                                                                    fieldName={assessmentItem.answerId}
                                                                    fieldId={"received-score-" + assessmentItem.answerId}
                                                                    fieldValue={receivedScore[assessmentItem.answerId].received_score}
                                                                    fieldWidth={"100%"}
                                                                    fieldType="number"
                                                                    handleChange={this.handleReceiveScoreChange}
                                                                    fieldError={receivedScore[assessmentItem.answerId].received_score > assessmentItem.maxScore}
                                                                />
                                                                {receivedScore[assessmentItem.answerId].received_score > assessmentItem.maxScore &&
                                                                    <Typography component={"span"} className={`${classes.scoreErrorMessage}`}>
                                                                        You can give score between 0 to max score only. Please add appropriate score.
                                                                    </Typography>
                                                                }
                                                            </Box>
                                                        </Grid>
                                                        <Grid xs={12} item className={classes.customTextEditorStyle}>
                                                            <Typography style={{ marginBottom: "15px" }} className={`${classes.textEditorLabel} ${classes.fontText16} ${classes.textCommonColor}`} >
                                                                Remarks
                                                            </Typography>
                                                            <CustomTextEditor
                                                                editorId={"received-remark-" + assessmentItem.answerId}
                                                                editorContent=""
                                                                placeHolderText="Enter Remarks"
                                                                onWysEditorStateChange={(event: any) => this.handleRemarkChange(event, assessmentItem.answerId)}
                                                                editorError={false}
                                                                helperText=""
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                :
                                                ''
                                            }
                                        </Box>
                                        {
                                            index + 1 < assessmentDetails.length ? <Box className={classes.dividerLine} /> : ''
                                        }
                                    </>
                                )))}
                                <Box marginTop={"25px"} className={classes.profileNameBox}>
                                    <Box className={classes.bottomScoreBox}>
                                        <Typography
                                            component={"span"}
                                            className={`${classes.fontBold500} ${classes.fontText24}`}
                                        >
                                            Score: {totalReceivedScore}/{totalMaxScore}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {showSubmitButton &&
                                            <CustomButton btnText="Submit" btnStyle={submitButton} buttonId={`submit-score-button`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleOpenSubmitDialog} isDisabled={false} />
                                        }
                                        {showCalculateButton ?
                                            <CustomButton btnText={calculateBtnText} btnStyle={submitButton} buttonId={`submit-score-button`} buttonVariant={"outlined"} type={"button"} handleButtonClick={this.handleCalculateScore} isDisabled={false} />
                                            :
                                            <Typography component={"span"} className={classes.noAnswerErrorMesg}>
                                                You cannot assign and submit grade, because student has not submitted answers.
                                            </Typography>
                                        }
                                    </Box>
                                </Box>
                            </>
                            :
                            <CustomEmptyResultComponent message="No questions available for this assessment." />
                        }
                    </Box>
                </Box>
                <SubmitScoreDialog
                    open={openSubmitDialog}
                    onCancel={this.handleCloseSubmitDialog}
                    onConfirm={this.handleScoreSubmit}
                    studentName={studentName}
                    activityName={assessmentName}
                    maxScore={totalMaxScore}
                    receivedScore={totalReceivedScore}
                />
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const submitButton = {
    width: "max-content",
    height: "50px",
    padding: "15px 20px",
    borderRadius: "12px",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    float: "right",
    marginLeft: "20px"
}
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AssessmentDetailPage);
// Customizable Area End
