// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  profileSettingOptions: {label: string, clickHandle: () => void}[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loading: false,
      profileSettingOptions: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.setProfileSettingOptions();
    // Customizable Area End
  }
  // Customizable Area Start

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  onEditProfile = () => {
    this.props.navigation.navigate("EditProfile");
  };

  setProfileSettingOptions = async () => {
    const userRole = await getStorageData('role');
    if (userRole) {
      switch (userRole) {
        case 'teacher':
          this.setState({
            profileSettingOptions: [
              {
                label: 'Edit Profile',
                clickHandle: this.onEditProfile
              },
              {
                label: 'Change Password',
                clickHandle: this.handleGoToChangePassword
              }
            ]
          });
          break;
        case 'student':
          this.setState({
            profileSettingOptions: [
              {
                label: 'Change Password',
                clickHandle: this.handleGoToChangePassword
              }
            ]
          });
          break;
          case 'tenant':
            this.setState({
              profileSettingOptions: [
                {
                  clickHandle: this.handleGoToChangePassword,
                  label: 'Change Password'
                }
              ]
            });
            break;
      }
    }
  };

  handleGoToChangePassword = () => {
    this.props.navigation.navigate("StudentChangePassword");
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area End
