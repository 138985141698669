// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { setStorageData } from "../../../framework/src/Utilities";
import { IDropdownMenuItemProps, ITableActionBtnConfigProps } from "../../../components/src/CommonType.web";
import { roleOptionsMock, rolePermissionsListMock } from "../assets/tenantRolesAndPermissionsMock.web";
import { handleInvalidToken } from "../../../components/src/CommonHelper.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedRoleTitleDDValue: any;
  roleTitles: Array<any>;
  roleTitleDDMenuItems: Array<IDropdownMenuItemProps>;
  rolesPermissionsList: Array<any>;
  tableActionBtnConfig: Array<ITableActionBtnConfigProps>;
  openDeleteConfirmModal: boolean;
  selectedRoleFromList: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantRolesAndPermissionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedRoleTitleDDValue: "",
      roleTitles: [],
      roleTitleDDMenuItems: roleOptionsMock,
      rolesPermissionsList: rolePermissionsListMock,
      tableActionBtnConfig: [],
      openDeleteConfirmModal: false,
      selectedRoleFromList: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    handleInvalidToken(this.props.navigation);
    this.handleSetTableActionConfig();
    // Customizable Area End
  }

  // Customizable Area Start

  handleSetTableActionConfig = () => {
    this.setState({
      tableActionBtnConfig: [
        {
          label: "View",
          action: (value: any) => this.handleViewRolePermission(value),
        },
        {
          label: "Edit",
          action: (value: any) => this.handleEditRolesPermission(value),
        },
        {
          label: "Delete",
          action: (value: any) => this.handleDeleteRolePermission(value),
        },
      ],
    });
  };

  handleEditRolesPermission = (value: any) => {
    const { rolesPermissionsList } = this.state;
    const selectedRole = rolesPermissionsList.find((item: any) => parseInt(item.id) === parseInt(value))
    this.props.navigation?.navigate("EditTenantRolesAndPermissions", {}, {
      roleId: selectedRole.id
    });
  }

  handleViewRolePermission = (value: any) => {
    const { rolesPermissionsList } = this.state;
    const selectedRole = rolesPermissionsList.find((item: any) => parseInt(item.id) === parseInt(value))
    this.props.navigation?.navigate("ViewTenantRolesAndPermissions", {}, {
      roleId: selectedRole.id
    });
  }

  handleDeleteRolePermission = (value: any) => {
    const { rolesPermissionsList } = this.state;
    const selectedRole = rolesPermissionsList.find((item: any) => parseInt(item.id) === parseInt(value))
    this.setState({ selectedRoleFromList: selectedRole }, () => {
      this.setState({ openDeleteConfirmModal: true })
    })
  }

  handleCloseDeletePopup = () => {
    this.setState({ openDeleteConfirmModal: false, selectedRoleFromList: "" })
  }

  handleAddNewRole = () => {
    this.props.navigation?.navigate("AddNewTenantRolesAndPermissions");
  }

  renderRoleDDMenuItem = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Role Title";
    }
    const { roleTitleDDMenuItems } = this.state;
    const selectedItem = roleTitleDDMenuItems.find(
      (item: IDropdownMenuItemProps) => item.value === selected
    );
    return selectedItem?.label;
  };

  handleSelectRoleDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  // Customizable Area End
}
// Customizable Area End
