// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Tooltip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
 
} from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import StudentMyGradebookController, {

  Props,
} from "./StudentMyGradebookController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomProgressCircle from "../../../components/src/CustomProgressCircle.web";
import { invalidImage } from "./assets";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    myGradebookMainContainer: {
      overflowX: "auto",
      maxHeight: "100%",
      padding: "15px 20px",
      borderRadius: "10px",
    },
    gradeMainBox: {
      width: "100%",
      display: "flex",
    },
    subjectCoursesTableBox: {
   
      display: "flex",
     
      borderRadius: "12px",
      overflow: "auto",
      maxHeight: "600px",
     
    },
    subjectLogoBg: {
      height: "40px",
      width: "40px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      padding: "2px",
      alignItems: "center",
    },
    subjectLogo: {
      objectFit: "contain",
      height: "30px",
      width: "30px",
    },
    gradeTableHeadingRow: {
      alignItems: "center",
      display: "flex",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    subjectItemRow: {
      flexDirection: "column",
      display: "flex",
    },
    courseItemRow: {
      flexDirection: "column",
      display: "flex",
      "&:nth-last-child(n + 2)": {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    cell: {
      padding: "10px 16px 16px 16px",
      textTransform: "capitalize"
    },
    bodyCellPadding: {
      padding: "10px 16px",
    },
    subjectTitleCell: {
      display: "flex",
      alignItems: "center",
      minWidth:300,
      maxWidth: 300
      
    },
    courseTitleCell: {
      display: "flex",
      alignItems: "center",
      minWidth: 150,
      maxWidth: 150
    },
    weightCell: {
      display: "flex",
      alignItems: "center",
      minWidth: 150,
      maxWidth: 150
    },
    courseQuaterCell: {
      display: "flex",
      alignItems: "center",
      width:"100%"
    },
    courseQuaterCell2: {
      display: "flex",
      alignItems: "center",
      minWidth: "95px",
      maxWidth: "95px",
    },
    courseTotalAvgCell: {
      display: "flex",
      alignItems: "center",
      minWidth: 150,
      maxWidth: 150
    },
    subjectName: {
      overflow: "hidden",
      display: "block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    quarterCell: {
      display: "flex",
      alignItems: "center",
     width:"100%"
    },
    quarterCellGrade: {
      display: "flex",
      alignItems: "center",
      minWidth: 250,
      maxWidth: 250,
      whiteSpace: "normal",   
      wordBreak: "break-word",
      overflow: "hidden",    
    },
    totalAvgCell: {
      display: "flex",
      alignItems: "center",
      minWidth: 100,
      maxWidth: 100
     
    },
    progressCell: {
      display: "flex",
      alignItems: "center",
      minWidth: 150,
      maxWidth: 150
   
    },
    totalCell: {
      display: "flex",
      width: "100px",
      paddingTop: "23px !important",
      alignItems: "center",
      paddingRight: "0px !important",
      paddingLeft: "13px !important",
      paddingBottom: "20px !important",
    },
    totalGradeCell: {
      alignItems: "flex-start",
      display: "flex",
      "&:nth-last-child(n + 2)": {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    subjectCoursesDetailsTable: {
     
      overflowX: "hidden",
      marginTop: "15px",
      marginBottom: "12px",
    },
    show: {
      transition: "display 0.3s ease-out",
      display: "block",
    },
    hide: {
      transition: "display 0.3s ease-in",
      display: "none",
    },
    avgBox: {
      borderRadius: "10px",
      padding: "20px 20px",
      flexDirection: "column",
      width: "92%",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
        width: "auto",
      },
    },
    cellWithCheckbox: {
      display: "flex",
      flexFlow: "column",
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#FFD92E",
      },
      "& .MuiCheckbox-root": {
        color: "#888"
      }
    },
    highlightBar: {
      width: "100%",
      background: "#CCF4EA",
      opacity: "0.3",
      position: "absolute",
      left:0,

    },
    customWidth: {
      maxWidth: '150px',
      color: '#ED1111',
      padding: '12px 30px 12px 6px',
      backgroundColor: '#FFE4E4',
    },
    borderRight: {
      borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
    dividerLine: {
      height: "1px",
      background: "#F1F1F1",
      marginTop: "25px",
      marginBottom: "25px",
      marginLeft: "-40px",
      marginRight: "-40px",
      width: "100%",
      left: "40px",
      position: "relative",
    },
    gradeTextColor: {
      color: "#01C996"
    },
    tableContainer: {
      
      overflowY: "hidden",
      position: "relative",
      
    },
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    topHeaderDiv: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    
    mainPagePadding: {
      paddingRight: "20px",
    },
    dropdownGrid: {
      gap: "12px",
      marginBottom: "30px",
    },
    gradebookFilterDivTop: {
      display: "flex",
      alignItems: "flex-end",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    schoolYearFilterDiv: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
      },
    },
   
    avgYtdGrid: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    topMargin: {
      marginTop: "30px",
    },
    
  });


  const gradeTableTheme = createTheme({
    overrides: {
      MuiTableCell: {
        body: {
          borderBottom:'none',

        },
      },
    },
  });
// Customizable Area End

// Customizable Area Start
export class StudentMyGradebook extends StudentMyGradebookController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedSchoolYearValue,
      teacherSchoolYearsList
    } = this.state;
    const getStatusColor = (status: string) => {
      switch (status) {
          case "open":
              return `${classes.fontSize18} ${classes.capitalText} ${classes.textPrimaryColor}`;

          case "waiting_teacher_approval":
              return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor17}`;

          case "waiting_registrar_approval":
              return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor18}`;

          case "lockedin":
              return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor4}`;

          case "rejected":
              return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor19}`;
      }
  };

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                My Gradebook
              </Typography>
            </Box>
          </Grid>
          <Grid className={`${classes.pageName}`} item xs={12}>
            <Box className={`${classes.topHeaderDiv}`}>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText32} ${classes.fontBold600}`}
                component={"span"}
              >
                My Gradebook
              </Typography>
              <Box>
                <CustomButton
                  btnText="View Report Card"
                  btnStyle={viewReportBtnStyle}
                  buttonId={`my-gradebook-view-report-card`}
                  buttonVariant={"contained"}
                  type={"button"}
                  handleButtonClick={this.handleSearchMyGradebook}
                  isDisabled={false}
                />
              </Box>
            </Box>
          </Grid>
         
          <Grid className={classes.dropdownGrid} item xs={12}>
            <Box className={classes.gradebookFilterDivTop}>
              <Box className={classes.schoolYearFilterDiv}>
                <Box marginBottom={"10px"}>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText16}`}
                    component={"span"}
                  >
                    School Year
                  </Typography>
                </Box>
                <Box>
                <CustomSelectDropdown
                  fieldName="selectedSchoolYearValue"
                  fieldId="my-gradebook-school-year"
                  fieldValue={selectedSchoolYearValue}
                  handleChange={this.onDropdownValueChange}
                  renderValue={this.renderSchoolYearDropDownValue}
                  fieldError={false}
                  fieldMenuItems={teacherSchoolYearsList}
                  fieldStyles={selectDropdownStyles}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
                </Box>
              </Box>
              <Box marginLeft={"10px"}>
                <CustomButton
                  btnText="Search"
                  btnStyle={btnStyle}
                  buttonId={`my-gradebook-search`}
                  buttonVariant={"contained"}
                  type={"button"}
                  handleButtonClick={this.handleSearchMyGradebook}
                  isDisabled={false}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={"20px"} marginBottom={"15px"}>
              <Typography
                className={`${classes.fontText28} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                component={"span"}
              >
                {`School Year: ${this.state.schoolYear}`}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box  className={`${classes.myGradebookMainContainer} ${classes.bgColor}`}>
              <Box className={`${classes.gradeMainBox}`}>
                <ThemeProvider theme={gradeTableTheme}>
                <TableContainer  className={classes.tableContainer}>
                  <Table  ref={this.tableRef} >
                   
                    <TableHead ref={this.rowRef} >
                      <TableRow  className={`${classes.gradeTableHeadingRow}`}>
                 
                        {this.state.studentListTableColumns?.map((heading: any) => (
                          <TableCell key={heading.id}
                          style={{ minWidth: heading.width, maxWidth: heading.maxWidth, padding: "0px 16px 16px 16px",width:"100%",position:'relative' }}>
                            <Box
                              className={`${classes.cellWithCheckbox}`}
                              key={heading.columnId}
                              width={"100%"}
                         
                            >
                               <Box height={"25px"} width={"100%"}    

                    >
                                {
                          (heading.label==="Grade" || heading.label==="Total" ) ? (
                                    <>
                                    
                                      {
                                      
                                          <Box style={{height:`${this.state.tableHeight}px`,
                                          borderTopRightRadius:heading.label!=="Total"?"12px":"none",
                                          borderBottomRightRadius:heading.label!=="Total"?"12px":"none",
                                          borderTopLeftRadius:heading.label==="Total"?"12px":"none",
                                          borderBottomLeftRadius:heading.label==="Total"?"12px":"none"}} 
                                          className={classes.highlightBar}></Box>
                                        
                                      }
                                    </>
                          )
                                    : ''
                                }
                              </Box>
                              <Box>
                                <Typography
                                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                                  component={"span"}
                                >
                                  {heading.label}
                                </Typography>
                              </Box>
                        
                              </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.schoolSubjectsList.map((subjectItem: any) => (
                        <TableRow style={{borderBottom: "1px solid rgba(224, 224, 224, 1)"}}   className={`${classes.subjectItemRow}`} key={subjectItem.subjectId}>
                          <Box display={"flex"}>
                            <TableCell  
                            className={`${classes.subjectTitleCell} 
                            ${classes.bodyCellPadding}`}>
                              <Box
                                className={classes.subjectLogoBg}
                                bgcolor={subjectItem?.subject?.bgColor}
                                mr={"10px"}
                              >
                                <img
                                  src={subjectItem?.subject?.icon}
                                  alt="Subject Logo"
                                  className={classes.subjectLogo}
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = invalidImage;
                                }} 
                                />
                                  
                              </Box>
                              <Box className={classes.subjectName}>
                                <Tooltip
                                  placement="top"
                                  title={subjectItem?.subject?.name}
                                >
                                  <Typography
                                    component="span"
                                    className={`${classes.fontText15} ${classes.textPrimaryColor}`}
                                  >
                                    {subjectItem?.subject?.name}
                                  </Typography>
                                </Tooltip>
                              </Box>
                              <Box
                                onClick={() =>
                                  this.handleSetSelectedSchoolSubject(
                                    subjectItem.subjectId
                                  )
                                }
                                data-testid={`subject-course-details-expand-${subjectItem.subjectId}`}
                              >
                                {subjectItem.subjectId === this.state.selectedSubjectId ? (
                                  <RemoveIcon className={`${classes.cursorPointer} ${classes.textCommonColor}`} />
                                ) : (
                                  <AddIcon className={`${classes.textCommonColor} ${classes.cursorPointer}`} />
                                )}
                              </Box>
                     
                            </TableCell>
      
                            {Object.keys(subjectItem) 
                            .filter(key => key.startsWith('quarterScore') && !key.endsWith('Status')) 
                            .map((quarterKey, index) => (
                              <TableCell key={index}  className={`${classes.quarterCell} ${classes.bodyCellPadding}`}>
                                <Typography component="span" className={getStatusColor(subjectItem[`${quarterKey}Status`])} >
                                  {subjectItem[quarterKey]}
                                </Typography>
                              </TableCell> 
                            ))}
                        <TableCell className={`${classes.progressCell}  ${classes.bodyCellPadding}`}>
                             
                           
                            <CustomProgressCircle
                            centerTextFontSize="12px"
                            
                            progressDataPosition={{
                              top: "6px",
                              left: "6px",
                            }}
                            progressCircleDimensions={50}
                            progressData={{
                              hoverText: "",
                              progressPercentage: subjectItem?.progress,
                            }}
                            customClasses={classes}
                          />
                        
                            </TableCell >
                         
                            <TableCell  className={` ${classes.totalAvgCell} ${classes.bodyCellPadding}`}>
                              <Typography
                                component="span"
                                className={`${classes.fontText20} ${classes.fontBold500} ${classes.textPrimaryColor}`}
                              >
                                {subjectItem?.totalScore}
                              </Typography>
                            </TableCell>
                            <TableCell className={`${classes.quarterCellGrade} ${classes.fontBold500} ${classes.bodyCellPadding}`}>
                              <Typography
                                component="span"
                                className={`${classes.fontText20} ${classes.textPrimaryColor}`}
                              >
                                {subjectItem?.grade}
                              </Typography>
                            </TableCell>
                          </Box>
                          <Box
                          style={{width:`${this.state.rowWidth}px`}}
                            className={
                              subjectItem.subjectId === this.state.selectedSubjectId
                                ? `${classes.show} ${classes.subjectCoursesDetailsTable} ${classes.subjectCoursesTableBox} ${classes.secondaryBgColor}`
                                : `${classes.hide} ${classes.subjectCoursesDetailsTable} ${classes.subjectCoursesTableBox} ${classes.secondaryBgColor}`
                            }
                          >

                          
                            <Box className={`${classes.gradeTableHeadingRow}`}>
                              {this.state.subjectCoursesDetailsTableColumns.map((column: any) => (
                              
                                <Box className={`${classes.cell} ${classes.textSecondaryColor}`}
                                  key={column.columnId}
                                  minWidth={column.width}
                                  maxWidth={column.maxWidth}
                                  width={"100%"}
                                  style={{paddingRight:column.label==="Title" ? 0 : "16px",
                                  paddingLeft:column.label==="Weight" ? 0 : "16px"}}
                                  >
                                  <Typography className={`${classes.textCommonColor} ${classes.fontText16}`}>
                                    {column.label}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                            {this.state.selectedSubjectCoursesGradesDetails.map((item: any) => (
                              <Box className={classes.courseItemRow} key={item.id}>
                                <Box display={"flex"}>
                                  <Box style={{paddingRight:0}} className={`${classes.courseTitleCell} ${classes.cell}`}>
                                    <Typography component="span" className={`${classes.textPrimaryColor} ${classes.fontText15}`}>
                                      {item?.title}
                                    </Typography>
                                  </Box>
                                  <Box style={{paddingLeft:0}} width={"100%"} className={`${classes.weightCell} ${classes.cell}`}>
                                    <Typography component="span"   className={`${classes.textPrimaryColor} ${classes.fontText15}`}>
                                      {item?.weight}
                                    </Typography>
                                  </Box>
                                  {Object.keys(item).map((key) => {
                                    if (key.startsWith('quarter')) {
                                      return (
                                        <Box key={key} className={`${classes.cell} ${classes.courseQuaterCell}`}>
                                          <Typography component="span" className={`${classes.textPrimaryColor} ${classes.fontText15}`}>
                                            {item[key]}
                                          </Typography>
                                        </Box>
                                      );
                                    }
                                    return null;
                                  })}
                                  <Box className={`${classes.courseTotalAvgCell} ${classes.cell}`}>
                                    <Typography component="span" className={`${classes.textPrimaryColor} ${classes.fontText15}`}>
                                      {item?.totalScore}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                </ThemeProvider>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid className={`${classes.topMargin}`} container direction="row">
              <Grid item xs={12} sm={12} md={6}>
                <Box className={`${classes.bgColor} ${classes.avgBox}`}>
                  <Typography
                    component={"span"}
                    className={`${classes.fontBold600} ${classes.textPrimaryColor} ${classes.fontText24}`}
                  >
                    Average- Current Term
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.primaryColor4} ${classes.fontText32}`}
                  >
                    {this.state.currentTermAvg}%
                  
                  </Typography>
                </Box>
              </Grid>
              <Grid className={`${classes.avgYtdGrid}`} item xs={12} sm={12} md={6}>
                <Box className={`${classes.bgColor} ${classes.avgBox}`}>
                  <Typography
                    component={"span"}
                    className={`${classes.fontBold600} ${classes.textPrimaryColor} ${classes.fontText24}`}
                  >
                    Average YTD - School Year
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.primaryColor4} ${classes.fontText32}`}
                  >
                    {this.state.totalAvgOfAllCourses}%
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const btnStyle = {
  width: "130px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
  marginBottom: "2px",
} as const;

const viewReportBtnStyle = {
  padding: "10px 10px",
  borderRadius: "10px",
  width: "200px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
  marginBottom: "2px",
} as const;

const selectDropdownStyles = {
  width: "235px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles); 
export default withStyles(combinedStyle)(StudentMyGradebook);
// Customizable Area End




