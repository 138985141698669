// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import AddNewStudentDetailsController, {
  IDDConfigProps,
  Props,
} from "./AddNewStudentDetailsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import { FIELD_TYPES } from "../../../components/src/CommonType.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomFileUploadTextFieldWeb from "../../../components/src/CustomFileUploadTextField.web";
import CustomDatepicker from "../../../components/src/CustomDatepicker.web";
import CustomStepperWeb from "../../../components/src/CustomStepper.web";
import AddNewParentGuardianDetailsStep1Web from "./AddNewParentGuardianDetailsStep1.web";
import AddNewParentGuardianDetailsStep2Web from "./AddNewParentGuardianDetailsStep2.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    studentAddUserMainBox: {
      height: "100%",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 0px 0px",
    },
    studentAddUserDDGrid: {
      marginBottom: "30px",
      padding: "20px",
      borderBottom: "4px solid #f0f0f0",
    },
    studentSelectDD: {
      marginBottom: "10px",
    },
    studentAddUserPageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    studentSelectDDWidth: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "96% !important",
      },
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    textCapital: {
      textTransform: "capitalize",
    },
  });
// Customizable Area End

// Customizable Area Start
export class AddNewStudentDetails extends AddNewStudentDetailsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      studentParentInputConfigs,
      screenName,
      steps,
      parentInputConfigs,
      parentList,
      searchParentText,
      isStepWithMultipleScreens,
    } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        id={"add-new-student-parent-user-main-box"}
        height={"100%"}
      >
        <Grid
          id={"add-new-student-parent-user-main-container"}
          container
          direction="row"
        >
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText12} ${classes.smallTopHeader}`}
                id={"add-new-student-parent-user-top-breadcrumbs"}
                component={"span"}
              >
                User Management
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textCommonColor} ${classes.textCapital}`}
              >
                {` / Students`}
              </Typography>
              <Typography
                component={"span"}
                data-testid={"add-new-student-parent-user-top-breadcrumbs"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5} ${classes.textCapital}`}
              >
                {` / Add New User`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.studentAddUserPageName} item xs={12}>
            <Box>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
                component={"span"}
                id={"add-new-student-parent-user-page-name"}
              >
                Add New User
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className={`${classes.bgColor} ${classes.studentAddUserMainBox}`}
            >
              <CustomStepperWeb
                stepperId="add-student-parent-detail-steppper"
                steps={steps}
                finishLabel="Submit"
                nextBtnLabel="Next"
                prevBtnLabel="Previous"
                showSaveAsDraft
                handleNext={this.handleNextStepper}
                handlePrevious={this.handlePreviousStepper}
                handleSaveAsDraft={() => this.handleAddUser(true)}
                handleSave={() => this.handleAddUser(false)}
                isStepWithMultipleScreens={isStepWithMultipleScreens}
                pause={this.handlePauseForStepper()}
              >
                {screenName === "student" && (
                  <Grid
                    className={`${classes.studentAddUserDDGrid}`}
                    direction="row"
                    container
                  >
                    {studentParentInputConfigs.map(
                      (ddItem: IDDConfigProps, index: number) => (
                        <Grid
                          id={`add-new-student-parent-user-input-${index + 1}`}
                          key={ddItem.id}
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={4}
                        >
                          <Box className={`${classes.studentSelectDD}`}>
                            <Box
                              data-testid={`add-new-student-parent-user-dropdown-label-${
                                index + 1
                              }`}
                              marginBottom={"10px"}
                            >
                              <Typography
                                component={"span"}
                                className={`${classes.textCommonColor} ${classes.fontText16}`}
                              >
                                {ddItem.label}
                              </Typography>
                            </Box>
                            <Box
                              data-testid={`add-new-student-parent-user-dropdown-${
                                index + 1
                              }`}
                              className={`${classes.studentSelectDDWidth}`}
                            >
                              {ddItem.fieldType === FIELD_TYPES.SELECT && (
                                <CustomSelectDropdown
                                  fieldId={ddItem.id}
                                  fieldValue={ddItem.value}
                                  fieldStyles={dropdownStyle}
                                  renderValue={(selected: any) =>
                                    this.renderDDMenuItem(
                                      selected,
                                      ddItem.fieldName,
                                      ddItem.placeHolderText
                                    )
                                  }
                                  fieldName={ddItem.name}
                                  handleChange={this.handleSelectDropdownValue}
                                  fieldMenuItems={ddItem?.menuItems || []}
                                  fieldError={false}
                                />
                              )}
                              {ddItem.fieldType === FIELD_TYPES.TEXT && (
                                <CustomTextField
                                  placeHolderText={ddItem.placeHolderText}
                                  fieldName={ddItem.name}
                                  fieldValue={ddItem.value}
                                  fieldId={ddItem.id}
                                  fieldWidth={"100%%"}
                                  textFieldStyles={textfieldStyles}
                                  fieldType="text"
                                  handleChange={this.handleChange}
                                  isDisabled={ddItem?.isDisabled}
                                />
                              )}
                              {ddItem.fieldType ===
                                FIELD_TYPES.FILE_UPLOAD_TEXT && (
                                <CustomFileUploadTextFieldWeb
                                  placeHolderText={`Select File`}
                                  fieldId={ddItem.id}
                                  fieldName={ddItem.name}
                                  fieldValue={ddItem.value.fileName}
                                  fieldWidth={"100%%"}
                                  fieldType="text"
                                  fileType="image"
                                  textFieldStyles={textfieldStyles}
                                  uploadText="Upload"
                                  removeText="Remove"
                                  handleFile={(file: any) =>
                                    this.handleUploadFile(
                                      file,
                                      ddItem.fieldName,
                                      ddItem.id
                                    )
                                  }
                                />
                              )}
                              {ddItem.fieldType === FIELD_TYPES.DATE && (
                                <CustomDatepicker
                                  disableFutureDate={ddItem.name == "dob" ? true : false}
                                  disablePastDate={ddItem.name == "studentDetailExpiringDate" ? true : false}
                                  placeHolderText={"DD/MM/YYYY"}
                                  format="DD/MM/YYYY"
                                  fieldError={false}
                                  isDisabled={false}
                                  fieldId={ddItem.id}
                                  selectedDate={ddItem.value}
                                  handleChangeDate={(date: any) =>
                                    this.handleDateChange(date, ddItem.name)
                                  }
                                />
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      )
                    )}
                  </Grid>
                )}
                {screenName === "parent" && (
                  <AddNewParentGuardianDetailsStep1Web
                    id="setubnit"
                    parentList={parentList}
                    handleChangeSearchParent={this.handleChangeSearchParent}
                    handleSelectParent={this.handleSelectParentFromList}
                    searchParentText={searchParentText}
                    handleSearchParent={this.handleSearchParent}
                    handleGotoStep2={this.handleNextStepper}
                    handleLoadMore={this.handleLoadMore}
                    isItemsPresent={this.state.isItemsPresent}
                  />
                )}
                {screenName === "parent_step_2" && (
                  <AddNewParentGuardianDetailsStep2Web
                    parentInputConfigs={parentInputConfigs}
                    data-test-id="addNewParent"
                    handleChange={this.handleChange}
                    handleMarkAsParentTeacher={this.handleMarkAsParentTeacher}
                    handleSelectDropdownValue={this.handleSelectDropdownValue1}
                    handleUploadFile={this.handleUploadFile}
                    renderDDMenuItem={this.renderDDMenuItem}
                  />
                )}
              </CustomStepperWeb>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const dropdownStyle = {
  width: "100%",
} as const;

const textfieldStyles = {
  width: "100%",
  padding: "8px 0px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddNewStudentDetails);
// Customizable Area End
