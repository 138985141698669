// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  studentProfile,
  parent1,
  parent2 as student2,
  englishSubjectIcon,
  historySubjectIcon,
  mathSubjectIcon,
  physicsSubjectIcon,
} from "./assets";

// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}

export interface IUserDetailProps {
  id: any;
  emailId: string;
  status: string;
  profilePic?: any;
  customField1: any;
  customField2: any;
  customField3: any;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  teacherFullName: string;
  teacherRole: string;
  parentTeacherDetails: IUserDetailProps;
  subjectHandledList: Array<any>;
  curriculumList: Array<any>;
  gradeLevelList: Array<any>;
  coursesList: Array<any>;
  assignedClassesList: Array<any>;
  assignedStudentsList: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ViewParentTeacherProfileDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      teacherFullName: "",
      parentTeacherDetails: {
        id: 1,
        emailId: "johndoe123@gmail.com",
        profilePic: parent1,
        status: "active",
        customField1: "Value 1",
        customField3: "Value 3",
        customField2: "Value 2",
      },
      subjectHandledList: [
        {
          subjectIcon: physicsSubjectIcon,
          id: 1,
          subjectName: "Science",
          subjectBgColor: "#01C996",
        },
        {
          subjectName: "Mathematics",
          subjectIcon: mathSubjectIcon,
          id: 2,
          subjectBgColor: "#0D46BC",
        },
        {
          subjectName: "English",
          id: 3,
          subjectIcon: englishSubjectIcon,
          subjectBgColor: "#019AFC",
        },
        {
          subjectName: "History",
          id: 4,
          subjectIcon: historySubjectIcon,
          subjectBgColor: "#FBD82C",
        },
      ],
      curriculumList: [
        {
          id: 1,
          name: "Curriculum 1",
        },
        {
          id: 2,
          name: "Curriculum 2",
        },
        {
          id: 3,
          name: "Curriculum 3",
        },
        {
          id: 4,
          name: "Curriculum 4",
        },
        {
          id: 5,
          name: "Curriculum 5",
        },
        {
          id: 6,
          name: "Curriculum 6",
        },
      ],
      gradeLevelList: [
        {
          id: 1,
          name: "Grade 6",
        },
        {
          id: 2,
          name: "Grade 7",
        },
        {
          id: 3,
          name: "Grade 9",
        },
      ],
      coursesList: [
        {
          subjectIcon: physicsSubjectIcon,
          courseId: 1,
          subjectName: "Physics",
          subjectBgColor: "#01C996",
        },
        {
          subjectIcon: physicsSubjectIcon,
          courseId: 2,
          subjectName: "Chemistry",
          subjectBgColor: "#01C996",
        },
        {
          subjectIcon: physicsSubjectIcon,
          courseId: 3,
          subjectName: "Biology",
          subjectBgColor: "#01C996",
        },
        {
          subjectName: "Algebra",
          subjectIcon: mathSubjectIcon,
          courseId: 4,
          subjectBgColor: "#0D46BC",
        },
        {
          subjectName: "Calculus",
          subjectIcon: mathSubjectIcon,
          courseId: 5,
          subjectBgColor: "#0D46BC",
        },
        {
          subjectName: "English",
          courseId: 6,
          subjectIcon: englishSubjectIcon,
          subjectBgColor: "#019AFC",
        },
        {
          subjectName: "English Poetry",
          courseId: 7,
          subjectIcon: englishSubjectIcon,
          subjectBgColor: "#019AFC",
        },
        {
          subjectName: "Roman History",
          courseId: 8,
          subjectIcon: historySubjectIcon,
          subjectBgColor: "#FBD82C",
        },
        {
          subjectName: "Indian History",
          courseId: 9,
          subjectIcon: historySubjectIcon,
          subjectBgColor: "#FBD82C",
        },
      ],
      assignedClassesList: [
        {
          id: 1,
          name: "Class 6A",
        },
        {
          id: 2,
          name: "Class 6C",
        },
        {
          id: 3,
          name: "Class 7A",
        },
        {
          id: 4,
          name: "Class 7B",
        },
        {
          id: 5,
          name: "Class 9A",
        },
        {
          id: 6,
          name: "Class 9C",
        },
      ],
      assignedStudentsList: [
        {
          studentId: 1,
          fullName: "Ruby Cameron",
          profilePic: studentProfile,
        },
        {
          studentId: 2,
          fullName: "John Cameron",
          profilePic: student2,
        },
      ],
      teacherRole: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetParentTeacherDetails();
    // Customizable Area End
  }

  // Customizable Area Start

  handleSetParentTeacherDetails = () => {
    const role = this.props.navigation?.getParam("role");
    const nameFromParam = this.props.navigation?.getParam("fullName");
    this.setState({ teacherFullName: nameFromParam, teacherRole: role });
  };

  // Customizable Area End
}
// Customizable Area End
