// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import DeleteRolesPermissionsModalController, {
  Props,
} from "./DeleteRolesPermissionsModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { deleteIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "520px",
        borderRadius: "10px"
      },
      paperWidthSm: {
        maxWidth: "520px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class DeleteRolesPermissionsModal extends DeleteRolesPermissionsModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, role } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          data-test-id="deleteRolePermission"
          className={`${classes.highlightBgColor}`}
          open={open}
          aria-labelledby="delete-role-permission-confirm-dialog-title"
        >
          <Box padding={"30px"} className={`${classes.bgColor}`}>
            <Box display={"flex"} justifyContent={"center"} id="delete-id-copy">
              <Box display={"flex"} justifyContent={"center"} width={"94%"}>
                <img src={deleteIcon} alt="delete icon" />
              </Box>
              <Box width={"6%"}>
                <IconButton
                  data-test-id="deleteCloseIcon"
                  aria-label="more"
                  style={{ float: "right" }}
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent style={{ padding: "0px" }}>
              <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
                <Typography
                  className={`${classes.textPrimaryColor} ${
                    classes.fontText22
                  } ${classes.fontBold600}`}
                  data-test-id="delete-role-permission-main-confirm-text"
                >
                  {`Sure want to delete ${role} role?`}
                </Typography>
              </Box>
              <Box mb={"30px"} textAlign={"center"} mt={"10px"}>
                <Typography
                  className={`${classes.textCommonColor} ${
                    classes.fontText18
                  } ${classes.fontBold500}`}
                  component={"span"}
                  data-test-id="delete-role-permission-info-text-1"
                >
                  {`Are you sure want to delete ${role} role`}
                </Typography>
                <Box>
                  <Typography
                    className={`${classes.textCommonColor} ${
                      classes.fontText18
                    } ${classes.fontBold500}`}
                    component={"span"}
                    data-test-id="delete-role-permission-info-text-2"
                  >
                    {" "}
                    from Roles and Permissions?
                  </Typography>
                </Box>
              </Box>
              <Box
                justifyContent={"space-between"}
                display={"flex"}
                alignItems={"center"}
                data-test-id="delete-role-permission-btn-box"
              >
                <CustomButton
                  btnStyle={copyCourseModalBtn}
                  btnText="Cancel"
                  buttonId="delete-role-permission-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.handleClose}
                />
                <CustomButton
                  btnText="Delete"
                  btnStyle={deleteBtnStyle}
                  buttonId={`delete-role-permission-confirm-btn`}
                  buttonVariant={"contained"}
                  type={"button"}
                  handleButtonClick={this.props.handleDelete}
                  isDisabled={false}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const copyCourseModalBtn = {
  width: "215px",
  padding: "5px",
  borderRadius: "10px",
  height: "50px",
  fontWeight: 600,
  fontSize: "16px",
  marginRight: "2px"
} as const;

const deleteBtnStyle = {
  width: "215px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
  color: "#ffffff",
  backgroundColor: "#ED1111",
  marginLeft: "2px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(DeleteRolesPermissionsModal);
// Customizable Area End
