// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentCompletedQuizMainController, {
  Props,
} from "./StudentCompletedQuizMainController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import AssessmentTopPagination from "./AssessmentTopPagination.web";
import StudentCompletedQuiz from "./StudentCompletedQuiz.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    quizQuestionPageNum: {
      padding: "22px 25px",
      borderRadius: "12px",
      overflow: "auto",
    },
    leftMargin: {
      marginLeft: "1%",
    },
    topMargin: {
      marginTop: "4%",
    },
    dueAssessmentQuizMainGrid: {
      width: "98%",
    },
    capitalText: {
      textTransform: "capitalize",
    },
  });

// Customizable Area End

// Customizable Area Start
export class StudentCompletedQuizMain extends StudentCompletedQuizMainController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { category, assessmentTitle, selectedQuizAssessmentDetails } =
      this.state;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid
          className={classes.dueAssessmentQuizMainGrid}
          container
          direction="row"
        >
          <Grid item xs={12}>
            <Box marginTop={"2%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                My Assessments
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.capitalText} ${classes.textCommonColor}`}
              >
                {` / ${category}`}
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.capitalText} ${classes.textCommonColor}`}
              >
                {` / Quiz`}
              </Typography>
              <Typography
                className={`${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5} `}
                component={"span"}
              >
                {` / ${assessmentTitle}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box
              onClick={this.handleGoBackToGradeStatistics}
              className={`${classes.cursorPointer}`}
              display={"flex"}
              alignItems={"center"}
            >
              <Box mr={"8px"}>
                <KeyboardBackspaceIcon
                  fontSize="large"
                  className={`${classes.textPrimaryColor}`}
                />
              </Box>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText32} ${classes.fontBold600}`}
                component={"span"}
              >
                {assessmentTitle}
              </Typography>
            </Box>
          </Grid>
          {/* Paginations */}
          <Grid item xs={12}>
            <AssessmentTopPagination
              totalQuestionNumber={selectedQuizAssessmentDetails?.data?.length}
              isCurrentQuestionAttempted={(index: number) =>
                this.checkIfQuestionAttempted(index)
              }
            />
          </Grid>
          {/* Questions List */}
          <Grid className={classes.topMargin} item xs={12}>
            <Box borderRadius={"12px"} className={`${classes.bgColor}`}>
              <Box className={`${classes.quizQuestionPageNum}`}>
                {selectedQuizAssessmentDetails?.data?.map(
                  (quizQuestion: any, index: number) => (
                    <React.Fragment key={quizQuestion?.id}>
                      <StudentCompletedQuiz
                        completedQuestion={quizQuestion}
                        index={index}
                      />
                    </React.Fragment>
                  )
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const submitbtnStyle = {
  width: "150px",
  padding: "5px 20px",
  backgroundColor: "#0D46BC",
  color: "#ffffff",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentCompletedQuizMain);
// Customizable Area End
