// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  myPathItemList: any;
  myPathCourseCategory: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  dueDateSortingList: Array<IDropdownItems>;
  selectedSortByDueDateDDValue: any;
  completionSortingDateList: Array<IDropdownItems>;
  selectedSortByCompletionDateDDValue: any;
  gradingComponent: Array<any>;
  gradingComponentDDMenuItems: Array<IDropdownItems>;
  selectedGradingComponentDDValue: any;
  selectedSortByGradeDDValue: any;
  selectedDateFilterText: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class MyPathOverdueController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      dueDateSortingList: [
        { id: "asc", value: "asc", label: "Ascending" },
        { id: "desc", value: "desc", label: "Descending" },
      ],
      selectedSortByDueDateDDValue: "",
      completionSortingDateList: [
        { id: "asc", value: "asc", label: "Ascending" },
        { id: "desc", value: "desc", label: "Descending" },
      ],
      selectedSortByCompletionDateDDValue: "",
      gradingComponent: [
        {
          id: 1,
          label: "All",
          value: 1,
        },
        {
          id: 2,
          label: "Quiz",
          value: 2,
        },
        {
          id: 3,
          label: "Project",
          value: 3,
        },
        {
          id: 4,
          label: "Exam",
          value: 4,
        },
        {
          id: 5,
          label: "Assignment",
          value: 5,
        },
      ],
      gradingComponentDDMenuItems: [],
      selectedGradingComponentDDValue: "",
      selectedSortByGradeDDValue: "",
      selectedDateFilterText: "tomorrow",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  renderGradingComponent = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponent = gradingComponent.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponent?.label;
  };

  renderDueDateComponent = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { dueDateSortingList } = this.state;
    const selectedSortByDueDateDDValue = dueDateSortingList.find(
      (dueDate: any) => dueDate?.id === selected
    );
    return selectedSortByDueDateDDValue?.label;
  };

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  handleChangeDateFilter = (filterText: string) => {
    this.setState({ selectedDateFilterText: filterText });
  };

  handleSearchMyPathCourses = () => {
    console.log("clicked my path search!");
  };

  // Customizable Area End
}
// Customizable Area End
