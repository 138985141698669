// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentGradedAssignmentStatisticsController, {
  Props,
} from "./StudentGradedAssignmentStatisticsController.web";
import CustomDoughnutChart from "../../../components/src/CustomDoughnutChart.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { imgTimeSpent, statisticsIcon } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";
import BookIcon from "../../../components/src/SVGIcons/BookIcon.web";
import QuizViewAnswerConfirmDialog from "./QuizViewAnswerConfirmDialog.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    statisticsMainBox: {
      display: "flex",
      marginBottom: "3%",
      padding: "22px 25px 0px 25px",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        padding: "10px 0px",
      },
    },
    quizStatisticsMainBox: {
      padding: "10px 15px",
      borderRadius: "12px",
    },
    quizStatisticsGraphBox: {
      width: "90%",
      position: "relative",
      overflowX: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > canvas": {
        height: "381px !important",
        width: "435px !important",
      },
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        "& > canvas": {
          width: "415px !important",
        },
      },
      [theme.breakpoints.down("xs")]: {
        width: "97%",
        "& > canvas": {
          height: "auto !important",
          width: "100% !important",
        },
      },
    },
    leftMargin: {
      marginLeft: "15px",
    },
    statisticsLogo: {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px",
      backgroundColor: "#FDD72F",
    },
    remark: {
      backgroundColor: "#0c85d017",
      borderRadius: "10px",
      padding: "10px 8px",
      display: "flex",
      marginTop: "5px",
      flexDirection: "column",
    },
    quizTimeSpentMainBox: {
      padding: "15px",
      borderRadius: "10px",
      width: "80%",
      marginBottom: "15px",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    quizTimeSpentIconBg: {
      height: "60px",
      width: "60px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    quizTimeSpentBgBox: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: "50%",
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      backgroundColor: "#019afc",
      opacity: 0.06,
    },
    quizTimeSpentTextBgBox: {
      position: "relative",
      height: "58px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "5px",
    },
    quizTimeBox: {
      width: "80px",
      textAlign: "center",
      padding: "12px 10px",
      background: "#ffffff",
      boxShadow: "1px 1px 1px 1px #ccc",
    },
    quizRemainingAttemptsBox: {
      width: "55px",
      textAlign: "center",
      padding: "8px",
      background: "#ffffff",
      boxShadow: "1px 1px 1px 1px #ccc",
    },
    timeSpentDivider: {
      marginLeft: "8px",
      marginRight: "8px",
    },
    topMargin: {
      [theme.breakpoints.down("md")]: {
        marginTop: "15px",
      },
    },
    quizAveragePercentageBox: {
      position: "absolute",
      top: "20px",
    },
    statisticsContainer: {
      [theme.breakpoints.down("xs")]: {
        padding: "0px 8px",
      },
    },
    bookIcon: {
      width: "34px",
      height: "34px",
    },
  });

// Customizable Area End

// Customizable Area Start
export class StudentGradedAssignmentStatistics extends StudentGradedAssignmentStatisticsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, typeOfAssessment } = this.props;
    const { quizStatistics, openQuizViewAnswerPopup } = this.state;

    return (
      <>
        <Box className={classes.statisticsMainBox}>
          <Box>
            <Typography
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
            >
              Analysis
            </Typography>
          </Box>
          <Grid
            className={classes.statisticsContainer}
            container
            direction="row"
          >
            {/* Statistics Graph */}
            <Grid item xs={12} lg={7}>
              <Box
                className={`${classes.quizStatisticsMainBox} ${classes.secondaryBgColor}`}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box display={"flex"} alignItems={"center"} mr={"10px"}>
                    <Box className={classes.statisticsLogo}>
                      <img src={statisticsIcon} alt="Analysis" />
                    </Box>
                    <Typography
                      component={"span"}
                      className={`${classes.textPrimaryColor} ${classes.fontText22} ${classes.fontBold600} ${classes.leftMargin}`}
                    >
                      Grade
                    </Typography>
                  </Box>
                  <CustomButton
                    btnText="View Correct Answer"
                    btnStyle={viewAnswerBtn}
                    buttonId="graded-quiz-view-answer"
                    buttonVariant={"outlined"}
                    handleButtonClick={this.handleOpenQuizViewAnswerPopup}
                    isDisabled={false}
                    type={"button"}
                  />
                </Box>
                <Box className={classes.quizStatisticsGraphBox}>
                  {typeOfAssessment === "quiz" ? (
                    <CustomDoughnutChart
                      labels={["Correct", "Incorrect", "Missed"]}
                      graphData={[
                        quizStatistics?.statistics?.correct ?? 0,
                        quizStatistics?.statistics?.incorrect ?? 0,
                        quizStatistics?.statistics?.missed ?? 0,
                      ]}
                      chartColors={["#01C996", "#FF0000", "#0947BF"]}
                    />
                  ) : (
                    <CustomDoughnutChart
                      labels={["Answered", "Unanswered"]}
                      graphData={[85, 15]}
                      chartColors={["#01C996", "#FF0000"]}
                    />
                  )}
                </Box>
                {typeOfAssessment === "quiz" && (
                  <Box className={classes.remark}>
                    <Typography
                      component={"span"}
                      className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600} ${classes.leftMargin}`}
                    >
                      Remarks
                    </Typography>
                    <Typography
                      component={"span"}
                      className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.leftMargin}`}
                    >
                      {quizStatistics?.remark}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid className={`${classes.topMargin}`} item xs={12} lg={5}>
              <Grid container direction="row">
                {/* Time Spent */}
                <Grid
                  className={`${classes.flexDisplay} ${classes.alignCenter} ${classes.justifyCenter}`}
                  item
                  xs={12}
                  md={6}
                  lg={12}
                >
                  <Box
                    display={"flex"}
                    alignItems="center"
                    flexDirection={"column"}
                    height={"215px"}
                    className={`${classes.secondaryBgColor} ${classes.quizTimeSpentMainBox}`}
                  >
                    <Box marginBottom={"10px"}>
                      <Box
                        bgcolor={"#0D47BE"}
                        className={classes.quizTimeSpentIconBg}
                      >
                        <img src={imgTimeSpent} alt="Quiz Time Spent" />
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}
                        component={"span"}
                      >
                        Time Spent on Quiz
                      </Typography>
                    </Box>
                    <Box
                      mt={"25px"}
                      width={"100%"}
                      display={"flex"}
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      <Box>
                        <Box className={classes.quizTimeSpentTextBgBox}>
                          <Box className={classes.quizTimeSpentBgBox}></Box>
                          <Box
                            className={classes.quizTimeBox}
                            padding="2px"
                            borderRadius={"8px"}
                          >
                            <Typography
                              className={`${classes.textSecondaryColor} ${classes.fontBold600} ${classes.fontText26}`}
                              component="p"
                            >
                              15
                            </Typography>
                          </Box>
                        </Box>
                        <Box textAlign={"center"}>
                          <Typography
                            className={`${classes.textCommonColor} ${classes.fontText12}`}
                            component="p"
                          >
                            Minutes
                          </Typography>
                        </Box>
                      </Box>
                      <Box marginBottom={"18px"}>
                        <Typography
                          className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText18} ${classes.timeSpentDivider}`}
                          component="p"
                        >
                          :
                        </Typography>
                      </Box>
                      <Box>
                        <Box className={classes.quizTimeSpentTextBgBox}>
                          <Box className={classes.quizTimeSpentBgBox}></Box>
                          <Box
                            className={classes.quizTimeBox}
                            padding="2px"
                            borderRadius={"8px"}
                          >
                            <Typography
                              className={`${classes.textSecondaryColor} ${classes.fontBold600} ${classes.fontText26}`}
                              component="p"
                            >
                              26
                            </Typography>
                          </Box>
                        </Box>
                        <Box textAlign={"center"}>
                          <Typography
                            className={`${classes.textCommonColor} ${classes.fontText12}`}
                            component="p"
                          >
                            Seconds
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                {/* Remaining Attempts */}
                <Grid
                  className={`${classes.flexDisplay} ${classes.alignCenter} ${classes.justifyCenter}`}
                  item
                  xs={12}
                  md={6}
                  lg={12}
                >
                  <Box
                    display={"flex"}
                    alignItems="center"
                    flexDirection={"column"}
                    height={"240px"}
                    className={`${classes.secondaryBgColor} ${classes.quizTimeSpentMainBox}`}
                  >
                    <Box marginBottom={"12px"}>
                      <Box
                        bgcolor={"#01C996"}
                        className={classes.quizTimeSpentIconBg}
                      >
                        <BookIcon
                          className={classes.bookIcon}
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          stroke={"#ffffff"}
                          strokeWidth="2.0"
                          fill={"none"}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText24}`}
                        component={"span"}
                      >
                        Remaining Attempts
                      </Typography>
                    </Box>
                    <Box
                      mt={"6px"}
                      width={"100%"}
                      display={"flex"}
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      <Box>
                        <Box className={classes.quizTimeSpentTextBgBox}>
                          <Box
                            className={classes.quizRemainingAttemptsBox}
                            padding="2px"
                            borderRadius={"8px"}
                          >
                            <Typography
                              className={`${classes.textSecondaryColor} ${classes.fontBold600} ${classes.fontText26}`}
                              component="p"
                            >
                              4
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box marginTop={"6px"}>
                      <CustomButton
                        btnText="Re-Attempt"
                        btnStyle={reAttemptBtn}
                        buttonId="graded-quiz-re-attempt"
                        buttonVariant={"contained"}
                        handleButtonClick={this.handleReAttempt}
                        isDisabled={false}
                        type={"button"}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <QuizViewAnswerConfirmDialog
          open={openQuizViewAnswerPopup}
          onCancel={this.handleCloseQuizViewAnswerPopup}
          onConfirm={this.handleConfirmViewAnswer}
          isFromAssessment={typeOfAssessment !== "quiz"}
        />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const viewAnswerBtn = {
  borderRadius: "8px",
  padding: "10px 12px",
  fontSize: "14px",
  fontWeight: 600,
  cursor: "pointer",
} as const;

const reAttemptBtn = {
  borderRadius: "8px",
  padding: "10px 12px",
  width: "150px",
  fontSize: "14px",
  fontWeight: 600,
  cursor: "pointer",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentGradedAssignmentStatistics);
// Customizable Area End
