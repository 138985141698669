// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ClearIcon from "@material-ui/icons/Clear";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentDueAssessmentMainController, {
  Props,
} from "./StudentDueAssessmentMainController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import AssessmentSubmitConfirmDialog from "./AssessmentSubmitConfirmDialog.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { StudentDueQuiz } from "./StudentDueQuiz.web";
import AssessmentTopPagination from "./AssessmentTopPagination.web";
import StudentDueAssignment from "./StudentDueAssignment.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    questionPageNum: {
      padding: "22px 0px",
      borderRadius: "12px",
      overflow: "auto",
    },
    topMargin: {
      marginTop: "25px",
    },
    leftMargin: {
      marginLeft: "1%",
    },
    optionGrid: {
      display: "flex",
      alignItems: "center",
    },
    dueAssessmentMainGrid: {
      width: "98%",
    },
    studentDueAssignmentBox: {
      position: "relative",
      paddingBottom: "30px",
      borderBottom: "1px solid #f1f1f1",
    },
    attachmentListBox: {
      display: "flex",
      gap: "8px",
      position: "absolute",
      bottom: "8%",
      margin: "0px 37px",
      maxWidth: "75%",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        width: "7px",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "45%",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "17%",
      },
    },
    attachmentItemBox: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px",
      border: "1px solid #888888",
      borderRadius: "10px",
      width: "155px",
      minWidth: "155px",
      zIndex: 1,
    },
    wordLimitText: {
      color: "red",
      padding: "0px 25px"
    }
  });

// Customizable Area End

// Customizable Area Start
export class StudentDueAssessmentMain extends StudentDueAssessmentMainController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      openAssessmentSubmitConfirmDialog,
      questionsList,
      typeOfAssessment,
      assessmentTitle,
      evaluationType,
      objectiveType,
    } = this.state;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid
          className={classes.dueAssessmentMainGrid}
          container
          direction="row"
        >
          <Grid item xs={12}>
            <Box marginTop={"2%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
                id="due-assessment"
              >
                My Assessments
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.textCommonColor}`}
              >
                {` / Due`}
              </Typography>
              <Typography
                className={`${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5} `}
                component={"span"}
                id="due-assessment-title"
              >
                {` / ${assessmentTitle}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box
              className={`${classes.cursorPointer}`}
              display={"flex"}
              onClick={this.handleGoBack}
              alignItems={"center"}
            >
              <Box mr={"8px"}>
                <KeyboardBackspaceIcon
                  fontSize="large"
                  className={`${classes.textPrimaryColor}`}
                  data-testid="due-assessment-back-icon"
                />
              </Box>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText32} ${classes.fontBold600}`}
                component={"span"}
                id="due-assessment-title-header"
              >
                {assessmentTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <AssessmentTopPagination
              totalQuestionNumber={questionsList?.length}
              isCurrentQuestionAttempted={(item: number) =>
                this.handleCheckAllQuestionsAttempted(item)
              }
            />
          </Grid>
          {/* Questions List */}
          <Grid className={classes.topMargin} item xs={12}>
            <Box borderRadius={"12px"} className={`${classes.bgColor}`}>
              <Box className={`${classes.questionPageNum}`}>
                {(typeOfAssessment === "quiz" &&
                  evaluationType === "objective") ||
                (typeOfAssessment === "exam" &&
                  evaluationType === "objective" &&
                  objectiveType !== "written") ||
                (typeOfAssessment === "assignment" &&
                  evaluationType === "objective" &&
                  objectiveType !== "written")
                  ? questionsList?.map((question: any, index: number) => (
                      <React.Fragment key={question?.id}>
                        <StudentDueQuiz
                          classes={classes}
                          question={question}
                          index={index}
                          handleSelectAnswer={(answerId: any) =>
                            this.handleSelectAnswer(question?.id, answerId)
                          }
                        />
                      </React.Fragment>
                    ))
                  : questionsList?.map(
                      (currentQuestion: any, indexNumber: number) => (
                        <React.Fragment key={currentQuestion?.id}>
                          <Box className={classes.studentDueAssignmentBox}>
                            <StudentDueAssignment
                              question={currentQuestion}
                              index={indexNumber}
                              dataTestId={`student-due-assignment-${
                                indexNumber + 1
                              }`}
                              handleUpdateAnswer={(textAnswer: any) =>
                                this.handleUpdateTextAnswer(
                                  currentQuestion?.id,
                                  textAnswer
                                )
                              }
                              handleUpdateAnswerAttachment={(
                                attachments: any
                              ) =>
                                this.handleUpdateAnswerAttachment(
                                  currentQuestion?.id,
                                  attachments
                                )
                              }
                            />
                            <Box className={classes.attachmentListBox}>
                              {currentQuestion?.attachments?.map(
                                (attachment: any, indexTwo: number) => (
                                  <Box
                                    key={`Attachment-${attachment?.fileSrc}`}
                                    className={`${classes.bgColor} ${classes.attachmentItemBox}`}
                                  >
                                    <Typography
                                      data-testid={`assessment-answer-attachment-${
                                        currentQuestion?.id
                                      }-answer-${indexTwo + 1}`}
                                      onClick={() =>
                                        this.handleOpenAttachmentLink(
                                          attachment?.fileSrc
                                        )
                                      }
                                      className={`${classes.textCommonColor} ${classes.fontText16} ${classes.cursorPointer}`}
                                      component={"span"}
                                    >
                                      {`Attachment ${indexTwo + 1}`}
                                    </Typography>
                                    <ClearIcon
                                      data-testid={`assessment-answer-attachment-remove-${
                                        currentQuestion?.id
                                      }-answer-${indexTwo + 1}`}
                                      onClick={() =>
                                        this.handleRemoveAnswerAttachment(
                                          currentQuestion?.id,
                                          indexTwo
                                        )
                                      }
                                      className={`${classes.textCommonColor} ${classes.cursorPointer}`}
                                    />
                                  </Box>
                                )
                              )}
                            </Box>
                            {this.handleCheckWordCountLimit(
                              currentQuestion?.id
                            ) && (
                              <Box>
                                <Typography
                                  component={"span"}
                                  className={`${classes.fontText12} ${classes.wordLimitText}`}
                                >{`Word limit exceeded.Word limit for this question is ${currentQuestion?.wordLimit}`}</Typography>
                              </Box>
                            )}
                          </Box>
                        </React.Fragment>
                      )
                    )}
              </Box>
              {/* Submit Button */}
              <Box
                marginTop={"1%"}
                marginBottom={"2%"}
                padding={"2% 3%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <CustomButton
                  btnStyle={submitbtnStyle}
                  btnText="Submit"
                  buttonId="assessment-due-quiz-submit"
                  buttonVariant={"contained"}
                  type={"button"}
                  isDisabled={this.handleCheckAllAnswersWithinWordLimit()}
                  handleButtonClick={() =>
                    this.handleOpenAssessmentSubmitDialog()
                  }
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <AssessmentSubmitConfirmDialog
          open={openAssessmentSubmitConfirmDialog}
          confirmationText="Are you sure you want to submit ?"
          diaglogTitle="Submit Quiz"
          onCancel={() => this.handleCloseAssessmentSubmitDialog()}
          onConfirm={() => this.handleSubmitAnswers()}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const submitbtnStyle = {
  width: "150px",
  padding: "5px 20px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentDueAssessmentMain);
// Customizable Area End
