// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import {
  FIELD_TYPES,
  TOASTER_NOTIFICATION_MSG,
} from "../../../components/src/CommonType.web";
import moment from "moment";
import { configJSON } from "./UserManagementTabComponentController.web";
import {
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}

export interface IDDConfigProps {
  id: any;
  name: string;
  fieldName: string;
  value: any;
  menuItems?: any;
  label: string;
  fieldType: string;
  customField?: boolean;
  customLabel?: string;
  customLabel2?: string;
  isDisabled?: boolean;
  placeHolderText: string;
}

export interface IStepperStepProps {
  id: any;
  label: string;
  optional?: boolean;
}

export interface IParentInputConfigProps {
  find?: any;
  id: any;
  label: string;
  role: string;
  ddConfigs: Array<IDDConfigProps>;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  curriculumList: Array<any>;
  screenName: string;
  curriculum: any;
  curriculumDDMenuItems: Array<IDropdownItems>;
  parentList: Array<any>;
  relationship: any;
  relationshipDDMenuItems: Array<IDropdownItems>;
  gradeLevelDDMenuItems: Array<IDropdownItems>;
  schoolYearTitle: any;
  schoolYearDDMenuItems: Array<IDropdownItems>;
  gradeLevelList: Array<any>;
  gradeLevel: any;
  subjectHandledList: Array<any>;
  subjectHandled: any;
  subjectHandledDDMenuItems: Array<IDropdownItems>;
  studentParentInputConfigs: Array<IDDConfigProps>;
  parentInputConfigs: Array<IParentInputConfigProps>;
  steps: Array<IStepperStepProps>;
  searchParentText: string;
  isStepWithMultipleScreens?: boolean;
  userRole: string;
  token: string;
  currentPage: number;
  isItemsPresent: boolean;
  parentOneCheckBox: boolean;
  parentSecCheckBox: boolean;
  customfeildOne: string;
  customfeildTwo: string;
  customfeildThree: string;
  curriculumValue: any;
  gradeLevelValue: any;
  schoolYearValue: any;
  expiringDateValue: any;
  isParentRoleId: any;
  isParentUserId: any;
  userRoleList: any;
  isParentRole: any;
  schoolYearText: any;
  gradeLevelText: any;
  curriculumText: any;
  gradeIdvalue: any;
  isDraftOn: boolean;
  isFromLoadMore: boolean;
  customFieldsList: Array<IDDConfigProps>;
  parentCustomFieldsList: Array<IDDConfigProps>;
  parent2CustomFieldsList: Array<IDDConfigProps>;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddNewUserDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addNewUserAPIRequestId: string = "";
  curriculumListAPIRequestId: string = "";
  apiGetGradesListingId: string = "";
  apiGetSchoolYearDataId: string = "";
  searchParentListAPIRequestId: string = "";
  apiGetRoleNamesDataId: string = "";
  apiGetStudentCustomFieldsId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      curriculumDDMenuItems: [],
      curriculumList: [],
      parentList: [],
      relationship: "",
      relationshipDDMenuItems: [
        { id: "father", label: "Father", value: "father" },
        { id: "mother", label: "Mother", value: "mother" },
      ],
      curriculum: "",
      schoolYearTitle: "",
      schoolYearDDMenuItems: [
        { id: 1, label: "2022-2023", value: 1 },
        { id: 2, label: "2021-2022", value: 2 },
      ],
      gradeLevelDDMenuItems: [
        { id: 1, label: "Grade 1", value: 1 },
        { id: 2, label: "Grade 2", value: 2 },
      ],
      gradeLevelList: [],
      gradeLevel: "",
      steps: [
        {
          id: "add-student-details",
          label: "Student Details",
        },
        {
          id: "add-parent-details",
          label: "Parent/Guardians Details",
        },
      ],
      subjectHandledDDMenuItems: [
        { id: 1, label: "Mathematics", value: 1 },
        { id: 2, label: "Physics", value: 2 },
        { id: 3, label: "Biology", value: 3 },
      ],
      subjectHandledList: [],
      subjectHandled: "",
      studentParentInputConfigs: [],
      parentInputConfigs: [],
      screenName: "student",
      searchParentText: "",
      isStepWithMultipleScreens: false,
      userRole: "",
      token: "",
      currentPage: 1,
      isItemsPresent: false,
      parentOneCheckBox: false,
      parentSecCheckBox: false,
      customfeildOne: "",
      customfeildTwo: "",
      customfeildThree: "",
      curriculumValue: "",
      gradeLevelValue: "",
      schoolYearValue: "",
      expiringDateValue: "",
      isParentRoleId: "",
      isParentUserId: "",
      userRoleList: [],
      isParentRole: "",
      schoolYearText: "",
      gradeLevelText: "",
      curriculumText: "",
      gradeIdvalue: "",
      isDraftOn: false,
      isFromLoadMore: false,
      customFieldsList: [],
      parentCustomFieldsList: [],
      parent2CustomFieldsList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    const token = await getStorageData("token");
    if (token) {
      this.setState({ token }, () => {
        this.handleSetUserRole();
        this.handleGetStudentsCustomFieldsList();
        this.getGradesLevel();
        this.GetSchoolYearList();
        this.getSearchParentList();
        this.handleGetRoleNamesList();
      });
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleStudentsCustomFieldsListResponse = (response: any) => {
    if (response) {
      if (response.data) {
        const studentCustomFields: Array<IDDConfigProps> = response.data.map(
          (item: any, index: number) => {
            return {
              fieldName: `${item?.attributes?.label}-${item?.attributes?.id}`,
              id: item?.attributes?.id,
              fieldType: "text",
              label: item?.attributes?.label,
              name: `${item?.attributes?.label}-${item?.attributes?.id}`,
              value: "",
              placeHolderText: "Enter Value",
              customField: true,
            };
          }
        );
        const parentCFList: Array<IDDConfigProps> = studentCustomFields.map(
          (item) => {
            return {
              ...item,
              isDisabled: true,
            };
          }
        );

        this.setState(
          {
            customFieldsList: [ ...studentCustomFields],
            parentCustomFieldsList: [ ...parentCFList],
            parent2CustomFieldsList: [ ...parentCFList]
          },
          () => {
            this.handleSetDDConfig();
          }
        );
      }
    }
    hideCustomLoader();
  };

  handleGetStudentsCustomFieldsList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentCustomFieldsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customFieldEndpoint}?model_type=User_management`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  setDefaultDataMethod = () => {
    this.state.studentParentInputConfigs.forEach((element) => {
      switch (element.name) {
        case "customField1":
          this.setState({
            customfeildOne: element.value,
          });
          break;

        case "customField2":
          this.setState({
            customfeildTwo: element.value,
          });
          break;

        case "customField3":
          this.setState({
            customfeildThree: element.value,
          });
          break;

        case "curriculum":
          this.setState({
            curriculumValue: element.value,
          });
          break;

        case "gradeLevel":
          this.setState({
            gradeLevelValue: element.value,
          });
          break;

        case "schoolYearTitle":
          this.setState({
            schoolYearValue: element.value,
          });
          break;
      }
    });
  };

  handleSetUserRole = () => {
    const roleFromParam = this.props.navigation?.getParam("role");
    if (roleFromParam) {
      this.setState({ userRole: roleFromParam });
    }
  };

  setDDValue = (selected: any, fieldName: string) => {
    const {
      curriculumDDMenuItems,
      gradeLevelDDMenuItems,
      relationshipDDMenuItems,
      schoolYearDDMenuItems,
    } = this.state;

    let label: any = "";

    if (fieldName === "curriculumDDMenuItems") {
      const selectedItem = curriculumDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "gradeLevelDDMenuItems") {
      const selectedItem = gradeLevelDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "relationshipDDMenuItems") {
      const selectedItem = relationshipDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "schoolYearDDMenuItems") {
      const selectedItem = schoolYearDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    }
    return label;
  };

  renderDDMenuItem = (
    selected: any,
    fieldName: string,
    placeholderText: string
  ) => {
    if (!selected || selected.length === 0) {
      return placeholderText;
    }
    return this.setDDValue(selected, fieldName);
  };

  handleSetParentInputConfigValue = (name: any, value: any, parentId: any) => {
    const { parentInputConfigs } = this.state;
    const ddConfig = parentInputConfigs.map((item) => {
      if (item.id === parentId) {
        item.ddConfigs.forEach((ddItem) => {
          if (ddItem.name === name) {
            ddItem.value = value;
          }
          return ddItem;
        });
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        parentInputConfigs: ddConfig,
      };
    });
  };

  handleSelectDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    parentId?: any
  ) => {
    const { studentParentInputConfigs, screenName, parentInputConfigs } =
      this.state;
    const { name, value } = event.target;

    this.setDefaultDataMethod();
    this.handleSetDDConfig();
    let fieldValue: any = value;
    if (fieldValue) {
      if (screenName === "student") {
        const ddConfigs = studentParentInputConfigs.map((item) => {
          if (item.name === name) {
            item.value = value;
          }
          return item;
        });
        this.setState((prev) => {
          return {
            ...prev,
            [name]: fieldValue,
            studentParentInputConfigs: ddConfigs,
          };
        });
        if (name == "gradeLevel") {
          this.setState(
            {
              gradeIdvalue: value,
            },
            () => {
              this.getCurriculumList();
            }
          );
        }
      } else {
        this.handleSetParentInputConfigValue(name, value, parentId);
      }
    }
    this.updateParentConfigs(parentInputConfigs, name, value);
  };

  handleSelectDropdownValue1 = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    parentId?: any
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.handleSetParentInputConfigValue(name, value, parentId);
    }
  };

  updateParentConfigs = (parentInputConfigs: any, name: string, value: any) => {
    const updatedParentConfigs = parentInputConfigs.map((parentConfig: any) => {
      const updatedDDConfigs = parentConfig.ddConfigs.map((ddConfig: any) => {
        switch (ddConfig.name) {
          case "schoolYear":
            this.state.schoolYearDDMenuItems.forEach((item) => {
              this.handleSetFeildValue(ddConfig, value, name, item);
            });

            break;
          case "grade":
            this.state.gradeLevelDDMenuItems.forEach((item) => {
              this.handleSetFeildValue(ddConfig, value, name, item);
            });
            break;
          case "curriculum":
            this.state.curriculumDDMenuItems.forEach((item) => {
              this.handleSetFeildValue(ddConfig, value, name, item);
            });
            break;
          case "relationship":
            this.state.relationshipDDMenuItems.forEach((item) => {
              this.handleSetFeildValue(ddConfig, value, name, item);
            });
            break;
          default:
            break;
        }
        return ddConfig;
      });
      return {
        ...parentConfig,
        ddConfigs: updatedDDConfigs,
      };
    });

    this.setDefaultDataMethod();
    this.setState({
      parentInputConfigs: updatedParentConfigs,
    });
  };

  handleSetFeildValue = (
    ddConfig: { value: any },
    value: any,
    name: any,
    item: { value: any; label: any }
  ) => {
    switch (name) {
      case "schoolYearTitle":
        if (item.value === value) {
          ddConfig.value = item.label;
          this.setState({
            schoolYearText: item.label,
          });
        }
        break;
      case "gradeLevel":
        if (item.value === value) {
          ddConfig.value = item.label;
          this.setState({
            gradeLevelText: item.label,
          });
        }
        break;
      case "curriculum":
        if (item.value === value) {
          ddConfig.value = item.label;
          this.setState({
            curriculumText: item.label,
          });
        }
        break;

      case "relationship":
        if (item.value === value) {
          ddConfig.value = item.label;
        }
        break;
      default:
        break;
    }
  };

  handleSetDDConfig = () => {
    const {
      curriculumDDMenuItems,
      gradeLevelDDMenuItems,
      relationshipDDMenuItems,
      schoolYearDDMenuItems,
      customFieldsList,
      parentCustomFieldsList,
    } = this.state;

    const ddConfig: Array<IDDConfigProps> = [
      {
        name: "firstName",
        id: "student-first-name",
        fieldName: "firstName",
        label: "First Name",
        fieldType: FIELD_TYPES.TEXT,
        value: "",
        placeHolderText: "Enter First Name",
      },
      {
        id: "student-last-name",
        label: "Last Name",
        fieldType: FIELD_TYPES.TEXT,
        name: "lastName",
        value: "",
        fieldName: "lastName",
        placeHolderText: "Enter Last Name",
      },
      {
        id: "student-emailId",
        fieldName: "emailId",
        label: "Email ID",
        name: "emailId",
        fieldType: FIELD_TYPES.TEXT,
        value: "",
        placeHolderText: "Enter Email ID",
      },
      {
        fieldName: "studentDOB",
        id: "student-dob",
        fieldType: FIELD_TYPES.DATE,
        label: "Date of Birth",
        name: "dob",
        value: null,
        placeHolderText: "Select DOB",
      },
      {
        fieldName: "gradeLevelDDMenuItems",
        id: "student-grade",
        fieldType: FIELD_TYPES.SELECT,
        label: "Grade Level",
        name: "gradeLevel",
        value: "",
        menuItems: gradeLevelDDMenuItems,
        placeHolderText: "Select Grade",
      },
      {
        fieldName: "role",
        id: "student-role",
        fieldType: FIELD_TYPES.TEXT,
        name: "role",
        value: "Student",
        isDisabled: true,
        label: "Role",
        placeHolderText: "Role",
      },
      {
        fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
        fieldName: "profileImage",
        id: "student-profile-mage",
        label: "Profile Image",
        name: "profileImage",
        value: {
          profile: "",
          fileName: "",
          contentType: "",
        },
        placeHolderText: "Select File",
      },
      {
        fieldName: "userId",
        label: "ID",
        id: "student-userId",
        fieldType: FIELD_TYPES.TEXT,
        name: "userId",
        value: "",
        placeHolderText: "Enter ID Number",
      },
      {
        fieldName: "curriculumDDMenuItems",
        id: "student-curriculum",
        fieldType: FIELD_TYPES.SELECT,
        label: "Curriculum",
        name: "curriculum",
        value: "",
        menuItems: curriculumDDMenuItems,
        placeHolderText: "Select Curriculum",
      },
      ...customFieldsList,
      {
        fieldName: "schoolYearDDMenuItems",
        id: "student-schoolYearTitle",
        fieldType: FIELD_TYPES.SELECT,
        label: "School Year Title",
        name: "schoolYearTitle",
        value: "",
        menuItems: schoolYearDDMenuItems,
        placeHolderText: "Select School Year",
      },
      {
        fieldName: "studentDetailExpiringDate",
        id: "student-detail-expiring-date",
        fieldType: FIELD_TYPES.DATE,
        label: "Expiring On",
        name: "studentDetailExpiringDate",
        value: null,
        placeHolderText: "Select Date",
      },
    ];

    const parentConfigs: Array<IParentInputConfigProps> = [
      {
        id: "parent-1",
        label: "Parent 1 Details",
        role: "parent_teacher",
        ddConfigs: [
          {
            customLabel: "test",
            id: "parent-1-first-name",
            fieldType: FIELD_TYPES.TEXT,
            fieldName: "firstName",
            label: "First Name",
            name: "firstName",
            customLabel2: "test",
            value: "",
            placeHolderText: "Enter First Name",
          },
          {
            customLabel2: "test",
            fieldName: "lastName",
            id: "parent-1-last-name",
            fieldType: FIELD_TYPES.TEXT,
            label: "Last Name",
            value: "",
            name: "lastName",
            placeHolderText: "Enter Last Name",
          },
          {
            id: "parent-1-emailId",
            customLabel2: "test",
            fieldName: "emailId",
            fieldType: FIELD_TYPES.TEXT,
            label: "Email ID",
            name: "emailId",
            value: "",
            placeHolderText: "Enter Email ID",
          },
          {
            fieldName: "relationshipDDMenuItems",
            fieldType: FIELD_TYPES.SELECT,
            customLabel2: "test",
            id: "parent-1-relationship",
            label: "Relationship",
            name: "relationship",
            value: "father",
            menuItems: relationshipDDMenuItems,
            placeHolderText: "Select Relationship",
          },
          {
            fieldName: "profileImage",
            id: "parent-1-profile-image",
            fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
            customLabel2: "test",
            label: "Profile Image",
            value: {
              profile: "",
              fileName: "",
              contentType: "",
            },
            name: "profileImage",
            placeHolderText: "Select File",
          },
          {
            fieldName: "contactNumber",
            id: "parent-1-contactNumber",
            label: "Contact Number",
            fieldType: FIELD_TYPES.TEXT,
            customLabel2: "test",
            name: "contactNumber",
            value: "",
            placeHolderText: "Enter Contact Number",
          },
          {
            fieldName: "curriculum",
            id: "parent-1-curriculum",
            label: "Curriculum",
            fieldType: FIELD_TYPES.TEXT,
            customLabel2: "test",
            name: "curriculum",
            value: this.state.curriculumValue,
            isDisabled: true,
            placeHolderText: "Curriculum",
          },
          ...parentCustomFieldsList,
          {
            fieldName: "grade",
            id: "parent-1-grade",
            label: "Grade Level",
            customLabel2: "test",
            fieldType: FIELD_TYPES.TEXT,
            name: "grade",
            value: this.state.gradeLevelValue,
            isDisabled: true,
            placeHolderText: "Grade Level",
          },
          {
            fieldName: "childrenCount",
            id: "parent-1-childrenCount",
            label: "Children",
            fieldType: FIELD_TYPES.TEXT,
            name: "childrenCount",
            value: "",
            customLabel2: "test",
            placeHolderText: "No. of Child",
          },
          {
            fieldName: "expiringOn",
            id: "parent-1-expiringOn",
            fieldType: FIELD_TYPES.TEXT,
            customLabel2: "test",
            label: "Expiring On",
            name: "expiringOn",
            value: this.state.expiringDateValue,
            isDisabled: true,
            placeHolderText: "Expiring On",
          },
          {
            fieldName: "schoolYear",
            id: "parent-1-schoolYear",
            fieldType: FIELD_TYPES.TEXT,
            label: "School Year Title",
            name: "schoolYear",
            value: this.state.schoolYearValue,
            customLabel2: "test",
            isDisabled: true,
            placeHolderText: "Select School Year",
          },
          {
            fieldName: "role",
            id: "parent-1-role",
            label: "Role",
            customLabel2: "test",
            fieldType: FIELD_TYPES.TEXT,
            name: "role",
            value: "Parent Teacher",
            isDisabled: true,
            placeHolderText: "Role",
          },
        ],
      },
      {
        id: "parent-2",
        label: "Parent 2 Details",
        role: "parent",
        ddConfigs: [
          {
            customLabel: "test",
            fieldType: FIELD_TYPES.TEXT,
            fieldName: "firstName",
            id: "parent-2-first-name",
            label: "First Name",
            customLabel2: "test",
            name: "firstName",
            value: "",
            placeHolderText: "Enter First Name",
          },
          {
            fieldName: "lastName",
            customLabel: "test",
            label: "Last Name",
            id: "parent-2-last-name",
            fieldType: FIELD_TYPES.TEXT,
            name: "lastName",
            placeHolderText: "Enter Last Name",
            value: "",
          },
          {
            customLabel: "test",
            fieldName: "emailId",
            fieldType: FIELD_TYPES.TEXT,
            name: "emailId",
            label: "Email ID",
            value: "",
            placeHolderText: "Enter Email ID",
            id: "parent-2-emailId",
          },
          {
            fieldType: FIELD_TYPES.SELECT,
            label: "Relationship",
            customLabel: "test",
            fieldName: "relationshipDDMenuItems",
            name: "relationship",
            value: "",
            id: "parent-2-relationship",
            menuItems: relationshipDDMenuItems,
            placeHolderText: "Select Relationship",
          },
          {
            customLabel: "test",
            label: "Contact Number",
            fieldType: FIELD_TYPES.TEXT,
            name: "contactNumber",
            fieldName: "contactNumber",
            id: "parent-2-contactNumber",
            value: "",
            placeHolderText: "Enter Contact Number",
          },
          {
            fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
            label: "Profile Image",
            fieldName: "profileImage",
            value: {
              profile: "",
              fileName: "",
              contentType: "",
            },
            customLabel: "test",
            name: "profileImage",
            id: "parent-2-profile-image",
            placeHolderText: "Select File",
          },
          {
            name: "role",
            fieldName: "role",
            value: "Parent",
            customLabel: "test",
            fieldType: FIELD_TYPES.TEXT,
            label: "Role",
            isDisabled: true,
            id: "parent-2-role",
            placeHolderText: "Role",
          },
        ],
      },
    ];
    this.setState({
      parentInputConfigs: parentConfigs,
      studentParentInputConfigs: ddConfig,
    });
  };

  handleAddUser = (isDraft = false) => {
    this.setState({ loading: true, isDraftOn: isDraft });
    showCustomLoader();
    const { token, parentInputConfigs, studentParentInputConfigs } = this.state;
    const attributes: any = {
      user_custom_fields_attributes: [],
    };

    if (isDraft) {
      attributes["status"] = 2;
    }

    if (this.state.isParentUserId) {
      attributes["user_id"] = this.state.isParentUserId;
      attributes["user_role_id"] = this.state.isParentRoleId;
    }

    let parentToAddAttributesarr: { first_name: string; last_name: string }[] =
      [];
    if (
      this.state.parentInputConfigs &&
      this.state.parentInputConfigs.length > 0
    ) {
      const parentToAddAttributes: {
        first_name: any;
        last_name: any;
        email: any;
        role_id: number;
        relationship: any;
        children: any;
        profile: any;
        phone: any;
        id_number: any;
      }[] = [];

      parentInputConfigs.forEach((parentConfig, index) => {
        const parentAttribute: any = {
          first_name: parentConfig.ddConfigs.find(
            (item) => item.fieldName === "firstName"
          )?.value,
          last_name: parentConfig.ddConfigs.find(
            (item) => item.fieldName === "lastName"
          )?.value,
          email: parentConfig.ddConfigs.find(
            (item) => item.fieldName === "emailId"
          )?.value,
          relationship: parentConfig.ddConfigs.find(
            (item) => item.fieldName === "relationshipDDMenuItems"
          )?.value,
          profile: parentConfig.ddConfigs.find(
            (item) => item.fieldName === "profileImage"
          )?.value.profile,
          content_type: parentConfig.ddConfigs.find(
            (item) => item.fieldName === "profileImage"
          )?.value.contentType,
          filename: parentConfig.ddConfigs.find(
            (item) => item.fieldName === "profileImage"
          )?.value.fileName,
          phone: parentConfig.ddConfigs.find(
            (item) => item.fieldName === "contactNumber"
          )?.value,
          children: parentConfig.ddConfigs.find(
            (item) => item.fieldName === "childrenCount"
          )?.value,
          role_id: Number(
            this.state.userRoleList.find(
              (user: any) =>
                user.attributes.name ===
                parentConfig.ddConfigs.find((item) => item.fieldName === "role")
                  ?.value
            )?.id
          ),
          id_number: index + 765,
          user_custom_fields_attributes: [],
        };

        studentParentInputConfigs.forEach(
          (element: { name: any; value: any }) => {
            switch (element.name) {
              case "gradeLevel":
                parentAttribute.grade_id = [`${element.value}`];
                break;
              case "curriculum":
                parentAttribute.curriculum_id = `${element.value}`;
                break;
              case "schoolYearTitle":
                parentAttribute.school_year_id = `${element.value}`;
                break;
            }
          }
        );

        parentToAddAttributesarr.push(parentAttribute);

        if (parentConfig.role === "parent_teacher") {
        }
        parentConfig.ddConfigs.forEach((item) => {
          if (item?.customField) {
            parentAttribute["user_custom_fields_attributes"].push({
              field_value: item.value,
              custom_field_id: item.id,
              _destroy: 0,
            });
          }
        });
      });
    }

    attributes["parents1"] = [parentToAddAttributesarr[0]];
    attributes["parents2"] = [parentToAddAttributesarr[1]];

    this.state.studentParentInputConfigs.forEach((element) => {
      switch (element.name) {
        case "firstName":
          attributes["first_name"] = element.value;
          break;

        case "lastName":
          attributes["last_name"] = element.value;
          break;

        case "emailId":
          attributes["email"] = element.value;
          break;

        case "profileImage":
          attributes["profile"] = element.value.profile;
          attributes["filename"] = element.value.fileName;
          attributes["content_type"] = element.value.contentType;
          break;

        case "role":
          attributes["role_id"] = 1;
          break;

        case "dob":
          attributes["DOB"] = element.value;
          break;

        case "studentDetailExpiringDate":
          attributes["expiring_on"] = element.value;
          break;

        case "gradeLevel":
          attributes["grade_id"] = [element.value.toString()];
          break;

        case "curriculum":
          attributes["curriculum_id"] = element.value.toString();
          break;

        case "schoolYearTitle":
          attributes["school_year_id"] = element.value;
          break;

        case "userId":
          attributes["id_number"] = element.value;
          break;
      }
    });

    studentParentInputConfigs.forEach((element) => {
      if (element?.customField) {
        attributes["user_custom_fields_attributes"].push({
          field_value: element.value,
          custom_field_id: element.id,
          _destroy: 0,
        });
      }
    });

    const header = {
      "Content-Type": configJSON.userStatusApiContentType,
      token,
    };

    const httpBody: any = {
      data: {
        attributes,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addNewUserAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatusApiEndpont
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userCreateAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSearchParentList = () => {
    showCustomLoader();
    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };

    this.searchParentListAPIRequestId = getUserStatusMsg.messageId;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchParentList}?per_page=9&page=${this.state.currentPage}&name=${this.state.searchParentText}`
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userCreateAPiMethod
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  };

  handleLoadMore = () => {
    showCustomLoader();
    const { currentPage } = this.state;
    const nextPage = currentPage + 1;
    this.setState({ currentPage: nextPage, isFromLoadMore: true }, () => {
      this.getSearchParentList();
    });
  };

  getGradesLevel = () => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };
    let getGradesEndpoint = configJSON.gradesEndpoint;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradesListingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getGradesEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCurriculumList() {
    showCustomLoader();
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.curriculumListAPIRequestId = getUserStatusMsg.messageId;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.curriculumsGradeEndpoint + this.state.gradeIdvalue
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  }

  GetSchoolYearList = () => {
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSchoolYearDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolYearEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetRoleNamesList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };

    this.apiGetRoleNamesDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherParentNmaesList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // Customizable Area Start
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.addNewUserAPIRequestId:
            this.handleAddNewStudentResponse(responseJson, errorResponse);
            break;

          case this.curriculumListAPIRequestId:
            this.handleCurriculumListResponse(responseJson, errorResponse);
            break;

          case this.apiGetGradesListingId:
            this.handleGradeListResponce(responseJson, errorResponse);
            break;

          case this.apiGetSchoolYearDataId:
            this.handleSchoolYearListResponce(responseJson, errorResponse);
            break;

          case this.searchParentListAPIRequestId:
            if (responseJson?.users?.length < responseJson?.total_users) {
              this.setState({ isItemsPresent: false });
            }
            this.handleSearchParentListResponce(responseJson, errorResponse);
            break;

          case this.apiGetRoleNamesDataId:
            this.handleGetroleLIstResponce(responseJson, errorResponse);
            break;
          case this.apiGetStudentCustomFieldsId:
            this.handleStudentsCustomFieldsListResponse(responseJson);
        }
      }
      // Customizable Area End
    }
  }

  handleAddNewStudentResponse = (responseJson: any, errorResponse: any) => {
    this.setState({ loading: false });
    hideCustomLoader();
    if (responseJson?.data?.id) {
      if (this.state.isDraftOn) {
        toast.success(TOASTER_NOTIFICATION_MSG.ADD_NEW_STUDENT_SUCCESS_DRAFT);
      } else {
        toast.success(TOASTER_NOTIFICATION_MSG.ADD_NEW_STUDENT_SUCCESS);
      }

      this.props.navigation.navigate("TenantUserManagement");
      const ddConfig = this.state.studentParentInputConfigs.map((item) => {
        if (item.fieldName === "profileImage") {
          return {
            ...item,
            value: { profile: "", fileName: "", contentType: "" },
          };
        } else if (item.fieldName !== "role") {
          return { ...item, value: "" };
        } else {
          return item;
        }
      });
      this.setState({ studentParentInputConfigs: ddConfig });
    } else if (responseJson.errors) {
      const errorField = Object.keys(responseJson.errors)[0];
      const errorMessage = responseJson.errors[errorField][0];
      toast.error(
        `${
          errorField.charAt(0).toUpperCase() + errorField.slice(1)
        } ${errorMessage}`
      );
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleCurriculumListResponse = (response: any, errorResponse: any) => {
    if (response) {
      if (response.data) {
        const curriculumList = response.data.map((item: any, index: number) => {
          return {
            id: ++index,
            label: item.attributes.name,
            value: Number(item.id),
          };
        });

        const ddConfigs = this.state.studentParentInputConfigs.map(
          (item: any) => {
            if (item.name === "curriculum") {
              item.menuItems = curriculumList;
            }
            return item;
          }
        );
        this.setState({
          studentParentInputConfigs: ddConfigs,
          curriculumDDMenuItems: curriculumList,
        });
      }
    }
    hideCustomLoader();
  };

  handleGradeListResponce = (response: any, errorResponse: any) => {
    if (response) {
      if (response.data) {
        const gradeList = response.data.map((item: any, index: number) => {
          return {
            id: ++index,
            label: item.attributes.name,
            value: Number(item.id),
          };
        });

        const ddConfigs = this.state.studentParentInputConfigs.map(
          (item: any) => {
            if (item.name === "gradeLevel") {
              item.menuItems = gradeList;
            }
            return item;
          }
        );
        this.setState({
          studentParentInputConfigs: ddConfigs,
          gradeLevelDDMenuItems: gradeList,
        });
      }
    }
    hideCustomLoader();
  };

  handleSchoolYearListResponce = (response: any, errorResponse: any) => {
    if (response) {
      if (response.data) {
        const shoolYearList = response.data.map((item: any, index: number) => {
          return {
            id: ++index,
            label: item.attributes.title,
            value: Number(item.id),
          };
        });

        const ddConfigs = this.state.studentParentInputConfigs.map(
          (item: any) => {
            if (item.name === "schoolYearTitle") {
              item.menuItems = shoolYearList;
            }
            return item;
          }
        );
        this.setState({
          studentParentInputConfigs: ddConfigs,
          schoolYearDDMenuItems: shoolYearList,
        });
      }
    }
  };

  handleSearchParentListResponce = (response: any, errorResponse: any) => {
    hideCustomLoader();
    if (response.users) {
      const searchParentList = response.users.map(
        (item: any, index: number) => {
          return {
            profilePic: item.profile_url,
            id: item.id,
            fullName: item.name,
            emailId: item.email,
            isSelected: false,
            role: item.role,
          };
        }
      );
      if (!this.state.isFromLoadMore) {
        this.setState({
          parentList: searchParentList,
        });
      } else {
        this.setState((state) => ({
          parentList: [...state.parentList, ...searchParentList],
        }));
      }
    }
    if (response.users.length < 9) {
      this.setState({
        isItemsPresent: true,
      });
    }
    this.setState({ isFromLoadMore: false });
    hideCustomLoader();
  };

  handleGetroleLIstResponce = (response: any, errorResponse: any) => {
    this.setState({
      userRoleList: response.data,
    });
  };

  handleDateChange = (data: any, fieldName: string) => {
    if (data) {
      const { studentParentInputConfigs } = this.state;
      const setDateFormat = moment(data).toLocaleString();

      const ddConfig = studentParentInputConfigs.map((item) => {
        if (item.name === fieldName) {
          item.value = setDateFormat;
        }
        return item;
      });
      this.setState({ studentParentInputConfigs: ddConfig });
    }

    if (data) {
      const { parentInputConfigs } = this.state;
      const setDateFormat = moment(data).toLocaleString();
      const parsedDate = moment(setDateFormat, "ddd MMM DD YYYY HH:mm:ss Z");

      const formattedDate = parsedDate.format("DD/MM/YYYY");
      const updatedParentConfigs = parentInputConfigs.map((parentConfig) => {
        const updatedDDConfigs = parentConfig.ddConfigs.map((ddConfig) => {
          if (ddConfig.fieldName === "expiringOn") {
            ddConfig.value = formattedDate;
          }
          return ddConfig;
        });
        parentConfig.ddConfigs = updatedDDConfigs;
        return parentConfig;
      });
      this.setState({
        expiringDateValue: formattedDate,
      });
      this.setState({ parentInputConfigs: updatedParentConfigs });
    }
  };

  handleChange = (event: any, parentId?: any) => {
    const { studentParentInputConfigs, screenName, parentInputConfigs } =
      this.state;
    const { name, value } = event.target;
    if (screenName === "student") {
      const updatedStudentParentInputConfigs = studentParentInputConfigs.map(
        (item) => {
          if (item.name === name) {
            return { ...item, value };
          }
          return item;
        }
      );

      this.setState((prev) => {
        return {
          ...prev,
          [name]: value,
          studentParentInputConfigs: updatedStudentParentInputConfigs,
        };
      });
    } else {
      this.handleSetParentInputConfigValue(name, value, parentId);
    }

    const updatedParentConfigs = parentInputConfigs.map((parentConfig) => {
      const updatedDDConfigs = parentConfig.ddConfigs.map((ddConfig) => {
        if (
          ddConfig.name === name &&
          ddConfig.name !== "firstName" &&
          ddConfig.name !== "lastName" &&
          ddConfig.name !== "emailId" &&
          ddConfig.name !== "contactNumber"
        ) {
          return { ...ddConfig, value };
        }
        return ddConfig;
      });
      return {
        ...parentConfig,
        ddConfigs: updatedDDConfigs,
      };
    });
    this.setDefaultDataMethod();
    this.setState({
      parentInputConfigs: updatedParentConfigs,
    });
  };

  handleChangeSearchParent = (event: any) => {
    this.setState({
      searchParentText: event.target?.value,
      currentPage: 1,
    });
  };

  handleSearchParent = () => {
    this.getSearchParentList();
  };

  handlePauseForStepper = () => {
    const { studentParentInputConfigs } = this.state;
    const emailField = studentParentInputConfigs.find(
      (element) => element.name === "emailId"
    );
    if (emailField && !emailField.value) {
      return true;
    } else {
      return false;
    }
  };

  handleNextStepper = () => {
    const { screenName } = this.state;

    if (screenName === "student") {
      const emailField = this.state.studentParentInputConfigs.find(
        (element) => element.name === "emailId"
      );

      if (emailField && !emailField.value) {
        toast.error("Please enter email address");
        return;
      } else {
        this.setState({ screenName: "parent" });
      }
    } else {
      this.setState({
        screenName: "parent_step_2",
        isStepWithMultipleScreens: true,
      });
    }
  };

  handlePreviousStepper = () => {
    const { screenName } = this.state;
    if (screenName === "parent_step_2") {
      this.setState({ screenName: "parent", isStepWithMultipleScreens: false });
    } else {
      this.setState({ screenName: "student" });
    }
  };

  handleSaveAsDraft = () => {
    this.setState({ loading: false });
  };

  handleUploadFile = (file: any, fieldName: string, parentId?: any) => {
    const { screenName, parentInputConfigs } = this.state;
    if (screenName === "student") {
      this.handleStudentFile(file, fieldName);
    } else {
      const ddConfig = parentInputConfigs.map((item) => {
        if (item.id === parentId) {
          this.fileuploadsetmethod(item, file, fieldName);
        }
        return item;
      });
      this.setState((prev) => {
        return {
          ...prev,
          parentInputConfigs: ddConfig,
        };
      });
    }
  };

  fileuploadsetmethod = (item: any, file: any, fieldName: any) => {
    item.ddConfigs.forEach(
      (ddItem: {
        name: any;
        value: {
          profile: string | ArrayBuffer | null;
          fileName: any;
          contentType: any;
        };
      }) => {
        if (ddItem.name === fieldName) {
          if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              ddItem.value = {
                profile: reader.result,
                fileName: file.name || "",
                contentType: file.type,
              };
            };
            reader.readAsDataURL(file);
            ddItem.value = {
              profile: "",
              fileName: file.name || "",
              contentType: file.type,
            };
          } else {
            ddItem.value = {
              profile: "",
              fileName: "",
              contentType: "",
            };
          }
        }
        return ddItem;
      }
    );
  };

  handleStudentFile = (file: any, fieldName: string) => {
    const { studentParentInputConfigs } = this.state;
    const ddConfig = studentParentInputConfigs.map((item) => {
      if (item.name === fieldName) {
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            item.value = {
              profile: reader.result,
              contentType: file.type,
              fileName: file.name || "",
            };
          };
          reader.readAsDataURL(file);

          item.value = {
            fileName: file.name || "",
            profile: "",
            contentType: file.type,
          };
        } else {
          item.value = {
            fileName: "",
            contentType: "",
            profile: "",
          };
        }
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        studentParentInputConfigs: ddConfig,
      };
    });
  };

  handleMarkAsParentTeacher = (parentId: any) => {
    const { parentInputConfigs, studentParentInputConfigs } = this.state;
    const studentCustomFieldsConfigs = studentParentInputConfigs.filter((item) => item.customField).map((cfItem) => {
       return { ...cfItem, isDisabled: true }
    })
    const ddConfig = parentInputConfigs.map((item) => {
      if (item.id === parentId && parentId == "parent-2") {
        if (item.role.toLowerCase() === "parent") {
          item.role = "parent_teacher";
          item.ddConfigs = [
            {
              name: "firstName",
              customLabel2: "test",
              customLabel: "test",
              id: "parent-2-first-name",
              fieldType: FIELD_TYPES.TEXT,
              fieldName: "firstName",
              label: "First Name",
              value: "",
              placeHolderText: "Enter First Name",
            },
            {
              label: "Last Name",
              value: "",
              customLabel2: "test",
              fieldName: "lastName",
              id: "parent-2-last-name",
              fieldType: FIELD_TYPES.TEXT,
              name: "lastName",
              placeHolderText: "Enter Last Name",
            },
            {
              id: "parent-2-emailId",
              customLabel2: "test",
              fieldName: "emailId",
              fieldType: FIELD_TYPES.TEXT,
              name: "emailId",
              value: "",
              placeHolderText: "Enter Email ID",
              label: "Email ID",
            },
            {
              fieldName: "relationshipDDMenuItems",
              fieldType: FIELD_TYPES.SELECT,
              customLabel2: "test",
              value: "father",
              id: "parent-2-relationship",
              label: "Relationship",
              name: "relationship",
              placeHolderText: "Select Relationship",
              menuItems: this.state.relationshipDDMenuItems,
            },
            {
              fieldName: "profileImage",
              id: "parent-2-profile-image",
              value: {
                profile: "",
                fileName: "",
                contentType: "",
              },
              name: "profileImage",
              fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
              customLabel2: "test",
              placeHolderText: "Select File",
              label: "Profile Image",
            },
            {
              fieldName: "contactNumber",
              id: "parent-2-contactNumber",
              customLabel2: "test",
              name: "contactNumber",
              label: "Contact Number",
              fieldType: FIELD_TYPES.TEXT,
              value: "",
              placeHolderText: "Enter Contact Number",
            },
            {
              fieldName: "curriculum",
              label: "Curriculum",
              id: "parent-2-curriculum",
              fieldType: FIELD_TYPES.TEXT,
              isDisabled: true,
              customLabel2: "test",
              name: "curriculum",
              value: this.state.curriculumText,
              placeHolderText: "Curriculum",
            },
            ...studentCustomFieldsConfigs,
            {
              fieldName: "grade",
              label: "Grade Level",
              customLabel2: "test",
              fieldType: FIELD_TYPES.TEXT,
              name: "grade",
              id: "parent-2-grade",
              value: this.state.gradeLevelText,
              isDisabled: true,
              placeHolderText: "Grade Level",
            },
            {
              fieldName: "childrenCount",
              id: "parent-2-childrenCount",
              label: "Children",
              fieldType: FIELD_TYPES.TEXT,
              customLabel2: "test",
              name: "childrenCount",
              value: "",
              placeHolderText: "No. of Child",
            },
            {
              fieldName: "expiringOn",
              id: "parent-2-expiringOn",
              fieldType: FIELD_TYPES.TEXT,
              customLabel2: "test",
              label: "Expiring On",
              name: "expiringOn",
              value: this.state.expiringDateValue,
              isDisabled: true,
              placeHolderText: "Expiring On",
            },
            {
              fieldName: "schoolYear",
              id: "parent-2-schoolYear",
              fieldType: FIELD_TYPES.TEXT,
              label: "School Year Title",
              name: "schoolYear",
              value: this.state.schoolYearText,
              customLabel2: "test",
              isDisabled: true,
              placeHolderText: "Select School Year",
            },
            {
              fieldName: "role",
              id: "parent-2-role",
              fieldType: FIELD_TYPES.TEXT,
              name: "role",
              label: "Role",
              customLabel2: "test",
              value: "Parent Teacher",
              isDisabled: true,
              placeHolderText: "Role",
            },
          ];
        } else {
          item.role = "parent";
          item.ddConfigs = [
            {
              customLabel: "test",
              fieldType: FIELD_TYPES.TEXT,
              fieldName: "firstName",
              id: "parent-2-first-name",
              label: "First Name",
              customLabel2: "test",
              name: "firstName",
              value: "",
              placeHolderText: "Enter First Name",
            },
            {
              fieldName: "lastName",
              customLabel: "test",
              name: "lastName",
              label: "Last Name",
              id: "parent-2-last-name",
              fieldType: FIELD_TYPES.TEXT,
              placeHolderText: "Enter Last Name",
              value: "",
            },
            {
              customLabel: "test",
              name: "emailId",
              label: "Email ID",
              value: "",
              fieldName: "emailId",
              fieldType: FIELD_TYPES.TEXT,
              placeHolderText: "Enter Email ID",
              id: "parent-2-emailId",
            },
            {
              fieldType: FIELD_TYPES.SELECT,
              label: "Relationship",
              customLabel: "test",
              id: "parent-2-relationship",
              menuItems: this.state.relationshipDDMenuItems,
              fieldName: "relationshipDDMenuItems",
              name: "relationship",
              value: "",
              placeHolderText: "Select Relationship",
            },
            {
              fieldName: "contactNumber",
              id: "parent-2-contactNumber",
              customLabel: "test",
              label: "Contact Number",
              fieldType: FIELD_TYPES.TEXT,
              name: "contactNumber",
              value: "",
              placeHolderText: "Enter Contact Number",
            },
            {
              fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
              label: "Profile Image",
              fieldName: "profileImage",
              name: "profileImage",
              id: "parent-2-profile-image",
              placeHolderText: "Select File",
              value: {
                profile: "",
                fileName: "",
                contentType: "",
              },
              customLabel: "test",
            },
            {
              name: "role",
              label: "Role",
              isDisabled: true,
              id: "parent-2-role",
              fieldName: "role",
              value: "Parent",
              customLabel: "test",
              fieldType: FIELD_TYPES.TEXT,
              placeHolderText: "Role",
            },
          ];
        }
      }
      if (item.id === parentId && parentId == "parent-1") {
        if (item.role.toLowerCase() === "parent") {
          item.role = "parent_teacher";
          item.ddConfigs = [
            {
              fieldName: "firstName",
              customLabel: "test",
              id: "parent-1-first-name",
              fieldType: FIELD_TYPES.TEXT,
              label: "First Name",
              name: "firstName",
              customLabel2: "test",
              placeHolderText: "Enter First Name",
              value: "",
            },
            {
              customLabel2: "test",
              fieldName: "lastName",
              id: "parent-1-last-name",
              fieldType: FIELD_TYPES.TEXT,
              name: "lastName",
              placeHolderText: "Enter Last Name",
              label: "Last Name",
              value: "",
            },
            {
              id: "parent-1-relationship",
              label: "Relationship",
              name: "relationship",
              value: "father",
              fieldName: "relationshipDDMenuItems",
              fieldType: FIELD_TYPES.SELECT,
              customLabel2: "test",
              menuItems: this.state.relationshipDDMenuItems,
              placeHolderText: "Select Relationship",
            },
            {
              label: "Email ID",
              id: "parent-1-emailId",
              customLabel2: "test",
              fieldName: "emailId",
              fieldType: FIELD_TYPES.TEXT,
              name: "emailId",
              value: "",
              placeHolderText: "Enter Email ID",
            },
            {
              label: "Profile Image",
              fieldName: "profileImage",
              id: "parent-1-profile-image",
              fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
              customLabel2: "test",
              value: {
                profile: "",
                fileName: "",
                contentType: "",
              },
              name: "profileImage",
              placeHolderText: "Select File",
            },
            {
              customLabel2: "test",
              name: "contactNumber",
              fieldName: "contactNumber",
              id: "parent-1-contactNumber",
              label: "Contact Number",
              fieldType: FIELD_TYPES.TEXT,
              value: "",
              placeHolderText: "Enter Contact Number",
            },
            {
              fieldName: "curriculum",
              id: "parent-1-curriculum",
              label: "Curriculum",
              fieldType: FIELD_TYPES.TEXT,
              value: this.state.curriculumText,
              isDisabled: true,
              placeHolderText: "Curriculum",
              customLabel2: "test",
              name: "curriculum",
            },
            ...studentCustomFieldsConfigs,
            {
              name: "grade",
              value: this.state.gradeLevelText,
              isDisabled: true,
              fieldName: "grade",
              id: "parent-1-grade",
              label: "Grade Level",
              customLabel2: "test",
              fieldType: FIELD_TYPES.TEXT,
              placeHolderText: "Grade Level",
            },
            {
              fieldName: "childrenCount",
              id: "parent-1-childrenCount",
              label: "Children",
              fieldType: FIELD_TYPES.TEXT,
              customLabel2: "test",
              name: "childrenCount",
              value: "",
              placeHolderText: "No. of Child",
            },
            {
              fieldName: "expiringOn",
              id: "parent-1-expiringOn",
              fieldType: FIELD_TYPES.TEXT,
              customLabel2: "test",
              label: "Expiring On",
              name: "expiringOn",
              value: this.state.expiringDateValue,
              isDisabled: true,
              placeHolderText: "Expiring On",
            },
            {
              fieldType: FIELD_TYPES.TEXT,
              label: "School Year Title",
              placeHolderText: "Select School Year",
              name: "schoolYear",
              value: this.state.schoolYearText,
              customLabel2: "test",
              isDisabled: true,
              fieldName: "schoolYear",
              id: "parent-1-schoolYear",
            },
            {
              fieldName: "role",
              id: "parent-1-role",
              label: "Role",
              customLabel2: "test",
              name: "role",
              value: "Parent Teacher",
              isDisabled: true,
              placeHolderText: "Role",
              fieldType: FIELD_TYPES.TEXT,
            },
          ];
        } else {
          item.role = "parent";
          item.ddConfigs = [
            {
              customLabel: "test",
              customLabel2: "test",
              name: "firstName",
              fieldType: FIELD_TYPES.TEXT,
              fieldName: "firstName",
              id: "parent-1-first-name",
              label: "First Name",
              value: "",
              placeHolderText: "Enter First Name",
            },
            {
              fieldName: "lastName",
              customLabel: "test",
              label: "Last Name",
              fieldType: FIELD_TYPES.TEXT,
              name: "lastName",
              placeHolderText: "Enter Last Name",
              id: "parent-1-last-name",
              value: "",
            },
            {
              name: "emailId",
              label: "Email ID",
              value: "",
              customLabel: "test",
              fieldName: "emailId",
              fieldType: FIELD_TYPES.TEXT,
              placeHolderText: "Enter Email ID",
              id: "parent-1-emailId",
            },
            {
              name: "relationship",
              value: "",
              id: "parent-1-relationship",
              fieldType: FIELD_TYPES.SELECT,
              label: "Relationship",
              customLabel: "test",
              fieldName: "relationshipDDMenuItems",
              menuItems: this.state.relationshipDDMenuItems,
              placeHolderText: "Select Relationship",
            },
            {
              customLabel: "test",
              label: "Contact Number",
              fieldType: FIELD_TYPES.TEXT,
              fieldName: "contactNumber",
              id: "parent-1-contactNumber",
              value: "",
              name: "contactNumber",
              placeHolderText: "Enter Contact Number",
            },
            {
              value: {
                profile: "",
                fileName: "",
                contentType: "",
              },
              fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
              label: "Profile Image",
              fieldName: "profileImage",
              customLabel: "test",
              name: "profileImage",
              id: "parent-1-profile-image",
              placeHolderText: "Select File",
            },
            {
              name: "role",
              fieldName: "role",
              label: "Role",
              isDisabled: true,
              id: "parent-1-role",
              value: "Parent",
              customLabel: "test",
              fieldType: FIELD_TYPES.TEXT,
              placeHolderText: "Role",
            },
          ];
        }
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        parentInputConfigs: ddConfig,
      };
    });
  };

  handleSelectParentFromList = (itemId: any) => {
    const { parentList, userRoleList, isParentUserId } = this.state;

    const updatedParentList = parentList.map((item: any) => {
      if (item.id === itemId) {
        item.isSelected = !item.isSelected;
        if (isParentUserId === item.id) {
          this.setState({
            isParentUserId: "",
            isParentRole: "",
            isParentRoleId: "",
          });
        } else {
          this.setState({
            isParentUserId: item.id,
            isParentRole: item.role,
          });
        }
      } else {
        item.isSelected = false;
      }
      return item;
    });

    this.setState({ parentList: updatedParentList }, () => {
      if (this.state.isParentUserId !== "") {
        let isParentRoleIdFound = false;
        userRoleList.forEach((item: any) => {
          if (item.attributes.name === this.state.isParentRole) {
            this.setState({ isParentRoleId: item.attributes.id });
            isParentRoleIdFound = true;
          }
        });
        if (!isParentRoleIdFound) {
          this.setState({ isParentRoleId: "" });
        }
      } else {
        this.setState({ isParentRoleId: "" });
      }
    });
  };

  // Customizable Area End
}
// Customizable Area End
