import {
  englishSubjectIcon,
  historySubjectIcon,
  mathSubjectIcon,
  physicsSubjectIcon,
  psychologySubjectIcon,
  profilePic1,
  profilePic2,
  man,
  man2,
  man3,
  man4,
  man5,
  man6,
  sort,
  alert,
} from "../src/assets";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";

const studentCoursesList = [
  {
    id: 1,
    subjectName: "Science",
    subjectMarks: 76,
    lessonsCovered: 99,
    lessonsTotal: 100,
    status: "Completed",
    subjectIcon: physicsSubjectIcon,
    timeSpentInMin: 400,
    subjectBgColor: "#01C996",
    completionProgress: 100,
  },
  {
    id: 2,
    subjectName: "Mathematics",
    subjectMarks: 89,
    lessonsCovered: 72,
    lessonsTotal: 100,
    status: "Late",
    subjectIcon: mathSubjectIcon,
    timeSpentInMin: 500,
    subjectBgColor: "#0D46BC",
    completionProgress: 46,
  },
  {
    id: 3,
    subjectName: "English",
    subjectMarks: 59,
    lessonsCovered: 77,
    lessonsTotal: 100,
    status: "On Going",
    subjectIcon: englishSubjectIcon,
    timeSpentInMin: 500,
    subjectBgColor: "#019AFC",
    completionProgress: 63,
  },
  {
    id: 4,
    subjectName: "History",
    subjectMarks: 29,
    lessonsCovered: 89,
    lessonsTotal: 100,
    status: "Scheduled",
    subjectIcon: historySubjectIcon,
    timeSpentInMin: 500,
    subjectBgColor: "#FBD82C",
    completionProgress: 96,
  },
  {
    id: 5,
    subjectName: "Psychology",
    subjectMarks: 39,
    lessonsCovered: 98,
    lessonsTotal: 100,
    status: "Late",
    subjectIcon: psychologySubjectIcon,
    timeSpentInMin: 1099,
    subjectBgColor: "#FF0000",
    completionProgress: 41,
  },
];

const gradingComponent = [
  {
    value: 10,
    label: "All",
    id: 10,
  },
  {
    value: 1,
    label: "Class 10A",
    id: 1,
  },
  {
    value: 2,
    label: "Class 10B",
    id: 2,
  },
  {
    id: 3,
    label: "Class 9A",
    value: 3,
  },
  {
    value: 4,
    label: "Assignment",
    id: 4,
  },
];

const studentListTableBody = [
  {
    id: 1,
    Name: {
      icon: profilePic1,
      name: "Milton peery",
    },
    Progress: "64%",
    Q1Avg: "80%",
    Q2Avg: "80%",
    Q3Avg: "80%",
    Q4Avg: "80%",
    GPA: "85",
    LastAccess: "July 20, 2022",
  },
  {
    id: 2,
    Name: {
      name: "Prescott MacCaff",
      icon: man5,
    },
    Progress: "64%",
    Q1Avg: "80%",
    Q2Avg: "80%",
    Q3Avg: "80%",
    Q4Avg: "80%",
    GPA: "85",
    LastAccess: "July 20, 2022",
  },
  {
    id: 3,
    Name: {
      name: "Isak Johannesson",
      icon: man2,
    },
    Progress: "64%",
    Q1Avg: "80%",
    Q2Avg: "80%",
    Q3Avg: "80%",
    Q4Avg: "80%",
    GPA: "85",
    LastAccess: "July 20, 2022",
  },
  {
    id: 3,
    Name: {
      name: "Jason Bailey",
      icon: man4,
    },
    Progress: "64%",
    Q1Avg: "80%",
    Q2Avg: "80%",
    Q3Avg: "80%",
    Q4Avg: "80%",
    GPA: "85",
    LastAccess: "July 20, 2022",
  },
];

const studentListTableColumns = [
  {
    id: "Name",
    columnId: 1,
    label: "Name",
    type: tableColumnTypes.STUDENT_ICON,
  },
  {
    id: "Progress",
    columnId: 2,
    label: "Progress",
    icon: sort,
    type: tableColumnTypes.GRADEBOOK_PROGRESS_BAR,
  },
  {
    id: "Q1Avg",
    columnId: 3,
    label: "Q1 Avg",
    icon: sort,
    type: tableColumnTypes.GRADEBOOK_AVG,
  },
  {
    id: "Q2Avg",
    columnId: 4,
    label: "Q2 Avg",
    icon: sort,
    type: tableColumnTypes.GRADEBOOK_AVG,
  },
  {
    id: "Q3Avg",
    columnId: 5,
    label: "Q3 Avg",
    icon: sort,
    type: tableColumnTypes.GRADEBOOK_AVG,
  },
  {
    id: "Q4Avg",
    columnId: 6,
    label: "Q4 Avg",
    icon: sort,
    type: tableColumnTypes.GRADEBOOK_AVG,
  },
  {
    id: "GPA",
    columnId: 7,
    label: "GPA",
    icon: sort,
    type: tableColumnTypes.PROGRESS_TEXT,
  },
  {
    id: "LastAccess",
    columnId: 8,
    label: "Last Access",
    icon: sort,
    type: tableColumnTypes.DUE_DATE,
  },
];

const tenantSubjectColumn = [
  {
    id: "Name",
    columnId: 1,
    label: "Name",
    type: tableColumnTypes.ICON_NAME,
  },
  {
    id: "Progress",
    columnId: 2,
    label: "Progress",
    type: tableColumnTypes.ProgressBar,
  },
];

const tenantSubjectBody = [
  {
    id: 1,
    Name: {
      name: "Milton peery",
      icon: man,
    },
    Progress: {
      value1: 50,
      value2: 25,
      value3: 25,
    },
  },
  {
    id: 2,
    Name: {
      name: "Amelia Cabel",
      icon: man2,
    },
    Progress: {
      value1: 30,
      value2: 40,
      value3: 30,
    },
  },
  {
    id: 3,
    Name: {
      name: "Isak Johannesson",
      icon: man3,
    },
    Progress: {
      value1: 30,
      value2: 30,
      value3: 40,
    },
  },
  {
    id: 4,
    Name: {
      icon: man4,
      name: "Jason Bailey",
    },
    Progress: {
      value1: 15,
      value2: 60,
      value3: 25,
    },
  },
  {
    id: 4,
    Name: {
      name: "Prescott MacCaff…",
      icon: man6,
    },
    Progress: {
      value1: 40,
      value2: 40,
      value3: 20,
    },
  },
  {
    id: 5,
    Name: {
      name: "Vicente de la Cruz",
      icon: man5,
    },
    Progress: {
      value1: 10,
      value2: 80,
      value3: 10,
    },
  },
];

const progressTableColumns = [
  {
    id: "Name",
    columnId: 1,
    label: "Name",
    type: tableColumnTypes.ICON,
  },
  {
    id: "TermAvg",
    columnId: 2,
    label: "Term Avg",
    type: tableColumnTypes.PROGRESS_TEXT,
  },
  {
    id: "YTD",
    columnId: 3,
    label: "YTD",
    type: tableColumnTypes.PROGRESS_TEXT,
  },
];

const progressSubjectBody = [
  {
    id: 1,
    Name: {
      name: "Milton peery",
      icon: man,
    },
    TermAvg: 80,
    YTD: 80,
  },
  {
    id: 2,
    Name: {
      name: "Amelia Cabel",
      icon: man2,
    },
    TermAvg: 80,
    YTD: 80,
  },
  {
    id: 3,
    Name: {
      name: "Isak Johannesson",
      icon: man3,
    },
    TermAvg: 80,
    YTD: 80,
  },
  {
    id: 4,
    Name: {
      name: "Jason Bailey",
      icon: man4,
    },
    TermAvg: 80,
    YTD: 80,
  },
  {
    id: 4,
    Name: {
      name: "Prescott MacCaff…",
      icon: man6,
    },
    TermAvg: 80,
    YTD: 80,
  },
  {
    id: 5,
    Name: {
      name: "Vicente de la Cruz",
      icon: man5,
    },
    TermAvg: 80,
    YTD: 80,
  },
];

const assignedTableColumns = [
  {
    id: "Title",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.ASSIGNED_TITLE,
  },
  {
    id: "Type",
    columnId: 2,
    label: "Type",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "Submitted",
    columnId: 3,
    label: "Submitted %",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "AvgGrade",
    columnId: 4,
    label: "Avg Grade",
    type: tableColumnTypes.ASSIGNED_ICON,
  },
  {
    id: "DueDate",
    columnId: 2,
    label: "Due Date",
    type: tableColumnTypes.DUE_DATE,
  },
];

const assignedSubjectBody = [
  {
    id: 1,
    Title: "Lorem Ipsum Dolor Sit Amet",
    Type: "Exam",
    Submitted: "85%",
    AvgGrade: {
      icon: alert,
      name: "87%",
    },
    DueDate: "July 20, 2022",
  },
  {
    id: 2,
    Title: "Roman History Quiz - Part I",
    Type: "Quiz",
    Submitted: "85%",
    AvgGrade: {
      icon: alert,
      name: "82%",
    },
    DueDate: "July 20, 2022",
  },
  {
    id: 3,
    Title: "English Assignment - Unit 1",
    Type: "Exam",
    Submitted: "85%",
    AvgGrade: {
      icon: alert,
      name: "",
    },
    DueDate: "July 20, 2022",
  },
  {
    id: 4,
    Title: "Tenses Assignment - Part 2",
    Type: "Exam",
    Submitted: "85%",
    AvgGrade: {
      icon: alert,
      name: "",
    },
    DueDate: "July 20, 2022",
  },
  {
    id: 4,
    Title: "India Map Quiz",
    Type: "Quiz",
    Submitted: "85%",
    AvgGrade: {
      name: "79%",
    },
    DueDate: "July 20, 2022",
  },
  {
    id: 5,
    Title: "World Map Quiz",
    Type: "Exam",
    Submitted: "85%",
    AvgGrade: {
      name: "90%",
    },
    DueDate: "July 20, 2022",
  },
];

const schoolSubjectsList = [
  {
    id: 1,
    subject: {
      name: "Physics",
      icon: physicsSubjectIcon,
      bgColor: "#01C996",
    },
    subjectId: 1,
    firstQuarterScore: 86,
    secondQuarterScore: 43,
    thirdQuarterScore: 78,
    fourthQuarterScore: 88,
    progress: "75%",
    totalScore: 87,
    grade: "B+",
    coursesDetails: {
      assignment: {
        firstQuarterScore: 45,
        secondQuarterScore: 43,
        thirdQuarterScore: 48,
        fourthQuarterScore: 85,
        totalScore: 87,
        weight: 15,
      },
      quizzes: {
        firstQuarterScore: 45,
        secondQuarterScore: 43,
        thirdQuarterScore: 48,
        fourthQuarterScore: 85,
        totalScore: 87,
        weight: 20,
      },
      exams: {
        firstQuarterScore: 45,
        secondQuarterScore: 43,
        thirdQuarterScore: 48,
        fourthQuarterScore: 85,
        totalScore: 87,
        weight: 30,
      },
      projects: {
        firstQuarterScore: 35,
        secondQuarterScore: 43,
        thirdQuarterScore: 48,
        fourthQuarterScore: 83,
        totalScore: 67,
        weight: 25,
      },
      oral: {
        firstQuarterScore: 45,
        secondQuarterScore: 43,
        thirdQuarterScore: 48,
        fourthQuarterScore: 45,
        totalScore: 78,
        weight: 10,
      },
    },
  },
  {
    id: 2,
    subject: {
      name: "Algebra",
      icon: mathSubjectIcon,
      bgColor: "#0D46BC",
    },
    subjectId: 2,
    firstQuarterScore: 87,
    secondQuarterScore: 43,
    thirdQuarterScore: 88,
    fourthQuarterScore: 88,
    progress: "33%",
    totalScore: 87,
    grade: "B",
    coursesDetails: {
      assignment: {
        firstQuarterScore: 33,
        secondQuarterScore: 43,
        thirdQuarterScore: 33,
        fourthQuarterScore: 85,
        totalScore: 56,
        weight: 15,
      },
      quizzes: {
        firstQuarterScore: 45,
        secondQuarterScore: 66,
        thirdQuarterScore: 56,
        fourthQuarterScore: 85,
        totalScore: 66,
        weight: 20,
      },
      exams: {
        firstQuarterScore: 23,
        secondQuarterScore: 43,
        thirdQuarterScore: 34,
        fourthQuarterScore: 56,
        totalScore: 46,
        weight: 10,
      },
      projects: {
        firstQuarterScore: 35,
        secondQuarterScore: 34,
        thirdQuarterScore: 56,
        fourthQuarterScore: 77,
        totalScore: 67,
        weight: 25,
      },
      oral: {
        firstQuarterScore: 45,
        secondQuarterScore: 22,
        thirdQuarterScore: 22,
        fourthQuarterScore: 45,
        totalScore: 40,
        weight: 20,
      },
    },
  },
  {
    id: 3,
    subject: {
      name: "Roman History",
      icon: historySubjectIcon,
      bgColor: "#FF0000",
    },
    subjectId: 3,
    firstQuarterScore: 96,
    secondQuarterScore: 53,
    thirdQuarterScore: 78,
    fourthQuarterScore: 88,
    progress: "25%",
    totalScore: 87,
    grade: "A+",
    coursesDetails: {
      assignment: {
        firstQuarterScore: 88,
        secondQuarterScore: 88,
        thirdQuarterScore: 88,
        fourthQuarterScore: 88,
        totalScore: 98,
        weight: 15,
      },
      quizzes: {
        firstQuarterScore: 67,
        secondQuarterScore: 43,
        thirdQuarterScore: 44,
        fourthQuarterScore: 87,
        totalScore: 44,
        weight: 20,
      },
      exams: {
        firstQuarterScore: 45,
        secondQuarterScore: 43,
        thirdQuarterScore: 19,
        fourthQuarterScore: 56,
        totalScore: 45,
        weight: 30,
      },
      projects: {
        firstQuarterScore: 55,
        secondQuarterScore: 43,
        thirdQuarterScore: 55,
        fourthQuarterScore: 89,
        totalScore: 56,
        weight: 25,
      },
      oral: {
        firstQuarterScore: 55,
        secondQuarterScore: 56,
        thirdQuarterScore: 23,
        fourthQuarterScore: 67,
        totalScore: 78,
        weight: 10,
      },
    },
  },
  {
    id: 4,
    subject: {
      name: "Victorian Literature",
      icon: englishSubjectIcon,
      bgColor: "#FBD82C",
    },
    subjectId: 4,
    firstQuarterScore: 84,
    secondQuarterScore: 46,
    thirdQuarterScore: 98,
    fourthQuarterScore: 88,
    progress: "75%",
    totalScore: 87,
    grade: "C+",
    coursesDetails: {
      assignment: {
        firstQuarterScore: 45,
        secondQuarterScore: 66,
        thirdQuarterScore: 56,
        fourthQuarterScore: 85,
        totalScore: 77,
        weight: 15,
      },
      quizzes: {
        firstQuarterScore: 87,
        secondQuarterScore: 89,
        thirdQuarterScore: 80,
        fourthQuarterScore: 90,
        totalScore: 88,
        weight: 20,
      },
      exams: {
        firstQuarterScore: 45,
        secondQuarterScore: 43,
        thirdQuarterScore: 65,
        fourthQuarterScore: 23,
        totalScore: 56,
        weight: 30,
      },
      projects: {
        firstQuarterScore: 44,
        secondQuarterScore: 43,
        thirdQuarterScore: 44,
        fourthQuarterScore: 44,
        totalScore: 44,
        weight: 20,
      },
      oral: {
        firstQuarterScore: 22,
        secondQuarterScore: 43,
        thirdQuarterScore: 11,
        fourthQuarterScore: 45,
        totalScore: 38,
        weight: 15,
      },
    },
  },
  {
    id: 5,
    subject: {
      name: "Creative Writing",
      icon: englishSubjectIcon,
      bgColor: "#FBD82C",
    },
    subjectId: 5,
    firstQuarterScore: 86,
    secondQuarterScore: 23,
    thirdQuarterScore: 73,
    fourthQuarterScore: 58,
    progress: "50%",
    totalScore: 87,
    grade: "B+",
    coursesDetails: {
      assignment: {
        firstQuarterScore: 92,
        secondQuarterScore: 56,
        thirdQuarterScore: 88,
        fourthQuarterScore: 95,
        totalScore: 87,
        weight: 15,
      },
      quizzes: {
        firstQuarterScore: 45,
        secondQuarterScore: 43,
        thirdQuarterScore: 57,
        fourthQuarterScore: 75,
        totalScore: 68,
        weight: 20,
      },
      exams: {
        firstQuarterScore: 67,
        secondQuarterScore: 77,
        thirdQuarterScore: 87,
        fourthQuarterScore: 97,
        totalScore: 90,
        weight: 30,
      },
      projects: {
        firstQuarterScore: 81,
        secondQuarterScore: 82,
        thirdQuarterScore: 83,
        fourthQuarterScore: 84,
        totalScore: 81,
        weight: 25,
      },
      oral: {
        firstQuarterScore: 61,
        secondQuarterScore: 62,
        thirdQuarterScore: 63,
        fourthQuarterScore: 64,
        totalScore: 61,
        weight: 10,
      },
    },
  },
];

const subjectTableColumns = [
  {
    id: "subject",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.SUBJECT_ICON_NAME,
    width: "230px",
    maxWidth: "230px",
  },
  {
    id: "progress",
    columnId: 2,
    label: "Progress",
    type: tableColumnTypes.GRADEBOOK_PROGRESS_BAR,
    width: "160px",
    maxWidth: "160px",
  },
  {
    id: "firstQuarterScore",
    columnId: 3,
    label: "1st Qtr",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "80px",
  },
  {
    id: "secondQuarterScore",
    columnId: 4,
    label: "2nd Qtr",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "80px",
  },
  {
    id: "thirdQuarterScore",
    columnId: 5,
    label: "3rd Qtr",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "80px",
  },
  {
    id: "fourthQuarterScore",
    columnId: 6,
    label: "4th Qtr",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "80px",
  },
  {
    id: "totalAvg",
    columnId: 7,
    label: "Total Avg%",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "100px",
  },
  {
    id: "grade",
    columnId: 8,
    label: "Grade",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "80px",
  },
];

const subjectCoursesDetailsTableColumns = [
  {
    id: "title",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.TEXT,
    width: "235px",
    maxWidth: "235px",
  },
  {
    id: "weight",
    columnId: 2,
    label: "Weight",
    type: tableColumnTypes.TEXT,
    width: "170px",
    maxWidth: "170px",
  },
  {
    id: "firstQuarterScore",
    columnId: 3,
    label: "1st Qtr",
    type: tableColumnTypes.TEXT,
    width: "95px",
    maxWidth: "95px",
  },
  {
    id: "secondQuarterScore",
    columnId: 4,
    label: "2nd Qtr",
    type: tableColumnTypes.TEXT,
    width: "90px",
    maxWidth: "90px",
  },
  {
    id: "thirdQuarterScore",
    columnId: 5,
    label: "3rd Qtr",
    type: tableColumnTypes.TEXT,
    width: "95px",
    maxWidth: "95px",
  },
  {
    id: "fourthQuarterScore",
    columnId: 6,
    label: "4th Qtr",
    type: tableColumnTypes.TEXT,
    width: "90px",
    maxWidth: "90px",
  },
  {
    id: "totalScore",
    columnId: 7,
    label: "Total",
    type: tableColumnTypes.TEXT,
    width: "70px",
    maxWidth: "70px",
  },
];

const studentBehaviorData = [
  {
    name: "Observation Title 1",
    id: 1,
    data: [
      {
        id: 1,
        title: "Main Skill 1",
        subSkills: [
          {
            id: 1,
            title: "Sub skill 1",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
          {
            id: 2,
            title: "Sub skill 2",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
          {
            id: 3,
            title: "Sub skill 3",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
          {
            id: 4,
            title: "Sub skill 4",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
        ],
      },
      {
        id: 2,
        title: "Main Skill 2",
        subSkills: [
          {
            id: 1,
            title: "Sub skill 1",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
          {
            id: 2,
            title: "Sub skill 2",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
          {
            id: 3,
            title: "Sub skill 3",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
          {
            id: 4,
            title: "Sub skill 4",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
        ],
      },
      {
        id: 3,
        title: "Main Skill 3",
        subSkills: [
          {
            id: 1,
            title: "Sub skill 1",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
          {
            id: 2,
            title: "Sub skill 2",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
        ],
      },
    ],
    menu: ["A", "B", "C", "D"],
    isQ1Selected: false,
    isQ2Selected: false,
    isQ3Selected: false,
    isQ4Selected: false,
  },
  {
    name: "Observation Title 2",
    id: 2,
    data: [
      {
        id: 1,
        title: "Main Skill 1",
        subSkills: [
          {
            id: 1,
            title: "Sub skill 1",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
          {
            id: 2,
            title: "Sub skill 2",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
          {
            id: 3,
            title: "Sub skill 3",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
          {
            id: 4,
            title: "Sub skill 4",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
        ],
      },
      {
        id: 2,
        title: "Main Skill 2",
        subSkills: [
          {
            id: 1,
            title: "Sub skill 1",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
          {
            id: 2,
            title: "Sub skill 2",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q1Target: "",
            q2Target: "",
            q3Target: "",
            q4Target: "",
          },
        ],
      },
    ],
    menu: ["Good", "Excellent", "Needs improvement"],
    isQ1Selected: false,
    isQ2Selected: false,
    isQ3Selected: false,
    isQ4Selected: false,
  },
];

const registrarStudentGradebookMockData = [
  {
    name: "Observation Title 1",
    id: 1,
    data: [
      {
        id: 1,
        title: "Main Skill 1",
        q1: "B",
        q2: "A",
        q3: "B",
        q4: "B",
        q1Target: null,
        q2Target: null,
        q3Target: null,
        q4Target: null,
      },
      {
        id: 2,
        title: "Main Skill 2",
        q1: "C",
        q2: "C",
        q3: "A",
        q4: "A",
        q1Target: null,
        q2Target: null,
        q3Target: null,
        q4Target: null,
      },
      {
        id: 3,
        title: "Main Skill 3",
        q1: "D",
        q2: "D",
        q3: "B",
        q4: "A",
        q1Target: null,
        q2Target: null,
        q3Target: null,
        q4Target: null,
      },
    ],
    menu: ["A", "B", "C", "D"],
    isQ1Selected: false,
    isQ2Selected: false,
    isQ3Selected: false,
    isQ4Selected: false,
  },
  {
    name: "Observation Title 2",
    id: 2,
    data: [
      {
        id: 1,
        title: "Main Skill 1",
        q1: "Excellent",
        q2: "Excellent",
        q3: "Good",
        q4: "Excellent",
        q1Target: null,
        q2Target: null,
        q3Target: null,
        q4Target: null,
      },
      {
        id: 2,
        title: "Main Skill 2",
        q1: "Good",
        q2: "Needs improvement",
        q3: "Excellent",
        q4: "Good",
        q1Target: null,
        q2Target: null,
        q3Target: null,
        q4Target: null,
      },
      {
        id: 3,
        title: "Main Skill 3",
        q1: "Excellent",
        q2: "Good",
        q3: "Excellent",
        q4: "Needs improvement",
        q1Target: null,
        q2Target: null,
        q3Target: null,
        q4Target: null,
      },
    ],
    menu: ["Good", "Excellent", "Needs improvement"],
    isQ1Selected: false,
    isQ2Selected: false,
    isQ3Selected: false,
    isQ4Selected: false,
  },
];

export {
  studentCoursesList,
  gradingComponent,
  studentListTableBody,
  studentListTableColumns,
  tenantSubjectColumn,
  tenantSubjectBody,
  progressTableColumns,
  progressSubjectBody,
  assignedTableColumns,
  assignedSubjectBody,
  schoolSubjectsList,
  subjectTableColumns,
  subjectCoursesDetailsTableColumns,
  studentBehaviorData,
  registrarStudentGradebookMockData
};
