// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Button, Tooltip } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import QuizActivityComponentController, {
  Props,
} from "./QuizActivityComponentController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { infoIcon, redPenIcon, redTrashIcon } from "./assets";
import AddObjectiveQuestionModal from "./AddObjectiveQuestionModal.web";
import AddSubjectiveQuestionModal from "./AddSubjectiveQuestionModal.web";
import DeleteConfirmDialogWeb from "./DeleteConfirmDialog.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    activityDetailBox: {
      minWidth: "500px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "97%",
      },
    },
    questionCard: {
      border: "1px solid #f1f1f1",
      borderRadius: "6px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        width: "84%",
      },
      marginBottom: "25px",
    },
    pagePadding: {
      padding: "15px",
    },
    questionContainer: {
      gap: "20px",
      marginTop: "20px",
    },
    aboutText: {
      marginTop: "5px",
    },
    examTitle: {
      display: "flex",
      alignItems: "center",
      gap: "7px",
      textTransform: "capitalize",
    },
    explanation: {
      borderTop: "1px solid #f1f1f1",
      borderBottom: "1px solid #f1f1f1",
      padding: "15px 20px",
    },
    questionDeleteBtn: {
      minWidth: "32px",
      maxWidth: "32px",
      height: "32px",
      border: "1px solid #ED1111",
      borderRadius: "6px",
    },
    questionEditBtn: {
      minWidth: "32px",
      maxWidth: "32px",
      height: "32px",
      border: "1px solid #0D46BC",
      borderRadius: "6px",
    },
    bottomBorder: {
      borderBottom: "1px solid #f1f1f1",
    },
  });

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: "#EEF8FF",
  },
  tooltip: {
    backgroundColor: "#EEF8FF",
    color: "#009DFF",
    minWidth: "200px",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #EEF8FF",
    borderRadius: "10px",
    padding: "9 12 9 12",
    textTransform: "capitalize",
  },
}))(Tooltip);
// Customizable Area End

// Customizable Area Start
export class QuizActivityComponent extends QuizActivityComponentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  toolTipBox() {
    const { detail, classes } = this.props;
    return (
      <Box>
        <Typography className={classes.fontText12}>
          {" "}
          Assessment Type: <b>{detail?.gradingComponent}</b>
        </Typography>
        <Typography className={classes.fontText12}>
          {" "}
          Evaluation Type: <b>{detail?.evaluationType}</b>
        </Typography>
        {detail?.maxScore && (
          <Typography className={classes.fontText12}>
            {" "}
            Max Score: <b>{detail?.maxScore}</b>
          </Typography>
        )}
        {detail?.allowedAttempts && (
          <Typography className={classes.fontText12}>
            {" "}
            Allowed Attempts: <b>{detail?.allowedAttempts}</b>
          </Typography>
        )}
        {detail?.showCorrectAnswer && (
          <Typography className={classes.fontText12}>
            {" "}
            Show Answers to Students:{" "}
            <b>{detail?.showCorrectAnswer ? "Yes" : "No"}</b>
          </Typography>
        )}
      </Box>
    );
  }
  myCourseQuiz = (isMyCoursesPath:boolean,btnStyle:object , btnOnClick:() => void, testId:string) => {    
    return !isMyCoursesPath ?
     (
       <CustomButton
         btnText="Add Another Question"
         btnStyle={btnStyle}
         buttonId={testId}
         buttonVariant={"outlined"}
         isDisabled={false}
         type={"button"}
         handleButtonClick={btnOnClick}
       />)
       : null
   }
   getImageQuize = (isMyCoursesPath:boolean , redpenFun:(dataId:number|string) => void , redTraFun:(dataId:number|string) => void , dataId:number|string , testEdit:string ,testDelete:string) => {
    return  !isMyCoursesPath && (
        <>
        <img src={redPenIcon} style={{border:"1px solid blue", borderRadius:"5px", padding:"7px" }} alt='redpen' 
       data-test-id={testEdit} onClick={() => redpenFun(dataId)}
      />                         
      <img src={redTrashIcon} alt="" style={{border:"1px solid red", borderRadius:"5px",padding:"7px"}} data-test-id={testDelete}  onClick={() =>redTraFun(dataId)}/>
     </>)
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      openSubjectiveQuestionModal,
      openObjectiveQuestionModal,
      editQuestion,
      selectedQuestionDetail,
      openDeleteQuestionConfirmModal,
      loadManualUi,
    } = this.state;
    const { classes, detail, questionList, isFromRegistrar } = this.props;

    return (
      <Box className={classes.activityDetailBox}>
        <Grid
          container
          direction="column"
          data-test-id={detail.typeOfActivity + "Sec"}
          className={`${classes.pagePadding}`}
        >
          {detail?.activityTitle && (
            <Grid xs={12} className={classes.titleGrid} item>
              <Typography
                component={"span"}
                className={`${classes.textPrimaryColor}  ${classes.fontText20} ${classes.examTitle} ${classes.fontBold600}`}
              >
                {detail?.activityTitle}
                <HtmlTooltip
                  placement="right-start"
                  title={<React.Fragment>{this.toolTipBox()}</React.Fragment>}
                >
                  <img src={infoIcon} alt="info" />
                </HtmlTooltip>
              </Typography>
            </Grid>
          )}

          {detail?.instruction && (
            <Grid xs={12} item className={classes.detailGrid}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontBold500} ${classes.fontText16}`}
                dangerouslySetInnerHTML={{ __html: detail.instruction }}
                component={"p"}
              ></Typography>
            </Grid>
          )}

          {detail.evaluationType === "objective" && (
            <Box
              className={`${classes.questionContainer}`}
              display={"flex"}
              flexDirection={"column"}
            >
              {questionList && questionList.length > 0 ? (
                <Box>
                  {questionList?.map((questionObj: any, index) => (
                    <Box
                      className={`${classes.questionCard}`}
                      width={"94%"}
                      display={"flex"}
                      key={questionObj?.id}
                      flexDirection={"column"}
                      borderRadius={"6px"}
                    >
                      <Box p={"20px 20px 0 20px"}>
                        <Box
                          display={"flex"}
                          width={"100%"}
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <Typography
                              className={`${classes.textCommonColor}`}
                              data-test-id='question'
                            >
                              Question {index + 1}
                            </Typography>
                          </Box>
                        </Box>

                        <Box>
                          <Typography
                            className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                            dangerouslySetInnerHTML={{
                              __html: questionObj?.question,
                            }}
                          />
                        </Box>

                        <Box
                          gridGap={"4px"}
                          margin={"12px 0"}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          {questionObj.options.map((option: any) => (
                            <Box
                              className={
                                option.inputValue === questionObj.correctAnswer
                                  ? `${classes.primaryColor4}`
                                  : `${classes.textCommonColor}`
                              }
                              key={option?.id}
                              display={"flex"}
                            >
                              <Box mr={"5px"}>
                                <Typography
                                  className={`${classes.fontBold600}`}
                                >
                                  {option?.optionLetter}:
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className={`${classes.fontBold500}`}
                                >
                                  {option?.inputValue}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      <Box
                        className={`${classes.explanation} ${classes.textCommonColor}`}
                      >
                        <Box>
                          <Typography className={`${classes.fontBold600}`}>
                            Explanation:
                          </Typography>
                        </Box>
                        <Box sx={{ marginTop: "-2px" }}>
                          <Typography
                            className={`${classes.fontBold500}`}
                            dangerouslySetInnerHTML={{
                              __html: questionObj?.explanation,
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        p={"20px"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Box display={"flex"}>
                          <Box marginRight={"5px"} component={"span"}>
                            <Typography
                              className={`${classes.textCommonColor}`}
                            >
                              Marks:
                            </Typography>
                          </Box>
                          <Box component={"span"}>
                            <Typography
                              className={`${classes.textPrimaryColor}`}
                            >
                              {questionObj?.marks}
                            </Typography>
                          </Box>
                        </Box>
                        {!isFromRegistrar && (
                          <Box display={"flex"} gridGap={"10px"}>
                           {this.getImageQuize(this.state.isMyCoursesPath, (dataId) => this.handleEditObjectiveQuestion(questionObj.id),  (dataId) => this.handleDeleteQuizObjectiveQuestion(questionObj.id) , questionObj.id , 'objective-question-edit-73' ,'objective-question-delete-73')}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ))}
                   
                  {!isFromRegistrar && (
                    <Box className={classes.footerButtonsBox}>
                      {this.myCourseQuiz(this.state.isMyCoursesPath ,addQuestionButton ,this.handleAddObjectiveQuestion , 'objective-add-another-question-button')}
                    </Box>
                  )}
                </Box>
              ) : (
                <CustomEmptyResultComponent
                  message="No question added for this Quiz"
                  showActionButton={!isFromRegistrar}
                  buttonText="Add Question"
                  handleButtonClick={this.handleAddObjectiveQuestion}
                />
              )}
            </Box>
          )}

          {detail.evaluationType === "subjective" && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              className={`${classes.questionContainer}`}
            >
              {questionList && questionList.length > 0 ? (
                <Box>
                  {questionList?.map(
                    (subjectiveQuizQuestionObj: any, index) => (
                      <Box
                        className={`${classes.questionCard}`}
                        key={subjectiveQuizQuestionObj?.id}
                        borderRadius={"6px"}
                        display={"flex"}
                        flexDirection={"column"}
                        width={"94%"}
                      >
                        <Box p={"20px"} className={classes.bottomBorder}>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            width={"100%"}
                          >
                            <Box>
                              <Typography
                                className={`${classes.textCommonColor}`}
                              >
                                Question {index + 1}
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                              dangerouslySetInnerHTML={{
                                __html: subjectiveQuizQuestionObj?.question,
                              }}
                            />
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          p={"20px"}
                        >
                          <Box display={"flex"}>
                            <Box component={"span"} marginRight={"5px"}>
                              <Typography
                                className={`${classes.textCommonColor}`}
                              >
                                Marks:
                              </Typography>
                            </Box>
                            <Box component={"span"}>
                              <Typography
                                className={`${classes.textPrimaryColor}`}
                              >
                                {subjectiveQuizQuestionObj?.marks}
                              </Typography>
                            </Box>
                            <Box
                              component={"span"}
                              marginLeft={"15px"}
                              marginRight={"5px"}
                            >
                              <Typography
                                className={`${classes.textCommonColor}`}
                              >
                                Word Limit:
                              </Typography>
                            </Box>
                            <Box component={"span"}>
                              <Typography
                                className={`${classes.textPrimaryColor}`}
                              >
                                {subjectiveQuizQuestionObj?.wordLimit}
                              </Typography>
                            </Box>
                          </Box>
                          {!isFromRegistrar && <Box display={"flex"} gridGap={"10px"}>
                           {this.getImageQuize(this.state.isMyCoursesPath, (dataId) => this.handleEditSubjectiveQuestion(subjectiveQuizQuestionObj.id),  (dataId) => this.handleDeleteQuizObjectiveQuestion(subjectiveQuizQuestionObj.id) , subjectiveQuizQuestionObj.id , 'subjective-question-edit-73' ,'subjective-question-delete-73')}
                          </Box>}
                        </Box>
                      </Box>
                    )
                  )}
                  {!isFromRegistrar && 
                      <Box className={classes.footerButtonsBox}>
                      {this.myCourseQuiz(this.state.isMyCoursesPath ,addQuestionButton ,this.handleAddSubjectiveQuestion , 'subjective-quiz-add-another-question-button')}
                      </Box>                  
                   }
                </Box>
              ) : (
                <CustomEmptyResultComponent
                  message="No question added for this Quiz"
                  showActionButton={!isFromRegistrar}
                  buttonText="Add Question"
                  handleButtonClick={this.handleAddSubjectiveQuestion}
                />
              )}
            </Box>
          )}

          {detail.evaluationType === "manual" && (
            <Box
              data-test-id="manual-quiz-box"
              display={"flex"}
              flexDirection={"column"}
              className={`${classes.questionContainer}`}
            >
              {questionList && questionList.length > 0 ? (
                <Box>
                  {questionList?.map((manualQuizQuestionObj: any, index) => (
                    <Box
                      className={`${classes.questionCard}`}
                      borderRadius={"6px"}
                      display={"flex"}
                      flexDirection={"column"}
                      key={manualQuizQuestionObj?.id}
                      width={"94%"}
                      data-test-id={`manual-quiz-box-${index + 1}`}
                    >
                      <Box p={"20px"} className={classes.bottomBorder}>
                        <Box
                          display={"flex"}
                          data-test-id={`manual-quiz-question-box-${manualQuizQuestionObj?.id}`}
                          justifyContent={"space-between"}
                          width={"100%"}
                        >
                          <Box>
                            <Typography
                              data-test-id={`manual-quiz-box-question-${
                                index + 1
                              }`}
                              className={`${classes.textCommonColor}`}
                            >
                              Question {index + 1}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            data-test-id={`manual-question-text-${manualQuizQuestionObj?.id}`}
                            className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                            dangerouslySetInnerHTML={{
                              __html: manualQuizQuestionObj?.question,
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        data-test-id="manual-quiz-marks-box"
                        p={"20px"}
                      >
                        <Box display={"flex"}>
                          <Box
                            data-test-id="manual-quiz-marks-box-1"
                            component={"span"}
                            marginRight={"5px"}
                          >
                            <Typography
                              data-test-id="manual-quiz-marks-text"
                              className={`${classes.textCommonColor}`}
                            >
                              Marks:
                            </Typography>
                          </Box>
                          <Box component={"span"}>
                            <Typography
                              data-test-id="manual-quiz-marks-value"
                              className={`${classes.textPrimaryColor}`}
                            >
                              {manualQuizQuestionObj?.marks}
                            </Typography>
                          </Box>
                        </Box>
                        {!isFromRegistrar && <Box
                          data-test-id="manual-quiz-button-box-1"
                          display={"flex"}
                          gridGap={"10px"}
                        >
                          {this.getImageQuize(this.state.isMyCoursesPath, (dataId) => this.handleEditSubjectiveQuestion(manualQuizQuestionObj.id),  (dataId) => this.handleDeleteQuizObjectiveQuestion(manualQuizQuestionObj.id) , manualQuizQuestionObj.id , 'manual-question-edit-73' ,'manual-question-delete-73')}
                        </Box>} 
                      </Box>
                    </Box>
                  ))}
                  {!isFromRegistrar &&                   
                  <Box className={classes.footerButtonsBox}>
                    {this.myCourseQuiz(this.state.isMyCoursesPath ,addQuestionButton ,this.handleAddSubjectiveQuestion , 'exam-add-another-objquestion-button')}
                  </Box> 
                  }
                </Box>
              ) : (
                <CustomEmptyResultComponent
                  message="No question added for this Quiz"
                  showActionButton={!isFromRegistrar}
                  buttonText="Add Item"
                  handleButtonClick={this.handleAddSubjectiveQuestion}
                />
              )}
            </Box>
          )}
        </Grid>

        {openSubjectiveQuestionModal && (
          <AddSubjectiveQuestionModal
            open={openSubjectiveQuestionModal}
            explanation={false}
            isEdit={editQuestion}
            onConfirm={this.handleCloseSubjectiveQuestionModal}
            handleAddSubjectiveQuestion={(data: any) =>
              this.handleQuizAddQuestion(data)
            }
            onCancel={this.handleCloseSubjectiveQuestionModal}
            handleUpdateSubjectiveQuestion={(data: any) =>
              this.handleQuizUpdateQuestion(data)
            }
            selectedSubjectiveQuestionData={selectedQuestionDetail}
            activityId={detail?.activityId}
            handleDeleteSubjectiveQuestion={() =>
              this.handleDeleteQuizObjectiveQuestion(selectedQuestionDetail?.id)
            }
            forManual={loadManualUi}
          />
        )}

        {openObjectiveQuestionModal && (
          <AddObjectiveQuestionModal
            open={openObjectiveQuestionModal}
            isEdit={editQuestion}
            onCancel={this.handleCloseObjectiveQuestionModal}
            onConfirm={this.handleCloseObjectiveQuestionModal}
            handleAddQuestion={(data: any) => this.handleQuizAddQuestion(data)}
            handleUpdateQuestion={(data: any) =>
              this.handleQuizUpdateQuestion(data)
            }
            activityId={detail?.activityId}
            selectedQuestionData={selectedQuestionDetail}
            handleDeleteQuestion={() =>
              this.handleDeleteQuizObjectiveQuestion(selectedQuestionDetail?.id)
            }
          />
        )}

        <DeleteConfirmDialogWeb
          open={openDeleteQuestionConfirmModal}
          headingTitle="Question"
          itemTitle={"this question"}
          onCancel={() => this.handleCloseQuizDeleteConfirmModal()}
          onConfirm={() => this.handleConfirmQuizDeleteQuestion()}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addQuestionButton = {
  width: "max-content",
  height: "50px",
  padding: "15px 20px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(QuizActivityComponent);
// Customizable Area End
