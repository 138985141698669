// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  categoryOfQuiz: string;
  titleOfAssessment: string;
  typeOfAssessment?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  quizStatistics: any;
  openQuizViewAnswerPopup: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentGradedAssignmentStatisticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      quizStatistics: {
        remark: "Needs improvement.You can do better.",
        statistics: {
          correct: 82,
          incorrect: 5,
          missed: 15,
        },
        timeSpentInMins: 182,
        noOfAttemptRemaining: 4,
      },
      openQuizViewAnswerPopup: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  handleGoToViewCorrectAnswer = () => {
    const { categoryOfQuiz, navigation, typeOfAssessment } = this.props;
    if (typeOfAssessment === "quiz") {
      navigation?.navigate(
        "StudentCompletedQuizMain",
        {},
        {
          category: categoryOfQuiz,
          title: "Correct Answers",
        }
      );
    } else {
      navigation?.navigate(
        "StudentRemarkedAssessmentMain",
        {},
        {
          typeOfAssessment: typeOfAssessment,
          title: "Correct Answers",
          assessmentCategory: "graded",
          fromStatistics: "statistics"
        }
      );
    }
  };

  handleReAttempt = () => {
    const { titleOfAssessment, typeOfAssessment } = this.props;
    this.props.navigation?.navigate(
      "StudentDueAssessmentMain",
      {},
      {
        typeOfAssessment: typeOfAssessment,
        title: titleOfAssessment,
      }
    );
  };

  handleOpenQuizViewAnswerPopup = () => {
    this.setState({ openQuizViewAnswerPopup: true });
  };

  handleCloseQuizViewAnswerPopup = () => {
    this.setState({ openQuizViewAnswerPopup: false });
  };

  handleConfirmViewAnswer = () => {
    this.setState({ openQuizViewAnswerPopup: false }, () => {
      this.handleGoToViewCorrectAnswer();
    });
  };

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area End
