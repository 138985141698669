// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentMyCoursesSubjectDetailsController, {
  Props,
} from "./StudentMyCoursesSubjectDetailsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import StudentProgressJourneyDesktopWeb from "./StudentProgressJourneyDesktop.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    topicLessonBox: {
      borderRadius: "6px",
      maxWidth: "98%",
      width: "98%",
      overflow: "auto",
      height: "100vh",
    },
    columnHeader: {
      backgroundColor: "rgba(0, 157, 255, 0.05)",
      padding: "10px 15px",
    },
    borderRight: {
      borderRight: "2px solid #f1f1f1",
    },
    searchTextBox: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    columnDataList: {
      borderTop: "2px solid #f1f1f1",
    },
    mainPagePadding: {
      paddingRight: "20px !important"
    }
  });

// Customizable Area End

// Customizable Area Start
export class StudentMyCoursesSubjectDetails extends StudentMyCoursesSubjectDetailsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      selectedCourseSubject,
      selectedLessonName,
      selectedTopicName,
      selectedCourseId,
      isFromRegistrar,
      selectedStudentName,
      isFromTeacher,
    } = this.state;
    return (
      <>
        <Box
          className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.mainPagePadding} ${classes.secondaryBgColor}`}
          height={"100%"}
        >
          <Grid container direction="row">
            <Grid item xs={12}>
              <Box marginTop={"1%"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText12}`}
                  component={"span"}
                >
                  {
                    isFromRegistrar ?  `Students / ${selectedStudentName}` : `My Courses / ${selectedCourseSubject || ""} / Journey`
                  }
                </Typography>
                {isFromRegistrar && (
                  <Typography
                    className={`${classes.fontBold600} ${classes.primaryColor5} ${classes.fontText12}`}
                    component={"span"}
                  >
                    {` / ${selectedCourseSubject}`}
                  </Typography>
                )}
                {!isFromRegistrar && selectedTopicName && (
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText12}`}
                    component={"span"}
                  >
                    {` / ${selectedTopicName}`}
                  </Typography>
                )}
                {!isFromRegistrar && selectedLessonName && (
                  <Typography
                    className={`${classes.fontBold600} ${classes.primaryColor5} ${classes.fontText12}`}
                    component={"span"}
                  >
                    {` / ${selectedLessonName}`}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid className={classes.pageName} item xs={12}>
              <Box
                data-test-id="student-my-course-detail-subject-name"
                className={`${classes.cursorPointer}`}
                display={"flex"}
                alignItems={"center"}
              >
                <Box onClick={this.handleGoBack} mr={"8px"}>
                  <KeyboardBackspaceIcon
                    fontSize="large"
                    className={`${classes.textPrimaryColor}`}
                  />
                </Box>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText32} ${classes.fontBold600}`}
                  component={"span"}
                >
                  {selectedCourseSubject || ""}
                </Typography>
              </Box>
            </Grid>
            {/* Topic Lessons Activity Box */}
            <Grid item xs={12}>
              {selectedCourseId && (
                <StudentProgressJourneyDesktopWeb
                  navigation={navigation}
                  isStudentCourse={!(isFromRegistrar)}
                  isTeacherCourse={isFromTeacher}
                  isFromRegistrar={isFromRegistrar}
                  isFromTenant={false}
                  handleSetLessonName={this.handleSetLessonName}
                  selectedSubjectName={selectedCourseSubject}
                  handleSetTopicName={this.handleSetTopicName}
                  courseData={{
                    id: selectedCourseId,
                    courseTitle: selectedCourseSubject,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentMyCoursesSubjectDetails);
// Customizable Area End
