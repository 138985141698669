// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  studentProfile,
  parent1,
  parent2 as student2,
} from "./assets";

// Customizable Area End

// Customizable Area Start

export interface IUserDetailProps {
  id: any;
  emailId: string;
  status: string;
  profilePic?: any;
  customField1: any;
  customField2: any;
  customField3: any;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  userFullName: string;
  userRole: string;
  userDetails: IUserDetailProps;
  curriculumList: Array<any>;
  wardsList: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ViewUserProfileDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      userFullName: "",
      userDetails: {
        id: 1,
        emailId: "clarke.gillebert@gmail.com",
        profilePic: parent1,
        status: "active",
        customField1: "Value 1",
        customField2: "Value 2",
        customField3: "Value 3",
      },
      curriculumList: [
        {
          id: 1,
          name: "Curriculum 1",
        },
        {
          name: "Curriculum 2",
          id: 2,
        },
        {
          id: 3,
          name: "Curriculum 3",
        },
        {
          id: 4,
          name: "Curriculum 4",
        },
        {
          name: "Curriculum 5",
          id: 5,
        },
        {
          id: 6,
          name: "Curriculum 6",
        },
      ],
      wardsList: [
        {
          studentId: 1,
          profilePic: studentProfile,
          fullName: "Ruby Cameron",
        },
        {
          studentId: 2,
          fullName: "John Cameron",
          profilePic: student2,
        },
      ],
      userRole: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetParentTeacherDetails();
    // Customizable Area End
  }

  // Customizable Area Start

  handleSetParentTeacherDetails = () => {
    const nameFromParam = this.props.navigation?.getParam("fullName");
    const role = this.props.navigation?.getParam("role");
    this.setState({ userFullName: nameFromParam, userRole: role });
  };

  // Customizable Area End
}
// Customizable Area End
