// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import moment from "moment";
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";
import {
  courseManagerInputConfigMock,
  parentInputConfigMock,
  parentTeacherInputConfigMock,
  registrarInputConfigMock,
  relationshipMock,
  teacherInputConfigMock,
} from "../assets/userStatusMock";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
import { toast } from "react-toastify";
import {
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}

export interface IDDConfigProps {
  id: any;
  name: string;
  fieldName: string;
  value: any;
  menuItems?: any;
  label: string;
  fieldType: string;
  fileType?: string;
  isDisabled?: boolean;
  customLabel?: string;
  customLabel2?: string;
  placeHolderText: string;
  customField?: boolean;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  curriculumList: Array<any>;
  userRole: string;
  curriculum: any;
  curriculumDDMenuItems: Array<IDropdownItems>;
  gradeLevelDDMenuItems: Array<IDropdownItems>;
  gradeLevelList: Array<any>;
  gradeLevel: any;
  relationship: any;
  relationshipDDMenuItems: Array<IDropdownItems>;
  subjectHandledList: Array<any>;
  subjectHandled: any;
  subjectHandledDDMenuItems: Array<IDropdownItems>;
  dropdownConfigs: Array<IDDConfigProps>;
  selectedGradesError: boolean;
  selectedGradesErrorMsg: string;
  draftStatus: number;
  token: string;
  gradeSubjectId: any;
  userRoleList: any;
  teacherId: any;
  parentId: any;
  parentteacherId: any;
  aceeptImg: boolean;
  selectedSubject: Array<any>;
  isDraftOn: boolean;
  parentteacherMock: Array<any>;
  tenantAdminRoleId: any;
  registrarRoleId: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddNewUserDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addNewUserAPIRequestId: string = "";
  curriculumListAPIRequestId: string = "";
  gradeLevelListAPIRequestId: string = "";
  handleSubjectsListAPIRequestId: string = "";
  apiGetRoleNamesDataId: string = "";
  apiGetCustomFieldsId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      curriculumList: [],
      curriculumDDMenuItems: [],
      curriculum: "",
      gradeLevelList: [],
      gradeLevelDDMenuItems: [],
      gradeLevel: "",
      subjectHandledList: [],
      subjectHandledDDMenuItems: [],
      relationship: "",
      relationshipDDMenuItems: relationshipMock,
      selectedGradesError: false,
      selectedGradesErrorMsg: "",
      subjectHandled: "",
      dropdownConfigs: [],
      userRole: "",
      draftStatus: 0,
      token: "",
      gradeSubjectId: 0,
      userRoleList: [],
      teacherId: null,
      parentId: null,
      parentteacherId: null,
      aceeptImg: false,
      selectedSubject: [],
      isDraftOn: false,
      parentteacherMock: [],
      registrarRoleId: "",
      tenantAdminRoleId: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetUserCreateToken();
    // Customizable Area End
  }

  // Customizable Area Start
  handleSetUserCreateToken = async () => {
    const token = await getStorageData("token");
    if (token) {
      this.setState({ token }, () => {
        this.handleSetUserRole();
        this.handleGetRoleNamesList(token);
      });
    }
  };

  handleSetUserRole = () => {
    const roleFromParam = this.props.navigation?.getParam("role");
    if (roleFromParam) {
      this.setState({ userRole: roleFromParam }, () => {
        this.handleSetDDConfig();
      });
    }
  };

  setDDValue = (selected: any, fieldName: string) => {
    const {
      curriculumDDMenuItems,
      subjectHandledDDMenuItems,
      gradeLevelDDMenuItems,
      relationshipDDMenuItems,
    } = this.state;

    let label: any = "";

    if (fieldName === "curriculumDDMenuItems") {
      const selectedItem = curriculumDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "subjectHandledDDMenuItems") {
      const selectedItem = subjectHandledDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "gradeLevelDDMenuItems") {
      const selectedItem = gradeLevelDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "relationshipDDMenuItems") {
      const selectedItem = relationshipDDMenuItems.find(
        (item: IDropdownItems) => item.value === selected
      );
      label = selectedItem?.label;
    }
    return label;
  };

  renderDDMenuItem = (
    selected: any,
    fieldName: string,
    placeholderText: string
  ) => {
    if (!selected || selected.length === 0) {
      return placeholderText;
    }
    return this.setDDValue(selected, fieldName);
  };

  handleSelectDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { dropdownConfigs } = this.state;
    const { name, value } = event.target;
    let fieldValue: any = value;

    if (fieldValue) {
      const ddConfigs = dropdownConfigs.map((item) => {
        if (item.name === name) {
          item.value = value;
        }
        return item;
      });

      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
          dropdownConfigs: ddConfigs,
        };
      });
      if (name == "gradeLevel") {
        this.setState({
          selectedSubject: [],
        });
        this.setState(
          {
            gradeSubjectId: value,
          },
          () => {
            this.getHandleSubjectsList();
          }
        );
      }
    }
  };

  handleSetDDConfig = async() => {
    const { userRole } = this.state;
    let ddConfig: Array<IDDConfigProps> = [];
    if (userRole === "registrar") {
      ddConfig = registrarInputConfigMock;
    } else if (userRole === "course manager") {
      ddConfig = courseManagerInputConfigMock;
    } else if (userRole === "teacher") {
      ddConfig = teacherInputConfigMock;
    } else if (userRole === "parent teacher") {
      ddConfig = parentTeacherInputConfigMock;
    } else if (userRole === "parent") {
      ddConfig = parentInputConfigMock;
    }
    this.setState({ dropdownConfigs: ddConfig }, async() => {
      await this.handleGetCustomFieldsList();
      if(userRole === "teacher"){
      this.getGradeLevelList();
      }
    });
  };

  handleDateChange = (data: any, fieldName: string) => {
    if (data) {
      const { dropdownConfigs } = this.state;
      const setDateFormat = moment(data).toLocaleString();
      const ddConfig = dropdownConfigs.map((item) => {
        if (item.name === fieldName) {
          item.value = setDateFormat;
        }
        return item;
      });
      this.setState({ dropdownConfigs: ddConfig });
    }
  };

  handleChange = (event: any) => {
    const { dropdownConfigs } = this.state;
    const { name, value } = event.target;
    const ddConfig = dropdownConfigs.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        dropdownConfigs: ddConfig,
      };
    });
  };

  handleIsDraft = (isDraft: boolean, attributes: any) => {
    if (isDraft) {
      attributes["status"] = 2;
    }
  };

  isFormValid = () => {
    const emailField = this.state.dropdownConfigs.find(
      (element) => element.name === "emailId"
    );
    const phoneNumber = this.state.dropdownConfigs.find(
      (element) => element.name === "contactNumber"
    );
    let numbersRegex = /^\d+$/;

    if (emailField && !emailField.value) {
      toast.error("Please enter email address");
      return false;
    }

    if (
      phoneNumber &&
      !numbersRegex.test(phoneNumber.value) &&
      this.state.userRole == "parent"
    ) {
      toast.error("Please enter valid contact number");
      return false;
    }

    return true;
  };

  handleAddUser = (isDraft = false) => {
    if (!this.isFormValid()) {
      return;
    }

    this.setState({ loading: true, isDraftOn: isDraft });
    showCustomLoader();
    const { token, dropdownConfigs } = this.state;
    const attributes: any = {
      user_custom_fields_attributes: [],
    };

    this.handleIsDraft(isDraft, attributes);

    dropdownConfigs.forEach((element) => {
      if (this.state.userRole == "parent teacher") {
        switch (element.name) {
          case "firstName":
            attributes["first_name"] = element.value;
            break;

          case "contactNumber":
            attributes["contact_number"] = element.value;
            break;

          case "lastName":
            attributes["last_name"] = element.value;
            break;

          case "emailId":
            attributes["email"] = element.value;
            break;

          case "expiringOn":
            attributes["expiring_on"] = element.value;
            break;

          case "relationship":
            attributes["relationship"] = element.value;
            break;

          case "childrenCount":
            attributes["children"] = element.value;
            break;

          case "role":
            attributes["role_id"] = this.state.parentteacherId;
            break;

          case "curriculum":
            attributes["curriculum_id"] = "";
            break;

          case "profileImage":
            attributes["profile"] = element.value.profile;
            attributes["filename"] = element.value.fileName;
            attributes["content_type"] = element.value.contentType;
            break;

          case "schoolYear":
            attributes["school_year"] = element.value.toString();
            break;

          case "gradeLevel":
            attributes["grade_id"] = "";
            break;
        }
      } else {
        switch (element.name) {
          case "firstName":
            attributes["first_name"] = element.value;
            break;

          case "contactNumber":
            attributes["contact_number"] = element.value;
            break;

          case "relationship":
            attributes["relationship"] = element.value;
            break;

          case "lastName":
            attributes["last_name"] = element.value;
            break;

          case "emailId":
            attributes["email"] = element.value;
            break;

          case "childrenCount":
            attributes["children"] = element.value;
            break;

          case "profileImage":
            attributes["profile"] = element.value.profile;
            attributes["filename"] = element.value.fileName;
            attributes["content_type"] = element.value.contentType;
            break;

          case "expiringOn":
            attributes["expiring_on"] = element.value;
            break;

          case "role":
            if (element.value === "Teacher") {
              attributes["role_id"] = this.state.teacherId;
            } else if (element.value === "Parent") {
              attributes["role_id"] = this.state.parentId;
            } else if (element.value === "Registrar") {
              attributes["role_id"] = this.state.registrarRoleId;
            } else {
              attributes["role_id"] = this.state.tenantAdminRoleId;
            }
            break;

          case "curriculum":
            attributes["curriculum_id"] = element.value.toString();
            break;

          case "gradeLevel":
            attributes["grade_id"] = [element.value.toString()];
            break;

          case "schoolYear":
            attributes["school_year"] = element.value.toString();
            break;

          case "subjectHandled":
            attributes["subject_id"] = element.value;
            break;

          case "customField1":
            attributes["user_custom_fields_attributes"].push({
              field_value: element.value,
              custom_field_id: 1,
              _destroy: 0,
            });
            break;

          case "customField2":
            attributes["user_custom_fields_attributes"].push({
              field_value: element.value,
              _destroy: 0,
              custom_field_id: 2,
            });
            break;

          case "customField3":
            attributes["user_custom_fields_attributes"].push({
              custom_field_id: 3,
              field_value: element.value,
              _destroy: 0,
            });
            break;

          case "userId":
            attributes["id_number"] = element.value;
            break;
        }
      }
    });

    dropdownConfigs.forEach((element) => {
      if(element?.customField) {
        attributes["user_custom_fields_attributes"].push({
          field_value: element.value,
          custom_field_id: element.id,
          _destroy: 0,
        });
      }
    })

    const httpBody: any = {
      data: {
        attributes,
      },
    };

    const header = {
      "Content-Type": configJSON.userStatusApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addNewUserAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatusApiEndpont
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userCreateAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCurriculumList(subjectList: any[]) {
    showCustomLoader();
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.curriculumListAPIRequestId = getUserStatusMsg.messageId;
    const selectedSubjects = this.state.selectedSubject.includes("All")
      ? subjectList
          .filter((item) => item.value !== "All")
          .map((item) => item.value.toString())
      : this.state.selectedSubject;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.curriculumsGradeEndpoint +
        this.state.gradeSubjectId +
        `&subject_id=[${selectedSubjects}]`
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  }

  getGradeLevelList() {
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.gradeLevelListAPIRequestId = getUserStatusMsg.messageId;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gradesEndpoint
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  }

  getHandleSubjectsList() {
    showCustomLoader();
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token,
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.handleSubjectsListAPIRequestId = getUserStatusMsg.messageId;
    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectHandlesList + this.state.gradeSubjectId
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  }

  handleCustomFieldsListResponse = async(response: any) => {
    if (response) {
      if (response.data) {
        const customFields: Array<IDDConfigProps> = response.data.map((item: any, index: number) => {
          return {
            fieldName: item?.attributes?.label,
            id: item?.attributes?.id,
            fieldType: "text",
            label: item?.attributes?.label,
            name: item?.attributes?.label,
            value: "",
            placeHolderText: "Enter Value",
            customField: true
          };
        });

        this.setState({
          dropdownConfigs: [ ...this.state.dropdownConfigs, ...customFields],
        });
      }
    }
    hideCustomLoader();
  };

  handleGetCustomFieldsList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomFieldsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customFieldEndpoint}?model_type=User_management`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetRoleNamesList = (token: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token,
    };

    this.apiGetRoleNamesDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherParentNmaesList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // Customizable Area Start
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.addNewUserAPIRequestId:
            this.handleAddNewUserResponse(responseJson, errorResponse);
            break;

          case this.curriculumListAPIRequestId:
            this.handleCurriculumListResponse(responseJson);
            break;

          case this.gradeLevelListAPIRequestId:
            this.handleGradeLevelListResponse(responseJson);
            break;

          case this.handleSubjectsListAPIRequestId:
            this.handleHandleSubjectListResponse(responseJson);
            break;

          case this.apiGetRoleNamesDataId:
            this.handleUserRoleListResponse(responseJson);
            break;
          case this.apiGetCustomFieldsId:
           await this.handleCustomFieldsListResponse(responseJson);
            break;
        }
      }
      // Customizable Area End
    }
  }

  toasterFunc = () => {
    switch (this.state.userRole) {
      case "teacher":
        this.state.isDraftOn
          ? toast.success(TOASTER_NOTIFICATION_MSG.ADD_NEW_TEACHER_AS_DRAFT)
          : toast.success(TOASTER_NOTIFICATION_MSG.ADD_NEW_TEACHER_SUCCESS);
        break;
      case "parent":
        this.state.isDraftOn
          ? toast.success(TOASTER_NOTIFICATION_MSG.ADD_NEW_PARENT_AS_DRAFT)
          : toast.success(TOASTER_NOTIFICATION_MSG.ADD_NEW_PARENT_SUCCESS);
        break;
      case "parent teacher":
        this.state.isDraftOn
          ? toast.success(TOASTER_NOTIFICATION_MSG.ADD_NEW_PARENT_TEACHER_DRAFT)
          : toast.success(
              TOASTER_NOTIFICATION_MSG.ADD_NEW_PARENT_TEACHER_SUCCESS
            );
        break;
    }
  };

  handleAddNewUserResponse = (responseJson: any, errorResponse: any) => {
    hideCustomLoader();

    if (responseJson?.data?.id) {
      this.toasterFunc();
      this.props.navigation.navigate("TenantUserManagement");
      const ddConfig = this.state.dropdownConfigs.map((item) => {
        if (item.fieldName === "profileImage") {
          return (item.value = {
            profile: "",
            fileName: "",
            contentType: "",
          });
        } else if (item.fieldName !== "role") {
          return (item.value = "");
        }
      });
      this.setState((prev: any) => {
        return {
          ...prev,
          dropdownConfigs: ddConfig,
        };
      });
    } else if (responseJson.errors) {
      const errorField = Object.keys(responseJson.errors)[0];
      const errorMessage = responseJson.errors[errorField][0];
      toast.error(
        `${
          errorField.charAt(0).toUpperCase() + errorField.slice(1)
        } ${errorMessage}`
      );
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleCurriculumListResponse = (response: any) => {
    if (response) {
      const { dropdownConfigs } = this.state;
      if (response.data) {
        const curriculumList = response.data.map((item: any, index: number) => {
          return {
            id: ++index,
            label: item.attributes.name,
            value: Number(item.id),
          };
        });

        const ddConfigs = dropdownConfigs.map((item: any) => {
          if (item.name === "curriculum") {
            item.menuItems = curriculumList;
          }
          return item;
        });
        this.setState({
          dropdownConfigs: ddConfigs,
          curriculumDDMenuItems: curriculumList,
        });
      }
    }
    hideCustomLoader();
  };

  handleGradeLevelListResponse = (response: any) => {
    if (response) {
      const { dropdownConfigs } = this.state;
      
      if (response.data) {
        const gradeLevelList = response.data.map((item: any, index: number) => {
          return {
            id: ++index,
            label: item.attributes.name,
            value: Number(item.id),
          };
        });

        const ddConfigs = dropdownConfigs.map((item: any) => {
          if (item.name === "gradeLevel") {
            item.menuItems = gradeLevelList;
          }
          return item;
        });
        this.setState({
          dropdownConfigs: ddConfigs,
          gradeLevelDDMenuItems: gradeLevelList,
        });
      }
    }
  };
  handleHandleSubjectListResponse = (response: any) => {
    if (response) {
      const { dropdownConfigs } = this.state;
      if (response.data) {
        const allSubjectOption = {
          id: "All",
          value: "All",
          label: "All Subjects",
        };
        const subjectLIstList = response.data.map(
          (item: any, index: number) => {
            return {
              id: Number(item.id),
              label: item.attributes.name,
              value: Number(item.id),
            };
          }
        );
        const updatedSubjectList = [allSubjectOption, ...subjectLIstList];

        const ddConfigs = dropdownConfigs.map((item: any) => {
          if (item.name === "subjectHandled") {
            item.menuItems = updatedSubjectList;
          }
          return item;
        });
        this.setState({
          dropdownConfigs: ddConfigs,
          subjectHandledDDMenuItems: updatedSubjectList,
        });
        hideCustomLoader();
      }
    }
  };

  handleUserRoleListResponse = (response: any) => {
    if (response) {
      if (response.data) {
        this.setState({
          userRoleList: response.data,
        });
      }
      response.data?.map((item: any) => {
        if (item.attributes.name == "Teacher") {
          this.setState({
            teacherId: item.id,
          });
        } else if (item.attributes.name == "Parent") {
          this.setState({
            parentId: item.id,
          });
        } else if (item.attributes.name == "Parent Teacher") {
          this.setState({
            parentteacherId: item.id,
          });
        } else if (item.attributes.name == "Course Manager") {
          this.setState({
            tenantAdminRoleId: item.id,
          });
        } else if (item.attributes.name == "Registrar") {
          this.setState({
            registrarRoleId: item.id,
          });
        }
      });
    }
  };

  handleChangeSubject = (event: any, subjectList: any) => {
    if (event.target.value.includes("All")) {
      this.setState(
        {
          selectedSubject: subjectList.map((item: { id: any; value: any }) => {
            return item.id;
          }),
        },
        () => {
          this.updateSubjectDropdownConfig();
        }
      );
    } else {
      const selectedSubjects = Array.isArray(event.target.value)
        ? event.target.value
        : [event.target.value];
      this.setState(
        {
          selectedSubject: selectedSubjects,
          selectedGradesError: false,
          selectedGradesErrorMsg: "",
        },
        () => {
          this.updateSubjectDropdownConfig();
        }
      );
    }
  };

  updateSubjectDropdownConfig = () => {
    const ddConfigs = this.state.dropdownConfigs.map((item) => {
      if (item.name === "subjectHandled") {
        item.value = this.state.selectedSubject;
      }
      return item;
    });
    this.setState({
      dropdownConfigs: ddConfigs,
    });
  };

  handleUploadFile = (file: any, fieldName: string) => {
    const { dropdownConfigs } = this.state;
    const ddConfig = dropdownConfigs.map((item) => {
      if (item.name === fieldName) {
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            item.value = {
              profile: reader.result,
              fileName: file.name || "",
              contentType: file.type,
            };
          };
          reader.readAsDataURL(file);
          item.value = {
            profile: "",
            fileName: file.name || "",
            contentType: file.type,
          };
        } else {
          item.value = {
            profile: "",
            fileName: "",
            contentType: "",
          };
        }
      }
      return item;
    });

    this.setState((prev) => {
      return {
        ...prev,
        dropdownConfigs: ddConfig,
      };
    });
  };

  // Customizable Area End
}
// Customizable Area End
