// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import DeleteEditEventCommanPopUpController, {
  Props,
} from "./DeleteEditEventCommanPopUpController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";
import CalendarIcon from "../../cfcurriculmschoolyearcreation2/src/assets/CalendarIcon.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "880px",
      },
      paperWidthSm: {
        maxWidth: "880px",
      },
    },
  },
});
const editTitleTheme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: "16px",
        height: "16px",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
        height: "38px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        paddingLeft: "5px",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class EditEventPopUp extends DeleteEditEventCommanPopUpController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          className={`${classes.highlightBgColor}`}
          aria-labelledby="Edit-pop-up"
          style={{ width: "100%" }}
          open={open}
        >
          <Box padding={"21px 30px 30px 30px"} className={`${classes.bgColor}`}>
            <Grid direction="row" container>
              <Grid xs={12} item>
                <Typography
                  className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  data-test-id="editText"
                >
                  Edit Event
                </Typography>
                <IconButton
                  data-test-id="EditPopUp"
                  style={{ float: "right" }}
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                  className={classes.textSecondaryColor}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <DialogContent
              data-test-id="editContent"
              style={{
                padding: "0px",
                marginTop: "24px",
                overflow: "hidden",
              }}
            >
              <Grid container spacing={2} direction="row">
                <Grid item xs={6}>
                  <Box className={classes.DateName}>
                    <Typography
                      data-test-id="editTy"
                      className={`${classes.textCommonColor} ${classes.fontText14}`}
                      component={"span"}
                    >
                      {`Event Title`}
                    </Typography>
                    <ThemeProvider theme={editTitleTheme}>
                      <Box mt={"6px"}>
                        <CustomTextField
                          placeHolderText="Enter Event Title"
                          fieldName="EditTitle"
                          fieldId="search-students"
                          fieldValue={this.state.searchEditStudent}
                          fieldWidth={"100%"}
                          fieldType="text"
                          handleChange={this.handleEditChange}
                        />
                      </Box>
                    </ThemeProvider>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.DateName}>
                    <Typography
                      data-test-id="spanTag"
                      component={"span"}
                      className={`${classes.textCommonColor} ${classes.fontText14}`}
                    >
                      {`Event Date`}
                    </Typography>
                    <div style={{ marginTop: "6px" }} className="DateContainers">
                      <span className="dateInput">
                        {" "}
                        <DatePicker
                          data-test-id="DatePicker"
                          onChange={this.editEndDate}
                          popperPlacement="left-start"
                          popperClassName="example-datepicker-class"
                          selected={this.state.editEndDate}
                        />
                      </span>
                      <span className="icon">
                        <CalendarIcon />
                      </span>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.DateName}>
                    <Typography
                      data-test-id="name"
                      className={`${classes.textCommonColor} ${classes.fontText14}`}
                      component={"span"}
                    >
                      {`Color`}
                    </Typography>
                    <div
                      className={`ColorPick ${classes.textCommonColor}`}
                      style={{ marginTop: "9px" }}
                    >
                      <div
                        data-test-id="colorpicker"
                        className="clickableDiv"
                        onClick={this.openColorPicker}
                      ></div>
                      <ThemeProvider theme={editTitleTheme}>
                        <CustomTextField
                          placeHolderText="Select Color"
                          fieldName="EditTitle"
                          fieldId="edit-students"
                          fieldValue={undefined}
                          fieldWidth={"100%"}
                          fieldType="text"
                          handleChange={this.handleEditChange}
                        />
                      </ThemeProvider>
                      <div
                        className="color-picker"
                        style={{
                          backgroundColor: `${this.state.colorChange}`,
                        }}
                      >
                        <input
                          type="color"
                          id="ColorChange"
                          onChange={this.EditChangeColor}
                          className="color-input"
                          value={this.state.colorChange}
                        />
                      </div>
                    </div>
                  </Box>
                </Grid>
                <Grid xs={12} item>
                  <Box marginBottom={"10px"} style={{ width: "100%" }}>
                    <CustomButton
                      btnText="Save"
                      btnStyle={editSaveBtn}
                      buttonId={`edit-save-btn`}
                      buttonVariant={"contained"}
                      type={"button"}
                      handleButtonClick={this.props.onCancel}
                      isDisabled={false}
                    />
                    <CustomButton
                      btnText="Cancel"
                      btnStyle={editCancleBtn}
                      buttonId={`edit-cancel-btn`}
                      buttonVariant={"outlined"}
                      type={"button"}
                      handleButtonClick={this.props.onCancel}
                      isDisabled={false}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const editCancleBtn = {
  width: "120px",
  marginLeft: "15px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  padding: "10px 10px",
  float: "right",
} as const;

const editSaveBtn = {
  width: "120px",
  textAlign: "center",
  height: "50px",
  marginLeft: "15px",
  fontSize: "18px",
  borderRadius: "12px",
  marginTop: "32px",
  padding: "10px 10px",
  fontWeight: 500,
  float: "right",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(EditEventPopUp);
// Customizable Area End
