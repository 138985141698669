// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js")

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  isStudentCourse?: boolean;
  isTeacherCourse?: boolean;
  isFromTenant?: boolean;
  isParentTeacherCourse?:boolean;
  selectedSubjectName: any;
  handleSetTopicName: any;
  handleSetLessonName: any;
  courseData: any;
  openActivityModalFromOutside?: boolean;
  isFromRegistrar?: boolean;
  classId?: string;
  isCourseMangement?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openCourseInfoDialog: boolean
  searchLesson: "";
  selectedTopic: any;
  subjectTopicList: any;
  selectedtLessonId: any;
  selectedActivity: any;
  selectedActivityId: any;
  topicLessonsCompleteList: any;
  lessonsActivityCompleteList: any;
  loading: boolean;
  selectedLesson: any;
  searchTopic: "";
  selectedTopicId: any;
  searchActivity: "";
  draggedIndex: any,
  openAddNewTopicModal: boolean,
  openAddNewLessonsModal: boolean,
  isActivityOpen: boolean,
  openAddNewActivityModal: boolean,
  searchTopicValue: string;
  searchLessonValue: string;
  searchActivityValue: string;
  editTopic: boolean;
  editTopicId: number;
  editLesson: boolean;
  editLessonId: number;
  openDeleteConfirmDialog: boolean;
  deleteItemId: number;
  deleteItemTitle: string,
  deleteItemType: string,
  editActivity: boolean,
  editActivityId: number,
  refreshActivityDetail: boolean;
  courseInfoContent: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentProgressJourneyDesktopController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUsersData: string = "";
  apiPostAddNewTopic: string = "";
  apiGetTopicList: string = "";
  apiPostAddNewLesson: string = "";
  apiGetLessonList: string = "";
  apiPutUpdateTopic: string = "";
  apiPutUpdateLesson: string = "";
  apiDeleteTopic: string = "";
  apiDeleteLesson: string = "";
  apiPostAddNewActivity: string = "";
  apiGetActivityist: string = "";
  apiDeleteActivity: string = "";
  apiPatchUpdateActivity: string = "";
  apiMarkActivityAsCompleted: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      openAddNewLessonsModal: false,
      draggedIndex: null,
      openAddNewTopicModal: false,
      loading: false,
      searchActivity: "",
      selectedLesson: null,
      selectedTopicId: null,
      topicLessonsCompleteList: [],
      selectedTopic: null,
      selectedActivity: null,
      searchTopic: "",
      lessonsActivityCompleteList: [],
      selectedtLessonId: null,
      selectedActivityId: null,
      isActivityOpen: false,
      searchLesson: "",
      openCourseInfoDialog: false,
      searchTopicValue: "",
      searchLessonValue: "",
      editTopic: false,
      searchActivityValue: "",
      editTopicId: 0,
      editLesson: false,
      editLessonId: 0,
      openAddNewActivityModal: false,
      subjectTopicList: [],
      deleteItemTitle: "",
      openDeleteConfirmDialog: false,
      deleteItemId: 0,
      deleteItemType: "",
      editActivity: false,
      editActivityId: 0,
      courseInfoContent: {},
      refreshActivityDetail: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleResetInitialValues();
    this.GoJourneyBack();
    this.handleGetTopicListApi({});
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { openActivityModalFromOutside } = this.props;
    if (openActivityModalFromOutside) {
      this.handleOpenAddNewActivityModal();
    }
  }

  handleResetInitialValues = () => {
    this.setState({
      draggedIndex: null,
      openAddNewTopicModal: false,
      openAddNewLessonsModal: false,
      lessonsActivityCompleteList: [],
      loading: false,
      selectedLesson: null,
      searchActivity: "",
      selectedTopic: null,
      selectedTopicId: null,
      selectedActivity: null,
      selectedActivityId: null,
      topicLessonsCompleteList: [],
      searchTopic: "",
      selectedtLessonId: null,
      searchLesson: "",
      openCourseInfoDialog: false,
      isActivityOpen: false,
      openAddNewActivityModal: false,
      searchTopicValue: "",
      searchLessonValue: "",
      searchActivityValue: "",
      editTopic: false,
      editTopicId: 0,
      editLesson: false,
      editLessonId: 0,
      subjectTopicList: [],
      openDeleteConfirmDialog: false,
      deleteItemId: 0,
      deleteItemTitle: "",
      deleteItemType: "",
      editActivity: false,
      editActivityId: 0,
      refreshActivityDetail: false,
      courseInfoContent: {}
    })
  }

  // Customizable Area Start
  handleSelectTopicDetails = (selectedTopic?: {
    id?: string,
    noOfLessons?: number,
    topicAvgGrades?: number,
    topicCompProgress?: number,
    expanded?: boolean,
    topicTitle?: string
  }) => {
    this.handleSelectTopics(selectedTopic?.id || this.state.subjectTopicList[0].id);
  }

  handleSelectTopics = (topicId: any) => {
    const { subjectTopicList } = this.state;
    const selectedTopic: any = subjectTopicList.find((topic: any) => topic?.id === topicId);

    const updatedTopicList = subjectTopicList?.map((items: any) => {
      if (items?.id === topicId) {
        items.expanded = !items?.expanded;
      }
      return items;
    });

    this.props.handleSetTopicName(selectedTopic?.topicTitle);
    this.props.handleSetLessonName('');
    this.setState({ selectedTopic, selectedTopicId: topicId, subjectTopicList: updatedTopicList }, () => {
      if (subjectTopicList.length > 0 && selectedTopic) {
        this.handleGetLessonListApi({});
      }
    });
  }

  handleSelectLessons = (lessonId: any, openInfoDialog?: boolean) => {
    const { topicLessonsCompleteList } = this.state;
    const selectedLesson: any = topicLessonsCompleteList.find((lessons: any) => lessons?.id === lessonId);
    this.props.handleSetLessonName(selectedLesson?.lessonTitle);
    this.setState({ selectedLesson, selectedtLessonId: lessonId }, () => {
      if (topicLessonsCompleteList.length > 0 && selectedLesson) {
        this.handleGetActivityListApi({});
      }
    });
  }

  handleSelectActivity = (activityId: any) => {
    const { lessonsActivityCompleteList } = this.state;
    const selectedActivityDetail: any = lessonsActivityCompleteList.find((topic: any) => topic?.id === activityId);
    this.setState({ selectedActivity: selectedActivityDetail, selectedActivityId: activityId, isActivityOpen: true });
  }

  handleSelectActivityFromAccordion = (topicId: any, lessonId: any, activityId: any) => {
    const { subjectTopicList, topicLessonsCompleteList, lessonsActivityCompleteList } = this.state;
    const { selectedSubjectName } = this.props;
    const selectedTopic: any = subjectTopicList.find((topic: any) => topic?.id === topicId);
    const selectedLesson: any = topicLessonsCompleteList.find((lesson: any) => lesson?.id === lessonId);
    const selectedActivityDetail: any = lessonsActivityCompleteList.find((activity: any) => activity?.id === activityId);
    this.setState({ selectedActivity: selectedActivityDetail, selectedActivityId: activityId }, () => {
      this.props.navigation.navigate("CourseActivityJourneyDetails", { selectedTopic, selectedLesson, selectedActivity: selectedActivityDetail, selectedSubjectName })
    })
  }

  handleGoBack = () => {
    this.props.navigation?.goBack()
  }

  handleOpenCourseInfoDialogs = () => {
    this.setState({ openCourseInfoDialog: true })
  }

  handleCloseCourseInfoDialogs = () => {
    this.setState({ openCourseInfoDialog: false })
  }

  handleDragEnd = (result1: any) => {
    if (!result1?.destination) {
      return;
    }
    const reorderedTopics = Array.from(this.state.subjectTopicList);
    const [reorderedTopic] = reorderedTopics.splice(result1.source.index, 1);
    reorderedTopics.splice(result1.destination.index, 0, reorderedTopic);
    this.setState({
      subjectTopicList: reorderedTopics,
    });
  };

  handleDragEndLessons = (result: any) => {
    if (!result?.destination) {
      return;
    }
    const reorderedLessons = Array.from(this.state.topicLessonsCompleteList);
    const [reorderedLesson] = reorderedLessons.splice(result.source.index, 1);
    reorderedLessons.splice(result.destination.index, 0, reorderedLesson);
    this.setState({
      topicLessonsCompleteList: reorderedLessons,
    });
  };

  handleDragEndActivities = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedActivities = Array.from(this.state.lessonsActivityCompleteList);
    const [reorderedActivity] = reorderedActivities.splice(result.source.index, 1);
    reorderedActivities.splice(result.destination.index, 0, reorderedActivity);
    this.setState({
      lessonsActivityCompleteList: reorderedActivities,
    });
  };

  handleOpenAddNewTopicModal = () => {
    this.setState({ openAddNewTopicModal: true });
  }

  handleCloseAddNewTopicModal = () => {
    this.setState({ openAddNewTopicModal: false, editTopic: false });
  }

  handleOpenAddNewLessonsModal = () => {
    const { selectedTopicId } = this.state;
    if (!selectedTopicId) {
      toast.warn("Please select a topic first");
    } else {
      this.setState({ openAddNewLessonsModal: true });
    }
  }

  handleCloseAddNewLessonsModal = () => {
    this.setState({ openAddNewLessonsModal: false, editLesson: false });
  }

  handleOpenAddNewActivityModal = () => {
    const { selectedtLessonId } = this.state
    if (!selectedtLessonId) {
      toast.warn("Please select a lesson first");
    } else {
      this.setState({ openAddNewActivityModal: true });
    }
  }

  handleCloseAddNewActivityModal = () => {
    this.setState({ openAddNewActivityModal: false, editActivity: false });
  }

  GoJourneyBack = () => {
    this.setState({ selectedActivity: null, selectedActivityId: null, isActivityOpen: false }, () => {
      this.handleGetTopicListApi("");
    });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiPostAddNewTopic:
            {
              this.handlePostAddNewTopicResponse(responseJson, errorResponse);
            }
            break;

          case this.apiGetTopicList:
            {
              this.handleGetTopicListResponse(responseJson, errorResponse);
            }
            break;

          case this.apiPostAddNewLesson:
            {
              this.handlePostAddNewLessonResponse(responseJson, errorResponse);
            }
            break;

          case this.apiGetLessonList:
            {
              this.handleGetLessonListResponse(responseJson, errorResponse);
            }
            break;

          case this.apiPutUpdateTopic:
            {
              this.handlePutUpdateTopicResponse(responseJson, errorResponse);
            }
            break;

          case this.apiPutUpdateLesson:
            {
              this.handlePutUpdateLessonResponse(responseJson, errorResponse);
            }
            break;

          case this.apiDeleteTopic:
            {
              this.handleDeleteTopicResponse(responseJson);
            }
            break;

          case this.apiDeleteLesson:
            {
              this.handleDeleteLessonResponse(responseJson);
            }
            break;

          case this.apiPostAddNewActivity:
            {
              this.handleAddNewActivityResponse(responseJson, errorResponse);
            }
            break;

          case this.apiGetActivityist:
            {
              this.handleGetActivityListResponse(responseJson, errorResponse);
            }
            break;

          case this.apiDeleteActivity:
            {
              this.handleDeleteActivityResponse(responseJson);
            }
            break;
          case this.apiPatchUpdateActivity:
            {
              this.handleUpdateActivityResponse(responseJson, errorResponse);
            }
            break;
          case this.apiMarkActivityAsCompleted:
            {
              this.handleUpdateActivityMarkCompleteResponse(responseJson, errorResponse);
            }
            break;
        }
      }
      // Customizable Area End
    }
  }

  handleInputChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    switch (name) {
      case "searchTopicValue":
        this.handleSearchTopic(value);
        break;

      case "searchLessonValue":
        this.handleSearchLesson(value);
        break;

      case "searchActivityValue":
        this.handleSearchActivity(value);
        break;

      default:
        break;
    }
  };

  handleAddNewTopicPayload = (payload: any) => {
    this.handleCloseAddNewTopicModal();
    this.handleAddNewTopicApi(payload);
  }

  handleAddNewTopicApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostAddNewTopic = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewTopic}?course_id=${this.props.courseData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostAddNewTopicResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data?.attributes) {
      toast.success(TOASTER_NOTIFICATION_MSG.TOPIC_CREATION_SUCCESS);
      const topic = responseJson.data.attributes;
      const addedTopicItem = {
        id: responseJson?.data?.id,
        topicAvgGrades: 60,
        noOfLessons: 8,
        expanded: false,
        topicTitle: topic?.topic_title,
        topicCompProgress: 0,
      }
      const updatedTopicList = [
        ...this.state.subjectTopicList,
        addedTopicItem,
      ];
      this.setState({ subjectTopicList: updatedTopicList });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetTopicListApi = async (searchQuery: any) => {
    showCustomLoader();
    const baseUrl = `${configJSON.postAddNewTopic}?course_id=${this.props.courseData.id}`;
    let requestUrl = baseUrl;

    const { classId,isParentTeacherCourse,isCourseMangement } = this.props;
    if ((!isParentTeacherCourse) && classId && classId != 'all') {
      requestUrl = requestUrl + `&class_id=${classId}`;
    }
    if(isParentTeacherCourse && classId && classId != 'all'){
      requestUrl = requestUrl + `&student_id=${classId}`;
    }
    
    if(isCourseMangement){
      requestUrl = requestUrl + `&course_management=true`;
    }

    if (searchQuery.query) {
      requestUrl = requestUrl + `&query=${searchQuery.query}`
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTopicList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTopicListResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      if (responseJson.data.length > 0) {
        const topicListData = responseJson.data.map((item: any) => {
          const topic = item.attributes;
          return {
            id: item.id,
            noOfLessons: 8,
            topicAvgGrades: 60,
            topicCompProgress: parseFloat(topic?.completion_score_by_student?.toFixed(2)),
            expanded: false,
            topicTitle: topic.topic_title,
          }
        });
        this.setState({ subjectTopicList: topicListData });

        this.handleSelectTopicDetails(this.state.selectedTopic);
      } else {
        this.setState({ subjectTopicList: [] });

      }
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleMenuItemEvent = (data: any) => {
    console.log("menu data: ", data);
    if (data.menu === "Edit" && data.itemType === "Topic" && data.itemId) {
      this.setState({ editTopic: true, editTopicId: data.itemId });
      this.handleOpenAddNewTopicModal();
    }

    if (data.menu === "Edit" && data.itemType === "Lesson" && data.itemId) {
      this.setState({ editLesson: true, editLessonId: data.itemId });
      this.handleOpenAddNewLessonsModal();
    }

    if (data.menu === "Edit" && data.itemType === "Activity" && data.itemId) {
      this.setState({ editActivity: true, editActivityId: data.itemId });
      this.handleOpenAddNewActivityModal();
    }

    if (data.menu === "Delete" && data.itemId) {
      this.setState({ deleteItemId: data.itemId, deleteItemTitle: data.itemName, deleteItemType: data.itemType });
      this.handleOpenDeleteConfirmDialog();
    }
  }

  handleAddNewLessonPayload = (payload: any) => {
    console.log("lesson payload: ", payload);
    this.handleCloseAddNewLessonsModal();
    this.handleAddNewLessonApi(payload);
  }

  handleAddNewLessonApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostAddNewLesson = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewLesson}?topic_id=${this.state.selectedTopicId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostAddNewLessonResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      toast.success(TOASTER_NOTIFICATION_MSG.LESSON_CREATION_SUCCESS);
      const addedLessonItem = this.getLessonObject(responseJson?.data);
      const updatedLessonList = [
        ...this.state.topicLessonsCompleteList,
        addedLessonItem,
      ];
      this.setState({ topicLessonsCompleteList: updatedLessonList });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetLessonListApi = async (searchQuery: any) => {
    showCustomLoader();
    this.resetDataOnTopicSelect();

    const baseUrl = `${configJSON.postAddNewLesson}?topic_id=${this.state.selectedTopicId}`;
    let requestUrl = baseUrl;
    if (searchQuery.query) {
      requestUrl = requestUrl + `&query=${searchQuery.query}`
    }

    const { classId,isParentTeacherCourse ,isCourseMangement} = this.props;
    if (!isParentTeacherCourse && classId && classId != 'all') {
      requestUrl = requestUrl + `&class_id=${classId}`;
    }
    if(isParentTeacherCourse && classId && classId != 'all'){
      requestUrl = requestUrl + `&student_id=${classId}`;
    }
    if(isCourseMangement){
      requestUrl = requestUrl + `&course_management=true`;
    }
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetLessonList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetLessonListResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      const lessonListData = responseJson?.data.map((item: any) => {
        return this.getLessonObject(item);
      });
      this.setState({ topicLessonsCompleteList: lessonListData });
      if (lessonListData.length > 0) {
        this.handleSelectLessons(lessonListData[0].id);
      }
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleUpdateTopicPayload = (payload: any) => {
    console.log("update topic payload: ", payload);
    this.handleCloseAddNewTopicModal();
    this.handleUpdateTopicApi(payload);
  }

  handleUpdateTopicApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPutUpdateTopic = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewTopic}/${this.state.editTopicId}?course_id=${this.props.courseData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePutUpdateTopicResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const { subjectTopicList } = this.state;
    if (responseJson?.data?.attributes) {
      const topic = responseJson.data.attributes;
      const updatedTopicItem = {
        id: responseJson.data.id,
        topicAvgGrades: 60,
        noOfLessons: 8,
        expanded: false,
        topicTitle: topic.topic_title,
        topicCompProgress: parseFloat(topic?.completion_score_by_student?.toFixed(2)),
      }
      const updatedTopicList = subjectTopicList.map((item: any) => {
        if (item.id === updatedTopicItem.id) {
          item = { ...updatedTopicItem };
        }
        return item;
      });
      this.setState({ subjectTopicList: updatedTopicList });
      toast.success(TOASTER_NOTIFICATION_MSG.TOPIC_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleUpdateLessonPayload = (payload: any) => {
    console.log("lesson update payload: ", payload);
    this.handleCloseAddNewLessonsModal();
    this.handleUpdateLessonApi(payload);
  }

  handleUpdateLessonApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPutUpdateLesson = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewLesson}/${this.state.editLessonId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePutUpdateLessonResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const { topicLessonsCompleteList } = this.state;
    if (responseJson?.data?.attributes) {
      const updatedLessonItem = this.getLessonObject(responseJson?.data);
      const updatedLessonList = topicLessonsCompleteList.map((item: any) => {
        if (parseInt(item.id) === parseInt(updatedLessonItem.id)) {
          item = { ...updatedLessonItem };
        }
        return item;
      });
      this.setState({ topicLessonsCompleteList: updatedLessonList });
      toast.success(TOASTER_NOTIFICATION_MSG.LESSON_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleOpenDeleteConfirmDialog = () => {
    this.setState({ openDeleteConfirmDialog: true });
  }

  handleCloseDeleteConfirmDialog = () => {
    this.setState({ openDeleteConfirmDialog: false });
  }

  handleDeleteItem = () => {
    const { deleteItemType } = this.state;
    this.handleCloseDeleteConfirmDialog();
    if (deleteItemType === "Topic") {
      this.handleDeleteTopicApi();
    }

    if (deleteItemType === "Lesson") {
      this.handleDeleteLessonApi();
    }

    if (deleteItemType === "Activity") {
      this.handleDeleteActivityApi();
    }
  }

  handleDeleteTopicApi = async () => {
    showCustomLoader();
    const { deleteItemId } = this.state;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteTopic = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewTopic}/${deleteItemId}?course_id=${this.props.courseData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteTopicResponse = (responseJson: any) => {
    const { subjectTopicList, deleteItemId } = this.state;
    if (responseJson) {
      const updatedTopicList = subjectTopicList.filter(
        (item: any) => item.id !== deleteItemId
      );
      this.setState({ subjectTopicList: updatedTopicList, selectedTopic: null, selectedTopicId: null, deleteItemId: 0 }, () => {
        this.resetDataOnTopicSelect();
        this.props.handleSetTopicName('');
        this.props.handleSetLessonName('');
      });
      toast.success(TOASTER_NOTIFICATION_MSG.TOPIC_DELETE_SUCCESS);
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.TOPIC_DELETE_FAILURE);
    }
    hideCustomLoader();
  };

  handleDeleteLessonApi = async () => {
    showCustomLoader();
    const { deleteItemId } = this.state;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteLesson = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewLesson}/${deleteItemId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteLessonResponse = (responseJson: any) => {
    const { topicLessonsCompleteList, deleteItemId } = this.state;
    if (responseJson) {
      const updatedLessonList = topicLessonsCompleteList.filter(
        (item: any) => item.id !== deleteItemId
      );
      this.setState({ topicLessonsCompleteList: updatedLessonList, selectedLesson: null, selectedtLessonId: null, deleteItemId: 0 }, () => {
        this.resetDataOnLessonSelect();
        this.props.handleSetLessonName('');
      });
      toast.success(TOASTER_NOTIFICATION_MSG.LESSON_DELETE_SUCCESS);
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.LESSON_DELETE_FAILURE);
    }
    hideCustomLoader();
  };

  handleAddNewActivityEvent = (payload: any) => {
    console.log("New activity payload: ", payload);
    this.handleCloseAddNewActivityModal();
    this.handleAddNewActivityApi(payload);
  }

  handleAddNewActivityApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      // "Content-Type": "multipart/form-data",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostAddNewActivity = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postAddNewActivity
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      requestBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddNewActivityResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const { lessonsActivityCompleteList } = this.state;
    if (responseJson?.data?.attributes) {
      const activity = responseJson.data.attributes;
      const addedActivityItem = {
        id: responseJson.data.id,
        activityTitle: activity.activities_title,
        typeOfActivity: activity.activity_type,
        activityCompletionProgress: activity?.is_completed ? 100 : 0,
      }
      const updatedActivityList = [
        ...lessonsActivityCompleteList,
        addedActivityItem,
      ];
      this.setState({ lessonsActivityCompleteList: updatedActivityList });
      toast.success(TOASTER_NOTIFICATION_MSG.ACTIVITY_CREATION_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetActivityListApi = async (searchQuery: any) => {
    showCustomLoader();
    this.resetDataOnLessonSelect();

    const baseUrl = `${configJSON.postAddNewActivity}/${this.state.selectedtLessonId}/get_activity`;
    let requestUrl = baseUrl;
    if (searchQuery.title) {
      requestUrl = requestUrl + `?title=${searchQuery.title}`
    }

    const { classId,isParentTeacherCourse,isCourseMangement } = this.props;
    if (!isParentTeacherCourse && classId && classId != 'all') {
      requestUrl = requestUrl + `?class_id=${classId}`;
    }
    if (isParentTeacherCourse && classId && classId != 'all') {
      requestUrl = requestUrl + `?student_id=${classId}`;
    }
    if(isCourseMangement){
      requestUrl = requestUrl + `?course_management=true`;
    }
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetActivityist = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetActivityListResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      if (responseJson.data.length > 0) {
        const activityListData = responseJson.data.map((item: any) => {
          const activity = item.attributes;
          return {
            id: item.id,
            activityTitle: activity.activities_title,
            typeOfActivity: activity.activity_type,
            activityCompletionProgress: activity?.is_completed ? 100 : 0,
            gradingComponent: activity?.grading_component,
          }
        });
        this.setState({ lessonsActivityCompleteList: activityListData });
      } else {
        this.setState({ lessonsActivityCompleteList: [] });
      }
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleSearchTopic = (searchString: string) => {
    const searchQuery = { query: searchString };
    if (searchString.length >= 2) {
      setTimeout(() => {
        this.handleGetTopicListApi(searchQuery);
      }, 1000);
    } else {
      setTimeout(() => {
        this.handleGetTopicListApi({});
      }, 1000);
    }
  }

  handleSearchLesson = (searchString: string) => {
    const searchQuery = { query: searchString };
    if (searchString.length >= 2) {
      setTimeout(() => {
        this.handleGetLessonListApi(searchQuery);
      }, 1000);
    } else {
      setTimeout(() => {
        this.handleGetLessonListApi({});
      }, 1000);
    }
  }

  handleSearchActivity = (searchString: string) => {
    const searchQuery = { title: searchString };
    if (searchString.length >= 2) {
      setTimeout(() => {
        this.handleGetActivityListApi(searchQuery);
      }, 1000);
    } else {
      setTimeout(() => {
        this.handleGetActivityListApi({});
      }, 1000);
    }
  }

  handleDeleteActivityApi = async () => {
    showCustomLoader();
    const { deleteItemId } = this.state;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteActivity = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewActivity}/${deleteItemId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteActivityResponse = (responseJson: any) => {
    const { lessonsActivityCompleteList, deleteItemId } = this.state;
    if (responseJson?.message) {
      const updatedActivityList = lessonsActivityCompleteList.filter(
        (item: any) => item.id !== deleteItemId
      );
      this.setState({ lessonsActivityCompleteList: updatedActivityList, deleteItemId: 0, });
      toast.success(TOASTER_NOTIFICATION_MSG.ACTIVITY_DELETE_SUCCESS);
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.ACTIVITY_DELETE_FAILURE);
    }
    hideCustomLoader();
  };

  handleUpdateActivityEvent = (payload: any) => {
    this.handleCloseAddNewActivityModal();
    this.handleUpdateActivityApi(payload);
  }

  handleUpdateActivityApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      // "Content-Type": "multipart/form-data",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPatchUpdateActivity = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewActivity}/${this.state.editActivityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      requestBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateActivityResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const { lessonsActivityCompleteList, selectedActivity, editActivityId } = this.state;
    if (responseJson?.data?.attributes) {
      const activity = responseJson.data.attributes;
      const updatedActivityItem = {
        id: responseJson.data.id,
        activityTitle: activity.activities_title,
        typeOfActivity: activity.activity_type,
        activityCompletionProgress: activity?.is_completed ? 100 : 0,
      }
      const updatedActivityList = lessonsActivityCompleteList.map((item: any) => {
        if (parseInt(item.id) === parseInt(updatedActivityItem.id)) {
          item = { ...updatedActivityItem };
        }
        return item;
      });
      this.setState({ lessonsActivityCompleteList: updatedActivityList }, () => {
        if (selectedActivity && ((selectedActivity?.id).toString() === editActivityId.toString())) {
          this.setState({ refreshActivityDetail: true });
        } else {
          this.setState({ refreshActivityDetail: false });
        }
      });
      toast.success(TOASTER_NOTIFICATION_MSG.ACTIVITY_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    if (!this.state.refreshActivityDetail) {
      hideCustomLoader();
    }
  };

  handleUpdateActivityMarkCompleteApi = async (activityId: any, activityTimeSpent: number, isCompleted?: boolean) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const requestBody: { activity_id: number, time_spent: number, is_completed?: boolean } = {
      activity_id: parseInt(activityId),
      time_spent: activityTimeSpent || 0,
    }

    if (isCompleted) {
      requestBody.is_completed = true;
    }

    this.apiMarkActivityAsCompleted = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.studentMarkActivityCompleted}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateActivityMarkCompleteResponse =
    (
      responseJson: any,
      errorResponse: any
    ) => {
      const { lessonsActivityCompleteList, selectedActivity, editActivityId } = this.state;
      if (responseJson?.data) {
        const updatedActivityList = lessonsActivityCompleteList.map((item: any) => {
          if (parseInt(item.id) === parseInt(responseJson?.data?.activity_id)) {
            item.activityCompletionProgress = responseJson?.data?.is_completed ? 100 : 0;
          }
          return item;
        });
        this.setState({ lessonsActivityCompleteList: updatedActivityList }, () => {
          if (selectedActivity && ((selectedActivity?.id).toString() === editActivityId.toString())) {
            this.setState({ refreshActivityDetail: true });
          } else {
            this.setState({ refreshActivityDetail: false });
          }
        });
      } else {
        //Check Error Response
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      if (!this.state.refreshActivityDetail) {
        hideCustomLoader();
      }
    };

  resetDataOnTopicSelect = () => {
    this.setState({
      topicLessonsCompleteList: [],
      selectedLesson: null,
      selectedtLessonId: null,
      lessonsActivityCompleteList: [],
      selectedActivity: null,
      selectedActivityId: null,
    });
  }

  resetDataOnLessonSelect = () => {
    this.setState({
      lessonsActivityCompleteList: [],
      selectedActivity: null,
      selectedActivityId: null
    });
  }

  handleGetSelectedActivityId = (data: any) => {
    this.setState({ selectedActivity: data });
  }

  getLessonObject(item: any) {
    const lesson = item.attributes;
    return {
      id: item.id,
      title: lesson?.lesson_title,
      topicId: lesson?.topic_id,
      noOfActivity: 8,
      lessonComplProgress: parseFloat(lesson?.completion_score_by_student?.toFixed(2)),
      lessonTitle: lesson?.lesson_title,
      expanded: false,
      objectives: lesson?.objectives,
      instructions: lesson?.teacher_instructions,
      studentInstructions: lesson?.student_instructions,
      studentMaterials: lesson?.student_materials,
      materials: lesson?.teacher_materials,
      details: lesson?.details,
      description: lesson?.description,
    }
  }

  handleCourseInfoDialog = (data: any) => {
    if (data) {
      const detail = this.state.topicLessonsCompleteList.find((item: any) => item.id === data.id);
      this.setState({ courseInfoContent: detail }, () => {
        this.handleOpenCourseInfoDialogs();
      })
    }
  }
  // Customizable Area End
}
// Customizable Area End