// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentCompletedQuizController, {
  Props
} from "./StudentCompletedQuizController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomRadioButton from "../../../components/src/CustomRadioButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    optionGrid:{
      display:"flex",
      alignItems:"center"
    },
    leftMargin:{
      marginLeft:"1%"
    },
    studentOption:{
      marginLeft:"3px"
    },
    explanation:{
      borderRadius:"8px",
      backgroundColor:"#009dff12",
      padding:"15px 15px",
      marginTop:"20px"
    },
    correctOptionBox:{
      width:"fit-content",
      padding:"10px 10px",
      marginTop:"10px"
    },
    optionsWidth: {
      width: "50%"
    }
});

// Customizable Area End

// Customizable Area Start
export class StudentCompletedQuiz extends StudentCompletedQuizController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes , completedQuestion , index } = this.props;
    const { studentAnswer } = this.state;
    return (
          <Box marginBottom={"3%"} key={completedQuestion?.id} display={"flex"} flexDirection={"column"}>
            <Box display={"flex"}>
              <Typography className={`${classes.textPrimaryColor} ${classes.fontText20}`}>{`Q${index+1}.`}</Typography>
              <Typography className={`${classes.textPrimaryColor} ${classes.fontText20} ${classes.leftMargin}`}>{completedQuestion?.question}</Typography>
            </Box>
            {/* Answers list */}
            <Box marginTop={"1%"}>
              <Grid container direction="row" className={classes.optionsWidth}>
                {
                  completedQuestion?.answers?.map((answerOption : any , indexTwo : number) => 
                  {
                  const optionSequence = String.fromCharCode(65 + indexTwo);
                  return (<Grid className={classes.optionGrid} key={`${completedQuestion?.id}-${answerOption?.id}`} item xs={12} sm={6} md={6} lg={3}>
                      <Box marginRight={"2px"}>
                        <CustomRadioButton 
                        checked={answerOption?.id === completedQuestion?.studentAnswerId}
                        isDisabled
                        isDueQuiz={false}
                        isCorrect={this.handleCheckIsCorrectQuizOption(completedQuestion?.studentAnswerId , answerOption)} 
                        fieldName={`${answerOption?.option}-${indexTwo}`}
                        handleChange={(e : any) => e.preventDefault()} 
                        />
                      </Box>
                      <Typography className={`${classes.textPrimaryColor} ${classes.fontText20} ${classes.leftMargin}`}>{`${optionSequence}. ${answerOption?.option}`}</Typography>
                  </Grid>)
                  }
                )
                }
              </Grid>
            </Box>
            {/* Student Answer */}
            <Box marginTop={"10px"} display={"flex"} alignItems={"center"}>
             <Typography className={`${classes.textCommonColor} ${classes.fontText20}`}>{`Your Answer: `}</Typography>
             <Typography className={this.handleCheckIsStudentAnswerCorrect() ? `${classes.primaryColor4} ${classes.fontText20} ${classes.fontBold600} ${classes.studentOption}` : `${classes.primaryColor15} ${classes.fontBold600} ${classes.fontText20} ${classes.studentOption}`}>{`${studentAnswer}`}</Typography>
            </Box>
            {/* Explanation */}
            <Box className={classes.explanation}>
                <Typography className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}>Explanation</Typography>
                <Typography className={`${classes.textPrimaryColor} ${classes.fontText16}`}>{completedQuestion?.explanation}</Typography>
              <Box display={"flex"} alignItems={"center"} borderRadius={"8px"} className={`${classes.bgColor} ${classes.correctOptionBox}`}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText20}`}>Correct Answer:</Typography>
                <Typography className={`${classes.primaryColor4} ${classes.fontText20} ${classes.fontBold600} ${classes.studentOption}`}>{this.handleReturnCorrectAnswerText()}</Typography>
              </Box>
            </Box>
          </Box> 
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentCompletedQuiz);
// Customizable Area End
