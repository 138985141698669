Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.teacherDashboardClassesEndpoint = "account_block/users/find_teacher_assign_classes";
exports.getTeacherCoursesEndpoint = "bx_block_profile/courses/get_teacher_courses_tab";
exports.teacherClassCourseStudentsApi = "account_block/users/get_class_based_students";
exports.teacherAssessmentEndpoint = "bx_block_activity/activities/assessments";
exports.gradingSystemListEndpoint = "bx_block_grade/grading_systems"
exports.apiMethodTypeAddDetail ="POST"
exports.apiMethodTypeDelete ="DELETE"
exports.apiMethodTypeUpdate="PUT"
exports.gradingComponentListEndpoint="bx_block_grade/grading_component_and_weightages"
exports.behavioursListEndpoint="bx_block_grade/behaviours"
exports.rulesEndpoint="bx_block_grade/grade_book_rules"
exports.gradeBookTenantEndpoint="bx_block_grade/grade_books"
exports.gradebookTableEndpoint = "bx_block_cfviewreportcardofficialandunofficial/grade_book_templates/get_school_year_based_on_curriculum"
exports.curriculumEndpoint ="bx_block_grade/grade_book_rules/get_available_curriculum"
exports.templateEndpoint ="bx_block_cfviewreportcardofficialandunofficial/grade_book_templates"
// Customizable Area End