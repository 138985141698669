// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  englishSubjectIcon,
  historySubjectIcon,
  mathSubjectIcon,
  physicsSubjectIcon,
  psychologySubjectIcon,
} from "./assets";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export const assessmentCategoryTabs = [
  {
    id:1,
    label:"Due",
    value:"due"
  },
  {
    id:2,
    label:"Completed",
    value:"completed"
  },
  {
    id:3,
    label:"Graded",
    value:"graded"
  }
]
export interface IDropdownItems {
  id:number;
  label:string;
  value:any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  studentAssessmentsList: any;
  subjectsList:any;
  gradingComponent: Array<any>;
  selectedAssessmentCategoryTab:number;
  subjectDDMenuItems:Array<IDropdownItems>;
  selectedSubjectDDValue:any;
  gradingComponentDDMenuItems:Array<IDropdownItems>;
  selectedGradingComponentDDValue:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentMyAssessmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      studentAssessmentsList: [
        {
          id: 1,
          courseName: "Physics",
          subjectIcon: physicsSubjectIcon,
          subjectBgColor: "#01C996",
          subjectId:1,
          points: 25,
          topicName:"Kinematics",
          dueDate: "24/08/2023",
          assignedDate:"23/06/2023",
          submittedDate:"22/07/2023",
          score: 41,
          typeOfAssessment:"quiz",
          assessmentTitle:"Graphing Quiz"
        },
        {
          id: 2,
          courseName: "Calculus",
          subjectIcon: mathSubjectIcon,
          subjectBgColor: "#0D46BC",
          subjectId:2,
          points: 25,
          topicName:"Derivatives",
          dueDate: "24/08/2023",
          assignedDate:"23/06/2023",
          submittedDate:"22/07/2023",
          score: 41,
          typeOfAssessment:"exam",
          assessmentTitle:"Differentiability Exam"
        },
        {
          id: 3,
          courseName: "Creative Writing",
          subjectIcon: englishSubjectIcon,
          subjectBgColor: "#019AFC",
          subjectId:3,
          points: 25,
          topicName:"Contemporary Fiction",
          dueDate: "24/08/2023",
          assignedDate:"23/06/2023",
          submittedDate:"22/07/2023",
          score: 70,
          typeOfAssessment:"assignment",
          assessmentTitle:"Fiction Assignment"
        },
        {
          id: 4,
          courseName: "Chemistry",
          subjectIcon: physicsSubjectIcon,
          subjectBgColor: "#FBD82C",
          subjectId:1,
          points: 80,
          topicName:"Organic Chemistry",
          dueDate: "24/08/2023",
          assignedDate:"23/06/2023",
          submittedDate:"22/07/2023",
          score: 41,
          typeOfAssessment:"quiz",
          assessmentTitle:"Alkenes Quiz"
        },
        {
          id: 5,
          courseName: "Child Psychology",
          subjectIcon: psychologySubjectIcon,
          subjectBgColor: "#FF0000",
          subjectId:5,
          points: 80,
          topicName:"Intelligence & Attainment",
          dueDate: "24/08/2023",
          assignedDate:"23/06/2023",
          submittedDate:"22/07/2023",
          score: 41,
          typeOfAssessment:"project",
          assessmentTitle:"Intelligences Project"
        },
        {
          id: 6,
          courseName: "Roman History",
          subjectIcon: historySubjectIcon,
          subjectBgColor: "#FF0000",
          subjectId:5,
          points: 80,
          topicName:"Punic Wars",
          dueDate: "24/08/2023",
          assignedDate:"23/06/2023",
          submittedDate:"22/07/2023",
          score: 41,
          typeOfAssessment:"quiz",
          assessmentTitle:"First Punic War Quiz"
        },
      ],
      subjectsList:[
        {
          id:1,
          subjectName:"Physics"
        },
        {
          id:2,
          subjectName:"Calculus"
        },
        {
          id:3,
          subjectName:"Creative Writing"
        },
        {
          id:4,
          subjectName:"Chemistry"
        },
        {
          id:5,
          subjectName:"Child Psychology"
        },
        {
          id:6,
          subjectName:"Roman History"
        }
      ],
      gradingComponent: [
        {
          id: 1,
          label: "Quiz",
          value: 1,
        },
        {
          id: 2,
          label: "Project",
          value: 2,
        },
        {
          id: 3,
          label: "Exam",
          value: 3,
        },
        {
          id: 4,
          label: "Assignment",
          value: 4,
        },
      ],
      selectedAssessmentCategoryTab:0,
      subjectDDMenuItems:[],
      selectedSubjectDDValue:"",
      gradingComponentDDMenuItems:[],
      selectedGradingComponentDDValue:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetSubjectDDItems();
    // Customizable Area End
  }

  // Customizable Area Start

  handleSetSubjectDDItems = () => {
    const { subjectsList } = this.state;
    const subjectDDMenuItems : Array<IDropdownItems> = subjectsList?.map((subject : any) => {
      return { id : subject?.id , value : subject?.id , label : subject?.subjectName }
    });
    this.setState({subjectDDMenuItems});
  }

  handleChangeAssessmentCategoryTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({selectedAssessmentCategoryTab : newValue});
  };

  renderSubjectDDValue = (selected:any) => {
    if (!selected || selected.length === 0) {
      return "Select Subject";
    }
    const { subjectDDMenuItems } = this.state;
    const selctedSubject = subjectDDMenuItems.find((subject : any) => subject?.id === selected);
    return selctedSubject?.label;
  };

  renderGradingComponenttDDValue = (selected:any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponent = gradingComponent.find((grade : any) => grade?.id === selected);
    return selectedGradingComponent?.label;
  };

  handleSearchAssessment = () => {
    console.log("search assessment" , this.state.selectedSubjectDDValue , this.state.selectedGradingComponentDDValue);
  }

  handleChangeDropdownValue = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
}>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue : any = value ;
    if(fieldValue){
      this.setState((prev) => {
        return {
            ...prev,
            [name] : fieldValue,
        };
     });
    }
  };


  // Customizable Area End
}
// Customizable Area End
