// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  handleUpdateAnswer: any;
  events?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedClassComponentDDValue: any;
  gradingComponent: Array<any>;
  openAssignCourseModal: boolean;
  selectStartDate: any;
  selectEndDate: any;
  isCreateModal: boolean;
  searchStudent: string;
  colorChange: any;
  selectEventPopUp: boolean;
  selectEventDetails: {
    title: string;
    date: any;
    desc: any;
    textColor: string;
  };
  openDeletePopUp: boolean;
  openEditPopUp: boolean;
  openCreateSuccessPopUp: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherCalendarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      selectEventDetails: {
        title: "",
        date: "",
        desc: "",
        textColor: "",
      },
      selectEventPopUp: false,
      openDeletePopUp: false,
      openEditPopUp: false,
      openCreateSuccessPopUp: false,
      selectedClassComponentDDValue: "",
      openAssignCourseModal: false,
      selectStartDate: new Date(),
      selectEndDate: new Date(),
      isCreateModal: false,
      searchStudent: "",
      colorChange: "#ff0000",
      gradingComponent: [
        {
          id: 1,
          label: "All",
          value: 1,
        },
        {
          id: 2,
          label: "Class 10A",
          value: 2,
        },
        {
          id: 3,
          label: "Class 10B",
          value: 3,
        },
        {
          id: 4,
          label: "Class 9A",
          value: 4,
        },
        {
          id: 5,
          label: "Assignment",
          value: 5,
        },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    localStorage.setItem("role", "teacher");
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }
  handleCalendarDropdownValues = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };
  renderCalendarComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "All";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponents = gradingComponent.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  handleOpenReschedulModal = () => {
    this.setState({ openAssignCourseModal: true });
  };

  handleCloseAssignCourseModal = () => {
    this.setState({ openAssignCourseModal: false });
  };
  selectStartDate = (date: any) => {
    this.setState({
      selectStartDate: date,
    });
  };
  selectEndDate = (date: any) => {
    this.setState({
      selectEndDate: date,
    });
  };
  createEventModal = () => {
    this.setState({
      isCreateModal: true,
    });
  };
  closeCreateModal = () => {
    this.setState({
      isCreateModal: false,
    });
  };
  handleChange = async (event: any) => {
    this.setState({
      searchStudent: event.target.value,
    });
  };
  colorChangeUpdate = async (event: any) => {
    this.setState({
      colorChange: event.target.value,
    });
  };
  openPIckCOlor = () => {
    let el = document.getElementById("ColorChange");
    if (el) {
      el.click();
    }
  };
  handleEventSelect = (selectedEvent: any) => {
    const startDate = moment(selectedEvent.start);
    const formattedStartDate = startDate.format("MMM DD, YYYY");
    this.setState({
      selectEventPopUp: true,
      selectEventDetails: {
        date: formattedStartDate,
        title: selectedEvent.title,
        desc: selectedEvent.desc,
        textColor: selectedEvent.textColor,
      },
    });
  };
  closeHandleEventSelect = () => {
    this.setState({
      selectEventPopUp: false,
    });
  };
  handleDeletePopUp = () => {
    this.setState({
      openDeletePopUp: true,
      selectEventPopUp: false,
    });
  };
  handleCloseDeletePopUp = () => {
    this.setState({ openDeletePopUp: false });
  };
  handleEditPopUp = () => {
    this.setState({
      openEditPopUp: true,
      selectEventPopUp: false,
    });
  };
  handleCloseEditPopUp = () => {
    this.setState({
      openEditPopUp: false,
    });
  };
  CreateEventSuccessPopUp = () => {
    this.setState({
      openCreateSuccessPopUp: true,
    });
  };
  CloseEventSuccessPopUp = () => {
    this.setState({
      openCreateSuccessPopUp: false,
    });
  };
  handleUpdateAnswer =()=>{
    console.log('error')
  }
  // Customizable Area End
}
// Customizable Area End
