import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { articleImg, ScienceColor, MathsColor, HistoryColor, EnglishColor, sort } from "../src/assets";

const instructionsList = [
    {
        id: 1,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
        id: 2,
        text: "Cras sit amet tincidunt ex. Aliquam sollicitudin non eros ullamcorper pellentesque.",
    },
    {
        id: 3,
        text: "Vivamus vulputate sapien sed ipsum scelerisque condimentum.",
    },
    {
        id: 4,
        text: "Sed ligula lectus, consequat sed gravida ac, dignissim vitae eros. Morbi vel dictum urna.",
    },
    {
        id: 5,
        text: "Fusce id sem risus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
    },
    {
        id: 6,
        text: "Ut mattis ipsum eu orci gravida lacinia. Sed pellentesque vitae eros rutrum efficitur.",
    },
    {
        id: 7,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
        id: 8,
        text: "Cras sit amet tincidunt ex. Aliquam sollicitudin non eros ullamcorper pellentesque.",
    },
    {
        id: 9,
        text: "Vivamus vulputate sapien sed ipsum scelerisque condimentum.",
    },
    {
        id: 10,
        text: "Sed ligula lectus, consequat sed gravida ac, dignissim vitae eros. Morbi vel dictum urna.",
    },
];
const studentListTableColumns = [
    {
        id: "Title",
        columnId: 1,
        label: "Title",
        type: tableColumnTypes.STUDENT_ICON,
        width: "300px",
        maxWidth: "300px",
    },
    {
        id: "Progress",
        columnId: 2,
        label: "Progress",
        icon: sort,
        type: tableColumnTypes.GRADEBOOK_PROGRESS_BAR,
        isSortingColumn: true,
        sortByOrder: "",
        width: "150px",
        maxWidth: "150px",
    },
    {
        id: "totalAvg",
        columnId: 7,
        label: "Total",
        type: tableColumnTypes.TEXT,
        width: "100px",
        maxWidth: "100px",
  
      },
      {
        id: "grade",
        columnId: 8,
        label: "Grade",
        type: tableColumnTypes.TEXT,
        width: "250px",
        maxWidth: "250px",
  
      },
];
const subjectCoursesDetailsTableColumns = [
    {
      id: "title",
      columnId: 1,
      label: "Title",
      type: tableColumnTypes.TEXT,
      width: "150px",
      maxWidth: "150px",
    },
    {
      id: "weight",
      columnId: 2,
      label: "Weight",
      type: tableColumnTypes.TEXT,
      width: "150px",
      maxWidth: "150px",
    },

    {
      id: "totalScore",
      columnId: 7,
      label: "Total",
      type: tableColumnTypes.TEXT,
      width: "150px",
      maxWidth: "150px",
     
    },
  ];

const currentLessonActivityList = [
    {
        id: 1,
        typeOfActivity: "text",
        evaluationType: "subjective",
        activityTitle: "Activity Title",
        objectiveType: null,
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        activityImage: articleImg,
        activityCompletionProgress: 100,
        expanded: false,
    },
    {
        id: 2,
        typeOfActivity: "project",
        evaluationType: "subjective",
        objectiveType: null,
        activityTitle: "Intelligence Project",
        about:
            " It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        activityCompletionProgress: 100,
        expanded: false,
        dueDate: "10/24/2023",
        instructions: instructionsList,
        allowedAttempts: 3,
        maxScore: 30,
    },
    {
        id: 3,
        typeOfActivity: "audio",
        evaluationType: null,
        objectiveType: null,
        activityTitle: "Audio Lec Title",
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        audioUrl: "https://samplelib.com/lib/preview/mp3/sample-15s.mp3",
        activityCompletionProgress: 60,
        mediaProgress: 0,
        expanded: false,
    },
    {
        id: 4,
        typeOfActivity: "task",
        activityTitle: "Task Title",
        evaluationType: "subjective",
        objectiveType: null,
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        activityImage: articleImg,
        activityCompletionProgress: 0,
        expanded: false,
    },
    {
        id: 5,
        typeOfActivity: "video",
        activityTitle: "Video Lec Title",
        evaluationType: null,
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        videoUrl: "https://samplelib.com/lib/preview/mp3/sample-15s.mp3",
        activityCompletionProgress: 29,
        expanded: false,
    },
    {
        id: 6,
        typeOfActivity: "assignment",
        evaluationType: "objective",
        objectiveType: "selection",
        activityTitle: "Assignment Objective",
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        videoUrl: "https://samplelib.com/lib/preview/mp3/sample-15s.mp3",
        activityCompletionProgress: 30,
        expanded: false,
        dueDate: "05/24/2023",
        instructions: instructionsList,
        allowedAttempts: 3,
        maxScore: 30,
    },
    {
        id: 61,
        typeOfActivity: "assignment",
        evaluationType: "objective",
        objectiveType: "written",
        activityTitle: "Assignment Written",
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        videoUrl: "https://samplelib.com/lib/preview/mp3/sample-15s.mp3",
        activityCompletionProgress: 30,
        expanded: false,
        dueDate: "05/24/2023",
        instructions: instructionsList,
        allowedAttempts: 3,
        maxScore: 30,
    },
    {
        id: 7,
        typeOfActivity: "assignment",
        evaluationType: "subjective",
        objectiveType: null,
        activityTitle: "Assignment Subjective",
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        videoUrl: "https://samplelib.com/lib/preview/mp3/sample-15s.mp3",
        activityCompletionProgress: 30,
        expanded: false,
        dueDate: "05/24/2023",
        instructions: instructionsList,
        allowedAttempts: 3,
        maxScore: 30,
    },
    {
        id: 8,
        typeOfActivity: "quiz",
        evaluationType: "objective",
        objectiveType: null,
        activityTitle: "Quiz Objective",
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        activityCompletionProgress: 0,
        expanded: false,
        dueDate: "05/24/2023",
        instructions: instructionsList,
        allowedAttempts: 3,
        maxScore: 30,
    },
    {
        id: 9,
        typeOfActivity: "quiz",
        evaluationType: "subjective",
        objectiveType: null,
        activityTitle: "Quiz Subjective",
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
        activityCompletionProgress: 0,
        expanded: false,
        dueDate: "05/24/2023",
        instructions: instructionsList,
        allowedAttempts: 3,
        maxScore: 30,
    },
    {
        id: 10,
        typeOfActivity: "exam",
        evaluationType: "objective",
        objectiveType: "selection",
        activityTitle: "Exam Objective",
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        activityCompletionProgress: 0,
        expanded: false,
        dueDate: "05/24/2023",
        instructions: instructionsList,
        allowedAttempts: 3,
        maxScore: 25,
    },
    {
        id: 12,
        typeOfActivity: "exam",
        evaluationType: "objective",
        objectiveType: "written",
        activityTitle: "Exam Written",
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        activityCompletionProgress: 0,
        expanded: false,
        dueDate: "05/24/2023",
        instructions: instructionsList,
        allowedAttempts: 3,
        maxScore: 25,
    },
    {
        id: 11,
        typeOfActivity: "exam",
        evaluationType: "subjective",
        objectiveType: null,
        activityTitle: "Exam Subjective",
        about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        activityCompletionProgress: 0,
        expanded: false,
        dueDate: "05/24/2023",
        instructions: instructionsList,
        allowedAttempts: 3,
        maxScore: 25,
    },
];

const coursesData = [
    {
        id: 1,
        Title: {
            name: "Physics",
            icon: ScienceColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Scheduled',
    },
    {
        id: 2,
        Title: {
            name: "Algebra",
            icon: MathsColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Late',
    },
    {
        id: 3,
        Title: {
            name: "Roman History",
            icon: HistoryColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Completed',
    },
    {
        id: 4,
        Title: {
            name: "Victorian Literature",
            icon: EnglishColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Scheduled',
    },
    {
        id: 5,
        Title: {
            name: "Creative Writing",
            icon: EnglishColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Late',
    },
    {
        id: 6,
        Title: {
            name: "English Poetry",
            icon: EnglishColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Completed',
    },
    {
        id: 7,
        Title: {
            name: "Velocity and Acceleration",
            icon: ScienceColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Scheduled',
    },
    {
        id: 8,
        Title: {
            name: "Calculas",
            icon: MathsColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Late',
    },
    {
        id: 9,
        Title: {
            name: "Industrial Revolution",
            icon: HistoryColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Completed',
    },
];

const subjectTopicList = [
    {
        id: 1,
        noOfLessons: 8,
        topicAvgGrades: 60,
        topicCompProgress: 60,
        expanded: false,
        topicTitle: "Kinematics",
        topicLessonsCompleteList: [
            {
                id: 1,
                noOfActivity: 8,
                lessonComplProgress: 30,
                lessonTitle: "Graphing 2",
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Activity Graphing 5",
                        typeOfActivity: "video",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        activityTitle: "Activity Graphings 4",
                        typeOfActivity: "video",
                        activityCompletionProgress: 0
                    },
                    {
                        id: 3,
                        typeOfActivity: "text",
                        activityCompletionProgress: 60,
                        activityTitle: "Activity 2 compl",
                    },
                    {
                        id: 4,
                        activityTitle: "Activity 4 Done",
                        activityCompletionProgress: 3,
                        typeOfActivity: "text",
                    },
                    {
                        id: 5,
                        activityCompletionProgress: 7,
                        typeOfActivity: "audio",
                        activityTitle: "Activity Graphing 9",
                    },
                    {
                        id: 6,
                        typeOfActivity: "audio",
                        activityCompletionProgress: 84,
                        activityTitle: "Activity Graph type",
                    },
                    {
                        id: 7,
                        typeOfActivity: "text",
                        activityCompletionProgress: 101,
                        activityTitle: "Activity TGraphing 9",
                    },
                ]
            },
            {
                id: 2,
                noOfActivity: 5,
                lessonTitle: "Vectors Comp",
                expanded: false,
                lessonComplProgress: 30,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Vectors Activity 32",
                        typeOfActivity: "video",
                        activityCompletionProgress: 105
                    },
                    {
                        id: 2,
                        activityCompletionProgress: 87,
                        typeOfActivity: "video",
                        activityTitle: "Activity Profile",
                    },
                    {
                        id: 3,
                        activityTitle: "Activity Vector types",
                        typeOfActivity: "text",
                        activityCompletionProgress: 80
                    },
                    {
                        id: 4,
                        activityCompletionProgress: 52,
                        typeOfActivity: "text",
                        activityTitle: "Activity Vector phy",
                    },
                    {
                        id: 5,
                        typeOfActivity: "audio",
                        activityCompletionProgress: 81,
                        activityTitle: "Activity Vector Math",
                    },
                ]
            },
            {
                id: 3,
                noOfActivity: 3,
                lessonTitle: "Motions in ...",
                expanded: false,
                lessonComplProgress: 30,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityCompletionProgress: 101,
                        typeOfActivity: "text",
                        activityTitle: "Motion Activity sc..",
                    },
                    {
                        id: 2,
                        activityCompletionProgress: 76,
                        activityTitle: "Activity Motion lesson 4",
                        typeOfActivity: "video",
                    },
                    {
                        id: 3,
                        activityCompletionProgress: 64,
                        activityTitle: "Activity Motion values",
                        typeOfActivity: "text",
                    },
                    {
                        id: 4,
                        activityCompletionProgress: 50,
                        typeOfActivity: "video",
                        activityTitle: "Activity Motion item 1",
                    },
                    {
                        id: 5,
                        activityCompletionProgress: 85,
                        activityTitle: "Activity Motion 5 types",
                        typeOfActivity: "audio",
                    },
                ]
            },
            {
                id: 4,
                lessonTitle: "Circular Motiont ty..",
                noOfActivity: 5,
                lessonComplProgress: 100,
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Circular Title for 3",
                        activityCompletionProgress: 100,
                        typeOfActivity: "video",
                    },
                    {
                        id: 2,
                        typeOfActivity: "text",
                        activityCompletionProgress: 103,
                        activityTitle: "Activity Circular for test",
                    },
                    {
                        id: 3,
                        typeOfActivity: "video",
                        activityCompletionProgress: 66,
                        activityTitle: "Activity Circular video 1",
                    },
                    {
                        id: 4,
                        activityTitle: "Activity Circuits 1",
                        typeOfActivity: "text",
                        activityCompletionProgress: 50
                    },
                    {
                        id: 5,
                        activityCompletionProgress: 89,
                        typeOfActivity: "test",
                        activityTitle: "Activity type motion 1",
                    },
                    {
                        id: 6,
                        activityTitle: "Motion Circular",
                        typeOfActivity: "audio",
                        activityCompletionProgress: 20
                    },
                ]
            },
            {
                id: 5,
                expanded: false,
                noOfActivity: 1,
                lessonTitle: "Planetary Motion 32",
                lessonComplProgress: 30,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Audio Lessons Activity Title",
                        activityCompletionProgress: 100,
                        typeOfActivity: "audio",
                    },
                    {
                        id: 2,
                        typeOfActivity: "text",
                        activityCompletionProgress: 106,
                        activityTitle: "Activity Planetary Title 1",
                    },
                    {
                        id: 3,
                        activityCompletionProgress: 56,
                        typeOfActivity: "video",
                        activityTitle: "Activity Planetary types 1",
                    },
                    {
                        id: 4,
                        activityTitle: "Activity Physics 1",
                        typeOfActivity: "video",
                        activityCompletionProgress: 50
                    },
                ]
            },
        ]
    },
    {
        id: 2,
        topicAvgGrades: 58,
        topicCompProgress: 68,
        expanded: false,
        topicTitle: "Dynamics Motion 1",
        noOfLessons: 6,
        topicLessonsCompleteList: [
            {
                id: 1,
                lessonTitle: "Dynamics part 1",
                noOfActivity: 8,
                expanded: false,
                lessonComplProgress: 100,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Title 2 Activity Dynamics",
                        activityCompletionProgress: 10,
                        typeOfActivity: "audio",
                    },
                    {
                        id: 2,
                        activityTitle: "Lessons Dynamics 3",
                        activityCompletionProgress: 100,
                        typeOfActivity: "audio",
                    },
                    {
                        id: 3,
                        activityTitle: "Activity audio 2",
                        activityCompletionProgress: 62,
                        typeOfActivity: "audio",
                    },
                    {
                        id: 4,
                        typeOfActivity: "Video",
                        activityTitle: "Activity Dynamics Type 1",
                        activityCompletionProgress: 101
                    },
                    {
                        id: 5,
                        typeOfActivity: "text",
                        activityCompletionProgress: 63,
                        activityTitle: "Activity Dynamics Vlume 1",
                    },
                    {
                        id: 6,
                        activityTitle: "Activity Dynamics volumn 3",
                        typeOfActivity: "video",
                        activityCompletionProgress: 30,
                    },
                ]
            },
            {
                id: 2,
                lessonTitle: "Dynamics Title 5",
                expanded: false,
                lessonComplProgress: 31,
                noOfActivity: 8,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        typeOfActivity: "audio",
                        activityCompletionProgress: 100,
                        activityTitle: "Dynamics Part 2 Activity",
                    },
                    {
                        id: 2,
                        activityCompletionProgress: 108,
                        typeOfActivity: "audio",
                        activityTitle: "Video Activity video 3",
                    },
                    {
                        id: 3,
                        activityTitle: "Video Activity types 34",
                        typeOfActivity: "video",
                        activityCompletionProgress: 60
                    },
                    {
                        id: 4,
                        typeOfActivity: "audio",
                        activityCompletionProgress: 58,
                        activityTitle: "Volume 4 Activity 4",
                    },
                ]
            },
            {
                id: 3,
                lessonComplProgress: 30,
                noOfActivity: 6,
                lessonTitle: "Dynamics parts 3",
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        typeOfActivity: "text",
                        activityTitle: "update Activity text 1",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        activityTitle: "Activity update parts updated",
                        activityCompletionProgress: 102,
                        typeOfActivity: "audio",
                    },
                    {
                        id: 3,
                        activityCompletionProgress: 67,
                        typeOfActivity: "video",
                        activityTitle: "Activity Math part 3",
                    },
                    {
                        id: 4,
                        typeOfActivity: "audio",
                        activityTitle: "Activity update audio ..",
                        activityCompletionProgress: 57,
                    },
                    {
                        id: 5,
                        typeOfActivity: "audio",
                        activityTitle: "Activity update Audio 5",
                        activityCompletionProgress: 80
                    },
                ]
            },
            {
                id: 4,
                lessonComplProgress: 98,
                noOfActivity: 5,
                lessonTitle: "Dynamics Chapter 4",
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Dynamics Chapter Activity 54",
                        typeOfActivity: "audio",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        activityTitle: "Activity Dynamics text 3",
                        typeOfActivity: "text",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        typeOfActivity: "video",
                        activityTitle: "Titles 3 Dynamics",
                        activityCompletionProgress: 66,
                    },
                    {
                        id: 4,
                        activityCompletionProgress: 57,
                        typeOfActivity: "audio",
                        activityTitle: "Activity 4 Part Two",
                    },
                    {
                        id: 5,
                        typeOfActivity: "video",
                        activityTitle: "Activity Audio Terminal 5",
                        activityCompletionProgress: 80
                    },
                ]
            },
            {
                id: 5,
                expanded: false,
                noOfActivity: 5,
                lessonTitle: "LessonsT topic 5",
                lessonComplProgress: 30,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityCompletionProgress: 100,
                        typeOfActivity: "audio",
                        activityTitle: "LessonsT Lesson 9 Activity Title",
                    },
                    {
                        id: 2,
                        activityCompletionProgress: 100,
                        typeOfActivity: "audio",
                        activityTitle: "Audio Video part 2",
                    },
                    {
                        id: 3,
                        typeOfActivity: "audio",
                        activityCompletionProgress: 66,
                        activityTitle: "Activity 3 Pendulam 3",
                    },
                    {
                        id: 4,
                        typeOfActivity: "audio",
                        activityTitle: "Activity Neuton Law",
                        activityCompletionProgress: 58,
                    },
                    {
                        id: 5,
                        activityTitle: "Mechanism Mechanism 5",
                        typeOfActivity: "video",
                        activityCompletionProgress: 80
                    },
                    {
                        id: 6,
                        activityCompletionProgress: 22,
                        typeOfActivity: "audio",
                        activityTitle: "GitLens 6 topic",
                    },
                ]
            },
        ]
    },
    {
        id: 3,
        topicTitle: "Heat & Temperature",
        topicCompProgress: 69,
        expanded: false,
        noOfLessons: 8,
        topicAvgGrades: 100,
        topicLessonsCompleteList: [
            {
                id: 1,
                noOfActivity: 6,
                expanded: false,
                lessonComplProgress: 100,
                lessonTitle: "Heat & Temp part",
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        typeOfActivity: "video",
                        activityTitle: "Heat 3 Quantum Title",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        activityTitle: "Physics Video 2 Heats",
                        typeOfActivity: "video",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        typeOfActivity: "audio",
                        activityTitle: "Physics 3 Heat",
                        activityCompletionProgress: 60
                    },
                    {
                        id: 4,
                        typeOfActivity: "audio",
                        activityTitle: "Neuton Theory 4 Heat",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 5,
                        typeOfActivity: "audio",
                        activityTitle: "Wave Motion Audio 5 Heat",
                        activityCompletionProgress: 60
                    },
                    {
                        id: 6,
                        typeOfActivity: "video",
                        activityTitle: "Electricity Activity 6 Heat",
                        activityCompletionProgress: 30
                    },
                    {
                        id: 7,
                        activityTitle: "Activity MOtion Wave 7 Heat",
                        typeOfActivity: "audio",
                        activityCompletionProgress: 80
                    },
                    {
                        id: 8,
                        typeOfActivity: "video",
                        activityTitle: "Thromo Heat 8",
                        activityCompletionProgress: 70
                    }
                ]
            },
            {
                id: 2,
                noOfActivity: 6,
                expanded: false,
                lessonComplProgress: 30,
                lessonTitle: "Thermodynamics & Temp ",
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Temp 2 Thermodynamics Title",
                        typeOfActivity: "audio",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        activityTitle: "No of Temp 2",
                        activityCompletionProgress: 100,
                        typeOfActivity: "video",
                    },
                    {
                        id: 3,
                        typeOfActivity: "video",
                        activityTitle: "Activity Lesson parts 2",
                        activityCompletionProgress: 60
                    },
                    {
                        id: 4,
                        typeOfActivity: "video",
                        activityTitle: "Activity Water 2",
                        activityCompletionProgress: 50
                    },
                    {
                        id: 5,
                        typeOfActivity: "text",
                        activityTitle: "Activity Text parts 5",
                        activityCompletionProgress: 80
                    },
                    {
                        id: 6,
                        activityTitle: "Activity Demo 2",
                        typeOfActivity: "video",
                        activityCompletionProgress: 20
                    },
                ]
            },
            {
                id: 3,
                noOfActivity: 5,
                expanded: false,
                lessonComplProgress: 30,
                lessonTitle: "Physics",
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Gravitaion Activity Title",
                        typeOfActivity: "audio",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        typeOfActivity: "text",
                        activityTitle: "Neuclear Part",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        activityTitle: "Neuclear Physics",
                        typeOfActivity: "audio",
                        activityCompletionProgress: 60
                    },
                    {
                        id: 4,
                        typeOfActivity: "video",
                        activityTitle: "Optics Physics",
                        activityCompletionProgress: 50
                    },
                    {
                        id: 5,
                        typeOfActivity: "audio",
                        activityTitle: "Astro Part 43",
                        activityCompletionProgress: 80
                    },
                ]
            },
            {
                id: 4,
                expanded: false,
                noOfActivity: 6,
                lessonComplProgress: 100,
                lessonTitle: "Electromagnetics induction",
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Induction 4 Activity Title",
                        activityCompletionProgress: 102,
                        typeOfActivity: "text",
                    },
                    {
                        id: 2,
                        activityTitle: "Electromagnetics part item",
                        typeOfActivity: "text",
                        activityCompletionProgress: 100
                    },
                ]
            },
            {
                id: 5,
                noOfActivity: 7,
                expanded: false,
                lessonTitle: "ElectroStatic",
                lessonComplProgress: 30,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Temp 5 ElectroStatic Title",
                        typeOfActivity: "video",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        typeOfActivity: "text",
                        activityTitle: "Kinematics Temp 5 check",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        typeOfActivity: "Energy",
                        activityTitle: "Energy Kinematics check",
                        activityCompletionProgress: 60
                    },
                    {
                        id: 4,
                        activityTitle: "Fluid Mechanics",
                        typeOfActivity: "Video",
                        activityCompletionProgress: 50
                    },
                    {
                        id: 5,
                        typeOfActivity: "text",
                        activityTitle: "Mechanics Audio 56",
                        activityCompletionProgress: 80
                    },
                    {
                        id: 6,
                        activityTitle: "Fluids 62",
                        activityCompletionProgress: 24,
                        typeOfActivity: "text",
                    },
                ]
            },
            {
                id: 6,
                noOfActivity: 5,
                expanded: false,
                lessonTitle: "Heat Progress",
                lessonComplProgress: 76,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        typeOfActivity: "video",
                        activityTitle: "Law Of GravitaionActivity Titles",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        typeOfActivity: "audio",
                        activityCompletionProgress: 107,
                        activityTitle: "Matter Physics Video 2",
                    },
                    {
                        id: 3,
                        typeOfActivity: "video",
                        activityTitle: "Classic Physics 33",
                        activityCompletionProgress: 60
                    },
                    {
                        id: 4,
                        typeOfActivity: "text",
                        activityTitle: "Activity 41",
                        activityCompletionProgress: 50
                    },
                    {
                        id: 5,
                        typeOfActivity: "audio",
                        activityTitle: "Activity Audio 51",
                        activityCompletionProgress: 80
                    },
                    {
                        id: 6,
                        typeOfActivity: "text",
                        activityTitle: "Activity 62",
                        activityCompletionProgress: 20
                    },
                ]
            }
        ]
    },
    {
        id: 4,
        topicTitle: "Magnetism",
        topicAvgGrades: 8,
        topicCompProgress: 60,
        expanded: false,
        noOfLessons: 8,
        topicLessonsCompleteList: []
    },
    {
        id: 5,
        expanded: false,
        topicTitle: "Conservation of En…",
        topicAvgGrades: 32,
        topicCompProgress: 33,
        noOfLessons: 5,
        topicLessonsCompleteList: [
            {
                id: 1,
                noOfActivity: 8,
                lessonComplProgress: 100,
                lessonTitle: "Magnetism Part 1",
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        typeOfActivity: "video",
                        activityTitle: "Periodic Motion Activity Title",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        activityTitle: "Activity Magnetism Periodic Motion",
                        typeOfActivity: "text",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        activityTitle: "Magnetism Waves in One Dimension",
                        typeOfActivity: "audio",
                        activityCompletionProgress: 60
                    },
                    {
                        id: 4,
                        activityCompletionProgress: 109,
                        activityTitle: "Activity Fluids",
                        typeOfActivity: "text",
                    },
                    {
                        id: 5,
                        activityCompletionProgress: 50,
                        typeOfActivity: "video",
                        activityTitle: " Ray (Geometric) Optics Activity",
                    },
                    {
                        id: 6,
                        activityTitle: "Activity Wave Optics (Interference)",
                        typeOfActivity: "audio",
                        activityCompletionProgress: 30
                    },
                    {
                        id: 7,
                        typeOfActivity: "text",
                        activityTitle: "Wave Optics (Interference) Magnetism 7",
                        activityCompletionProgress: 80
                    },
                ]
            },
            {
                id: 2,
                lessonTitle: "Gauss's Law",
                noOfActivity: 8,
                lessonComplProgress: 30,
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Capacitors and Dielectrics Activity",
                        typeOfActivity: "text",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        typeOfActivity: "video",
                        activityTitle: "Activity Magnetic Interactions",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        typeOfActivity: "audio",
                        activityCompletionProgress: 67,
                        activityTitle: "Activity Magnetism 2",
                    },
                    {
                        id: 4,
                        activityTitle: "Activity Circuits and Electronics",
                        typeOfActivity: "video",
                        activityCompletionProgress: 50
                    },
                    {
                        id: 5,
                        activityCompletionProgress: 87,
                        typeOfActivity: "text",
                        activityTitle: "Activity  E&M Waves",
                    },
                    {
                        id: 6,
                        typeOfActivity: "text",
                        activityCompletionProgress: 27,
                        activityTitle: "Activity Alternating Current Circuits",
                    },
                ]
            },
            {
                id: 3,
                expanded: false,
                noOfActivity: 7,
                lessonComplProgress: 30,
                lessonTitle: "Oscillations and Waves",
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityCompletionProgress: 100,
                        typeOfActivity: "video",
                        activityTitle: "Oscillations Activity part 1",
                    },
                    {
                        id: 2,
                        activityTitle: "Activity Oscillations 2",
                        typeOfActivity: "text",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        typeOfActivity: "audio",
                        activityCompletionProgress: 64,
                        activityTitle: "Activity Volume 1 Oscillations",
                    },
                    {
                        id: 4,
                        typeOfActivity: "text",
                        activityTitle: "Fields Activity 4 Magnetisms",
                        activityCompletionProgress: 50
                    },
                    {
                        id: 5,
                        activityTitle: "CompletionProgress Audios 5",
                        typeOfActivity: "audio",
                        activityCompletionProgress: 80
                    },
                ]
            },
            {
                id: 4,
                lessonTitle: "Electric Potential 4 check",
                noOfActivity: 6,
                lessonComplProgress: 200,
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityCompletionProgress: 100,
                        typeOfActivity: "audio",
                        activityTitle: "Potential lessons 4 Activity",
                    },
                    {
                        id: 2,
                        activityTitle: "Activity Electric Forces and Fields",
                        typeOfActivity: "text",
                        activityCompletionProgress: 105,
                    },
                    {
                        id: 3,
                        activityCompletionProgress: 63,
                        typeOfActivity: "video",
                        activityTitle: "Electric Forces Activity 32",
                    },
                    {
                        id: 4,
                        typeOfActivity: "text",
                        activityCompletionProgress: 56,
                        activityTitle: "Momentum and Collisions Activity",
                    },
                    {
                        id: 5,
                        typeOfActivity: "video",
                        activityCompletionProgress: 81,
                        activityTitle: "Activity Collisions text",
                    },
                    {
                        id: 6,
                        activityCompletionProgress: 21,
                        typeOfActivity: "text",
                        activityTitle: "Activity Motion in Two-Dimensions",
                    },
                ]
            },
            {
                id: 5,
                lessonComplProgress: 10,
                noOfActivity: 4,
                lessonTitle: "Laws of Thermodynamics",
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityCompletionProgress: 106,
                        typeOfActivity: "audio",
                        activityTitle: "Interactions and Force Activity",
                    },
                    {
                        id: 2,
                        typeOfActivity: "text",
                        activityCompletionProgress: 101,
                        activityTitle: "Activity Interactions 23",
                    },
                    {
                        id: 3,
                        typeOfActivity: "video",
                        activityTitle: " Gravity Activity 3 Progress",
                        activityCompletionProgress: 60
                    },
                    {
                        id: 4,
                        typeOfActivity: "video",
                        activityCompletionProgress: 40,
                        activityTitle: "Periodic Motion Activity 4 Progress",
                    },
                    {
                        id: 5,
                        typeOfActivity: "text",
                        activityTitle: "Activity Entropy",
                        activityCompletionProgress: 80
                    },
                ]
            },
        ]
    },
    {
        id: 6,
        topicTitle: "Wave Energy",
        topicAvgGrades: 50,
        noOfActivity: 4,
        topicCompProgress: 50,
        expanded: false,
        noOfLessons: 2,
        topicLessonsCompleteList: [
            {
                id: 1,
                lessonTitle: "Dielectrics part 1",
                noOfActivity: 8,
                expanded: false,
                lessonComplProgress: 100,
                lessonsActivityCompleteList: []
            },
            {
                id: 2,
                lessonTitle: "Equilibrium and Elasticity",
                noOfActivity: 4,
                lessonComplProgress: 30,
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        typeOfActivity: "audio",
                        activityTitle: "Equilibrium Part Activity Title",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        activityTitle: "Changing Magnetic and Electric Field",
                        typeOfActivity: "video",
                        activityCompletionProgress: 106,
                    },
                    {
                        id: 3,
                        typeOfActivity: "text",
                        activityTitle: "Activity 3 Equilibrium",
                        activityCompletionProgress: 60
                    },
                    {
                        id: 4,
                        typeOfActivity: "video",
                        activityTitle: "Activity Electric Work and Energy",
                        activityCompletionProgress: 50
                    },
                    {
                        id: 5,
                        activityTitle: "Activity Linear motion",
                        typeOfActivity: "text",
                        activityCompletionProgress: 80
                    },
                    {
                        id: 6,
                        typeOfActivity: "audio",
                        activityCompletionProgress: 22,
                        activityTitle: "Activity Momentum",
                    },
                ]
            },
            {
                id: 3,
                noOfActivity: 2,
                expanded: false,
                lessonComplProgress: 30,
                lessonTitle: "Energy check",
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        typeOfActivity: "text",
                        activityTitle: "Two-Dimensions Activity Title",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        typeOfActivity: "audio",
                        activityTitle: " Motion in Two-Dimensions Activity",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        typeOfActivity: "audio",
                        activityTitle: "Activity VS Part one",
                        activityCompletionProgress: 60
                    },
                    {
                        id: 4,
                        typeOfActivity: "video",
                        activityTitle: "Activity Controller part one",
                        activityCompletionProgress: 90
                    },
                    {
                        id: 5,
                        activityCompletionProgress: 81,
                        typeOfActivity: "text",
                        activityTitle: "Activity Subject List one",
                    },
                ]
            },
            {
                id: 4,
                lessonTitle: "Circular and Rotational Motio check",
                noOfActivity: 7,
                expanded: false,
                lessonComplProgress: 100,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        typeOfActivity: "video",
                        activityTitle: "Rotational Motio Activity Title",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        activityCompletionProgress: 101,
                        typeOfActivity: "audio",
                        activityTitle: "Activity Of Gravity Part 1",
                    },
                    {
                        id: 3,
                        activityCompletionProgress: 61,
                        typeOfActivity: "video",
                        activityTitle: "Activity Electric Interactions",
                    },
                    {
                        id: 4,
                        typeOfActivity: "video",
                        activityTitle: "Electric Interactions and Field",
                        activityCompletionProgress: 50
                    },
                    {
                        id: 5,
                        typeOfActivity: "video",
                        activityCompletionProgress: 84,
                        activityTitle: "Changing Magnetic and Electric Field",
                    },
                    {
                        id: 6,
                        typeOfActivity: "video",
                        activityTitle: "Biophysics Activity 6 Of",
                        activityCompletionProgress: 20
                    },
                ]
            },
            {
                lessonTitle: "Interactions and Forces",
                id: 5,
                noOfActivity: 0,
                expanded: false,
                lessonComplProgress: 75,
                lessonsActivityCompleteList: []
            }
        ]
    },
    {
        id: 7,
        expanded: false,
        noOfLessons: 6,
        topicCompProgress: 100,
        topicTitle: "Electromagnetism &  Electromagnetics",
        topicAvgGrades: 101,
        topicLessonsCompleteList: [
            {
                id: 1,
                expanded: false,
                noOfActivity: 0,
                lessonComplProgress: 100,
                lessonTitle: "Waves in Two and Three Dimensions",
                lessonsActivityCompleteList: []
            },
            {
                id: 2,
                noOfActivity: 6,
                lessonComplProgress: 30,
                lessonTitle: "Ray (Geometric) Optic 2 check",
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "(Geometric) Optic Title",
                        typeOfActivity: "text",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        typeOfActivity: "text",
                        activityTitle: "Volume part One Electromagnetism 2",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        typeOfActivity: "video",
                        activityCompletionProgress: 62,
                        activityTitle: "Activity Electromagnetism Equilibrium",
                    },
                    {
                        id: 4,
                        activityTitle: "Activity Equilibrium Part",
                        typeOfActivity: "text",
                        activityCompletionProgress: 52
                    },
                    {
                        id: 5,
                        typeOfActivity: "audio",
                        activityTitle: "Equilibrium and Elasticity Activity",
                        activityCompletionProgress: 82
                    },
                ]
            },
            {
                id: 3,
                lessonTitle: "Oscillations and Waves Types",
                expanded: false,
                noOfActivity: 7,
                lessonComplProgress: 30,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Oscillations Activity Types",
                        typeOfActivity: "audio",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        typeOfActivity: "video",
                        activityTitle: "Activity Optics expanded",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        activityCompletionProgress: 62,
                        typeOfActivity: "text",
                        activityTitle: "Activity Electric Forces and Fields",
                    },
                    {
                        id: 4,
                        typeOfActivity: "video",
                        activityTitle: "Activity Expanded Electric",
                        activityCompletionProgress: 52,
                    },
                    {
                        id: 5,
                        activityTitle: "Electric Potential Activity",
                        typeOfActivity: "text",
                        activityCompletionProgress: 80
                    },
                ]
            },
            {
                id: 4,
                noOfActivity: 3,
                expanded: false,
                lessonTitle: "Electromagnetism Electric Current and Circuits",
                lessonComplProgress: 100,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityCompletionProgress: 101,
                        typeOfActivity: "video",
                        activityTitle: "Volume Types 4 Activity check",
                    },
                    {
                        id: 2,
                        typeOfActivity: "video",
                        activityTitle: "Induction and E&M Waves Video check",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        typeOfActivity: "video",
                        activityCompletionProgress: 62,
                        activityTitle: "Activity Alternating Current Circuit",
                    },
                    {
                        id: 4,
                        activityCompletionProgress: 52,
                        typeOfActivity: "text",
                        activityTitle: "Activity Will Check",
                    },
                    {
                        id: 5,
                        activityCompletionProgress: 82,
                        typeOfActivity: "audio",
                        activityTitle: "Momentum and Collisions Audio 5 check",
                    },
                    {
                        id: 6,
                        activityCompletionProgress: 22,
                        typeOfActivity: "video",
                        activityTitle: "Activity Motion and Vectors",
                    },
                ]
            },
            {
                id: 5,
                lessonTitle: "Electric Circuits and Electr check",
                expanded: false,
                noOfActivity: 4,
                lessonComplProgress: 75,
                lessonsActivityCompleteList: []
            }
        ]
    },
    {
        id: 8,
        topicAvgGrades: 8,
        topicCompProgress: 60,
        noOfLessons: 8,
        expanded: false,
        topicTitle: "Astronomy (Blackh…",
        topicLessonsCompleteList: [
            {
                id: 1,
                noOfActivity: 8,
                expanded: false,
                lessonComplProgress: 100,
                lessonTitle: "Volumn Astronomy 1",
                lessonsActivityCompleteList: []
            },
            {
                id: 2,
                lessonTitle: "Collisions Astronomy 4 check",
                expanded: false,
                noOfActivity: 4,
                lessonComplProgress: 30,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityCompletionProgress: 101,
                        typeOfActivity: "video",
                        activityTitle: "Collisions Volume Part Activity",
                    },
                    {
                        id: 2,
                        typeOfActivity: "text",
                        activityCompletionProgress: 100,
                        activityTitle: "Wave Optics (Interference) Activity",
                    },
                    {
                        id: 3,
                        activityCompletionProgress: 1,
                        typeOfActivity: "audio",
                        activityTitle: "Interference Activity Astronomy",
                    },
                    {
                        id: 4,
                        typeOfActivity: "text",
                        activityTitle: "Activity Astronomy Types",
                        activityCompletionProgress: 50
                    },
                    {
                        id: 5,
                        typeOfActivity: "audio",
                        activityTitle: "MOtion Activity Audio Astronomy",
                        activityCompletionProgress: 80
                    },
                ]
            },
            {
                id: 3,
                noOfActivity: 8,
                lessonComplProgress: 31,
                lessonTitle: "Entropy Astronomy 3 check",
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        typeOfActivity: "video",
                        activityTitle: "Three Dimension Activity Title",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        activityTitle: "Activity Dimension Astronomy 3",
                        typeOfActivity: "text",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 3,
                        activityCompletionProgress: 61,
                        typeOfActivity: "text",
                        activityTitle: "Activity Gauss's Law Astronomy",
                    },
                    {
                        id: 4,
                        activityCompletionProgress: 52,
                        typeOfActivity: "text",
                        activityTitle: "Activity 4 Astronomy Volumn",
                    },
                    {
                        id: 5,
                        activityCompletionProgress: 82,
                        typeOfActivity: "audio",
                        activityTitle: "Types Of Audio 5 Astronomy",
                    },
                ]
            },
            {
                id: 4,
                lessonTitle: "Forces and Newton's Laws",
                noOfActivity: 3,
                lessonComplProgress: 100,
                expanded: false,
                lessonsActivityCompleteList: [
                    {
                        id: 1,
                        activityTitle: "Forces and Newton's Laws 4 Activity",
                        typeOfActivity: "audio",
                        activityCompletionProgress: 100
                    },
                    {
                        id: 2,
                        activityCompletionProgress: 101,
                        typeOfActivity: "video",
                        activityTitle: "Momentum and Collisions Video 2 work",
                    },
                    {
                        id: 3,
                        typeOfActivity: "video",
                        activityCompletionProgress: 63,
                        activityTitle: "Activity Circular and Rotational Motion",
                    },
                    {
                        id: 4,
                        activityCompletionProgress: 51,
                        typeOfActivity: "video",
                        activityTitle: "Activity 4 Done",
                    },
                    {
                        id: 5,
                        typeOfActivity: "text",
                        activityCompletionProgress: 81,
                        activityTitle: "Activity Physics Motion",
                    },
                    {
                        id: 6,
                        typeOfActivity: "video",
                        activityTitle: "Voulmn Part one 6 work",
                        activityCompletionProgress: 21
                    },
                ]
            },
            {
                id: 5,
                noOfActivity: 0,
                lessonTitle: "Periodic Motion 6 check",
                expanded: false,
                lessonComplProgress: 77,
                lessonsActivityCompleteList: []
            }
        ]
    },
];
const subjectTableColumns = [
    {
      id: "subject",
      columnId: 1,
      label: "Title",
      type: tableColumnTypes.SUBJECT_ICON_NAME,
      width: "300px",
      maxWidth: "300px",
    },
    {
      id: "progress",
      columnId: 2,
      label: "Progress",
      type: tableColumnTypes.GRADEBOOK_PROGRESS_BAR,
      width: "230px",
      maxWidth: "230px",
    },
 
    {
      id: "totalAvg",
      columnId: 7,
      label: "Total Avg%",
      type: tableColumnTypes.TEXT,
      width: "100px",
      maxWidth: "100px",

    },
    {
      id: "grade",
      columnId: 8,
      label: "Grade",
      type: tableColumnTypes.TEXT,
      width: "100px",
      maxWidth: "100px",

    },
  ];

export {
    instructionsList,
    currentLessonActivityList,
    coursesData,
    subjectTopicList,
    studentListTableColumns,
    subjectCoursesDetailsTableColumns,
    subjectTableColumns
};