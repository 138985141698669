// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  headingTitle: string;
  itemTitle: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openConfirmDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class DeleteConfirmDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      openConfirmDialog: false,
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
  }
  // Customizable Area End
}
// Customizable Area End
