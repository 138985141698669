// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedQuizAssessmentId: any;
  selectedQuizAssessmentDetails: any;
  currentPageQuestionsList: Array<any>;
  totalPageNumber: number;
  currentPage: number;
  category: string;
  assessmentTitle: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentCompletedQuizMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedQuizAssessmentId: null,
      selectedQuizAssessmentDetails: {
        data: [
          {
            id: 1,
            question: "What is the circumference for a 3D hexagon?",
            attachments: [],
            answers: [
              {
                id: 1,
                option: "105 m",
                correct_answer: false,
              },
              {
                id: 2,
                option: "490 m",
                correct_answer: false,
              },
              {
                id: 3,
                option: "110 m",
                correct_answer: true,
              },
              {
                id: 4,
                option: "914 m",
                correct_answer: false,
              },
            ],
            studentAnswerId: 3,
            explanation:
              "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
            pageNo: 1,
          },
          {
            id: 2,
            question: "What is the circumference for a 3D hexagon?",
            attachments: [
              {
                id: 1,
                src: "https://images.pexels.com/photos/1319584/pexels-photo-1319584.jpeg?auto=compress&cs=tinysrgb&w=400",
                fileName: "Attachment 1.jpg",
                size: "80.86kb",
              },
              {
                id: 2,
                src: "https://images.pexels.com/photos/6238039/pexels-photo-6238039.jpeg?auto=compress&cs=tinysrgb&w=400",
                fileName: "Attachment 2.jpg",
                size: "894.86kb",
              },
            ],
            answers: [
              {
                id: 1,
                option: "105 m",
                correct_answer: false,
              },
              {
                id: 2,
                option: "390 m",
                correct_answer: false,
              },
              {
                id: 3,
                option: "14 m",
                correct_answer: true,
              },
              {
                id: 4,
                option: "904 m",
                correct_answer: false,
              },
            ],
            studentAnswerId: 1,
            explanation:
              "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
            pageNo: 1,
          },
          {
            id: 3,
            question: "What is the surface area for a 3D hexagon?",
            attachments: [],
            answers: [
              {
                id: 1,
                option: "105 m",
                correct_answer: false,
              },
              {
                id: 2,
                option: "390 m",
                correct_answer: false,
              },
              {
                id: 3,
                option: "110 m",
                correct_answer: false,
              },
              {
                id: 4,
                option: "904 m",
                correct_answer: true,
              },
            ],
            studentAnswerId: 3,
            explanation:
              "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
            pageNo: 1,
          },
          {
            id: 4,
            question: "What is the surface area for a 3D hexagon?",
            attachments: [],
            answers: [
              {
                id: 1,
                option: "105 m",
                correct_answer: false,
              },
              {
                id: 2,
                option: "34 m",
                correct_answer: true,
              },
              {
                id: 3,
                option: "110 m",
                correct_answer: false,
              },
              {
                id: 4,
                option: "904 m",
                correct_answer: false,
              },
            ],
            studentAnswerId: 2,
            explanation:
              "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
            pageNo: 1,
          },
          {
            id: 5,
            question: "What is the surface area for a 3D hexagon?",
            attachments: [],
            answers: [
              {
                id: 1,
                option: "105 m",
                correct_answer: false,
              },
              {
                id: 2,
                option: "390 m",
                correct_answer: true,
              },
              {
                id: 3,
                option: "110 m",
                correct_answer: false,
              },
              {
                id: 4,
                option: "904 m",
                correct_answer: false,
              },
            ],
            studentAnswerId: 1,
            explanation:
              "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
            pageNo: 2,
          },
          {
            id: 6,
            question: "What is the surface area for a 3D hexagon?",
            attachments: [],
            answers: [
              {
                id: 1,
                option: "105 m",
                correct_answer: false,
              },
              {
                id: 2,
                option: "390 m",
                correct_answer: false,
              },
              {
                id: 3,
                option: "110 m",
                correct_answer: true,
              },
              {
                id: 4,
                option: "904 m",
                correct_answer: false,
              },
            ],
            studentAnswerId: 3,
            explanation:
              "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
            pageNo: 2,
          },
          {
            id: 7,
            question: "What is the surface area for a 3D hexagon?",
            attachments: [],
            answers: [
              {
                id: 1,
                option: "105 m",
                correct_answer: true,
              },
              {
                id: 2,
                option: "320 m",
                correct_answer: false,
              },
              {
                id: 3,
                option: "110 m",
                correct_answer: false,
              },
              {
                id: 4,
                option: "94 m",
                correct_answer: false,
              },
            ],
            studentAnswerId: 2,
            explanation:
              "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
            pageNo: 2,
          },
          {
            id: 8,
            question: "What is the surface area for a 3D hexagon?",
            attachments: [],
            answers: [
              {
                id: 1,
                option: "105 m",
                correct_answer: true,
              },
              {
                id: 2,
                option: "390 m",
                correct_answer: false,
              },
              {
                id: 3,
                option: "110 m",
                correct_answer: false,
              },
              {
                id: 4,
                option: "904 m",
                correct_answer: false,
              },
            ],
            studentAnswerId: 2,
            explanation:
              "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
            pageNo: 2,
          },
          {
            id: 9,
            question: "What is the surface area for a 3D hexagon?",
            attachments: [],
            answers: [
              {
                id: 1,
                option: "105 m",
                correct_answer: true,
              },
              {
                id: 2,
                option: "390 m",
                correct_answer: false,
              },
              {
                id: 3,
                option: "110 m",
                correct_answer: false,
              },
              {
                id: 4,
                option: "904 m",
                correct_answer: false,
              },
            ],
            studentAnswerId: 2,
            explanation:
              "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
            pageNo: 3,
          },
          {
            id: 10,
            question: "What is the surface area for a 3D hexagon?",
            attachments: [],
            answers: [
              {
                id: 1,
                option: "105 m",
                correct_answer: true,
              },
              {
                id: 2,
                option: "390 m",
                correct_answer: false,
              },
              {
                id: 3,
                option: "110 m",
                correct_answer: false,
              },
              {
                id: 4,
                option: "904 m",
                correct_answer: false,
              },
            ],
            studentAnswerId: null,
            explanation:
              "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
            pageNo: 3,
          },
        ],
        meta: {
          totalPages: 3,
        },
      },
      currentPageQuestionsList: [],
      totalPageNumber: 1,
      currentPage: 1,
      category: "",
      assessmentTitle: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleGetCompletedCurrentQuestionsList(1);
    // Customizable Area End
  }

  // Customizable Area Start

  handleGetCompletedCurrentQuestionsList = (pageNum?: number) => {
    const { selectedQuizAssessmentDetails } = this.state;
    const paramAssessmentCategory = this.props.navigation?.getParam("category");
    const paramAssessmentTitle = this.props.navigation?.getParam("title");
    const currentPageQuestionsList =
      selectedQuizAssessmentDetails?.data?.filter(
        (item: any) => item.pageNo === pageNum
      );
    const totalPageNumber = selectedQuizAssessmentDetails?.meta?.totalPages;
    this.setState({
      currentPageQuestionsList,
      totalPageNumber,
      category: paramAssessmentCategory,
      assessmentTitle: paramAssessmentTitle,
      currentPage: 1,
      loading: false,
      selectedQuizAssessmentDetails: "",
      selectedQuizAssessmentId: ""
    });
  };

  handleGoBackToGradeStatistics = () => {
    this.props.navigation?.goBack();
  };

  checkIfQuestionAttempted = (index: number) => {
    const { selectedQuizAssessmentDetails } = this.state;
    const questions = selectedQuizAssessmentDetails?.data;
    const isQuestionAttempted = !!questions[index].studentAnswerId;
    return isQuestionAttempted;
  };

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

  
      console.log("quiz completed apiRequestCallId");

      // Customizable Area Start

      // Customizable Area End
  }

  // Customizable Area End
}
// Customizable Area End
