// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Button, Tooltip } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ProjectActivityComponentController, {
  Props,
} from "./ProjectActivityComponentController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { infoIcon, redPenIcon, redTrashIcon } from "./assets";
import AddSubjectiveQuestionModal from "./AddSubjectiveQuestionModal.web";
import DeleteConfirmDialogWeb from "./DeleteConfirmDialog.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    questionCard: {
      border: "1px solid #f1f1f1",
      borderRadius: "6px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        width: "84%",
      },
      marginBottom: "25px",
    },
    activityDetailBox: {
      [theme.breakpoints.down("sm")]: {
        minWidth: "97%",
      },
      minWidth: "500px",
    },
    questionContainer: {
      marginTop: "20px",
      gap: "20px",
    },
    pagePadding: {
      padding: "15px",
    },
    examTitle: {
      display: "flex",
      gap: "7px",
      textTransform: "capitalize",
      alignItems: "center",
    },
    aboutText: {
      marginTop: "5px",
    },
    questionDeleteBtn: {
      minWidth: "32px",
      height: "32px",
      maxWidth: "32px",
      borderRadius: "6px",
      border: "1px solid #ED1111",
    },
    explanation: {
      borderBottom: "1px solid #f1f1f1",
      padding: "15px 20px",
      borderTop: "1px solid #f1f1f1",
    },
    bottomBorder: {
      borderBottom: "1px solid #f1f1f1",
    },
    questionEditBtn: {
      minWidth: "32px",
      height: "32px",
      maxWidth: "32px",
      borderRadius: "6px",
      border: "1px solid #0D46BC",
    },
  });

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: "#EEF8FF",
  },
  tooltip: {
    backgroundColor: '#EEF8FF',
    color: '#009DFF',
    minWidth: '200px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #EEF8FF',
    borderRadius: '10px',
    padding: "9 12 9 12",
    textTransform: 'capitalize',
  },
}))(Tooltip);

// Customizable Area End

// Customizable Area Start
export class ProjectActivityComponent extends ProjectActivityComponentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  getImagePro = (isMyCoursesPath:boolean , redpenFunPro:(dataId:number|string) => void , redTraFunPro:(dataId:number|string) => void , dataIdPro:number|string , testEditPro:string ,testDeletePro:string) => {
    return  !isMyCoursesPath && (
        <>
        <img src={redPenIcon}
         alt='redpen' 
        data-test-id={testEditPro} onClick={() => redpenFunPro(dataIdPro)}
        style={{border:"1px solid blue", borderRadius:"5px", padding:"7px" }}
         
      />                         
      <img src={redTrashIcon} 
       style={{border:"1px solid red", borderRadius:"5px",padding:"7px"}} 
       alt="" 
       data-test-id={testDeletePro}  onClick={() =>redTraFunPro(dataIdPro)}/>
     </>)
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      openSubjectiveQuestionModal,
      editQuestion,
      selectedSubjectiveQuestionDetail,
      openDeleteQuestionConfirmModal,
    } = this.state;

    const { classes, detail, questionList, isFromRegistrar } = this.props;

    return (
      <Box className={classes.activityDetailBox}>
        <Grid container className={`${classes.pagePadding}`} direction="column">
          <Grid xs={12} className={classes.titleGrid} item>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText20} ${classes.fontBold600} ${classes.examTitle}`}
              component={"span"}
            >
              {`${detail.activityTitle}`}
              <HtmlTooltip
                placement="right-start"
                title={
                  <React.Fragment>
                    <Typography className={classes.fontText12}> Assessment Type: <b>{detail?.gradingComponent}</b></Typography>
                    <Typography className={classes.fontText12}> Evaluation Type: <b>{detail?.evaluationType}</b></Typography>
                    {
                      detail?.duration &&
                      <Typography className={classes.fontText12}> Duration: <b>{detail?.duration} mins</b></Typography>
                    }
                    {
                      detail?.maxScore &&
                      <Typography className={classes.fontText12}> Max Score: <b>{detail?.maxScore}</b></Typography>
                    }
                    {
                      detail?.dueDate &&
                      <Typography className={classes.fontText12}> Due Date: <b>{detail?.dueDate}</b></Typography>
                    }
                  </React.Fragment>
                }
              >
                <img src={infoIcon} alt="info" />
              </HtmlTooltip>
            </Typography>
          </Grid>

          {detail?.instruction && (
            <Grid className={classes.detailGrid} item xs={12}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold500}`}
                component={"p"}
                dangerouslySetInnerHTML={{ __html: detail.instruction }}
              ></Typography>
            </Grid>
          )}

          <Box
            display={"flex"}
            className={`${classes.questionContainer}`}
            flexDirection={"column"}
          >
            {
              questionList && questionList.length > 0 ?
                <Box>

                  {questionList?.map((projectQuestionObj: any, index) => (
                    <Box
                      key={projectQuestionObj?.id}
                      className={`${classes.questionCard}`}
                      borderRadius={"6px"}
                      display={"flex"}
                      flexDirection={"column"}
                      width={"94%"}
                    >
                      <Box p={"20px"} className={classes.bottomBorder}>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          width={"100%"}
                        >
                          <Box>
                            <Typography className={`${classes.textCommonColor}`}>
                              Question {index + 1}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                            dangerouslySetInnerHTML={{
                              __html: projectQuestionObj?.question,
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        p={"20px"}
                      >
                        <Box display={"flex"}>
                          <Box component={"span"} marginRight={"5px"}>
                            <Typography className={`${classes.textCommonColor}`}>
                              Marks:
                            </Typography>
                          </Box>
                          <Box component={"span"}>
                            <Typography className={`${classes.textPrimaryColor}`}>
                              {projectQuestionObj?.marks}
                            </Typography>
                          </Box>
                          <Box
                            component={"span"}
                            marginLeft={"15px"}
                            marginRight={"5px"}
                          >
                            <Typography className={`${classes.textCommonColor}`}>
                              Word Limit:
                            </Typography>
                          </Box>
                          <Box component={"span"}>
                            <Typography className={`${classes.textPrimaryColor}`}>
                              {projectQuestionObj?.wordLimit}
                            </Typography>
                          </Box>
                        </Box>
                        {!isFromRegistrar && <Box display={"flex"} gridGap={"10px"}>
                        {this.getImagePro(this.state.isMyCoursesPath, (dataId) => this.handleEditSubjectiveQuestion(projectQuestionObj.id),  (dataId) =>this.handleDeleteProjectQuestion(projectQuestionObj.id) , projectQuestionObj.id , 'subjective-project-question-edit-73' ,'subjective-project-question-delete-73')}
                        </Box>}
                      </Box>
                    </Box>
                  ))}
                  {!isFromRegistrar &&                                 
                  <Box className={classes.footerButtonsBox}>
                    {!this.state.isMyCoursesPath && (                          
                  <CustomButton
                    btnText="Add Another Question"
                    btnStyle={addQuestionButton}
                    buttonId="project-add-another-question-button"
                    buttonVariant={"outlined"}
                    isDisabled={false}
                    type={"button"}
                    handleButtonClick={this.handleAddSubjectiveQuestion}
                  />)}
                  </Box>                  
                }
                </Box>
                :
                <CustomEmptyResultComponent
                  message="No question added for this Project"
                  showActionButton={!isFromRegistrar}
                  buttonText="Add Question"
                  handleButtonClick={this.handleAddSubjectiveQuestion}
                />
            }
          </Box>
        </Grid>

        {openSubjectiveQuestionModal && <AddSubjectiveQuestionModal
          classes={classes}
          open={openSubjectiveQuestionModal}
          isEdit={editQuestion}
          explanation={false}
          onCancel={this.handleCloseSubjectiveQuestionModal}
          onConfirm={this.handleCloseSubjectiveQuestionModal}
          handleAddSubjectiveQuestion={(data: any) =>
            this.handleProjectAddQuestion(data)
          }
          handleUpdateSubjectiveQuestion={(data: any) =>
            this.handleProjectUpdateQuestion(data)
          }
          activityId={detail?.activityId}
          selectedSubjectiveQuestionData={selectedSubjectiveQuestionDetail}
          handleDeleteSubjectiveQuestion={() =>
            this.handleDeleteProjectQuestion(
              selectedSubjectiveQuestionDetail?.id
            )
          }
          forManual={false}
        />}

        <DeleteConfirmDialogWeb
          headingTitle="Question"
          open={openDeleteQuestionConfirmModal}
          onCancel={() => this.handleCloseQuizDeleteConfirmModal()}
          itemTitle={"this question"}
          onConfirm={() => this.handleConfirmQuizDeleteQuestion()}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addQuestionButton = {
  width: "max-content",
  height: "50px",
  padding: "15px 20px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ProjectActivityComponent);
// Customizable Area End
