// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TeacherBrowseCourseCatModalController, {
  Props,
} from "./TeacherBrowseCourseCatModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { ScienceColor, MathsColor, HistoryColor, EnglishColor } from "./assets";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";

// Customizable Area End
const browseCoursesTableColumns = [
  {
    columnId: 1,
    id: "Title_Browse",
    type: tableColumnTypes.COURSES_NAME,
    label: "Title",
  },
  {
    columnId: 2,
    id: "Subject_Browse",
    type: tableColumnTypes.TEXT,
    label: "Subject",
  },
  {
    id: "Grade_Browse",
    columnId: 3,
    label: "Grade",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "Type_Browse",
    columnId: 4,
    label: "Type",
    type: tableColumnTypes.TEXT,
  },
  {
    columnId: 5,
    id: "CreatedOn_Browse",
    type: tableColumnTypes.TEXT,
    label: "Created On",
  },
  {
    columnId: 6,
    id: "LastModifiedOn_Browse",
    type: tableColumnTypes.TEXT,
    label: "Last Modified On",
  },
];
const coursesData = [
  {
    id: 1,
    Title_Browse: {
      name: "Velocity and Acceleration",
      icon: ScienceColor,
    },
    Subject_Browse: "Science",
    Grade_Browse: "Grade 3",
    Type_Browse: "Global",
    CreatedOn_Browse: "July 20, 2022",
    LastModifiedOn_Browse: "July 20, 2022",
  },
  {
    id: 2,
    Title_Browse: {
      name: "Profit and Loss",
      icon: MathsColor,
    },
    Subject_Browse: "Maths",
    Grade_Browse: "Grade 3",
    Type_Browse: "Global",
    CreatedOn_Browse: "July 20, 2022",
    LastModifiedOn_Browse: "July 20, 2022",
  },
  {
    id: 3,
    Title_Browse: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subject_Browse: "History",
    Grade_Browse: "Grade 3",
    Type_Browse: "Global",
    CreatedOn_Browse: "July 20, 2022",
    LastModifiedOn_Browse: "July 20, 2022",
  },
  {
    id: 4,
    Title_Browse: {
      name: "Victorian Literature",
      icon: EnglishColor,
    },
    Subject_Browse: "English",
    Grade_Browse: "Grade 3",
    Type_Browse: "Global",
    CreatedOn_Browse: "July 20, 2022",
    LastModifiedOn_Browse: "July 20, 2022",
  },
  {
    id: 5,
    Title_Browse: {
      name: "Creative Writing",
      icon: EnglishColor,
    },
    Subject_Browse: "English",
    Grade_Browse: "Grade 3",
    Type_Browse: "Global",
    CreatedOn_Browse: "July 20, 2022",
    LastModifiedOn_Browse: "July 20, 2022",
  },
  {
    id: 6,
    Title_Browse: {
      name: "English Poetry",
      icon: EnglishColor,
    },
    Subject_Browse: "English",
    Grade_Browse: "Grade 3",
    Type_Browse: "Global",
    CreatedOn_Browse: "July 20, 2022",
    LastModifiedOn_Browse: "July 20, 2022",
  },
  {
    id: 7,
    Title_Browse: {
      name: "Velocity and Accel…",
      icon: ScienceColor,
    },
    Subject_Browse: "Science",
    Grade_Browse: "Grade 3",
    Type_Browse: "Global",
    CreatedOn_Browse: "July 20, 2022",
    LastModifiedOn_Browse: "July 20, 2022",
  },
  {
    id: 8,
    Title_Browse: {
      name: "Profit and Loss",
      icon: MathsColor,
    },
    Subject_Browse: "Maths",
    Grade_Browse: "Grade 3",
    Type_Browse: "Global",
    CreatedOn_Browse: "July 20, 2022",
    LastModifiedOn_Browse: "July 20, 2022",
  },
  {
    id: 9,
    Title_Browse: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subject_Browse: "History",
    Grade_Browse: "Grade 3",
    Type_Browse: "Global",
    CreatedOn_Browse: "July 20, 2022",
    LastModifiedOn_Browse: "July 20, 2022",
  },
];
// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    browseCourseCatalogContainer: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
      "& .MuiDialog-paperWidthSm": {
        width: "970px",
        maxWidth: "100%",
        minHeight: "500px",
        overflowY: "hidden",
        overflowX: "hidden",
        [theme.breakpoints.down("sm")]: {
          width: "auto",
          maxWidth: "-webkit-fill-available",
        },
      },
    },
    BrowseDividerLine: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "6px",
      marginBottom: "0px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    browseCatalogCoursesRow: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    browseHighlightCourseRow: {
      background: "#FFD92E",
    },
    selectFromCatalogHeaderGrid: {
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
    },
  });

// Customizable Area End

// Customizable Area Start
export class TeacherBrowseCourseCatModal extends TeacherBrowseCourseCatModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    const { selectedRowId } = this.state;

    return (
      <Dialog
        className={`${classes.highlightBgColor} ${classes.browseCourseCatalogContainer}`}
        aria-labelledby="browse-cat-Modal-confirm-dialog-title"
        open={open}
        scroll={"body"}
        data-test-id="BrowseModal"
      >
        <Box className={`${classes.bgColor}`} data-test-id="BrowseModalBox">
          <Grid direction="row" container data-test-id="BrowseModalContainer">
            <Grid
              style={{ padding: "30px 30px 0px 30px" }}
              xs={12}
              className={`${classes.selectFromCatalogHeaderGrid}`}
              item
              data-test-id="BrowseModalPadding"
            >
              <Typography
                className={`${classes.fontBold600} ${classes.fontText24} ${classes.textPrimaryColor}`}
                component={"span"}
                data-test-id="BrowseModalCata"
              >
                Select Course from Catalog
              </Typography>
              <Button
                onClick={this.props.onCancel}
                style={{ float: "right" }}
                data-test-id="BrowseModalX"
                className={`${classes.textPrimaryColor}`}
              >
                <CloseIcon
                  className={`${classes.textCommonColor} ${classes.cursorPointer}`}
                  id="select-course-from-catalog-close-modal-icon"
                />
              </Button>
            </Grid>
          </Grid>
          <DialogContent
            style={{ padding: "0px", marginTop: "1px", overflow: "hidden" }}
            data-test-id="BrowseModalContent"
          >
            <Grid
              container
              spacing={2}
              direction="row"
              data-test-id="BrowseModalHidden"
            >
              <Grid xs={12} item>
                <Box
                  style={{ height: "1px" }}
                  className={classes.BrowseDividerLine}
                />
              </Grid>
              <Grid
                xs={12}
                item
                style={{ padding: "0px 30px 0px 30px" }}
                data-test-id="BrowseModalDDGrid"
              >
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  data-test-id="BrowseModalChild"
                >
                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    item
                    data-test-id="BrowseTitleGrid"
                  >
                    <Box>
                      <Typography
                        component={"span"}
                        className={`${classes.fontText16} ${classes.textCommonColor}`}
                        data-test-id="BrowseModalName"
                      >
                        Title
                      </Typography>
                      <Box marginBottom={"10px"} data-test-id="BrowseModalDD">
                        <CustomSelectDropdown
                          fieldId="courseCustomFieldDropdown"
                          handleChange={this.handleBrowseDropdownChange}
                          fieldName="selectedBrowseTitleDDValue"
                          fieldError={false}
                          fieldValue={this.state.selectedBrowseTitleDDValue}
                          renderValue={this.renderBrowseTitleDDValues}
                          fieldMenuItems={
                            this.state.titleBrowseTitleComponentValue
                          }
                          iconComponent={
                            <DropdownIcon
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke={"#888888"}
                              strokeWidth="1.5"
                              fill={"none"}
                            />
                          }
                          fieldStyles={dropdownStyles}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    md
                    lg
                    item
                    data-test-id="BrowseModalSubjectDD"
                  >
                    <Box>
                      <Typography
                        component={"span"}
                        className={`${classes.fontText16} ${classes.textCommonColor}`}
                        data-test-id="BrowseModalSub"
                      >
                        Subject
                      </Typography>
                      <Box
                        marginBottom={"10px"}
                        data-test-id="BrowseModalDDSub"
                      >
                        <CustomSelectDropdown
                          fieldId="browseSubjectieldDropdown"
                          handleChange={this.handleBrowseDropdownChange}
                          fieldName="selectedBrowseSubjectDDValue"
                          fieldError={false}
                          fieldValue={this.state.selectedBrowseSubjectDDValue}
                          renderValue={this.renderBrowseSubjectDDValues}
                          fieldMenuItems={
                            this.state.BrowseSubjectComponentValue
                          }
                          iconComponent={
                            <DropdownIcon
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke={"#888888"}
                              strokeWidth="1.5"
                              fill={"none"}
                            />
                          }
                          fieldStyles={dropdownStyles}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    md
                    lg
                    item
                    data-test-id="BrowseModalGradeDD"
                  >
                    <Box>
                      <Typography
                        component={"span"}
                        className={`${classes.fontText16} ${classes.textCommonColor}`}
                        data-test-id="BrowseModalDDFal"
                      >
                        Grade
                      </Typography>
                      <Box marginBottom={"10px"} data-test-id="BrowseModal_DD">
                        <CustomSelectDropdown
                          fieldId="browseGradeieldDropdown"
                          handleChange={this.handleBrowseDropdownChange}
                          fieldName="selectedBrowseGradeDDValue"
                          fieldError={false}
                          fieldValue={this.state.selectedBrowseGradeDDValue}
                          renderValue={this.renderBrowseGradeDDValues}
                          fieldMenuItems={this.state.BrowseGradeComponentValue}
                          iconComponent={
                            <DropdownIcon
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke={"#888888"}
                              strokeWidth="1.5"
                              fill={"none"}
                            />
                          }
                          fieldStyles={dropdownStyles}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    md
                    lg
                    item
                    data-test-id="BrowseModalBtns"
                  >
                    <Box mt={"-19px"}>
                      <CustomButton
                        btnText="Search"
                        btnStyle={{
                          ...browseCourseBtnStyle,
                          ...{ width: "100%" },
                        }}
                        buttonId={`catalog-search-button`}
                        buttonVariant={"contained"}
                        type={"button"}
                        handleButtonClick={this.handleSearch}
                        isDisabled={false}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} item>
                <Box className={classes.BrowseDividerLine} />
              </Grid>
              <Grid
                xs={12}
                item
                style={{ padding: "0px 30px 0px 30px" }}
                data-test-id="BrowseModalTable"
              >
                <Box
                  className={`${classes.customTableStyles} ${classes.browseCatalogCoursesRow}`}
                >
                  <TableContainer
                    className={classes.tableContainer}
                    id="catalog-courses-tables"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          {browseCoursesTableColumns.map((column: any) => (
                            <TableCell key={column.id}>
                              <Box display={"flex"}>
                                <Typography
                                  component={"span"}
                                  className={`${classes.textCommonColor} ${classes.fontSize15}`}
                                  data-test-id="columnData"
                                >
                                  {column.label}
                                </Typography>
                                {column?.icon ? (
                                  <img
                                    src={column?.icon}
                                    className={classes.columnIcon}
                                  />
                                ) : (
                                  ""
                                )}
                              </Box>
                            </TableCell>
                          ))}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {coursesData?.length > 0 &&
                          coursesData.map((row: any, i: any) => {
                            return (
                              <TableRow
                                onClick={() => this.handleSelectedRow(row.id)}
                                key={row.id}
                                tabIndex={-1}
                                className={
                                  selectedRowId === row.id
                                    ? classes.browseHighlightCourseRow
                                    : ""
                                }
                                id={`Row-${i}`}
                              >
                                {browseCoursesTableColumns.map(
                                  (columns: any) => {
                                    return (
                                      <TableCell
                                        key={`${columns.id}-${row.id}`}
                                        style={{
                                          minWidth: "auto",
                                          maxWidth: "auto",
                                        }}
                                        className={`${classes.tableBodyCell}`}
                                      >
                                        {columns.id === "Title_Browse" ? (
                                          <Box
                                            alignItems="center"
                                            display={"flex"}
                                          >
                                            <Box
                                              className={classes.subjectLogoBg}
                                              mr={"10px"}
                                            >
                                              <img
                                                src={row[columns.id]?.icon}
                                              />
                                            </Box>
                                            <Box>
                                              <Typography
                                                component="span"
                                                className={`${classes.textPrimaryColor} ${classes.fontText15}`}
                                              >
                                                {row[columns.id]?.name}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        ) : (
                                          <Typography
                                            component={"span"}
                                            className={`${classes.fontSize15} ${classes.textPrimaryColor}`}
                                          >
                                            {row[columns.id]}
                                          </Typography>
                                        )}
                                      </TableCell>
                                    );
                                  }
                                )}
                                <TableCell align="right">
                                  <IconButton
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    aria-label="more"
                                  >
                                    <VisibilityIcon
                                      className={`${classes.textCommonColor}`}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
              <Grid xs={12} item style={{ padding: "0px 30px 30px 30px" }}>
                <Box marginBottom={"10px"} style={{ width: "100%" }}>
                  <CustomButton
                    btnText="Copy"
                    btnStyle={browseCourseBtnStyle}
                    buttonId={`catalogCopyButton`}
                    buttonVariant={"contained"}
                    type={"button"}
                    handleButtonClick={this.props.onCancel}
                    isDisabled={false}
                  />
                  <CustomButton
                    btnText="Cancel"
                    btnStyle={browseCancelBtnStyle}
                    buttonId={`catalogCancelButton`}
                    buttonVariant={"outlined"}
                    type={"button"}
                    handleButtonClick={this.props.onCancel}
                    isDisabled={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const browseCancelBtnStyle = {
  width: "120px",
  height: "50px",
  padding: "10px 10px",
  fontSize: "18px",
  borderRadius: "12px",
  marginTop: "32px",
  textAlign: "center",
  float: "right",
  marginLeft: "15px",
  fontWeight: 500,
} as const;

const browseCourseBtnStyle = {
  height: "50px",
  width: "120px",
  padding: "10px 10px",
  fontSize: "18px",
  textAlign: "center",
  borderRadius: "12px",
  marginTop: "32px",
  float: "right",
  marginLeft: "15px",
  fontWeight: 500,
} as const;

const dropdownStyles = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherBrowseCourseCatModal);
// Customizable Area End
