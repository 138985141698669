// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import AddNewResourceModalController, {
  Props,
} from "./AddNewResourceModalController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomColorPickerFieldWeb from "../../../components/src/CustomColorPickerField.web";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";
import CustomTextField from "../../../components/src/CustomTextField.web";

// Customizable Area End
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    colorInput: {
      marginRight: "15px",
      "& .MuiInputBase-input": {
        width: "40px",
        height: "40px",
        padding: "0px",
      },
      "&::before": {
        borderBottom: "0px",
      },
      "&::after": {
        borderBottom: "0px",
      },
      "&:hover:not(.Mui-)::before": {
        borderBottom: "0px",
      },
      "&.MuiInputBase-root.Mui-": {
        background: "#F3F3F3",
      },
    },
    inputColorCircle: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      overflow: "hidden",
      marginRight: "15px",
    },
    addNewTopicModal: {
      "& .MuiDialog-paperWidthSm": {
        width: "880px",
        maxWidth: "100%",
        minHeight: "500px",
        overflowX: "hidden",
        overflowY: "hidden",
      },
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    customTextarea: {
      borderRadius: "12px",
      width: "96%",
      padding: "17px 15px",
      fontWeight: 400,
      fontSize: "14px",
      marginTop: "10px",
      border: "1px solid #888",
      "&::after": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px",
      },
      "&:hover:not(.Mui-)::before": {
        borderRadius: "12px",
        borderBottom: "1px solid #888",
      },
      "& .MuiInput-underline::before": {
        content: "none",
        borderBottom: "0px",
      },
      "&.MuiInputBase-root.Mui-": {
        background: "#F3F3F3",
      },
      "& .MuiInput-underline::after": {
        borderBottom: "0px",
      },
    },
    customInput: {
      width: "100%",
      height: "50px",
      padding: "17px 15px",
      borderRadius: "12px",
      fontSize: "14px",
      fontWeight: 400,
      marginTop: "10px",
      border: "1px solid #888",
      "&::after": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px",
      },
      "&.MuiInputBase-root.Mui-": {
        background: "#F3F3F3",
      },
      "&:hover:not(.Mui-)::before": {
        borderBottom: "1px solid #888",
        borderRadius: "12px",
      },
    },
    circularContainer: {
      left: "50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      top: "40%",
    },
    customLoader: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgb(0, 0, 0, .4)",
      bottom: -30,
      display: "block",
      zIndex: 100,
    },
    colorPicker: {
      top: "50%",
      right: "5%",
    }
  });

// Customizable Area End

// Customizable Area Start
export class AddNewResourceModal extends AddNewResourceModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, isEdit } = this.props;
    const {
      selectedColorValue,
      selectedColorName,
      topicTitleValue,
      subjectValue,
      lessonsPerDayValue,
      iconValue,
      gradeValue,
      shortCodeValue,
      schoolYearValue,
      decriptionValue,
      topicTitleError,
      topicTitleErrorMsg,
      loading,
    } = this.state;
    return (
      <Box>
        <Dialog
          className={`${classes.highlightBgColor} ${classes.addNewTopicModal}`}
          aria-labelledby="quiz-view-answer-confirm-dialog-title"
          open={open}
          scroll={"body"}
        >
          {loading && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <Box padding={"30px"} className={`${classes.bgColor}`}>
            <Grid direction="row" container>
              <Grid xs={12} item>
                <Typography
                  className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                >
                  {isEdit ? 'Edit Resource' : 'Add New Resource'}
                </Typography>
                <Button
                  onClick={this.props.onCancel}
                  className={classes.textPrimaryColor}
                  style={{ float: "right" }}
                >
                  {" "}
                  <CloseIcon />{" "}
                </Button>
              </Grid>
            </Grid>
            <DialogContent
              style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}
            >
              <Grid container spacing={2} direction="row">
                <Grid xs={6} item>
                  <Box marginBottom={"10px"} width={"100%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      Topic Title
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Topic Title"
                      fieldType="text"
                      fieldId="topic-title-input"
                      fieldName="topicTitleValue"
                      fieldValue={topicTitleValue}
                      textFieldStyles={customInputStyle}
                      handleChange={this.handleInputChange}
                      fieldWidth={"100%"}
                      fieldError={topicTitleError}
                      helperText={topicTitleErrorMsg}
                    />
                  </Box>
                </Grid>
                <Grid xs={6} item>
                  <Box marginBottom={"10px"} width={"99%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      Subject
                    </Typography>
                    <CustomTextField
                      fieldType="text"
                      placeHolderText="Enter Subject"
                      fieldId="subject-input"
                      fieldName="subjectValue"
                      textFieldStyles={customInputStyle}
                      fieldWidth={"100%"}
                      fieldValue={subjectValue}
                      isDisabled={true}
                      handleChange={this.handleInputChange}
                    />
                  </Box>
                </Grid>
                <Grid xs={4} item>
                  <Box marginBottom={"10px"} width={"99%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      No. of Lessons a day
                    </Typography>
                    <CustomTextField
                      fieldType="text"
                      placeHolderText="Enter No. of Lessons a day"
                      fieldId="lessons-a-day-input"
                      fieldName="lessonsPerDayValue"
                      textFieldStyles={customInputStyle}
                      fieldWidth={"100%"}
                      fieldValue={lessonsPerDayValue}
                      isDisabled={true}
                      handleChange={this.handleInputChange}
                    />
                  </Box>
                </Grid>
                <Grid xs={4} item>
                  <Box marginBottom={"10px"} width={"99%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      Color
                    </Typography>
                    <Box>
                      <CustomColorPickerFieldWeb
                        fieldId="add-course-color-picker"
                        fieldName="selectedColor"
                        handleColorChange={this.handleColorChange}
                        fieldWidth="100%"
                        fieldValue={selectedColorName}
                        fieldValueHexCode={selectedColorValue}
                        isDisabled={true}
                        textFieldStyles={colorPickerStyles}
                        placeHolderText={"Select Color"}
                        classes={classes}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={4} item>
                  <Box marginBottom={"10px"} width={"99%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      Icon
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Icon"
                      fieldType="text"
                      fieldName="iconValue"
                      fieldId="icon-input"
                      textFieldStyles={customInputStyle}
                      fieldValue={iconValue}
                      fieldWidth={"100%"}
                      handleChange={this.handleInputChange}
                      isDisabled={true}
                    />
                  </Box>
                </Grid>
                <Grid xs={4} item>
                  <Box marginBottom={"10px"} width={"99%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      Grade
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Grade"
                      fieldName="gradeValue"
                      fieldType="text"
                      textFieldStyles={customInputStyle}
                      fieldId="grade-input"
                      fieldWidth={"100%"}
                      fieldValue={gradeValue}
                      handleChange={this.handleInputChange}
                      isDisabled={true}
                    />
                  </Box>
                </Grid>
                <Grid xs={4} item>
                  <Box marginBottom={"10px"} width={"99%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      Short Code
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Short Code"
                      fieldName="shortCodeValue"
                      fieldType="text"
                      textFieldStyles={customInputStyle}
                      fieldId="short-code-input"
                      fieldWidth={"100%"}
                      handleChange={this.handleInputChange}
                      fieldValue={shortCodeValue}
                      isDisabled={true}
                    />
                  </Box>
                </Grid>
                <Grid xs={4} item>
                  <Box marginBottom={"10px"} width={"99%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      School Year
                    </Typography>
                    <CustomTextField
                      fieldName="schoolYearValue"
                      placeHolderText="Enter School Year"
                      textFieldStyles={customInputStyle}
                      fieldType="text"
                      fieldId="short-code-input"
                      handleChange={this.handleInputChange}
                      fieldWidth={"100%"}
                      isDisabled={true}
                      fieldValue={schoolYearValue}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} item>
                  <Box marginBottom={"10px"} width={"100%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      Description
                    </Typography>

                    <TextField
                      className={`${classes.customTextarea} ${classes.secondaryBgColor}`}
                      placeholder="Enter Description"
                      value={decriptionValue}
                      name="decriptionValue"
                      onChange={this.handleInputChange}
                      multiline
                      minRows={4}
                      maxRows={4}
                      disabled={true}
                      inputProps={{
                        variant: "standard",
                        className: `${classes.textCommonColor}`,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} item>
                  <Box style={{ width: "100%" }} marginBottom={"10px"}>
                    <CustomButton
                      btnText={isEdit ? "Save" : "Add"}
                      buttonId={`resource-submit-button`}
                      btnStyle={submitButton}
                      buttonVariant={"contained"}
                      handleButtonClick={this.handleFormSubmit}
                      type={"button"}
                      isDisabled={false}
                    />
                    <CustomButton
                      btnText="Cancel"
                      buttonId={`resource-cancel-button`}
                      btnStyle={cancelButtonStyle}
                      buttonVariant={"outlined"}
                      handleButtonClick={this.props.onCancel}
                      type={"button"}
                      isDisabled={false}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    );

    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const customInputStyle = {
  padding: "10px 0",
  width: "100%",
  maxWidth: "100%",
  marginTop: "10px"
} as const;

const submitButton = {
  width: "120px",
  height: "50px",
  padding: "10px 10px",
  textAlign: "center",
  fontSize: "18px",
  borderRadius: "12px",
  marginTop: "32px",
  marginLeft: "15px",
  fontWeight: 500,
  float: "right",
} as const;

const cancelButtonStyle = {
  width: "120px",
  height: "50px",
  padding: "10px 10px",
  border: "1px solid",
  borderRadius: "12px",
  textAlign: "center",
  fontSize: "18px",
  fontWeight: 500,
  marginLeft: "15px",
  float: "right",
  marginTop: "32px",
} as const;
const colorPickerStyles = {
  marginTop: "10px"
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddNewResourceModal);
// Customizable Area End
