// Customizable Area Start
import React from "react";
import { Box, Switch, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme, ThemeProvider, createTheme } from "@material-ui/core/styles";
import GradeBookCommonTableController, { Props } from "./GradeBookCommonTableController"
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomTable from "../../../components/src/CustomTable.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
import { addBoxIcon } from "./assets";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    searchBox: {
      padding: "32px 24px",
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: 14,
      marginBottom: "6px",
      marginTop: "10px",
      color: "#888888",
    },
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    paginationBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px 10px 21px",
    },
    searchBtnGrid: {
      display: "flex",
      alignItems: "center",
      marginTop: "20px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px"
      }
    },
  
      fieldValue: {
        color: "#090909",
        fontSize: "16px",
        fontWeight: 600
      },
      fieldBox: {
        margin: theme.spacing(2.5, 0),
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        paddingBottom:"30px"
      },
      parentHeader: {
        color: "#090909",
        fontSize: "20px",
        fontWeight: 600,
        padding: "25px"
      },
      studentHeader: {
        color: "#090909",
        fontSize: "22px",
        fontWeight: 600
      },
      profileHeading: {
        color: "#090909",
        fontSize: "34px",
        fontWeight: 700
      },
      headerWrappper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "15px 0 30px 0"
      },
      headLineWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer"
      },
      backIcon: {
        cursor: "pointer",
        fontSize: "34px"
      },
      fieldWrapper: {
        display: "flex",
        gap: "15px",
        alignItems: "center",
        justifyContent:"space-between",
        padding: "16px",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
      },
      boxWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0 25px"
      },
      parentHeaderWrapper: {
        backgroundColor: "#F1F1F1",
        height: "1px"
      },
      parentBodyWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0 25px"
      },
      contentCenter: {
        display: "flex",
        alignItems: "center"
      },
      imageMargin: {
        margin: "0 8px"
      },
      rootBox: {
        padding: "40px"
      },
      flexBox: {
        display: "flex",
        flexDirection: "column"
      },
      highlightedText: {
        fontWeight: 'bold',
        color: '#009DFF', // or any other style you want for highlighting
      },
      defaultText:{
        fontWeight: 'bold',
        color: 'grey', 
      }
  });
  const schoolYearTableTheme = createTheme({
    overrides: {
      MuiTableCell: {
        body: {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          paddingRight: "0px !important",
          paddingLeft: "0px !important",
        },
        head: {
          padding: "16px 0px",
        },
      },
    },
  });

// Customizable Area End


// Customizable Area Start
export class GradeBookCommonTable extends GradeBookCommonTableController {
  // Customizable Area Start
  AntSwitch = withStyles((theme) => ({
    root: {
        width: 46,
        height: 26,
        borderRadius: "18.5px",
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        '&$checked': {
          transform: 'translateX(20px)',
          color: theme.palette.common.white,
          '& + $track': {
            backgroundColor: "#009DFF",
            borderColor: "#009DFF",
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          color: '#52d869',
          border: '6px solid #fff',
        },
    },
    
    thumb: {
        width: 22,
        height: 22,
        boxShadow: 'none',
        background: "#FFF",
    },
    track: {
        border: `1px solid #888`,
        borderRadius: "18.5px",
        opacity: 1,
        backgroundColor: "#888",
    },
   
    checked: {},
}))(Switch);
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
   const {checked} = this.state
    const { classes ,title,tableColumnHead,tableBodyData,addButtonClick,emptyTableText, showMenuIcon,
        actionButtonConfig,switchChecked,addButton,id} = this.props;
    return (
        <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
            <Box >
        <Box className={classes.fieldWrapper}>
         
          <Box>
            <Typography className={`${classes.studentHeader} ${classes.textPrimaryColor}`}>
              {title}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',gap:20 }}>
        {
            switchChecked &&
        
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',gap:10 }}>

        
        <Typography className={classes.defaultText}>
            Default
          </Typography>
          <this.AntSwitch
              checked={checked}
              onChange={this.handleSwitchChange}
              name="showCorrectAnswers"
            />
           
           <Typography className={checked ? classes.highlightedText : classes.defaultText}>
        Customized
      </Typography>
      </Box>
  }
{addButton &&
            <img style={{ cursor: "pointer"}} src={addBoxIcon} data-testid={`${id}-add-button`} alt="addicon" onClick={addButtonClick}/>
}
          </Box>
          
        </Box>
        {tableBodyData.length > 0 ?

               <Box className={`${classes.bgColor}`} padding={"0px 20px"}>
                <ThemeProvider theme={schoolYearTableTheme}>
                          <CustomTable
                            tableHeaderColumns={tableColumnHead}
                            tableBodyData={tableBodyData}
                            tableId={`${title}`}
                            showMenuIcon={showMenuIcon}
                            actionButtonConfig={actionButtonConfig}
                            showEditMenuIcon={false}
                            // isHandleCellAction={true}
                            // handleAction={this.openAssessmentDetailPage}
                          />
                      </ThemeProvider>
                      </Box>
                        :
                        <Box style={{padding:"20px"}} >
                          <CustomEmptyResultComponent message={emptyTableText} />
                        </Box>
                      }
        </Box>
      </Box>
      
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(GradeBookCommonTable);
// Customizable Area End
