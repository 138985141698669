// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import StudentCourseActivityJourneyDetailsController, {
  Props,
} from "./StudentCourseActivityJourneyDetailsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import StudentCourseActivityJourneyBox from "./StudentCourseActivityJourneyBox.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { plus } from "./assets";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    activityDetailBox: {
      borderRadius: "6px",
      maxWidth: "100%",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "95%",
      },
    },
    activityColumnHeader: {
      backgroundColor: "rgba(0, 157, 255, 0.05)",
      padding: "5% 12%",
    },
    activityDetailListColumn: {
      overflow: "auto",
      borderRight: "2px solid #f1f1f1"
    },
    activityDeatilHeader: {
      padding: "2% 3%",
    },
    activityContainers: {
      borderTop: "2px solid #f1f1f1",
      overflowY: "auto",
    },
    activityDetailsGrid: {
      overflow: "auto",
    },
    cursorPointer: {
      cursor: "pointer",
    }
  });

// Customizable Area End

// Customizable Area Start
export class StudentCourseActivityJourneyDetails extends StudentCourseActivityJourneyDetailsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, selectedLesson, selectedTopic, isFromTenant, activityList, isStudent, isFromRegistrar } = this.props;
    const {
      selectedActivityTaskDetailId,
      selectedActivityTaskDetail,
    } = this.state;
    return (
      <Box
        className={`${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
        data-test-id="activity_details"
      >
        <Grid container direction="row" data-test-id="activity_details_grid">
          <Grid item xs={12}></Grid>
          {/* Topic Lessons Activity Box */}
          <Grid item xs={12} data-test-id="activity_details_grid12">
            <Box className={`${classes.bgColor} ${classes.activityDetailBox}`}>
              <Box
                onClick={this.handleGoBacks}
                className={`${classes.cursorPointer} ${classes.activityDeatilHeader}`}
                display={"flex"}
                alignItems={"center"}
              >
                <Box mr={"8px"} data-test-id="activity_details_Back">
                  <KeyboardBackspaceIcon
                    fontSize="small"
                    className={`${classes.textPrimaryColor}`}
                    data-test-id="activity_details_Backs"
                  />
                </Box>
                <Box>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                    component={"span"}
                    data-test-id="activity_details_Activity"
                  >
                    {selectedActivityTaskDetail ? `${selectedActivityTaskDetail.activityTitle}, ` : `Activity Title, `}
                  </Typography>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold500}`}
                    component={"span"}
                    data-test-id="activity_details_Graphics"
                  >
                    {selectedLesson ? `${selectedLesson}, ` : `Graphics, `}
                  </Typography>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold500}`}
                    component={"span"}
                    data-test-id="activity_details_Kinematics"
                  >
                    {selectedTopic ? `${selectedTopic}` : `Kinematics`}
                  </Typography>
                </Box>
              </Box>
                <Grid
                  className={classes.activityContainers}
                  container
                  direction="row"
                  data-test-id="activity_details_Hidden"
                >
                  {/* Column Header */}
                  <Grid
                    className={classes.activityDetailListColumn}
                    item
                    xs={5}
                    sm={3}
                    data-test-id="activity_details_Kinematics_Grid"
                  >
                    <Box
                      className={classes.activityColumnHeader}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      borderBottom={"2px solid #f1f1f1"}
                    >
                      <Typography
                        className={`${classes.primaryColor14} ${classes.fontText18} ${classes.fontBold600}`}
                        component={"span"}
                        data-test-id="activity_details_Kinematics_Ixon"
                      >
                        Activity
                      </Typography>
                      {!(isStudent || isFromRegistrar) && <img
                        src={plus}
                        alt=""
                        className={`${classes.leftSIdeMargin28} ${classes.cursorPointer}`}
                        onClick={this.handleAddNewActivityButtonClick}
                      />}
                    </Box>
                    <DragDropContext onDragEnd={this.handleDragEnd}>
                      <Droppable
                        droppableId="activityList"
                        type="SELECTED_LIST"
                      >
                        {(provided) => (
                          (
                            <Grid
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {activityList?.map(
                                (activity: any, index: any) => (
                                  <Draggable
                                    key={activity.id.toString()}
                                    draggableId={activity.id.toString()}
                                    index={index}
                                    data-test-id="saasa"
                                  >
                                    {(providedDraggable) => (
                                     ( <div
                                        ref={providedDraggable.innerRef}
                                        {...providedDraggable.draggableProps}
                                        {...providedDraggable.dragHandleProps}
                                      >
                                        <StudentCourseActivityJourneyBox
                                          isFromAccordions={false}
                                          key={activity.id}
                                          detail={{
                                            completionProgress:
                                              activity?.activityCompletionProgress,
                                            id: activity.id,
                                            title: activity.activityTitle || "",
                                          }}
                                          handleActivitySelectItem={
                                            this.handleSelectActivityTasks
                                          }
                                          selectedItemId={
                                            selectedActivityTaskDetailId
                                          }
                                          typesOfActivity={
                                            activity.typeOfActivity
                                          }
                                          gradeComponent={activity?.gradingComponent}
                                          index={index}
                                          isFromTenant={isFromTenant}
                                          isStudent={isStudent}
                                          menuType={"Activity"}
                                          getMenuItem={(menu: any) => { this.handleMenuAction(menu) }}
                                        />
                                      </div>)
                                    )}
                                  </Draggable>
                                )
                              )}
                              {provided.placeholder}
                            </Grid>
                          )
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Grid>

                  {/* Activity Detail */}
                  <Grid
                    className={classes.activityDetailsGrid}
                    item
                    xs={7}
                    sm={9}
                  >
                    {selectedActivityTaskDetail && this.handleActivityDetailsRenders(selectedActivityTaskDetail, false)}
                  </Grid>
                </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentCourseActivityJourneyDetails);
// Customizable Area End
