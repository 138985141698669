// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js")

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedAssessmentId:any;
  completedAssignmentQuestionAnswerList:any;
  assignmentDetails:any;
  typeOfAssessment:string;
  assessmentTitle:string;
  categoryOfAssessment:string;
  fromStatistics: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentCompletedAssessmentMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loading: false,
      selectedAssessmentId:null,
      completedAssignmentQuestionAnswerList:[
        {
          id:1,
          question:"What is a surface area for a polygon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:2,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[
            {
            id:1,
            src:"https://images.pexels.com/photos/1319584/pexels-photo-1319584.jpeg?auto=compress&cs=tinysrgb&w=400",
            fileName:"Attachment 1.jpg",
            size:"890.86kb"
            },
            {
            id:2,
            src:"https://images.pexels.com/photos/6238039/pexels-photo-6238039.jpeg?auto=compress&cs=tinysrgb&w=400",
            fileName:"Attachment 2.jpg",
            size:"890.86kb"
            }
          ],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:3,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:4,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:5,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:6,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:7,
          question:"What is surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:8,
          question:"What is the area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:9,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:10,
          question:"What is the circumference for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        }
      ],
      assignmentDetails:{
        lessonName:"Contemporary Fiction",
        courseName:"Creative Writing",
        obtainedScore: 42,
        maxScore:50,
        noOfAttempts:1,
        maxNoOfAttempts:3,
        submittedDate:"Jun 22, 2023",
      },
      typeOfAssessment:"",
      assessmentTitle:"",
      categoryOfAssessment:"",
      fromStatistics: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
   await super.componentDidMount();
    // Customizable Area Start
    this.handleGetCurrentQuestionsList()
    // Customizable Area End
  }

  // Customizable Area Start

  handleGetCurrentQuestionsList = () => {
    const paramAssessmentType = this.props.navigation?.getParam("typeOfAssessment");
    const paramAssessmentTitle = this.props.navigation?.getParam("title");
    const paramAssessmentCategory = this.props.navigation?.getParam("assessmentCategory");
    const paramFromStatistics = this.props.navigation?.getParam("fromStatistics");
    this.setState({ typeOfAssessment : paramAssessmentType , assessmentTitle : paramAssessmentTitle , categoryOfAssessment : paramAssessmentCategory || "submitted" , fromStatistics: paramFromStatistics, assignmentDetails : { ...this.state.assignmentDetails , assessmentType : paramAssessmentType} });
  }

  handleOpenAttachmentFile = (url : string) => {
    window.open(url , "_blank")
  }

  handleGoBack = () => {
    this.props.navigation?.goBack()
  }

  handleReAttempt = () => {
    const { assessmentTitle , typeOfAssessment } = this.state;
    this.props.navigation?.navigate("StudentDueAssessmentMain" , {} , {
      typeOfAssessment : typeOfAssessment,
      title : assessmentTitle
    });
  }

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

   
  }

  // Customizable Area End
}
// Customizable Area End