// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { displayApiErrorMsg, getMessageData } from "../../../components/src/CommonHelper.web";
import { Message } from "../../../framework/src/Message";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
const ntc = require("ntcjs");
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  courseData: any;
  isEdit?: boolean;
  resourceId?: number;
  handleAddNewResource: any;
  handleUpdateNewResource: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedColorValue: string;
  selectedColorName: string;
  resourceTableData: Array<any>;
  topicTitleValue: string;
  subjectValue: string;
  lessonsPerDayValue: string;
  iconValue: string;
  gradeValue: string;
  shortCodeValue: string;
  schoolYearValue: string;
  decriptionValue: string;
  topicTitleError: boolean;
  topicTitleErrorMsg: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddNewResourceModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetResourceDataById: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      selectedColorValue: "",
      selectedColorName: "",
      resourceTableData: [],
      topicTitleValue: "",
      subjectValue: "",
      lessonsPerDayValue: "",
      iconValue: "",
      gradeValue: "",
      shortCodeValue: "",
      schoolYearValue: "",
      decriptionValue: "",
      topicTitleError: false,
      topicTitleErrorMsg: "",
      loading: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    if (this.props.courseData) {
      this.handleFormDataPrefill(this.props.courseData);
    }
    if (this.props.isEdit) {
      this.handleResourceDataByIdApi();
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null && apiRequestCallId === this.apiGetResourceDataById) {
        this.handleGetResourceDataByIdResponse(responseJson, errorResponse);
      }
    }
  }

  handleColorChange = (color: string) => {
    const n_match = ntc.name(color);
    this.setState({
      selectedColorValue: color,
      selectedColorName: n_match[1],
    });
  };

  handleResourceDataByIdApi = async () => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetResourceDataById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewResource}/${this.props.resourceId}?course_id=${this.props.courseData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetResourceDataByIdResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.setState({ topicTitleValue: responseJson?.data?.attributes?.topic_title });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  handleInputChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    if (name === "topicTitleValue" && value.length > 0) {
      this.setState({ topicTitleError: false, topicTitleErrorMsg: "" });
    }
  };

  handleFormDataPrefill = (data: any) => {
    const course = data.attributes;
    this.setState({
      subjectValue: course?.subject?.name,
      lessonsPerDayValue: course?.no_of_lession_a_day,
      iconValue: course?.subject?.filename ? course?.subject?.filename : 'No Icon Available',
      gradeValue: course?.grade?.name,
      shortCodeValue: "0101",
      schoolYearValue: course?.school_year.title,
      decriptionValue: course?.description,
      selectedColorValue: course?.subject?.color,
    });
    if (course?.subject?.color) {
      this.handleColorChange(course?.subject?.color);
    }
  }

  handleFormValidation = () => {
    let isValid = true;
    const { topicTitleValue } = this.state;
    if (!topicTitleValue.trim()) {
      this.setState({ topicTitleError: true, topicTitleErrorMsg: "Please enter topic title" });
      isValid = false;
    }
    return isValid;
  }

  handleFormSubmit = () => {
    if (this.handleFormValidation()) {
      const { topicTitleValue } = this.state;
      const { isEdit, courseData } = this.props;

      const requestBody = {
        course_id: courseData.id,
        topic_title: topicTitleValue,
        status: 0,
      }

      if (isEdit) {
        this.props.handleUpdateNewResource(requestBody);
      } else {
        this.props.handleAddNewResource(requestBody);
      }
    }
  }

  showModalLoader = () => {
    this.setState({ loading: true });
  }

  hideModalLoader = () => {
    this.setState({ loading: false });
  }
  // Customizable Area End
}
// Customizable Area End