// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

import React from "react";
import  AttandanceTable  from "./tables/AttandanceTable.web";

import  BehaviourTable  from "./tables/BehaviourTable.web";
import  GradeTable  from "./tables/GradeTable.web";
import { selectWord } from "@uiw/react-md-editor";
import  StudentDetails  from "./tables/StudentDetails.web";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}

export interface IDDConfigProps {
  id: any;
  name: string;
  fieldName: string;
  value: any;
  menuItems?: any;
  label: string;
  fieldType: string;
  customField?: boolean;
  customLabel?: string;
  customLabel2?: string;
  isDisabled?: boolean;
  placeHolderText: string;
  
}

export interface IStepperStepProps {
  id: any;
  label: string;
  optional?: boolean;
}

export interface IParentInputConfigProps {
  find?: any;
  id: any;
  label: string;
  role: string;
  ddConfigs: Array<IDDConfigProps>;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  templateType:string;
  gradeBookId:any;
  handlCreateTemplateApi: (requestBody: any) => Promise<boolean>;
  handleUpdateTemplateApi: (requestBody: any, gradeBookTemplateId: number) => Promise<boolean>
  gradebookTemplateType:string;
  getDataOfTemplate:any
  orientationType:string
  getTemplateId:number
  AttandanceTableHead:any;
  AttandanceTableData:any
  gradebookListTableHead:any;
  gradebookTableData:any
  behaviourTableHead:any;
  behaviourTableData:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  gridContent: React.ReactNode[]; 
  gridContentBackPage:React.ReactNode[]; 

 
  screenName: string;
 
  steps: Array<IStepperStepProps>;
  searchParentText: string;
  isStepWithMultipleScreens?: boolean;
  openContentType:boolean
  openContentModal:boolean
  selectedGridIndex: number | null;
  selectedBlockType:string,
  dialogInput:string,
  isPreviewOpen:boolean,
  selectedDataBlockPlacement:string,
  

  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class EditGradeBookTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addNewUserAPIRequestId: string = "";
  curriculumListAPIRequestId: string = "";
  apiGetGradesListingId: string = "";
  apiGetSchoolYearDataId: string = "";
  searchParentListAPIRequestId: string = "";
  apiGetRoleNamesDataId: string = "";
  apiGetStudentCustomFieldsId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    const typeMode = this.props.templateType === "portrait" ? 5 :6
    this.state = {
      loading: false,
      gridContent: Array(typeMode).fill({ content: '+Edit', blockType: null }),
      gridContentBackPage: Array(typeMode).fill({ content: '+Edit', blockType: null }),
    
      openContentType:false,
      openContentModal:false,
      steps: [
        {
          id: "front-page-details",
          label: "Front Page",
        },
        {
          id: "back-page-details",
          label: "Back Page",
        },
      ],
      selectedDataBlockPlacement:"Grades",
     
      screenName: "front_back",
      searchParentText: "",
      isStepWithMultipleScreens: false,
      selectedGridIndex:null,
      selectedBlockType:"DataBlocks",
      dialogInput:"",
      isPreviewOpen:false
     
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    if(this.props.orientationType === this.props.templateType){
      this.tranformGridContent()
    }
   
    // Customizable Area End
  }
  // Customizable Area Start
  handleGetBlockTypeConvert = (selectedDataBlockPlacement:string)=>{
    let newContent = "";
    if (selectedDataBlockPlacement === "grades") {
      newContent = "Grades";
    } else if (selectedDataBlockPlacement === "behaviour") {
      newContent = "Behavior";
    } else if (selectedDataBlockPlacement === "attendence") {
      newContent = "Attendance";
    } else if (selectedDataBlockPlacement === "student_information") {
      newContent = "StudentInformation";
    }else if(selectedDataBlockPlacement === "TextEditor"){
      newContent = ""
    }
    return newContent
  }
  handleGetConvertBlockTypeContent = (selectedValue:string) => {
    let newContent = "";
    if (selectedValue === "data_blocks") {
      newContent = "DataBlocks";
    } else if (selectedValue === "text_editor") {
      newContent = "TextEditor";
    }
    return newContent;
  }
  handleGetContentForType = (selectedDataBlockPlacement:string,description:string)=>{
    let newContent;
    if (selectedDataBlockPlacement === "Grades") {
      newContent = <GradeTable gradebookTableData={this.props.gradebookTableData} gradebookListTableHead={this.props.gradebookListTableHead} />;
    } else if (selectedDataBlockPlacement === "Behavior") {
      newContent = <BehaviourTable  behaviourTableHead={this.props.behaviourTableHead} behaviourTableData={this.props.behaviourTableData}/>;
    } else if (selectedDataBlockPlacement === "Attendance") {
      newContent = <AttandanceTable AttandanceTableHead={this.props.AttandanceTableHead} AttandanceTableData={this.props.AttandanceTableData}/>;
    } else if (selectedDataBlockPlacement === "StudentInformation") {
      newContent = <StudentDetails />;
    }  else {
      newContent = description || "+Edit"
    }
    return newContent
  }

  tranformGridContent = () => {
    const backPageBlocks:any =[];
    const frontPageBlocks:any=[];

    if (Array.isArray(this.props.getDataOfTemplate)) {
        this.props.getDataOfTemplate.forEach((item: any) => {
          const index = this.props.templateType==="landscape" ? (item.row_number * 2 + item.column_number) : item.row_number;
          const blockType = this.handleGetBlockTypeConvert(item.data_block_content_type)
          const contentType = this.handleGetContentForType(blockType,item.description);
          const blockTypeContent = this.handleGetConvertBlockTypeContent(item.data_block_type)
            const block = {
                content:contentType,
                blockType: blockType,
                blockTypeContent:blockTypeContent
            };

            if (item.page_type === "back_page") {
                backPageBlocks[index] = block;
            } else if (item.page_type === "front_page") {
                frontPageBlocks[index] = block;
            }
        });
    } else {
        console.error("getDataOfTemplate is not an array or is undefined.");
    }
    this.setState({gridContent:frontPageBlocks,gridContentBackPage:backPageBlocks})
}
 
  handleGetBlockType = (selectedDataBlockPlacement:string)=>{
    let newContent = "";
    if (selectedDataBlockPlacement === "Grades") {
      newContent = "grades";
    } else if (selectedDataBlockPlacement === "Behavior") {
      newContent = "behaviour";
    } else if (selectedDataBlockPlacement === "Attendance") {
      newContent = "attendence";
    } else if (selectedDataBlockPlacement === "StudentInformation") {
      newContent = "student_information";
    }else if(selectedDataBlockPlacement === "TextEditor"){
      newContent = ""
    }
    return newContent
  }
  handleGetBlockTypeContent = (selectedValue:string) => {
    let newContent = "";
    if (selectedValue === "DataBlocks") {
      newContent = "data_blocks";
    } else if (selectedValue === "TextEditor") {
      newContent = "text_editor";
    }
    return newContent;
  }
  handleGetTemplateType = (selectedType:string)=>{
    let newContent = "";
    if (selectedType === "landscape") {
      newContent = "landscape_template";
    } else if (selectedType === "portrait") {
      newContent = "portrait_template";
    }
    return newContent;
  }
  transformData = (gradeBookId: number,includeTemplateDetails: boolean = false) => {
    const pageTypes = ["front_page", "back_page"]; 

    const templateContentTypesAttributesFrontPage = this.state.gridContent.map((item:any, index) => {
      let description;
      const row = this.props.templateType==="landscape"?Math.floor(index / 2):index;
      const column =this.props.templateType==="landscape"? index % 2:0;
      const blockType = this.handleGetBlockType(item.blockType);
      const blockTypeContent = this.handleGetBlockTypeContent(item.blockTypeContent)

      if(item.blockTypeContent){
       
        if(item.blockTypeContent === "TextEditor"){
          description=item.content
        }
        else{
          description=""
        }
      }
      return {
          data_block_type: blockTypeContent, // Replace with actual logic if needed
          data_block_content_type:blockType, // Replace with actual logic if needed
          row_number: row,
          column_number: column,
          description:description,
          page_type: pageTypes[0], // Assuming first 2 items are for front_page, next 2 for back_page
      };
    });
    const templateContentTypesAttributesBackPage = this.state.gridContentBackPage.map((item:any, index) => {
      let description; 
      const row = this.props.templateType==="landscape"?Math.floor(index / 2):index;
        const column =this.props.templateType==="landscape"? index % 2:0;
        const blockType = this.handleGetBlockType(item.blockType)
        const blockTypeContent = this.handleGetBlockTypeContent(item.blockTypeContent)
        if(item.blockTypeContent){
          if(item.blockTypeContent=== "TextEditor"){
            description=item.content
          }
          else{
            description=""
          }
        }

        return {
            data_block_type: blockTypeContent, // Replace with actual logic if needed
            data_block_content_type:blockType, // Replace with actual logic if needed
            row_number: row,
            column_number: column,
            description:description,
            page_type: pageTypes[1], // Assuming first 2 items are for front_page, next 2 for back_page
        };
    });

    const templateContentTypesAttributes = [...templateContentTypesAttributesBackPage,...templateContentTypesAttributesFrontPage]
   

    const requestBody:any = {
      grade_book_id: gradeBookId,
      template_content_types_attributes: templateContentTypesAttributes,
    }
    if(includeTemplateDetails){
      const orientation_type = this.handleGetTemplateType(this.props.templateType)
      requestBody.template_type = this.props.gradebookTemplateType;
      requestBody.orientation_type = orientation_type;
    }

    return requestBody;
  };

  handleSubmitDetails=()=>{
    let requestData;
    if(this.props.orientationType === this.props.templateType){
      requestData = this.transformData(this.props.gradeBookId);
      console.log("submit api",requestData)
      this.props.handleUpdateTemplateApi(requestData,this.props.getTemplateId);
    }else{
      requestData = this.transformData(this.props.gradeBookId, true);
      console.log("put apis",requestData)
      this.props.handlCreateTemplateApi(requestData);
    }
  }
  handleNextStepper = () => {
    const { screenName } = this.state;

    if (screenName === "front_back") {
      this.setState({
        screenName: "back_page",
        isStepWithMultipleScreens: false,
      });
    } else {
      this.setState({
        screenName: "front_back",
        isStepWithMultipleScreens: true,
      });
    }
  };

  handleOpenContentType= (index:number,blockType:string,blockTypeContent:string,content:string)=>{
    if(blockType === null){
      this.setState({dialogInput:"",openContentType: true,selectedGridIndex: index,selectedBlockType:"DataBlocks",selectedDataBlockPlacement:"Grades"})
    }else if (blockTypeContent === "TextEditor") {
      this.setState({dialogInput:content, openContentType: true,selectedGridIndex: index,selectedBlockType:blockTypeContent ,selectedDataBlockPlacement:blockType })
    }
    else{
    this.setState({dialogInput:"", openContentType: true,selectedGridIndex: index,selectedBlockType:blockTypeContent ,selectedDataBlockPlacement:blockType })
    }
  }
  handleCloseContentType=()=>{
    this.setState({ openContentType: false })
  }
  handlePreview = () => {
    this.setState({ isPreviewOpen: true });
  };

  handleOpenAddContent= ()=>{
    if(this.state.selectedBlockType === "DataBlocks"){


      const { selectedBlockType,selectedGridIndex, dialogInput, gridContent,gridContentBackPage, selectedDataBlockPlacement } =
      this.state;
     
      if (selectedGridIndex !== null) {
        const updatedContent = [...gridContent];
        const updatedContentBackPage = [...gridContentBackPage]
        let newContent;
    
        if (selectedDataBlockPlacement === "Grades") {
          newContent = <GradeTable gradebookTableData={this.props.gradebookTableData} gradebookListTableHead={this.props.gradebookListTableHead} />;
        } else if (selectedDataBlockPlacement === "Behavior") {
          newContent = <BehaviourTable  behaviourTableHead={this.props.behaviourTableHead} behaviourTableData={this.props.behaviourTableData}/>;
        } else if (selectedDataBlockPlacement === "Attendance") {
          newContent = <AttandanceTable AttandanceTableHead={this.props.AttandanceTableHead} AttandanceTableData={this.props.AttandanceTableData}/>;
        } else if (selectedDataBlockPlacement === "StudentInformation") {
          newContent = <StudentDetails />;
        } else {
          newContent = '+Edit';
        }
    
       
        if(this.state.screenName === "back_page"){
          updatedContentBackPage[selectedGridIndex] = { content: newContent, blockType: selectedDataBlockPlacement,blockTypeContent:selectedBlockType };
          this.setState({ dialogInput: "", gridContentBackPage: updatedContentBackPage, openContentType: false });
        }else{
          updatedContent[selectedGridIndex] = { content: newContent, blockType: selectedDataBlockPlacement,blockTypeContent:selectedBlockType };
          this.setState({ dialogInput: "", gridContent: updatedContent, openContentType: false });
        }
        
      }
    }
    else{
      this.setState({ openContentModal: true,openContentType:false })
    }
   
  }
  handleCloseAddContent=()=>{
    this.setState({ openContentModal: false })
  }
  handleClosePreview = () => {
    this.setState({ isPreviewOpen: false });
  };
  handleOpenPreview = () => {
    this.setState({ isPreviewOpen: true });
  };
  handlePreviousStepper = () => {
    const { screenName } = this.state;
    if (screenName === "back_page") {
     
      this.setState({ screenName: "front_back", isStepWithMultipleScreens: false });
    } else {
      
      this.setState({ screenName: "back_page" });
    }
  };
  handleActivityTypeRadioDynamicClass = (activityType: string) => {
    const classes = this.props.classes;
    if (this.state.selectedBlockType === activityType) {
      return `${classes.radio} ${classes.checked}`;
    } else {
      return `${classes.radioUnchecked} ${classes.checked}`;
    }
  }
  handleActivityTypeLabelDynamicClass = (activityType: string) => {
    const classes = this.props.classes;
    if (this.state.selectedBlockType === activityType) {
      return `${classes.radioButtonLabel} ${classes.checkedLabelColor}`;
    } else {
      return `${classes.radioButtonLabel}`;
    }
  }
  handleActivityTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedBlockType: event.target.value });
  }
  handleSaveDialog = () => {
    const { selectedGridIndex, gridContent, gridContentBackPage,selectedBlockType,dialogInput } =
      this.state;
     
    if (selectedGridIndex !== null) {
      const updatedContent = [...gridContent];
      const updatedContentBackPage= [...gridContentBackPage]
      if (selectedBlockType === "TextEditor") {
        updatedContent[selectedGridIndex] = {
          content:  dialogInput ,
          blockType: selectedBlockType,
          blockTypeContent:selectedBlockType
        };
        updatedContentBackPage[selectedGridIndex] = {
          content: dialogInput,
          blockTypeContent:selectedBlockType
        };
      }
      if(this.state.screenName === "back_page"){
        this.setState({ dialogInput: "", gridContentBackPage: updatedContentBackPage, openContentModal: false });
      }
      else{
      this.setState({ dialogInput: "", gridContent: updatedContent, openContentModal: false });
      }
    }
  };
  handleEditorChange = (content: any) => {
    this.setState({ dialogInput: content });
  
  }
  handleDataBlockTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedDataBlockPlacement: event.target.value });
  }

}
// Customizable Area End
