import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";

// Customizable Area Start
import { studentCoursesList, gradingComponent } from "../../assets/mockdata";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedCourseId: any;
  studentCoursesList: any;
  selectedSubjectTopicList: any;
  selectedCourse: any;
  gradingComponent: Array<any>;
  selectedGradingComponentDDValue: any;
  // Customizable Area End
}

export default class ParentTeacherDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      selectedCourseId: null,
      studentCoursesList: studentCoursesList,
      selectedSubjectTopicList: [],
      selectedCourse: null,
      selectedGradingComponentDDValue: '',
      gradingComponent: gradingComponent,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  handleChangeDropdownValue = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
  }>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  handleSelectCourse = (courseId: any) => {
    const { studentCoursesList } = this.state;
    const selectedCourse: any = studentCoursesList.find((course: any) => course?.id === courseId);
    this.setState({ selectedCourseId: courseId, selectedCourse })
  }

  renderGradingComponenttDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponent = gradingComponent.find((grade: any) => grade?.id === selected);
    return selectedGradingComponent?.label;
  };
  // Customizable Area End
}