// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import ParenTeacherGradebookController, {
  Props,
} from "./ParenTeacherGradebookController";
import CustomTable from "../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
// Customizable Area End

// Customizable Area Start
const themes = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
      },
    },
    MuiSelect: {
      icon: {
        right: "10px",
      },
      select: {
        minWidth: "140px",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        width: "16px",
        height: "16px",
      },
    },
    MuiFormControl: {
      root: {
        marginRight: "24px",
        width: "17.1%",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
});

const styles = (theme: Theme) =>
  createStyles({
    breadCrumbsRoot: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    customTableStyle: {
      borderRadius: "12px",
      padding: "15px 25px 25px 25px",
      width: "94%",
      "& th": {
        paddingLeft: "0px",
      },
      "& td": {
        paddingLeft: "0px",
      }
    },
    colorNames: {
      display: "flex",
      flexFlow: "row",
      justifyContent: "start",
      gap: "20px",
      marginTop: "25px",
    },
    colorBox: {
      width: "24px",
      height: "24px",
      borderRadius: "6px",
      marginRight: "10px",
    },
    colorLabel: {
      color: "#888",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      opacity: "0.6",
    },
    colorItem: {
      display: "flex",
      flexFlow: "row",
      alignItems: "center",
    }
  });
// Customizable Area End

// Customizable Area Start
export class ParenTeacherGradebook extends ParenTeacherGradebookController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { selectedSchoolYearValue, selectedCurriculumValue, selectedStudentValue, selectedSortByGpaValue } = this.state;
    const progressColors = [
      {
        label: "Locked In",
        color: "#01C996"
      },
      {
        label: "For Registrar Approval",
        color: "#0947BF"
      },
      {
        label: "For Advisor Approval",
        color: "#FBDD49"
      },
      {
        label: "Open",
        color: "#090909"
      }
    ];
    const tableActionMenu = [
      {
        label: 'View Student Profile',
        action: () => { }
      },
      {
        label: 'Open Gradebook',
        action: () => { }
      },
      {
        label: 'Print Unofficial',
        action: () => { }
      },
      {
        label: 'Print Official',
        action: () => { }
      }
    ];

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.secondaryBgColor} ${classes.highlightBgColor}`}
        height={"100%"}
        style={{ overflowX: "hidden" }}
      >
        <Box className={classes.breadCrumbsRoot}>
          <Typography className={`${classes.fontText14} ${classes.textCommonColor}`} component={"span"}>
            {`Gradebook`}
          </Typography>
        </Box>
        <Box marginBottom={"17px"}>
          <Box marginBottom={"13px"}>
            <Typography className={`${classes.fontText28} ${classes.textPrimaryColor} ${classes.fontBold600}`} component={"span"}>
              Gradebook
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={3} direction="row">
          <Grid item xs={12} lg={2} md={3}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                  School Year
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldName="selectedSchoolYearValue"
                  fieldId="selectSchoolYearDropdown"
                  fieldValue={selectedSchoolYearValue}
                  handleChange={this.handleChangeDropdownValue}
                  renderValue={this.renderSchoolYearDropdownValue}
                  fieldError={false}
                  fieldMenuItems={this.getDropdownItemsArray("2020-202")}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldStyles={dropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={2} md={3}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                  Curriculum
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldName="selectedCurriculumValue"
                  fieldId="selectCurriculumDropdown"
                  fieldValue={selectedCurriculumValue}
                  handleChange={this.handleChangeDropdownValue}
                  renderValue={this.renderCurriculumDropdownValue}
                  fieldError={false}
                  fieldMenuItems={this.getDropdownItemsArray("Curriculum")}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldStyles={dropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={2} md={3}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                  Student
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldName="selectedStudentValue"
                  fieldId="selectStudentDropdown"
                  fieldValue={selectedStudentValue}
                  handleChange={this.handleChangeDropdownValue}
                  renderValue={this.renderStudentDropdownValue}
                  fieldError={false}
                  fieldMenuItems={this.getDropdownItemsArray("Student")}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldStyles={dropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={2} md={3}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                  Sort By GPA
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldName="selectedSortByGpaValue"
                  fieldId="selectSortByGpaDropdown"
                  fieldValue={selectedSortByGpaValue}
                  handleChange={this.handleChangeDropdownValue}
                  renderValue={this.renderSortByGPADropdownValue}
                  fieldError={false}
                  fieldMenuItems={[{
                    id: 1,
                    label: `Ascending`,
                    value: `Ascending`,
                  },
                  {
                    id: 2,
                    label: `Decending`,
                    value: `Decending`,
                  }]}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldStyles={dropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box className={classes.colorNames}>
          {
            progressColors.map((item: any) => (
              <Box className={classes.colorItem}>
                <Typography className={classes.colorBox} style={{ background: item.color }}></Typography>
                <Typography component={"span"} className={classes.colorLabel}>
                  {item.label}
                </Typography>
              </Box>
            ))
          }
        </Box>

        <Box
          className={`${classes.customTableStyle} ${classes.bgColor}`}
          mt={4.5}
        >
          <Box className={classes.bgColor} pr={1} pb={2} style={{ borderRadius: "12px" }}>
            <ThemeProvider theme={themes}>
              <CustomTable
                tableId={"gradebook-listing-table"}
                tableBodyData={this.state.studentListTableBody}
                tableHeaderColumns={this.state.studentListTableColumns}
                showMenuIcon={true}
                handleAction={this.openUserDetailPage}
                actionButtonConfig={tableActionMenu}
              />
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const dropdownStyle = {
  width: "100%",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ParenTeacherGradebook);
// Customizable Area End
