// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getMessageData, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  id?: string;
  navigation?: any;
  detail: any;
  forTeacher?: boolean;
  questionList?: Array<any>;
  handleAddQuestion?: any;
  handleUpdateQuestion?: any;
  handleDeleteQuestion?: any;
  setSelectedQuestion?: any;
  isStudent?: boolean;
  isFromRegistrar?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  openSubjectiveQuestionModal: boolean;
  editQuestion: boolean;
  openObjectiveQuestionModal: boolean;
  selectedQuestionDetail: any;
  openDeleteQuestionConfirmModal: boolean;
  assignmentDetails: any;
  isMyCoursesPath:boolean
 
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AssignmentActivityComponentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiAssessmentActivityDetails: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.receive = this.receive.bind(this);


    this.state = {
      loading: false,
      openSubjectiveQuestionModal: false,
      editQuestion: false,
      openObjectiveQuestionModal: false,
      selectedQuestionDetail: "",
      openDeleteQuestionConfirmModal: false,
      assignmentDetails: "",
      isMyCoursesPath:false
    };


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    const urlPath = new URL(document.URL);    
    const currentPath = urlPath.pathname.toLowerCase();      
    this.setState({ isMyCoursesPath: currentPath === '/mycourses' });
  }

  handleGetAssignmentDetailsApi = async () => {
    showCustomLoader();
    const baseUrl = `assignmentDetails`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAssessmentActivityDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAssignmentDetailsResponse = (responseJson: any, errorResponse: any) => {
    this.setState({ assignmentDetails: responseJson });
    console.log("errorResponse", errorResponse)
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Recieive: " + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiAssessmentActivityDetails:
            this.handleAssignmentDetailsResponse(responseJson, errorResponse)
        }
      }
    }
  }

  handleCloseSubjectiveQuestionModal = () => {
    this.setState({ openSubjectiveQuestionModal: false });
  }

  handleGoToAssessmentDueScreen = (typeOfAssessment: string, title: string) => {
    this.props.navigation?.navigate("StudentDueAssessmentMain", {}, {
      typeOfAssessment: typeOfAssessment,
      title: title,
    });
  }

  handleOpenSubjectiveQuestionModal = () => {
    this.setState({ openSubjectiveQuestionModal: true });
  }

  handleAddSubjectiveQuestion = () => {
    this.setState({ editQuestion: false });
    this.handleOpenSubjectiveQuestionModal();
  }

  handleEditSubjectiveQuestion = (id: any) => {
    const { questionList } = this.props;
    const selectedAssignmentSubjectiveQuestion = questionList?.find(
      (item: any) => parseInt(item.id) === parseInt(id)
    );
    this.setState({ selectedQuestionDetail: selectedAssignmentSubjectiveQuestion }, () => {
      this.setState({ editQuestion: true });
      this.props.setSelectedQuestion(selectedAssignmentSubjectiveQuestion?.id);
      this.handleOpenSubjectiveQuestionModal();
    });
  }

  handleOpenObjectiveQuestionModal = () => {
    this.setState({ openObjectiveQuestionModal: true });
  }

  handleCloseObjectiveQuestionModal = () => {
    this.setState({ openObjectiveQuestionModal: false });
  }

  handleEditObjectiveQuestion = (id: any) => {
    const { questionList } = this.props;
    const selectedAssignmentQuestion = questionList?.find(
      (item: any) => parseInt(item.id) === parseInt(id)
    );
    this.setState({ selectedQuestionDetail: selectedAssignmentQuestion }, () => {
      this.setState({ editQuestion: true });
      this.props.setSelectedQuestion(selectedAssignmentQuestion?.id);
      if (this.props.detail.objectiveType === "selection") {
        this.handleOpenObjectiveQuestionModal();
      } else if (this.props.detail.objectiveType === "written") {
        this.handleOpenSubjectiveQuestionModal();
      }
    });
  };

  handleDeleteAssignmentObjectiveQuestion = (id: any) => {
    const { questionList } = this.props;
    const selectedQuestion = questionList?.find(
      (item: any) => parseInt(item.id) === parseInt(id)
    );
    this.setState({ selectedQuestionDetail: selectedQuestion }, () => {
      this.setState({ openDeleteQuestionConfirmModal: true });
      this.props.setSelectedQuestion(selectedQuestion?.id);
    });
  };

  handleCloseAssignmentDeleteConfirmModal = () => {
    this.setState({ openDeleteQuestionConfirmModal: false });
  };

  handleConfirmAssignmentDeleteQuestion = () => {
    this.setState({
      openDeleteQuestionConfirmModal: false,
      openObjectiveQuestionModal: false,
      openSubjectiveQuestionModal: false,
    });
    this.props.handleDeleteQuestion();
  };

  handleSaveQuiz = () => {
    console.log("Save");
  }

  handleAddObjectiveQuestion = () => {
    this.setState({ editQuestion: false });
    if (this.props.detail.objectiveType === "selection") {
      this.handleOpenObjectiveQuestionModal();
    } else if (this.props.detail.objectiveType === "written") {
      this.handleOpenSubjectiveQuestionModal();
    }
  }

  checkObjectiveType = () => {
    if (this.props.detail.objectiveType === "selection") {
      return false;
    } else if (this.props.detail.objectiveType === "written") {
      return true;
    } else {
      return false;
    }
  }

  handleAddAssignmentQuestion = (data: any) => {
    this.handleCloseSubjectiveQuestionModal();
    this.handleCloseObjectiveQuestionModal();
    this.props.handleAddQuestion(data);
  };

  handleUpdateAssignmentQuestion = (data: any) => {
    this.handleCloseObjectiveQuestionModal();
    this.handleCloseSubjectiveQuestionModal();
    this.props.handleUpdateQuestion(data);
  };
  // Customizable Area End
}
// Customizable Area End