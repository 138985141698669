import React from "react";

// Customizable Area Start
import { Grid, Box, Typography } from "@material-ui/core";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import AddEditTenantRolesAndPermissionsController, {
  Props,
} from "./AddEditTenantRolesAndPermissionsController.web";
import { IInputConfigProps } from "../../../components/src/CommonType.web";
import CustomTextFieldWeb from "../../../components/src/CustomTextField.web";
import CustomCheckBoxComponent from "../../../components/src/CustomCheckBoxComponent.web";
import CheckboxCheckIcon from "../../../components/src/SVGIcons/CheckboxCheckIcon.web";
import CheckboxUnCheckIcon from "../../../components/src/SVGIcons/CheckboxUnCheckIcon.web";
import DashboardIcon from "../../../components/src/SVGIcons/DashboardIcon.web";
import GearIcon from "../../../components/src/SVGIcons/GearIcon.web";
import UserIcon from "../../../components/src/SVGIcons/UserIcon.web";
import MyCoursesIcon from "../../../components/src/SVGIcons/MyCoursesIcon.web";
import RolesPermissionsIcon from "../../../components/src/SVGIcons/RolesPermissionsIcon.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DeleteRolesPermissionsModalWeb from "./DeleteRolesPermissionsModal.web";
import AppearanceMngIcon from "../../../components/src/SVGIcons/AppearanceMngIcon.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "15px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    AddEditTenantRolesAndPermissionsTopHeader: {
      display: "flex",
      alignItems: "center",
    },
    rolePermissionsBox: {
      marginTop: "20px",
      borderRadius: "10px",
      overflow: "auto",
    },
    permissionBox: {
      border: "1px solid rgba(224, 224, 224, 1)",
      borderRadius: "10px",
      marginTop: "20px",
    },
    permissionBoxHeader: {
      display: "flex",
      padding: "15px 20px",
      backgroundColor: "#F2F9FF",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    textfieldBox: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "98%",
      },
    },
    inputConfigGrid: {
      padding: "10px 0px",
    },
    permissionBoxGridItem: {
      padding: "20px",
      borderBottom: "1px solid #f1f1f1",
      [theme.breakpoints.down("xs")]: {
        width: "500px",
      },
    },
    read: {
      marginRight: "70px",
    },
    permissionRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #f1f1f1",
    },
    permissionRowPadding1: {
      padding: "20px 63px 20px 20px",
    },
    permissionRowPadding2: {
      padding: "15px 40px 15px 20px",
    },
    permissionRolesMainBox: {
      [theme.breakpoints.down("xs")]: {
        width: "500px",
        overflow: "auto",
      },
    },
  });

const getTopSmallHeaderSubRoute = (isViewOrEdit: any, role?: string) => {
  if (isViewOrEdit) return role;
  return "Add New Role";
};

const getIcon = (screen: string) => {
  switch (screen) {
    case "dashboard":
      return (
        <DashboardIcon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke={"#2C98EE"}
          strokeWidth={"1.5"}
          fill={"#2C98EE"}
        />
      );
    case "setup":
      return (
        <GearIcon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke={"#2C98EE"}
          strokeWidth={"0.55"}
          fill={"#2C98EE"}
        />
      );
    case "user_management":
      return (
        <UserIcon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke={"#2C98EE"}
          strokeWidth={"0.55"}
          fill={"#2C98EE"}
        />
      );
    case "course_management":
      return (
        <MyCoursesIcon
          width="28"
          height="18"
          viewBox="0 0 28 18"
          stroke={"#2C98EE"}
          strokeWidth={"0.55"}
          fill={"#2C98EE"}
        />
      );
    case "roles_permissions":
      return (
        <RolesPermissionsIcon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke={"#2C98EE"}
          strokeWidth={"0.55"}
          fill={"#2C98EE"}
        />
      );
    case "appearance_management":
      return (
        <AppearanceMngIcon
          viewBox="0 0 512 512"
          stroke={"#2C98EE"}
          strokeWidth={"0.55"}
          fill={"#2C98EE"}
        />
      );
    default:
      return "";
  }
};

// Customizable Area End

export class AddEditTenantRolesAndPermissions extends AddEditTenantRolesAndPermissionsController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      isEdit,
      isView,
      roleFromUrl,
      permissionsList,
      permissionsInputConfig,
      openDeleteConfirmModal,
    } = this.state;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                Roles and Permissions
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textButton}`}
              >
                {` / ${getTopSmallHeaderSubRoute(
                  isEdit || isView,
                  roleFromUrl
                )}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box
              className={`${classes.AddEditTenantRolesAndPermissionsTopHeader}`}
            >
              <Box mr={"8px"}>
                <KeyboardBackspaceIcon
                  fontSize="large"
                  data-testid="add-edit-role-permissions-go-back-icon"
                  onClick={() => this.handleGoBackToMainScreen()}
                  className={`${classes.textPrimaryColor} ${classes.cursorPointer}`}
                />
              </Box>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
                component={"span"}
              >
                {isEdit || isView ? `${roleFromUrl}` : "Add New Role"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={`${classes.rolePermissionsBox} ${classes.bgColor}`}>
              <Grid
                className={`${classes.permissionBoxGridItem}`}
                container
                direction="row"
              >
                {permissionsInputConfig.map((inputItem: IInputConfigProps) => (
                  <Grid
                    className={`${classes.inputConfigGrid}`}
                    key={inputItem.id}
                    item
                    xs={6}
                    md={6}
                  >
                    <Box marginBottom={"10px"}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText16}`}
                        id={`appearance-management-branding-${inputItem.id}`}
                        component={"span"}
                      >
                        {inputItem.label}
                      </Typography>
                    </Box>
                    <Box className={`${classes.textfieldBox}`}>
                      <CustomTextFieldWeb
                        fieldName={inputItem.fieldName}
                        fieldWidth={"100%"}
                        fieldId={inputItem.id}
                        fieldValue={inputItem.value}
                        isDisabled={inputItem.isDisabled}
                        textFieldStyles={textfieldStyles}
                        placeHolderText={inputItem.placeHolderText}
                        fieldType="text"
                        handleChange={this.handleTextChange}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box className={`${classes.permissionRolesMainBox}`}>
                <Box>
                  <Box
                    className={`${classes.permissionRow} ${classes.permissionRowPadding1}`}
                  >
                    <Box>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText16}`}
                        component={"span"}
                      >
                        Permissions
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.read} ${classes.fontBold600}`}
                        component={"span"}
                      >
                        Read
                      </Typography>
                      <Typography
                        className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                        component={"span"}
                      >
                        Write
                      </Typography>
                    </Box>
                  </Box>
                  <Box padding={"0px 20px 20px 20px"}>
                    {permissionsList.map((item: any) => (
                      <Box
                        className={`${classes.permissionBox}`}
                        key={`${item.id} - ${item.baseCategoryTitle}`}
                      >
                        <Box className={`${classes.permissionBoxHeader}`}>
                          <Box marginRight={"10px"}>{getIcon(item.icon)}</Box>
                          <Typography
                            className={`${classes.fontText18} ${classes.primaryColor16} ${classes.fontBold600}`}
                            component={"span"}
                          >
                            {item.baseCategoryTitle}
                          </Typography>
                        </Box>
                        {item.subCategory.length ? (
                          <>
                            {item.subCategory.map((subCategoryItem: any) => (
                              <Box
                                key={`${subCategoryItem.id}-${subCategoryItem.subCategoryTitle}`}
                              >
                                <Box
                                  className={`${classes.secondaryBgColor}`}
                                  padding={"14px 15px"}
                                >
                                  <Typography
                                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold600}`}
                                    component={"span"}
                                  >
                                    {subCategoryItem.subCategoryTitle}
                                  </Typography>
                                </Box>
                                {subCategoryItem.subItems.length > 0 && (
                                  <>
                                    {subCategoryItem.subItems.map(
                                      (subMenuItem: any) => (
                                        <Box
                                          key={`${subMenuItem.id}-${subMenuItem.subItemTitle}`}
                                          className={`${classes.permissionRow} ${classes.permissionRowPadding2}`}
                                        >
                                          <Box>
                                            <Typography
                                              className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                                              component={"span"}
                                            >
                                              {subMenuItem.subItemTitle}
                                            </Typography>
                                          </Box>
                                          <Box
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                          >
                                            <Box className={`${classes.read}`}>
                                              <CustomCheckBoxComponent
                                                fieldId={`read-${subMenuItem.id}-${subMenuItem.subItemTitle}`}
                                                checked={
                                                  subMenuItem.isReadChecked
                                                }
                                                checkedIcon={
                                                  <CheckboxCheckIcon
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    stroke={"#fff"}
                                                    strokeWidth="1.5"
                                                    fill={"#FBD82C"}
                                                  />
                                                }
                                                uncheckIcon={
                                                  <CheckboxUnCheckIcon
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    stroke={"#FBD82C"}
                                                    strokeWidth="1.5"
                                                    fill={"none"}
                                                  />
                                                }
                                                handleChange={() =>
                                                  this.handleUpdateReadPermissions(
                                                    item.id,
                                                    subMenuItem.id,
                                                    subCategoryItem.id
                                                  )
                                                }
                                              />
                                            </Box>
                                            <CustomCheckBoxComponent
                                              fieldId={`write-${subMenuItem.id}-${subMenuItem.subItemTitle}`}
                                              checked={
                                                subMenuItem.isWriteChecked
                                              }
                                              checkedIcon={
                                                <CheckboxCheckIcon
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  stroke={"#fff"}
                                                  strokeWidth="1.5"
                                                  fill={"#FBD82C"}
                                                />
                                              }
                                              uncheckIcon={
                                                <CheckboxUnCheckIcon
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  stroke={"#FBD82C"}
                                                  strokeWidth="1.5"
                                                  fill={"none"}
                                                />
                                              }
                                              handleChange={() =>
                                                this.handleUpdateWritePermissions(
                                                  item.id,
                                                  subMenuItem.id,
                                                  subCategoryItem.id
                                                )
                                              }
                                            />
                                          </Box>
                                        </Box>
                                      )
                                    )}
                                  </>
                                )}
                              </Box>
                            ))}
                          </>
                        ) : (
                          <>
                            {item.subItems.length > 0 && (
                              <>
                                {item.subItems.map((subMenuItem: any) => (
                                  <Box
                                    key={`${subMenuItem.id}-${subMenuItem.subItemTitle}`}
                                    className={`${classes.permissionRow} ${classes.permissionRowPadding2}`}
                                    data-testid="permissions-submenu-item-box"
                                  >
                                    <Box data-testid="permissions-submenu-item-label">
                                      <Typography
                                        className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                                        component={"span"}
                                      >
                                        {subMenuItem.subItemTitle}
                                      </Typography>
                                    </Box>
                                    <Box
                                      data-testid="permissions-submenu-item-checkbox"
                                      display={"flex"}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                    >
                                      <Box className={`${classes.read}`}>
                                        <CustomCheckBoxComponent
                                          fieldId={`read-${subMenuItem.id}-${subMenuItem.subItemTitle}`}
                                          checked={subMenuItem.isReadChecked}
                                          checkedIcon={
                                            <CheckboxCheckIcon
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              stroke={"#fff"}
                                              strokeWidth="1.5"
                                              fill={"#FBD82C"}
                                            />
                                          }
                                          uncheckIcon={
                                            <CheckboxUnCheckIcon
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              stroke={"#FBD82C"}
                                              strokeWidth="1.5"
                                              fill={"none"}
                                            />
                                          }
                                          handleChange={() =>
                                            this.handleUpdateReadPermissions(
                                              item.id,
                                              subMenuItem.id
                                            )
                                          }
                                        />
                                      </Box>
                                      <CustomCheckBoxComponent
                                        fieldId={`write-${subMenuItem.id}-${subMenuItem.subItemTitle}`}
                                        checked={subMenuItem.isWriteChecked}
                                        checkedIcon={
                                          <CheckboxCheckIcon
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke={"#fff"}
                                            strokeWidth="1.5"
                                            fill={"#FBD82C"}
                                          />
                                        }
                                        uncheckIcon={
                                          <CheckboxUnCheckIcon
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke={"#FBD82C"}
                                            strokeWidth="1.5"
                                            fill={"none"}
                                          />
                                        }
                                        handleChange={() =>
                                          this.handleUpdateWritePermissions(
                                            item.id,
                                            subMenuItem.id
                                          )
                                        }
                                      />
                                    </Box>
                                  </Box>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              marginTop={"20px"}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              {!(isView || isEdit) && (
                <CustomButton
                  btnText="Add"
                  btnStyle={btnStyle}
                  buttonId={`add-role-permissions`}
                  buttonVariant={"contained"}
                  type={"button"}
                  handleButtonClick={this.handleGoBackToMainScreen}
                  isDisabled={false}
                />
              )}
              {isView && !isEdit && (
                <CustomButton
                  btnText="Edit"
                  btnStyle={btnStyle}
                  buttonId={`edit-role-permissions`}
                  buttonVariant={"contained"}
                  type={"button"}
                  handleButtonClick={() => this.handleEditNewRole()}
                  isDisabled={false}
                />
              )}
              {isEdit && (
                <>
                  <CustomButton
                    btnText="Delete"
                    btnStyle={deleteBtnStyle}
                    buttonId={`delete-role-permissions`}
                    buttonVariant={"contained"}
                    type={"button"}
                    handleButtonClick={this.handleOpenDeleteRolePermission}
                    isDisabled={false}
                  />
                  <CustomButton
                    btnText="Save"
                    btnStyle={btnStyle}
                    buttonId={`save-role-permissions`}
                    buttonVariant={"contained"}
                    type={"button"}
                    handleButtonClick={this.handleGoBackToMainScreen}
                    isDisabled={false}
                  />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        <DeleteRolesPermissionsModalWeb
          handleDelete={this.handleDeleteRolePermissionConfirm}
          handleClose={this.handleDeleteRolePermissionCancel}
          open={openDeleteConfirmModal}
          role={roleFromUrl}
        />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const btnStyle = {
  width: "160px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;

const deleteBtnStyle = {
  width: "160px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
  color: "#ffffff",
  backgroundColor: "#ED1111",
  marginRight: "15px",
} as const;

const textfieldStyles = {
  width: "100%",
  padding: "8px 0px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddEditTenantRolesAndPermissions);
// Customizable Area End
