// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getMessageData, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id?: string;
  navigation?: any;
  classes?: any;
  forTeacher?: boolean;
  detail: any;
  questionList?: Array<any>;
  handleAddQuestion?: any;
  handleUpdateQuestion?: any;
  handleDeleteQuestion?: any;
  setSelectedQuestion?: any;
  isFromRegistrar?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  editQuestion: boolean;
  openSubjectiveQuestionModal: boolean;
  selectedSubjectiveQuestionDetail: any;
  openDeleteQuestionConfirmModal: boolean;
  projectDetails: any;
  isMyCoursesPath:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ProjectActivityComponentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiProjectAssessmentActivityDetails: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.state = {
      loading: false,
      editQuestion: false,
      openSubjectiveQuestionModal: false,
      selectedSubjectiveQuestionDetail: "",
      openDeleteQuestionConfirmModal: false,
      projectDetails: "",
      isMyCoursesPath:false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  // Customizable Area Start

  handleSaveQuiz = () => {
    console.log("Save");
  }

  handleProjectAssessmentDetailsApi = async () => {
    showCustomLoader();
    const baseUrl = `examDetails`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProjectAssessmentActivityDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleProjectAssessmentDetailsResponse = (responseJson: any, errorResponse: any) => {
    this.setState({ projectDetails: responseJson });
    console.log("project Activity errorResponse", errorResponse)
  }

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.apiProjectAssessmentActivityDetails){
          this.handleProjectAssessmentDetailsResponse(responseJson, errorResponse)
        }
      }
    }
  }

  handleAddSubjectiveQuestion = () => {
    this.setState({ editQuestion: false });
    this.handleOpenSubjectiveQuestionModal();
  }

  handleCloseSubjectiveQuestionModal = () => {
    this.setState({ openSubjectiveQuestionModal: false });
  }

  async componentDidMount() {
    await super.componentDidMount();
    const urlPath = new URL(document.URL);    
    const currentPath = urlPath.pathname.toLowerCase();      
    this.setState({ isMyCoursesPath: currentPath === '/mycourses' });
  }

  handleDeleteProjectQuestion = (id: any) => {
    const { questionList } = this.props;
    const selectedProjectSubjectiveQuestion = questionList?.find((item: any) => parseInt(item.id) === parseInt(id));
    this.setState({ selectedSubjectiveQuestionDetail: selectedProjectSubjectiveQuestion }, () => {
      this.setState({ openDeleteQuestionConfirmModal: true });
      this.props.setSelectedQuestion(selectedProjectSubjectiveQuestion?.id);
    });
  }

  handleCloseQuizDeleteConfirmModal = () => {
    this.setState({ openDeleteQuestionConfirmModal: false });
  }

  handleConfirmQuizDeleteQuestion = () => {
    this.setState({ openDeleteQuestionConfirmModal: false, openSubjectiveQuestionModal: false });
    this.props.handleDeleteQuestion();
  }

  handleEditSubjectiveQuestion = (id: any) => {
    const { questionList } = this.props;
    const selectedProjectQuestion = questionList?.find((item: any) => parseInt(item.id) === parseInt(id));
    this.setState({ selectedSubjectiveQuestionDetail: selectedProjectQuestion }, () => {
      this.setState({ editQuestion: true });
      this.handleOpenSubjectiveQuestionModal();
      this.props.setSelectedQuestion(selectedProjectQuestion?.id);
    });
  }

  handleOpenSubjectiveQuestionModal = () => {
    this.setState({ openSubjectiveQuestionModal: true });
  }

  handleProjectAddQuestion = (data: any) => {
    this.handleCloseSubjectiveQuestionModal();
    this.props.handleAddQuestion(data);
  }

  handleProjectUpdateQuestion = (data: any) => {
    this.handleCloseSubjectiveQuestionModal();
    this.props.handleUpdateQuestion(data);
  }
  
  // Customizable Area End
}
// Customizable Area End